import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KaService } from './ka.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicantService } from 'src/app/applicant.service';
import { MatDialog } from '@angular/material/dialog';
import { DynamicModalComponent } from 'src/app/dynamic-modal/dynamic-modal.component';
import { KaLandVerifyComponent } from './ka-land-verify/ka-land-verify.component';
import { JourneyType, Milestone } from '../../enum';

@Component({
  selector: 'app-land-records-ka',
  templateUrl: './land-records-ka.component.html',
  styleUrls: ['./land-records-ka.component.scss']
})
export class LandRecordsKaComponent {
  bhoomiForm: FormGroup;
  districts:any;
  taluks:any;
  hoblis: any;
  village:any;
  surnoc_num:any;
  hissa_num:any;
  rtc_period:any;
  btnTxt:any = "ಹಿಸ್ಸಾಸ್ ಅನ್ನು ತರಲು(Fetch Hissas)";
  showFetch:boolean;
  periodFetch:boolean;
  hissaFetch:boolean;
  surnocFetch:boolean;
  hobliFetch:boolean;
  talukFetch:boolean;
  distFetch:boolean;
  distictValue: any;
  talukValue: any;
  hobliValue: any;
  villages: any;
  surveyNo: any;
  hissas: any;
  villageValue: any;
  isSurveyNoReadOnly: boolean;
  showHissas: boolean;
  hissaValue: any;
  showPeriods: boolean;
  disablePeriods: boolean = true;
  disableSubmit: boolean = true;
  periodValue: any;
  showSpinner:boolean;
  @Output() value = new EventEmitter();
  @Input() landRecordsList: any;
  @Input() isResume:boolean;
  @Input() milestone:string;
  landDetailsArray: any = [];
  showLandVerifiedTick: boolean;
  districtReadOnly: boolean;
  showDropdowns:boolean = true;
  isLandAreaReadOnly:boolean;
  landArea: any;
  kaLandArea:any;
  fetchDisabled: boolean ;
  currentLandRecordIndex: any;

  constructor(private fb:FormBuilder, private bhoomi :KaService, private snackbar: MatSnackBar, private applicant:ApplicantService, private dialog:MatDialog){
    this.bhoomiForm = this.fb.group({
      distId: ['', Validators.required],
      talId: ['', Validators.required],
      hobId: ['', Validators.required],
      village: ['', Validators.required],
      surveyno: ['', Validators.required],
      surnoc: [''],
      hisNo: ['', ],
      rtcPeriod: ['', ],
      landArea: ['',]
    });
  }

  ngOnInit(){
    this.getDistricts()
    this.showSpinner = true;
    // debugger
    if(this.milestone == Milestone.PAN_VERIFICATION_SUCCESS && sessionStorage.getItem('jrnyType') == JourneyType.RENEWAL ){
      this.fetchDisabled = false;
      this.disableSubmit = false;
    }
   
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['landRecordsList']) {
      console.log('Data updated:', changes['landRecordsList']);
      console.log(this.landRecordsList)
      this.updateLandsTable()
    }
  }

  updateLandsTable(){
    if(this.landRecordsList && this.landRecordsList.length && this.isResume){
      let formattedLRArray:[] = this.landRecordsList.map((item)=>{
        console.log(item)
        let land = {};
      //  this.landArea = this.kaVerify.landArea;
        land['district'] = item.districtName;
        land['taluk'] = item.talukName;
        land['hobli'] = item.hobliName;
        land['village'] = item.villageName;
        land['surveyNo'] = item.surveyNo;
        land['hissaNo'] = item.hissaNo;
        land['period'] = item.period;
        land['storageId'] = item.storageId;
        land['verified'] = true;

        land['surveyNoArea'] = item.landArea?item.landArea:'-';
        sessionStorage.setItem('storageID',item.storageId)
        return land;
      })
      this.landDetailsArray = formattedLRArray;
      this.showLandVerifiedTick = true;
      this.districtReadOnly = true;
    }
  }

  getDistricts(){
    this.showSpinner = true;
    this.bhoomi.getDistricts().subscribe((resp)=>{
      console.log(resp)
      this.showSpinner = false;
      if(resp && resp.data && resp.data.length){
        this.districts = resp.data
      }

    },(err)=>{
      this.snackbar.open("Failed to fetch districts.","OK")
      this.showSpinner = false;
    })
  }

  getTaluks(value){
    console.log(value)
    this.distictValue = value;
    this.showSpinner = true;
    this.bhoomi.getTaluks(this.distictValue).subscribe((resp)=>{
      console.log(resp)
      this.showSpinner = false;
      if(resp && resp.data && resp.data.length){
        this.taluks = resp.data
      }

    },(err)=>{
      console.log(err)
      this.snackbar.open("Failed to fetch Taluks.","OK")
      this.showSpinner = false;
    })
  }

  getHobli(value){
    this.talukValue = value;
    this.showSpinner = true;
    this.bhoomi.getHoblis(this.distictValue,this.talukValue).subscribe((resp)=>{
      console.log(resp)
      this.showSpinner = false;
      if(resp && resp.data && resp.data.length){
        this.hoblis = resp.data
      }

    },(err)=>{
      this.snackbar.open("Failed to fetch Hoblis.","OK")
      this.showSpinner = false;
    })
  }

  getVillage(value){
    this.hobliValue = value;
    this.showSpinner = true;
    this.bhoomi.getVillages(this.distictValue,this.talukValue,this.hobliValue).subscribe((resp)=>{
      console.log(resp)
      this.showSpinner = false;
      if(resp && resp.data && resp.data.length){
        this.villages = resp.data
      }

    },(err)=>{
      this.snackbar.open("Failed to fetch Villages.","OK")
      this.showSpinner = false;
    })
  }

  setVillage(value){
    console.log(value)
    this.villageValue = value;
  }

  verify(value){
    this.showSpinner = true;
    if(this.btnTxt == "ಹಿಸ್ಸಾಸ್ ಅನ್ನು ತರಲು(Fetch Hissas)"){
      this.surveyNo = value.surveyno;
      this.bhoomi.getHissas(this.distictValue,this.talukValue,this.hobliValue,this.villageValue, this.surveyNo).subscribe((resp)=>{
        console.log(resp)
        this.showSpinner = false;
        if(resp && resp.data && resp.data.length){
          this.hissas = resp.data
          this.btnTxt = "ಭೂ ದಾಖಲೆಗಳನ್ನು ಪಡೆದುಕೊಳ್ಳಿ(Fetch Land Records)"
          this.isSurveyNoReadOnly = true;
          this.showHissas = true;
          this.showPeriods = true;
          this.disablePeriods = true;
          this.bhoomiForm.controls['hisNo'].setValidators(Validators.required)
          this.bhoomiForm.controls['rtcPeriod'].setValidators(Validators.required)
          this.bhoomiForm.controls['landArea'].setValidators(Validators.required)
        }

      },(err)=>{
        this.snackbar.open("Failed to fetch Hissas.","OK")
        this.showSpinner = false;
      })
    }else{
      this.getLandRecords(value);
    }

  }

  getPeriod(value){
    this.hissaValue = value;
    this.showSpinner = true;
    this.bhoomi.getPeriods(this.distictValue,this.talukValue,this.hobliValue,this.villageValue, this.surveyNo,this.hissaValue).subscribe((resp)=>{
      console.log(resp);
      this.showSpinner = false;
      if(resp && resp.data && resp.data.length){
        this.disablePeriods = false;
        this.rtc_period = resp.data;
      }
    },(err)=>{
      this.snackbar.open("Failed to fetch Periods.","OK")
      this.showSpinner = false;
    })

  }

  getLandRecords(value) {
    this.periodValue = value.rtcPeriod;
    //this.landArea = value.landArea;
    this.showSpinner = true;
    this.bhoomi.fetchLandRecord(this.distictValue, this.talukValue, this.hobliValue, this.villageValue, this.surveyNo, this.hissaValue, this.periodValue).subscribe((resp) => {
     console.log(resp)
      this.showSpinner = false;
      if (resp && resp.data && resp.data) {
        this.disableSubmit = true;
        // let mimeType;
        // // if (this.isPdfBase64(resp.data)) {
        // //   mimeType = 'application/pdf';
        // // } else if (this.isPngBase64(resp.data)) {
        //   mimeType = 'image/png';
        // // } else {
        // //   console.error('Invalid base64 data format');
        // //   return;
        // // }
        // const blob = new Blob([this.base64ToArrayBuffer(resp.data)], { type: mimeType });
        // const url = window.URL.createObjectURL(blob);
        // const anchor = document.createElement('a');
        // anchor.href = url;
        // anchor.download = 'Land-Record.' + (mimeType === 'application/pdf');
        // anchor.click();
        // window.URL.revokeObjectURL(url);
       // this.landArea = this.kaVerify.landArea;
        let land = {};
        land['district'] = resp.data.districtName;
        land['taluk'] = resp.data.talukName;
        land['hobli'] = resp.data.hobliName;
        land['village'] = resp.data.villageName;
        land['surveyNo'] = resp.data.surveyNo;
        land['hissaNo'] = resp.data.hissaNo;
        land['period'] = resp.data.period;
        land['storageId'] = resp.data.storageId;

        land['surveyNoArea'] = resp.data.kaLandArea;

        this.landDetailsArray.push(land)
        sessionStorage.setItem('selectedDistrict',JSON.stringify({name:this.landDetailsArray[0].district}))
        sessionStorage.setItem('storageID',this.landDetailsArray[0].storageId)
       // sessionStorage.setItem('landArray', JSON.stringify(this.landDetailsArray));
      }
    },(err)=>{
      this.snackbar.open("Failed to fetch Land records.","OK")
      this.showSpinner = false;
    });
  }

  openLR(i) {
    this.showSpinner = true;
    let storageID = this.landDetailsArray[i].storageId;
    sessionStorage.setItem("storageID",storageID);
    this.bhoomi.getLoanDocument(storageID).subscribe((resp: Blob)=>{
      const file = new Blob([resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      this.showSpinner = false;
      window.open(fileURL); // Open the PDF in a new tab/window
    },(err)=>{
      this.showSpinner = false;
      this.snackbar.open("Unable to open the document","OK",{duration:3000})
    })
  }

  deleteLR(i){
    this.showSpinner = true;
    let storageID = this.landDetailsArray[i].storageId;
    this.bhoomi.deleteLandRecord(storageID).subscribe((resp)=>{
      console.log(resp)
      if(resp && resp.success){
        this.landDetailsArray.splice(i,1)
        this.showSpinner = false;
      }

    },(err)=>{
      this.showSpinner = false;
      this.snackbar.open("Unable to open the document","OK",{duration:3000})
    })
  }

  submitLR(){
    this.showSpinner = true;
    this.disableSubmit = false;
    this.bhoomi.submitLR().subscribe((resp)=>{
      this.showSpinner = false;
      if(resp && resp.success){
        this.disableSubmit = true;
        this.landRecordsDone();
        this.showLandVerifiedTick = true;
      }else{
        this.showSpinner = false;
        this.snackbar.open("Unable to Submit Land Records","OK",{duration:3000})
      }
    },(err)=>{
      this.showSpinner = false;
      this.snackbar.open("Unable to Submit Land Records","OK",{duration:3000})
    })
  }

  verifyLR(i){

  }

  landRecordsDone() {
    this.fetchDisabled = true;
    this.districtReadOnly = true;


    let data = {
      success: true,
      landRecords: this.landDetailsArray
    }
    this.value.emit(data)
  }

  openVerifyLandRecord(i){
    this.currentLandRecordIndex = i;
    let dialogRef = this.dialog.open(KaLandVerifyComponent, {
      disableClose: true,
      height: "auto",
      width: "auto",
      data: this.landDetailsArray[i],
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      console.log("Result from KA Verify", res)
      if(res && res.success){
        this.landDetailsArray[i].verified = true;
        this.landDetailsArray[i].surveyNoArea = res.data[i].landArea;
        this.kaLandArea = res.data[i].landArea;

        sessionStorage.setItem('landArray', JSON.stringify(this.landDetailsArray));
        console.log("land array after survey number addition",this.landDetailsArray);

      }
      if(this.landDetailsArray[i].verified == true){
        this.disableSubmit = false;
      }
    })
  }



  base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  isPdfBase64(base64: string): boolean {
    // Check if base64 data represents a PDF
    return base64.startsWith('data:application/pdf;base64,');
  }

  isPngBase64(base64: string): boolean {
    // Check if base64 data represents a PNG
    return base64.startsWith('data:image/png;base64,');
  }



  // verify(value){

  // }
}
