<div class="wrapper" *ngIf="showSpinner">

  <div class="overlay" *ngIf="showSpinner">

    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
    <p class="subText">{{subText}}</p>
  </div>

  <div class="loaded-content" [class.blurred]="showSpinner">
    <ng-content></ng-content>
  </div>
</div>
