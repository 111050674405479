<app-spinner-overlay [showSpinner]="showSpinner"></app-spinner-overlay>
<app-spinner-overlay [showSpinner]="showSpinnerWithMessage" [subText]="subText"></app-spinner-overlay>
<div class="loading-overlay" *ngIf="loading">
  <div class="spinner"></div>
</div>

<!-- <wl-card heading="ग्राहक आधार विवरण - Customer Aadhaar Details " [subheading1]="showAadharWhitetick"
[style.display]="showAadhaarField ? 'block' : 'none'"> -->
<wl-card heading="Customer Aadhaar Details " [subheading1]="showAadharWhitetick"
  [style.display]="true"  [@fadeInOut]>

  <form [formGroup]="detailsForm">
    <div class="row" #aadhaarLayout>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" >
        <!-- <p style="text-align: center; padding-bottom: 0px;margin-bottom: 0px;">कृपया आधार नंबर दर्ज करें </p> -->
        <p style="text-align: center;  padding-top: 0px;margin-top: 0px;">Enter Aadhaar Number</p>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field style="width: 100%;" appearance="outline">
          <input matInput [type]="typeType" formControlName="aadhaar" [readonly]="isAadhaarButtonDisabled"
            autocomplete="off"
            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
        </mat-form-field>
        <div style="text-align: right;margin-top:-60px;margin-left:90%;z-index:999">
          <img *ngIf="showPassword" (click)="togglePassword()" style="height:14px; opacity:0.5; "
            src="../../../assets/icons/eye.png" />
          <img *ngIf="!showPassword" (click)="togglePassword()" style="height:18px;opacity:0.5; "
            src="../../../assets/icons/eye_off.png" />

        </div>

      </div>
      <br>
    </div>
    <br><br>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">

      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="text-align: center;">
        <button class="mybutton" (click)="skipAadhaar(detailsForm.value)"
        [disabled]="detailsForm.controls['aadhaar'].invalid || isAadhaarButtonDisabled">Verify</button>
<!--
        <button class="mybutton" (click)="skipAadhaar(detailsForm.value)"
          [disabled]="detailsForm.controls['aadhaar'].invalid || isAadhaarButtonDisabled">जांच करे
          (Verify)</button> -->
        <!-- <img style="display: none; height:24px; width:24px; margin-left: 5px; margin-bottom:-10px"
      src="../../../assets/icons/check_circle.png" *ngIf="showAadharGreentick"> -->
      </div>
     
    </div>
    <br>
   <div class="row" *ngIf="isAadhaarButtonDisabled" >
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">

    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="text-align: center;">
      <button class="mybutton" (click)="openAadharReport()">View Aadhar Report</button>
    </div>
  </div>
    <div class="row" *ngIf="aadhaarName">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">

      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="text-align: center;">
        <p class="aadhaarName">Name of the customer: {{aadhaarName}}</p>
      </div>
    </div>
    <div class="row justify-content-center" *ngIf="showWaitingAadhaar">
      <div class="col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5"
        style="text-align: center; margin-left: 30%; ">
        <mat-spinner style="height:25px; margin-top: 5px; "></mat-spinner>
      </div>
    </div>
  </form>
</wl-card>
