<div class="loading-overlay" *ngIf="loading">
  <div class="spinner"></div>
</div>
<form [formGroup]="loanDetailsForm">
  <wl-card heading="{{LOCAL_LANGUAGE.LOAN_AMOUNT_DETAILS}}- Loan Amount Details" [subheading1]="showLoanAmountTick"
    [@fadeInOut]>

    <div class="row" style="width: 100%">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="padding-top: -20px !important">
        <p style="
        text-align: center;
        padding-bottom: 0px;
        margin-bottom: 0px;
      ">
          {{LOCAL_LANGUAGE.ESTIMATED_LOAN_AMOUNT}}
        </p>
        <p style="text-align: center; padding-top: 0px; margin-top: 0px">
          (Estimated Loan Amount)
        </p>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field style="width: 100%;margin-top: 10px;margin-left: 4vw" appearance="outline">
          <input type="text" matInput placeholder="Loan Amount" formControlName="estimatedLoanAmt" maxlength="10"
            [readonly]="true">
        </mat-form-field>
      </div>
    </div>

    <div style="width: 100%; text-align: center; margin-top: 1rem;">
      <button class="mybutton" (click)="submitCropNLoan(loanDetailsForm.value)"
        [disabled]="disabledFinalSubmit">

        {{LOCAL_LANGUAGE.SUBMIT_DETAILS}} (Submit)
      </button>


    </div>

    <br>

    <div class="row" *ngIf="loadDocsGenerated">
      <div class="col-xs-2 col-sm-2 col-md-4 col-lg-4 col-xl-4 col-xxl-4">

      </div>
      <div class="col-xs-8 col-sm-8 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <div class="row">

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12" style="text-align: center;padding: 1rem;">
            <button class="mybutton" (click)="openAnnexure(2)">View Annexure II</button>
          </div>

        </div>
      </div>
    </div>
    <br>
    <div class="row" *ngIf="loadDocsGenerated">
      <div class="col-xs-2 col-sm-2 col-md-4 col-lg-4 col-xl-4 col-xxl-4">

      </div>
      <div class="col-xs-8 col-sm-8 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <div class="row">

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12" style="text-align: center;padding: 1rem;">
            <button class="mybutton" (click)="openAnnexure(3)">View Annexure III</button>
          </div>

        </div>
      </div>
    </div>
  </wl-card>

</form>
