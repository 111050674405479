import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'wl-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
