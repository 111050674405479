import {
  Component,
  Inject,
  OnInit
} from "@angular/core";


import {
  MAT_DIALOG_DATA,
  MatDialog, MatDialogRef
} from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import * as constants from "../../../constant";

import { ApplicantService } from "../../../applicant.service";
import { OpenDialogYesOrNo } from "../../dialog-yes-or-no/dialog-yes-or-no.component";


@Component({
  selector: "wl-bank-or-bc-login",
  templateUrl: "./bank-or-bc-login.component.html",
  styleUrls: ['./bank-or-bc-login.component.scss'
  ],
})
export class BankOrBcLoginComponent implements OnInit {
  otpForm: FormGroup;
  showOTPfield: boolean;
  sendOrResend: string = constants.GENERATE_OTP;

  constructor(
    public dialogRef: MatDialogRef<BankOrBcLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public fb: FormBuilder,
    public applicant: ApplicantService,
    // public snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {
    dialogRef.disableClose = true;
    this.otpForm = this.fb.group({
      otpCode: ["",],
      id: ["", Validators.required],
      password:["",Validators.required]
    });
  }

  ngOnInit() { }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onKeyPress(event: KeyboardEvent) {
    // Add your custom keypress logic here if needed
    if (event.charCode !== 8 && (event.charCode === 0 || (event.charCode >= 48 && event.charCode <= 57))) {
      // Allow the keypress
    } else {
      event.preventDefault(); // Prevent the keypress
    }
  }

  submit() {
    // this.showOTPfield = true;
    // this.sendOrResend = "Resend OTP";
  }

  onKeyPressPassword(event){

  }

  verify(data) {
    // this.dialogRef.close(data);
    let type = "";
    if (this.data.type == "BC") {
      type = "bc_assisted";
    } else if (this.data.type == "Bank") {
      type = "bank_assisted";
    }
    this.applicant.login(data.id,data.password).subscribe(
      (resp) => {
        // let resp = {
        //   success: true,
        //   message: "success",
        //   result: {
        //     referenceId: "053987bc-a89c-4dbe-a73e-ec4a8edf87ca",
        //     referenceName:"Shashivardhan"
        //   },
        // };
        if (resp && resp.success && resp.data) {

          sessionStorage.setItem('token',resp.data.token)
          sessionStorage.setItem('username',resp.data.name)
          sessionStorage.setItem('userId',resp.data.id)
          // this.applicant.loginReferenceId = resp.data.result.referenceId;
          let referenceName = resp.data.name ? resp.data.name : ""
          const dialogRef = this.showDialog(
            "auto",
            "auto",
            `Are you ${referenceName}?, Please Confirm.`,
            "",
            // "हाँ (Yes)",
            // "नहीं (No)",
            "Yes",
            "No",
            ""
          );
          dialogRef.afterClosed().subscribe((res) => {
            if (res == "close") {
              this.otpForm.reset()
              this.dialogRef.close(resp);
            } else if (res == "dontClose") {
              // resp.result['referenceName'] ? this.applicant.loginReferenceName = resp.result['referenceName'] : "";
              this.dialogRef.close(resp);
            }
          })

        } else {
          if (resp.message) {
            const dialogRef = this.showDialog(
              "auto",
              "auto",
              resp.message,
              "",
              "OK",
              "",
              this.applicant.moreInfo(resp)
            );
          }
        }
      },
      (err) => {
        const dialogRef = this.showDialog(
          "auto",
          "auto",
          constants.SOMETHING_WENT_WRONG,
          "",
          "OK",
          "",
          ""
        );
      }
    );
  }

  showDialog(
    width: any,
    height: any,
    content1: string,
    content2: string,
    button1Text: string,
    button2Text: string,
    errorText: string
  ) {
    let dialogRef = this.dialog.open(OpenDialogYesOrNo, {
      width: width,
      height: height,
      data: {
        content1: content1,
        content2: content2,
        button1Text: button1Text,
        button2Text: button2Text,
        errorText: errorText,
        width: width,
        height: height
      },
    });

    return dialogRef;
  }
}
