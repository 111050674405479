import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild,OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApplicantService } from 'src/app/applicant.service';
import { FreshCaseOtpDialogComponent } from '../fresh-otp-dialog/otp-dialog.component';
import * as CryptoJS from 'crypto-js';
import * as constants from "../../constant";
import { SpinnerData } from 'src/app/applicant.service';
import * as HindiText from "../../mp-popup-message";
import { OpenDialogYesOrNo } from '../dialog-yes-or-no/dialog-yes-or-no.component';
import { environment as ENV } from "../../../environments/environment";
import { fadeInOut } from '../animation';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-aadhaar-verification',
  templateUrl: './aadhaar-verification.component.html',
  styleUrls: ['./aadhaar-verification.component.scss'],
  animations: [fadeInOut]
})
export class AadhaarVerificationComponent {
  showAadhaarField: boolean = false;
  @ViewChild("aadhaarLayout")
  private aadhaarLayout!: ElementRef;
  showAadharWhitetick: boolean = false;
  isAadhaarButtonDisabled: boolean = false;
  isviewreport:boolean =false;
  typeType: any = "password";
  showPassword: any;
  showWaitingAadhaar: boolean = false;
  showAadharGreentick: boolean = false;
  detailsForm: FormGroup;
  isBypass: any;
  showWaiting: boolean = false;
  DataFromBiFur: any = {};
  isNTB: boolean;
  rand: any;
  // stepper: any = 0;
  state: any;
  isMP: boolean;
  isKA: boolean;
  isUP: boolean;
  showPanOrVoterIdField: boolean;
  ipAddress: any;
  loading: boolean;
  isBypassFlow: boolean;
  encRand: any;
  aadhaarName: string;
  @Output() value = new EventEmitter();
  isAP: boolean;
  @Input() aadhaarInfo: any;
  isTel: boolean;

  showSpinner: boolean;
  showSpinnerWithMessage: boolean;
  subText: any;

  constructor(private dialog: MatDialog,
    private applicant: ApplicantService,
    private fb: FormBuilder,
  private snackbar:MatSnackBar) {
    this.detailsForm = this.fb.group({
      aadhaar: ["", [Validators.required, Validators.pattern("^[0-9]{12}$")]],
    });
    //sessionStorage.setItem('lang', 'telugu')
  }

  ngOnInit(){
    this.applicant.stringSubject.subscribe((spinnerData: SpinnerData) => {
      console.log(spinnerData)
      this.showSpinner = (spinnerData['showSpinner']);
      this.showSpinnerWithMessage = spinnerData['spinnerWithMessage'];
      this.subText = spinnerData['subText'];
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['aadhaarInfo']) {
      console.log('Data updated:', changes['aadhaarInfo']);
      // Perform actions based on the updated value in the child component
      console.log(this.aadhaarInfo)
      this.updateCard()
    }
  }

  updateCard() {
    if (this.aadhaarInfo && this.aadhaarInfo.success) {
      this.detailsForm.controls['aadhaar'].patchValue("xxxxxxxxxxxx")
      this.isAadhaarButtonDisabled = true;
      this.showAadharWhitetick = true;
      this.isviewreport = true;
    } else if (this.aadhaarInfo && this.aadhaarInfo.assisted) {

    }
  }



  skipAadhaar(value: any) {
    this.isAadhaarButtonDisabled = true;
    var dialogRef: MatDialogRef<FreshCaseOtpDialogComponent>;
    let dataToApi = {
      aadhaarNumber: value.aadhaar,
      // custId: sessionStorage.getItem("custId")
    }
    dialogRef = this.dialog.open(FreshCaseOtpDialogComponent, {
      disableClose: true,
      height: "auto",
      width: "auto",
      data: {
        dataToApi: dataToApi,
        // rand: this.rand,
        type: constants.AADHAAR,
        // custId: sessionStorage.getItem("custId"),
        aadhaarNumber: value.aadhaar,
      },
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      // debugger
      if (res == false) {
        this.isAadhaarButtonDisabled = false;
        this.showWaitingAadhaar = false;
      } else {
        console.log("res---Aadhar--dialog " + JSON.stringify(res));
        sessionStorage.setItem("applicantId", res && res.result && res.result.applicantId ? res.result.applicantId : "");
        // sessionStorage.setItem("applicantId", "658ea59ba70c1dc6e1452d0e"); //hardcoded applicantId
        // debugger
        if (res && !res.success) {
          console.log('test1');
          if (res && res.message) {
            const dialogRef = this.showDialog(
              "auto",
              "auto",
              "",
              res.message,
              "OK",
              this.applicant.moreInfo(res)
            );
          }


          this.isAadhaarButtonDisabled = false;
          this.showWaitingAadhaar = false;
        } else if (res && res.success && res.message.toLowerCase() == constants.MESSAGE_SUCCESS.toLowerCase()) {
          console.log('test2');
          // this.applicant.updateStatus();
          debugger
          // res.result.ageStatus = true //hardcoding agestatus
          if (res && res.result) {
            this.isAadhaarButtonDisabled = true;
            this.showAadharWhitetick = true;
            this.showAadharGreentick = true;
            this.showWaitingAadhaar = false;
            // this.stepper = 2;
            // res.state = "AP"; //hardcoding state
            debugger
            if (res && res.result && res.result.aadhaarVerificationId) {
              sessionStorage.setItem('aadhaarVerificationId', res.result.aadhaarVerificationId)
            }
            // debugger
            if (res && res.result && res.result.name) {
              this.aadhaarName = res.result.name;
            }

            // if (!this.isNTB) {
            if (res && res.result.state && res.result.state == "MP") {
              this.state = res.result.state;
              sessionStorage.setItem('lang', 'hindi')
              this.isMP = true;
              this.aadhaarVerificationDone()
              this.assistedPopup()
            } else if (res && res.result.state && res.result.state == constants.STATE_LIST.KA) {
              this.state = res.result.state;
              sessionStorage.setItem('lang', 'kannada')
              sessionStorage.setItem("state", constants.STATE_LIST.KA)
              this.isKA = true;
              this.aadhaarVerificationDone()
              this.assistedPopup()
            } else if (res && res.result.state == "UP") {
              this.state = res.result.state;
              sessionStorage.setItem('lang', 'hindi')
              this.isUP = true;
              this.aadhaarVerificationDone()
              this.assistedPopup()
            } else if (res && res.result.state == constants.STATE_LIST.AP) {
              sessionStorage.setItem('lang', 'telugu')
              sessionStorage.setItem("state", constants.STATE_LIST.AP)
              this.state = res.result.state;
              this.isAP = true;
              this.aadhaarVerificationDone()
              this.assistedPopup()
            }else if (res && res.result.state == constants.STATE_LIST.TEL) {
              sessionStorage.setItem('lang', 'telugu')
              sessionStorage.setItem("state", constants.STATE_LIST.TEL)
              this.state = res.result.state;
              this.isTel = true;
              this.aadhaarVerificationDone()
              this.assistedPopup()
            }
            await this.getHeaders();
            // this.applicant.getHeaders()
            // }
          }

        } else {
          this.isAadhaarButtonDisabled = false;
          this.showWaiting = false;
          this.showWaitingAadhaar = false;
          const dialogRef1 = this.showDialog(
            "auto",
            "auto",
            res && res.message ? res.message : "Something went wrong!",
            "",
            "OK",
            this.applicant.moreInfo(res)
          );
          dialogRef1.afterClosed().subscribe((res) => {
            console.log(res);

            if (res == "close") {
              // window.close();
            } else {
            }
          });
        }
      }
    });
  }

  async getHeaders() {
    try {
      const resp = await firstValueFrom(this.applicant.getHeaders());

    } catch (error) {
      console.error("Error fetching headers:", error);
    }
  }

  assistedPopup() {
    if (this.aadhaarInfo && this.aadhaarInfo.assisted) {
      this.dialog.open(OpenDialogYesOrNo, {
        disableClose: true,
        height: "auto",
        width: "auto",
        data: {
          content1: "Hi, Your KYC is done. Please inform the BC to continue the process.",
          // button1Text:"OK"
        }
      }

      )
    }
  }

  aadhaarVerificationDone() {
    let data = {
      success: true,
      state: this.state,
    }
    this.value.emit(data);
  }

  togglePassword() {
    this.typeType == "password"
      ? (this.typeType = "text")
      : (this.typeType = "password");
    let password = this.showPassword;
    this.showPassword = !password;
  }

  encryptDecryptApiData(data: any, encryptOrDecrypt: any) {
    console.log(data, encryptOrDecrypt)
    if (encryptOrDecrypt == "enc") {
      const myArray = new Uint32Array(1);
      //generate random number
      let rand = crypto.getRandomValues(myArray)['0'];
      this.rand = JSON.stringify(rand);
      console.log(this.rand)
      //setting token for future improvements
      // this.TokenServiceService.setToken(this.rand);
      //encrypting random string with env key
      this.encRand = this.encrypt(JSON.stringify(this.rand), this.secretKey);
      //add encrpted random string to data
      data['keyValue'] = this.encRand;
      console.log("Data with keyvalue", data)
      //encrypt the data with env key
      let encData = this.encrypt(JSON.stringify(data), this.secretKey);
      return encData;
    } else if (encryptOrDecrypt == "dec") {
      //create dec key with custId
      let custIddecKey = this.rand + '_' + sessionStorage.getItem('custId');
      console.log("custIddecKey", custIddecKey)
      //decrypt
      let decData = this.decrypt(data, custIddecKey);
      return decData;
    } else if (encryptOrDecrypt == "mobDec") {
      //create dec key with custId
      let mobNodecKey = this.rand + "_" + this.detailsForm.controls['mobileNo'].value;
      console.log("custIddecKey", mobNodecKey);
      //decrypt
      let decData = this.decrypt(data, mobNodecKey);
      return decData;
    } else if (encryptOrDecrypt == "panOrVoterIdDec") {
      // debugger
      let panVot = this.detailsForm.controls['panVotNumber'].value;
      let decKey = this.rand + "_" + panVot;
      let decData = this.decrypt(data, decKey)
      return decData;
    }

  }

  secretKey = ENV.shareKey;
  encrypt(value: string, key: string): string {
    return CryptoJS.AES.encrypt(value, key.trim()).toString();
  }
  decrypt(textToDecrypt: string, key: string) {
    return JSON.parse(CryptoJS.AES.decrypt(textToDecrypt, key.trim()).toString(CryptoJS.enc.Utf8));
  }


  showDialog(
    width: any,
    height: any,
    content1: string,
    content2: string,
    buttonText: string,
    errorText: string,
  ) {
    let dialogRef = this.dialog.open(OpenDialogYesOrNo, {
      width: width,
      height: height,
      data: {
        content1: content1,
        content2: content2,
        button1Text: buttonText,
        errorText: errorText
      },
    });

    return dialogRef;
  }


openAadharReport() {
    this.loading = true;
    this.applicant.getLoanDocument({ type: "aadharReport" }).subscribe((resp: Blob) => {
      const contentType = resp.type; // Get the content type of the blob
    this.isviewreport =true;
          console.log(resp)
      // Check if the content type indicates it's a PDF file
      if (contentType && contentType === 'application/pdf') {
        const file = new Blob([resp], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        this.loading = false;
        window.open(fileURL); // Open the PDF in a new tab/window
      } else {
        this.loading = false;
        this.snackbar.open("Received content is not a PDF file", "OK", { duration: 3000 });
      }
    }, (err) => {
      this.loading = false;
      this.snackbar.open("Unable to open the document", "OK", { duration: 3000 });
      
    });
  }

}
