<!--<p *ngIf="!id" fxLayout="row" fxLayoutAlign="start center" style="padding:12px 0">
  <label fxFlex="30" fxShow fxHide.xs>{{label}}</label>
  <md-select fxFlex.xs="100" fxFlex.gt-xs="50" [placeholder]="placeholder" [(ngModel)]="text" [multiple]="multiple" [required]="required" [disabled]="disabled">
       <md-select fxFlex.xs="100" fxFlex.gt-xs="50" [placeholder]="placeholder" [(ngModel)]="text" [multiple]="multiple" [required]="required">
    <mat-option *ngFor="let opt of options" [value]="opt.name">
      <span>{{opt.name}}</span>
    </mat-option>
  </md-select>
</p>-->

<!--<p *ngIf="id" fxLayout="row" fxLayoutAlign="start center" style="padding:12px 0">
  <label fxFlex="30" fxShow fxHide.xs>{{label}}</label>
  <md-select fxFlex.xs="100" fxFlex.gt-xs="50" [placeholder]="placeholder" [id]="id" [(ngModel)]="text" [multiple]="multiple" [required]="required" [disabled]="disabled">
       <md-select fxFlex.xs="100" fxFlex.gt-xs="50" [placeholder]="placeholder" [id]="id" [(ngModel)]="text" [multiple]="multiple" [required]="required" >
    <mat-option *ngFor="let opt of options" [value]="opt.id || opt.id">
      <span>{{opt.name}}</span>
    </mat-option>
  </md-select>
</p>-->

<div class="container">
  <div *ngIf="id" class="row">
    <div class="col-4" style="padding-top: 10px !important;">
      <label>{{label}}</label>
    </div>
    <div class="col-5" style="width: 100% !important;">
      <md-select [placeholder]="placeholder" [id]="id" [(ngModel)]="text" [multiple]="multiple" [required]="required" [disabled]="disabled" style="width: 100% !important;">
        <!-- <md-select fxFlex.xs="100" fxFlex.gt-xs="50" [placeholder]="placeholder" [id]="id" [(ngModel)]="text" [multiple]="multiple" [required]="required" > -->
      <mat-option *ngFor="let opt of options" [value]="opt.id || opt.id" style="width: 100% !important;">
        <span>{{opt.name}}</span>
      </mat-option>
    </md-select>
    </div>
  </div>
</div>

<!-- <div class="container">
  <div *ngIf="id" class="row">
    <div class="col-4" style="padding-top: 10px !important;">
      <label>{{label}}</label>
    </div>
    <div class="col-5" style="width: 100% !important;">
      <md-select [placeholder]="placeholder" [id]="id" [(ngModel)]="text" [multiple]="multiple" [required]="required" [disabled]="disabled" style="width: 100% !important;">
      <mat-option *ngFor="let opt of options" [value]="opt.name" style="width: 100% !important;">
        <span>{{opt.name}}</span>
      </mat-option>
    </md-select>
    </div>
  </div>
</div> -->

<!-- <link rel="stylesheet" href="../../../assets/bootstrap-grid.css"> -->

