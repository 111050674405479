<app-spinner-overlay [showSpinner]="showSpinner"></app-spinner-overlay>
<app-spinner-overlay [showSpinner]="showSpinnerWithMessage" [subText]="subText"></app-spinner-overlay>
<div class="loading-overlay" *ngIf="loading">
  <div class="spinner"></div>
</div>


<wl-card heading="{{LOCAL_LANGUAGE.CUSTOMER_ID_DETAILS}} - Customer ID Details " [subheading1]="showPanOrVoterTick" [style.display]="true" [@fadeInOut]>
  <form [formGroup]="detailsForm">
    <div class="row" #panVoterLayout>

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <p style="text-align: center; padding-bottom: 0px;margin-bottom: 0px;margin-top: 1rem;">{{LOCAL_LANGUAGE.SELECT_ID_TYPE}}</p>

        <p style="text-align: center;  padding-top: 0px;margin-top: 0px;">(Select ID Type) </p>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 ">

        <mat-form-field style="width: 100%;" appearance="outline">
          <mat-select placeholder="Select PAN/VoterID" formControlName="selectPanOrVoterId" style="width: 100%;"
          (selectionChange)="panOrVoterIDSelected($event.value)" appearance="outline" [disabled]="showPanOrVoterTick">
          <mat-option value="pan">PAN</mat-option>
          <mat-option value="voterid">Voter ID</mat-option>
        </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 100%;" *ngIf="panOrVoterIdSelected" appearance="outline">
          <input type="text" matInput placeholder="Enter {{panOrVoterIdString}} Number" formControlName="panVotNumber"
            maxlength="10"  [readonly]="showPanOrVoterTick">
          <mat-error *ngIf="detailsForm.get('panVotNumber').hasError('pattern')">
            Please enter correct {{panOrVoterIdString}}
          </mat-error>
        </mat-form-field>

      </div>

      <div class="row">

      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="text-align: center;">
        <br>
        <button class="mybutton" style="margin-top: -1rem;" (click)="verifyPanOrVoterIdNTB(detailsForm.controls['selectPanOrVoterId'].value)"
          [disabled]="detailsForm.controls['panVotNumber'].invalid || disableWhileResume || disableIfVerified">{{LOCAL_LANGUAGE.VERIFY}} (Verify)</button>
      </div>

    </div>
  </form>
</wl-card>
