<button *ngIf="!showFreshOrResumeCard" class="mybuttonNoBG" (click)="backButton()">Back</button>
<!-- <div [ngStyle]="{'background-image':'url('+bgImage+')';'filter': 'blur(5px)'}" class="background-container-new"> -->
  <div [ngStyle]="{
    'background-image': 'url(' + bgImage + ')',
  }" class="background-container-new">
  <wl-fresh-or-resume (value)="freshOrResume($event)" *ngIf="showFreshOrResumeCard" style="z-index: 2;"></wl-fresh-or-resume>

    <mat-card *ngIf="!showFreshOrResumeCard" style="background-color: rgba(0, 255, 255, 0);">

    <div class="row" style="vertical-align: middle; text-align: center; margin-top: 12vh;justify-content: center;">

      <div style="display:flex;flex-flow: column;align-items: center;">
        <button class="mybuttonWhite" (click)="selectSelfAssisted()">स्वयं सेवा <br> Self Service</button>
        <button class="mybuttonWhite" (click)="selectBCassisted()">बीसी सहायता प्राप्त <br> BC Assisted</button>
        <button class="mybuttonWhite" (click)="selectBankAssisted()">बैंक सहायता प्राप्त <br> Bank Assisted</button>
      </div>
    </div>
  </mat-card>


</div>

