import {
  Component,
  Inject,
} from "@angular/core";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment as ENV } from "../../../environments/environment";
import * as constants from "../../constant";
import * as CryptoJS from "crypto-js";
import { ApplicantService } from "src/app/applicant.service";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: "wl-freshcase-otp-dialog",
  templateUrl: './otp-dialog.component.html',
  styleUrls: ['./otp-dialog.component.scss'],
  providers: [ApplicantService, MatSnackBar],
})
export class FreshCaseOtpDialogComponent {

  //otpForm: FormGroup;
  otpBoxLength = 4;
  otp: any;
  isOtpVerifying: boolean = false;
  custId: any;
  password: any;
  company: any;
  roleId: any;
  aadhaarNo: any;
  txn: any;
  showMobileMessage: boolean = false;
  token: any;
  disableVerify: boolean = true;
  showWaiting: boolean = false;
  secondsTimer: number = 60;
  disableResend: boolean = false;
  resendCount: any = 0;
  showMaxRetries: boolean = false;
  rand: string | undefined;
  encRand: any;
  onloading: boolean = false;
  showTickMark: boolean = false;


  constructor(
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<FreshCaseOtpDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any,

    private applicant: ApplicantService,
    private dialog: MatDialog
  ) {
    // this.otpForm = this.fb.group({
    //   otpCode: ["", Validators.required],
    // });
    console.log('------------>', this.disableVerify);
    //this.disableVerify = true;
  }

  ngOnInit() {
    localStorage.setItem('subdm', ENV.congratsUrlSubDom);
    this.onloading = true;
    this.showWaiting = true;
    this.rand = this.data.rand;
    console.log(this.data);
    //this.data.type = constants.AADHAAR
    this.disableVerify = true;
    //console.log('------------>',this.disableVerify);
    if (this.data.isBypass) {
      this.showMobileMessage = true;
      if (this.data.type == constants.AADHAAR) {
        this.otpBoxLength = 6;
      } else if (this.data.type == constants.ID) {
        this.otpBoxLength = 4;
      }
      this.sendOtp(this.data);
      this.disableResend = true;
    }

    // } else if (this.data.type == constants.ID) {
    //   this.showMobileMessage = true;
    //   this.sendOtp(this.data);
    //   this.otpBoxLength = 4;
    //   //  this.otpForm.controls['otpCode'].setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(4)])
    // } else if (this.data.type == constants.MOBILE_NUM_NTB) {
    //   this.sendOtp(this.data);
    //   this.otpBoxLength = 4;
    //   // this.otpForm.controls['otpCode'].setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(4)])
    // } else if (this.data.type == constants.AADHAAR_NUM_NTB) {
    //   this.sendOtp(this.data);
    //   this.otpBoxLength = 6;
    //   // this.otpForm.controls['otpCode'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6)])
    // }else
    if (this.data.type == constants.MOBILE_NUM) {
      this.sendOtp(this.data);
      this.otpBoxLength = 4;
    } else if (this.data.type == constants.AADHAAR) {
      this.showMobileMessage = false;
      this.otpBoxLength = 6;
      // this.otpForm.controls['otpCode'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6)])
      this.sendOtp(this.data);
    }
  }

  onOtpChange(otp: any) {
    console.log('otp--------->', otp, '------', this.otpBoxLength);
    this.otp = otp;
    if (this.otpBoxLength == 4 && this.otp.length == 4) {
      this.disableVerify = false;
    } else if (this.otpBoxLength == 6 && this.otp.length == 6) {
      this.disableVerify = false;
    } else if (this.otpBoxLength == 6 && this.otp.lenght != 6) {
      this.disableVerify = true;
    } else if (this.otpBoxLength == 4 && this.otp.lenght != 4) {
      this.disableVerify = true;
    }
  }

  sendOtp(val: any) {
    this.showWaiting = true;
    this.disableResend = true;
    this.secondsTimer = 60;
    // debugger
    const timerInterval = setInterval(() => {
      if (this.secondsTimer > 0) {
        this.secondsTimer--;
      } else {
        this.disableResend = false;
        clearInterval(timerInterval);
      }
    }, 1000);
    if (this.data.isBypass) {
      this.handleVerificationType(val);
    } else {

      if (this.data.type == constants.MOBILE_NUM) {
        // let timeInMs = new Date().getTime();
        // let token = timeInMs + this.data.mobile_no;
        // this.token = token;
        let dataToApi = {
          // token: this.token,
          mobileNumber: this.data.mobile_no,
        geoIpInfo: JSON.parse(sessionStorage.getItem('geo-loc-n-ip1')) ,
          // panNo: this.data.pan,
           dynamicSegment: "LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQklqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FROEFNSUlCQ2dLQ0FRRUE3cEpEZERYMTI5eFNCTW5wUndiQgpQMUIxL0xlbjFVRnpwK2ZmSndsRi9RdFAwd2lncERqdGc0eDUzbHZjODBzSEsya0YrUTkrV3loVVl0T1hONGF3CjZCUC93MzBOVk9LazM3TExWOW1LUkRKcU1pa0FFdG5nQ3YrV0hadTBUdVVWTitONXdHVmZxZS81QzBQY0ozKy8KcVFiMzB2Y0QvZTU4MDRONVFPZEdvb0dzZkJVeHZlekd2VmRRL1QzT0lHMllsQXovRmdrNTcrSGpJVnpENE4xagp1UXVSRUI1U1c3c0Qvd2s5c290emtxZTBHZWczcnFSK2FNOGcxcDd0R1hPelFPMUJ2NksrU0NGblB3cmE2M2lkCkM5YjhyczBlTSs3Y0YxYXdCR2hBWmNicDNjRmtQekNveVdWa2VDdG1pVzJHb3FqNk9RcUs5MnBlZDB2M0NIWHAKZFFJREFRQUIKLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0t"
        };
        console.log(dataToApi);
        // let encDataToApi = this.encryptDecryptApiData(dataToApi, "enc");
        this.applicant
          .generateOTPMobileNo(dataToApi)
          .subscribe((resp) => {
            console.log(resp);
            this.showWaiting = false;
            this.onloading = false;
            if (resp && resp.success) {
              // resp = this.encryptDecryptApiData(resp.data, "mobDec");
              if (
                resp &&
                resp.message &&
                resp.message == constants.MESSAGE_SUCCESS_OTP
              ) {
                // this.disableVerify = false;

              } else {
                // resp.message = constants.MESSAGE_INVALID_MOB_NO;
                this.dialogRef.close(resp);
              }
            } else {
              // let decData = this.decrypt(resp.data, this.secretKey);
              this.dialogRef.close(resp);
            }
          },
            (err) => {
              this.snackbar.open(err.statusText + ' - ' + err.status, 'OK', { duration: 3000 })
              this.onloading = false;
              this.showWaiting = false;
            });
      } else if (this.data.type == constants.AADHAAR) {
        this.data.dataToApi.mobileVerificationId = sessionStorage.getItem('mobileVerificationId')
        this.applicant.generateAadharOtp(this.data.dataToApi).subscribe((resp: any) => {
          if (resp && resp.data && resp.data.requestId) {
            this.txn = resp.data.requestId
          }
          console.log("generateAadharOtp---->", resp);
          this.onloading = false;
          this.showWaiting = false;
          if (resp && resp.success) {
            // resp = this.encryptDecryptApiData(resp.data, "dec");
            if (resp && resp.message.toLowerCase() == constants.MESSAGE_SUCCESS.toLowerCase()) {
              // this.txn = resp.txn?resp.txn:"";
              //this.disableVerify = false;
              if(resp && resp.data &&  resp.data.result &&  resp.data.result.message){
                this.snackbar.open(resp.data.result.message, "OK", {duration:3000})
              }

            } else {
              if (resp && resp.message) {
                this.dialogRef.close(resp);
              } else {
                resp.message = constants.MESSAGE_INVALID_REQUEST;
                this.dialogRef.close(resp);
              }
            }
          } else {
            // let decData = this.decrypt(resp.data, this.secretKey);
            this.dialogRef.close(resp);
          }
        },
          (err: any) => {
            this.snackbar.open(err.statusText + ' - ' + err.status, 'OK', { duration: 3000 })
            this.onloading = false;
            this.showWaiting = false;
          });
      }
    }

  }

  resend() {
    this.resendCount++;
    if (this.resendCount < 6 && !this.showMaxRetries) {

      // } else if (this.data.type == constants.ID) {
      //   this.sendOtp(this.data);
      //   this.snackbar.open(
      //     "OTP has been sent again to your mobile number",
      //     "OK",
      //     { duration: 3000 }
      //   );
      // } else if (this.data.type == constants.MOBILE_NUM_NTB) {
      //   this.sendOtp(this.data);
      //   this.snackbar.open(
      //     "OTP has been sent again to your mobile number",
      //     "OK",
      //     { duration: 3000 }
      //   );
      // } else if (this.data.type == constants.AADHAAR_NUM_NTB) {
      //   this.sendOtp(this.data);
      //   this.snackbar.open(
      //     "OTP has been sent again to your mobile number",
      //     "OK",
      //     { duration: 3000 }
      //   );
      // }
      if (this.data.type == constants.MOBILE_NUM) {
        this.sendOtp(this.data);
        this.snackbar.open(
          "OTP has been sent again to your mobile number",
          "OK",
          { duration: 3000 }
        );
      } else if (this.data.type == constants.AADHAAR) {
        this.sendOtp(this.data);
        this.snackbar.open(
          "OTP has been sent again to your mobile number",
          "OK",
          { duration: 3000 }
        );
      }
    } else {
      this.showMaxRetries = true;
    }
  }

  verify() {
    console.log('this.otp--------->', this.otp);
    this.showWaiting = true;
    this.disableVerify = true;
    if (this.data.isBypass) {
      this.handleVerificationTypeVerify()
    } else {
      if (this.data.type == constants.MOBILE_NUM) {
        let dataToApi = {
          mobileNumber: this.data.mobile_no,
          otp: this.otp,
        };
        // let encDataToApi = this.encryptDecryptApiData(dataToApi, "enc");
        this.applicant
          .verifyOTPMobileNo(dataToApi)
          .subscribe((resp) => {
            this.showWaiting = true;
            this.disableVerify = true;
            if (resp && resp.success) {
              this.showTickMark = true;
              // resp = this.encryptDecryptApiData(resp.data, "mobDec");
              if (resp && resp.message == constants.MESSAGE_SUCCESS_VERIFIED) {
                sessionStorage.setItem('mobileVerificationId', resp && resp.data && resp.data.mobileVerifcationId ? resp.data.mobileVerifcationId : "")
                this.dialogRef.close(resp);
              } else {
                this.dialogRef.close(resp);
              }
            } else {
              // let decData = this.decrypt(resp.data, this.secretKey);
              this.dialogRef.close(resp);
            }
          }, (err: any) => {
            console.log(err)
            this.snackbar.open(err.statusText + ' - ' + err.status, 'OK', { duration: 3000 })
            this.showWaiting = false;
            this.disableVerify = false;
          });
      }

      else if (this.data.type == constants.AADHAAR) {

        let verifyData = {
          aadhaarNumber: this.data.dataToApi.aadhaarNumber,
          otp: this.otp,
          txn: this.txn,
          requestId: this.txn,
          mobileVerificationId: this.data.dataToApi.mobileVerificationId
        };
        console.log(verifyData);
        // let encDataToApi = this.encryptDecryptApiData(verifyData, "enc");

        this.applicant
          .verifyAadharOtp(verifyData )
          .subscribe(
            {
              next: (resp) => {
                console.log("aadharVerification---->", resp);
                this.showWaiting = true;
                this.disableVerify = true;
                if (resp && resp.success) {
                  this.showTickMark = true;
                  // resp = this.encryptDecryptApiData(resp.data, "dec");
                  if (resp && resp.message == constants.MESSAGE_SUCCESS) {
                    this.dialogRef.close(resp);
                  } else {
                    this.dialogRef.close(resp);
                  }
                } else {
                  // let decData = this.decrypt(resp.data, this.secretKey);
                  this.dialogRef.close(resp);
                }
              }, error: (err) => {
                console.log(err)
                this.snackbar.open(err.statusText + ' - ' + err.status, 'OK', { duration: 3000 })
                this.showWaiting = false;
                this.disableVerify = false;
              }
            });
      }
      else {
        this.dialogRef.close(false);
      }
    }

  }

  closeDialog(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isOtpVerifying = false;
    this.dialogRef.close();
  }

  encryptDecryptApiData(data, encryptOrDecrypt) {
    if (encryptOrDecrypt == "enc") {
      const myArray = new Uint32Array(1);
      //generate random number
      let rand = crypto.getRandomValues(myArray)["0"];
      this.rand = JSON.stringify(rand);
      //setting token for future improvements
      // this.TokenServiceService.setToken(this.rand);
      //encrypting random string with env key
      this.encRand = this.encrypt(JSON.stringify(this.rand), this.secretKey);
      //add encrpted random string to data
      data["keyValue"] = this.encRand;
      //encrypt the data with env key
      let encData = this.encrypt(JSON.stringify(data), this.secretKey);
      return encData;
    } else if (encryptOrDecrypt == "dec") {
      //create dec key with custId
      let custIddecKey = this.rand + "_" + sessionStorage.getItem("custId");
      console.log(
        "custIddecKey",
        custIddecKey,
        this.decrypt(data, custIddecKey)
      );
      //decrypt
      let decData = this.decrypt(data, custIddecKey);
      return decData;
    } else if (encryptOrDecrypt == "mobDec") {
      //create dec key with custId
      let mobNodecKey = this.rand + "_" + this.data.mobile_no;
      console.log("custIddecKey", mobNodecKey);
      //decrypt
      let decData = this.decrypt(data, mobNodecKey);
      return decData;
    }
  }
  secretKey = ENV.shareKey;
  encrypt(value: string, key: string): string {
    return CryptoJS.AES.encrypt(value, key.trim()).toString();
  }
  decrypt(textToDecrypt: string, key: string) {
    return JSON.parse(
      CryptoJS.AES.decrypt(textToDecrypt, key.trim()).toString(
        CryptoJS.enc.Utf8
      )
    );
  }

  //below is bypass stuff

  handleVerificationType(val: any) {
    if (this.data.type == constants.AADHAAR) {
      setTimeout(() => {
        this.showWaiting = false;
        this.onloading = false;
      }, 2000)
    } else if (this.data.type == constants.ID) {
      setTimeout(() => {
        this.showWaiting = false;
        this.onloading = false;
      }, 2000)
    }
  }

  handleVerificationTypeVerify() {
    if (this.data.type == constants.AADHAAR) {
      let resp = {
        message: "Success",
        success: true
      }
      setTimeout(() => {
        this.showWaiting = false;
        this.onloading = false;
        this.dialogRef.close(resp);
      }, 2000)
    } else if (this.data.type == constants.ID) {
      let resp = {
        message: "Success",
        success: true
      }
      setTimeout(() => {
        this.showWaiting = false;
        this.onloading = false;
        this.dialogRef.close(resp);
      }, 2000)
    } else {
      this.dialogRef.close(false);
    }
  }
}
