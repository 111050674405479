import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ApplicantService } from 'src/app/applicant.service';
import { RenewalService } from '../renewal.service';
import { CreditService } from '../../credit-service/credit.service';
import { OpenDialogYesOrNo } from '../../dialog-yes-or-no/dialog-yes-or-no.component';
import { firstValueFrom } from 'rxjs';
import { JourneyType } from '../../enum';

@Component({
  selector: 'app-renewal-steps',
  templateUrl: './renewal-steps.component.html',
  styleUrls: ['./renewal-steps.component.scss']
})
export class RenewalStepsComponent {
  mobileNumber: any;
  user: string;
  token: string;
  state: string;
  showKACard: boolean;
  landDetails: any;
  showCreditScoreCard: boolean;
  loading: boolean;
  creditStatus: any;
  creditScore: any;
  showSpinner: boolean;
  showCropCard: boolean;
  cropList:any;
  loanAmountInfo: { loanAmount: number; isResume: boolean, milestone:string };
  showLoanCard: boolean;
  loadingCredit: boolean;
  landRecordsListKA:any;
  milestone: any;
  isResume: boolean;

  constructor(
    private route: ActivatedRoute,
    private snack: MatSnackBar,
    private fb: FormBuilder,
    private dialogRef:MatDialog,
    private applicant:ApplicantService,
    private renewal:RenewalService,
    private creditService: CreditService,){

  }

  ngOnInit(){

    // this.showCropCard = true;
    // this.showLoanCard = true;

    sessionStorage.setItem('jrnyType',JourneyType.RENEWAL);
    this.route.queryParams.subscribe(async(params) => {
      try{
        if(params['avdaKedvra']){
          this.user = JSON.parse(atob(params['avdaKedvra']));
          sessionStorage.setItem('applicantId',this.user['_id'] || this.user['id'])
          this.state = this.user['state'];
          sessionStorage.setItem('state',this.state)
          await this.getHeaders()
          if(this.renewal.STATES.KA.POSSIBILITIES.includes(this.state)){
            this.proceedWithKArenewal();
          }
        }else{
          this.snack.open("Invalid URL.Please try with the correct link.", "OK")
        }

        if(params['xpctoPtrnm']){
          this.token = atob(params['xpctoPtrnm']);
          sessionStorage.setItem('token',this.token)
        }else{
          this.snack.open("Invalid URL.Please try with the correct link.", "OK")
        }
      }catch(err){
        console.log(err)
        this.snack.open("Invalid URL.Please try with the correct link.", "OK")
      }

    });
  }

  scrollTo(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  proceedWithKArenewal(){
    sessionStorage.setItem('lang', 'kannada');

    this.applicant.getResumeData(sessionStorage.getItem('applicantId')).subscribe((resp)=>{
      if(resp && resp.success && resp.data){
        this.milestone = resp.data.milestone;
        this.showKACard = true;
        if(resp.data.landRecords && resp.data.landRecords.length){
          this.isResume = true;
          this.passDataToKaCard(resp.data.landRecords);
        }else{
          return
        }


        if(resp.data.creditScore){

          this.creditScore = resp.data.creditScore;
          this.showCreditScoreCard = true;
          this.showCropCard = true;
        }else{
          return
        }

        if(resp.data.crops && ((resp.data.crop.rabiCrops && resp.data.crop.rabiCrops.length)
        || (resp.data.crop.summerCrops && resp.data.crop.summerCrops.length)
        || (resp.data.crop.kharifCrops && resp.data.crop.kharifCrops.length))){
          this.showCropCard = true;
          this.cropList = resp.data.crops;
        }else{
          return
        }

        if(resp.data.loanAmounts && resp.data.loanAmounts.proposedLoanAmount){
          this.loanAmountInfo = resp.data.proposedLoanAmount;
        }
      }
    },(err)=>{
      this.snack.open("Failed to fetch information.","OK")
    })

  }

  passDataToKaCard(landArray){
    this.landRecordsListKA = landArray.map((item)=>{
      let land = {};
      land['district'] = item.districtName;
      land['talukName'] = item.talukName;
      land['hobliName'] = item.hobliName;
      land['villageName'] = item.villageName;
      land['surveyNo'] = item.surveyNo;
      land['surveyNoArea'] = item.landArea;
      land['hissaNo'] = item.hissaNo;
      land['period'] = item.period;

      return land;
    })
  }



  async getHeaders() {
    try {
      const resp = await firstValueFrom(this.applicant.getHeaders());

    } catch (error) {
      console.error("Error fetching headers:", error);
    }
  }

  landRecordsDone(value) {
    console.log(value);
    this.landDetails = value;
    this.loading = true;
    this.loadingCredit = true;
    this.showCreditScoreCard = true;
    this.applicant.getResumeData(sessionStorage.getItem('applicantId')).subscribe((resp)=>{
      if(resp && resp.success){
        if(resp && resp.data && resp.data.aadhaarVerificationId){
          sessionStorage.setItem('aadhaarVerificationId',resp.data.aadhaarVerificationId);
          this.creditCheck();
        }else{
          this.snack.open('Record yet to be processed','OK')
        }

      }else{
        this.loading = false;
        this.loadingCredit = false;
        this.snack.open('Failed to fetch Applicant Information','OK');
      }
    },(err)=>{
      this.loading = false;
      this.loadingCredit = false;
      this.snack.open('Failed to fetch Applicant Information','OK');
    })

  }

  creditCheck() {
    let data = {}
    this.loading = true;
    this.loadingCredit = true;
    this.creditService.getCreditData(data).subscribe((resp) => {
      this.creditStatus = resp;
      this.loading = false;
      this.loadingCredit = false;

      if(resp && resp.success && resp.data  && resp.data && resp.data.SCORE){
        // debugger
        this.creditScore = resp.data.SCORE;
        this.showCreditScoreCard = true;

      }
      this.loading = false;
      this.loadingCredit = false;

      if (this.creditStatus && this.creditStatus.success) {
        // this.stepperCreditReportVerified();
        this.showCropCard = true;
      } else {
        const dialogRef = this.dialogRef.open(OpenDialogYesOrNo, {
          width: "auto",
          height: "auto",
          data: {
            content2: "Failed at credit check",
            button1Text: "OK",
          },
        });
      }
    }, (err) => {
      // this.creditStatus = false;
      this.showSpinner = false;
      this.loading = false;
      this.loadingCredit = false;

    })
  }

  openCreditReport() {
    this.loading = true;
    this.loadingCredit = true;

    this.applicant.getLoanDocument({ type: "creditReport" }).subscribe((resp: Blob) => {
      const contentType = resp.type; // Get the content type of the blob
      this.loadingCredit = false;

      // Check if the content type indicates it's a PDF file
      if (contentType && contentType === 'application/pdf') {
        const file = new Blob([resp], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        this.loading = false;
        window.open(fileURL); // Open the PDF in a new tab/window
      } else {
        this.loading = false;
        this.loadingCredit = false;

        this.snack.open("Received content is not a PDF file", "OK", { duration: 3000 });
      }
    }, (err) => {
      this.loading = false;
      this.loadingCredit = false;

      this.snack.open("Unable to open the document", "OK", { duration: 3000 });
    });
  }

  loanAmountCalculationDone(value, isResume) {
    console.log(value);
    value.isResume = isResume;
    this.loanAmountInfo = value;
    this.showLoanCard = true;
  }
}
