import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { BankOrBcLoginComponent } from '../bc-login/bank-or-bc-login/bank-or-bc-login.component';
import { ApplicantService } from 'src/app/applicant.service';
import { DynamicModalComponent } from 'src/app/dynamic-modal/dynamic-modal.component';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent {
  bgImage: string = "https://c.pxhere.com/images/45/4f/a2773d2017237529ef32297bb125-1455421.jpg!d";
  resumeCase: boolean = false;
  showFreshOrResumeCard: boolean = true;
  freshResume: any;
  referenceId: any;
  subscription: any;
  tickerText: string = "";
  showAssistedMode: boolean = false;
  lanState:string = '';

  constructor(private router: Router,
    private dialog: MatDialog,

    private applicant: ApplicantService,
    // private locationStrategy: LocationStrategy
  ) { }

  ngOnInit() {
    // this.tickerText = constants.MPLRS_DOWNTIME_TEXT;
    sessionStorage.clear();
    this.getLocation();

  //  // history.pushState(null, null, location.href);

  //   this.subscription = fromEvent(window, 'popstate').subscribe(_ => {
  //     history.pushState(null, null, location.href);
  //     //  this.openModal(`You can't make changes or go back at this time.`, 'Okay');

  //   });
    this.getIPaddress()
  }

  backButton() {
    this.showFreshOrResumeCard = true;
  }

  getIPaddress() {
    this.applicant.getGeoLocation().subscribe(resp => {
      console.log(resp)
      //sessionStorage.setItem('state1',resp.state);
    
      localStorage.setItem("state1",resp.data.state);
      if (resp && resp.data.ipv4) {
        sessionStorage.setItem('ipv4', resp.data.ipv4);
        // let locationFromBrowser = this.getLocation();
        this.getLocation()
          .then((position) => {
            // Handle successful position retrieval
            resp.latitude = position['coords']['latitude'];
            resp.longitude = position['coords']['longitude'];
            console.log('Position:', position);
            let geolocation = {
              "country_code": resp.data.countryCode,
        "country_name": resp.data.country_name,
        "city": resp.data.city,
        "postal": resp.data.postal,
        "latitude": resp.data.latitude,
        "longitude": resp.data.longitude,
        "IPv4": resp.data.ipv4,
        "state": resp.data.state
         // "state":"Karnataka"
            }
            console.log(resp)
            sessionStorage.setItem('geo-loc-n-ip',JSON.stringify(resp.data));
            sessionStorage.setItem('geo-loc-n-ip1',JSON.stringify(geolocation));
            
          })
          .catch((error) => {
            // Handle errors
            console.error('Error:', error);
            this.dialog.open(DynamicModalComponent,{disableClose:true,data:{divContent:"<p style'padding:1rem'>&nbsp;&nbsp;&nbsp;Please enable location access and reload the page&nbsp;&nbsp;&nbsp;<p>"}})
          });


      } else {
        sessionStorage.setItem('ipv4', "IP not captured");
      }
    }, (err) => {
      sessionStorage.setItem('ipv4', "IP not captured");
    })
  }

  // getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       const longitude = position.coords.longitude;
  //       const latitude = position.coords.latitude;
  //       console.log(longitude, latitude);
  //       sessionStorage.setItem('coordinates',JSON.stringify({lon:longitude,lat:latitude}))
  //       sessionStorage.setItem('geo-loc',JSON.stringify(position))
  //       return position
  //     },(err)=>{
  //       return {}
  //     });
  //   } else {
  //     console.log('No support for geolocation');
  //     return {}

  //   }

  getLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const longitude = position.coords.longitude;
            const latitude = position.coords.latitude;
            console.log(longitude, latitude);
            sessionStorage.setItem('coordinates', JSON.stringify({ lon: longitude, lat: latitude }));
            sessionStorage.setItem('geo-loc', JSON.stringify(position));
            resolve(position);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        console.log('No support for geolocation');
        reject('Geolocation not supported');
      }
    });
  }


  // history.pushState(null, null, location.href);

  // this.subscription = fromEvent(window, 'popstate').subscribe(_ => {
  //    history.pushState(null, null, location.href);
  //   //  this.openModal(`You can't make changes or go back at this time.`, 'Okay');
  // });


  state = 0;

  scrollDone() {
    this.state++;
  }

  selectSelfAssisted() {
    // this.router.navigate(['journey', {type:this.freshResume}])
    sessionStorage.setItem("journeyType", "S");
    this.router.navigateByUrl('/landing/journey');
  }

  selectBCassisted() {
    const dialogRef = this.dialog.open(BankOrBcLoginComponent, {
      width: "350px",
      height: "auto",
      data: { type: "BC" },
    });
    dialogRef.afterClosed().subscribe((resp) => {
      this.referenceId = resp;
      if (resp) {
        sessionStorage.setItem("journeyType", "BC");
        this.router.navigateByUrl('/landing/journey');
      }

    })
  }

  selectBankAssisted() {
    const dialogRef = this.dialog.open(BankOrBcLoginComponent, {
      width: "350px",
      height: "auto",
      data: { type: "Bank" },
    });
    dialogRef.afterClosed().subscribe((resp) => {
      this.referenceId = resp;
      if (resp) {
        sessionStorage.setItem("journeyType", "BK");
        this.router.navigateByUrl('/landing/journey');
      }
    })
  }

  freshOrResume(value: any) {
    this.freshResume = value;
    this.showFreshOrResumeCard = false;
    console.log(value);
    // this.bgImage = "";
    if (value == "fresh") {
      // this.resumeCase = false; //disabled self and bc assisted buttons view
      sessionStorage.setItem('freshORresume', 'fresh');
      sessionStorage.setItem("journeyType", "S");
      this.router.navigateByUrl('/landing/journey');
    } else if (value == "resume") {
      this.resumeCase = true;
      sessionStorage.setItem('freshORresume', 'resume');
      this.router.navigateByUrl('/landing/journey');

    }
  }

  ngOnDestroy() {
    this.applicant.freshOrResume = this.freshResume;

  }

}
