import { Component, ElementRef, ViewChild,OnInit } from '@angular/core';
import { LANGUAGES } from 'src/app/constant';
import { LanguageService } from 'src/app/language.service';

@Component({
  selector: 'app-keyfact',
  templateUrl: './keyfact.component.html',
  styleUrls: ['./keyfact.component.scss']
})
export class KeyfactComponent implements OnInit {
  bottomReached: boolean = false;
  showModal: boolean;
  @ViewChild('landLayout')
  landLayout!: ElementRef;
  LOCAL_LANGUAGE:any;
  langState:any;

  constructor(private languageService:LanguageService){
    this.showModal = true;
  }
  
  
  ngOnInit(): void {
    this.langState = localStorage.getItem('state1');
    // this.langState1 = sessionStorage.getItem("state");
   // this.langState = "Madhya Pradesh";
    const telugu = ["andhra pradesh","telangana"];
    const hindi = ["madhya pradesh"];
    console.log(this.langState);
    if(this.langState == "Karnataka")
      {
        this.LOCAL_LANGUAGE = this.languageService.getConstants(LANGUAGES.KANNADA);
    
      }
      else if(telugu.includes(this.langState.toLowerCase()))
      {
        this.LOCAL_LANGUAGE = this.languageService.getConstants(LANGUAGES.TELUGU);
      }
      else if(hindi.includes(this.langState.toLowerCase()))
      {
        this.LOCAL_LANGUAGE = this.languageService.getConstants(LANGUAGES.HINDI);
      }
      else if(this.langState == "Tamil Nadu")
        {
          this.LOCAL_LANGUAGE = this.languageService.getConstants(LANGUAGES.TAMIL);
        }
      else{
        this.LOCAL_LANGUAGE = this.languageService.getConstants(LANGUAGES.HINDI);
      }


  }

  handleScroll(event: Event): void {
    console.log(event.target)
    let threshold = 10;
    const scrollableContainer = event.target as HTMLElement;
    console.log(scrollableContainer.scrollHeight, scrollableContainer.scrollTop, scrollableContainer.clientHeight)
    // Check if scrolled to the bottom
    this.bottomReached = Math.ceil(scrollableContainer.scrollHeight - scrollableContainer.scrollTop) <= Math.ceil(scrollableContainer.clientHeight + threshold);
  }

  closeDialog() {
    this.showModal = false;
  }

  scrollOnClick() {
    this.landLayout.nativeElement.scrollIntoView();
    //     const element = document.getElementById("contentLayout");
    // element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }
}
