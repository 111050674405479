import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApplicantService } from '../../applicant.service';
import { OpenDialogYesOrNo } from '../dialog-yes-or-no/dialog-yes-or-no.component';
import { environment as ENV } from "../../../environments/environment";
import { LanguageService } from 'src/app/language.service';
import { fadeInOut } from '../animation';
import * as constants from "../../constant";
import { SpinnerData } from '../../applicant.service';

@Component({
  selector: 'app-pan-vot-verification',
  templateUrl: './pan-vot-verification.component.html',
  styleUrls: ['./pan-vot-verification.component.scss'],
  animations: [fadeInOut]
})
export class PanVotVerificationComponent {
  panOrVoterIdString: string;
  detailsForm: FormGroup;
  showPanOrVoterIdField: boolean = false;
  panOrVoterIdSelected: boolean = false;
  isKA: boolean;
  isMP: boolean;
  isUP: boolean;
  rand: string;
  encRand: any;
  showPanOrVoterTick: boolean = false;
  state: string;
  @Output() value = new EventEmitter();
  panVotNumber: any;
  @Input() panOrVoterInfo:{ type: string; number: string; success:true};
  disableWhileResume: boolean;
  DisabledFlagValue: string;
  disableIfVerified: boolean;
  LOCAL_LANGUAGE:any;

  loading: boolean;
  showSpinner: boolean;
  showSpinnerWithMessage: boolean;
  subText: any;

  constructor(
    private applicant: ApplicantService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private languageservice: LanguageService
    // private TokenServiceService: TokenServiceService,
    // private route: ActivatedRoute,
    // private router: Router
    ) {

    this.detailsForm = this.fb.group({
      panVotNumber: ["", Validators.required],
      selectPanOrVoterId: ["", Validators.required],
    });

    // if (sessionStorage.getItem('journey') == 'journey') {
    //   this.router.navigateByUrl('/landing/start')
    // }


  }

  ngOnInit(){
    let language = sessionStorage.getItem('lang')

    this.LOCAL_LANGUAGE = this.languageservice.getConstants(language)

    this.applicant.stringSubject.subscribe((spinnerData: SpinnerData) => {
      console.log(spinnerData)
      this.showSpinner = (spinnerData['showSpinner']);
      this.showSpinnerWithMessage = spinnerData['spinnerWithMessage'];
      this.subText = spinnerData['subText'];
    })

  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['panOrVoterInfo']) {
      console.log('Data updated:', changes['panOrVoterInfo']);
      console.log(this.panOrVoterInfo)
      this.updateCard()
    }
  }

  updateCard(){
    if(this.panOrVoterInfo && this.panOrVoterInfo.number && this.panOrVoterInfo.success && this.panOrVoterInfo.type){
      this.detailsForm.controls['selectPanOrVoterId'].setValue(this.panOrVoterInfo.type)
      this.panOrVoterIdSelected = true;
      this.detailsForm.controls['panVotNumber'].patchValue(this.panOrVoterInfo.number);
      this.detailsForm.controls['selectPanOrVoterId'].disable();
      this.detailsForm.controls['panVotNumber'].disable();
      this.disableWhileResume = true;
      this.showPanOrVoterTick = true;
    }
  }

  panOrVoterIDSelected(value) {
    console.log(value);
    this.panOrVoterIdSelected = true;
    this.isKA = false;
    this.isMP = false;
    this.isUP = false;

    this.detailsForm.controls['panVotNumber'].reset();
    if (value === 'pan') {
      this.panOrVoterIdString = constants.PAN
      this.detailsForm.controls['panVotNumber'].setValidators([Validators.required, Validators.pattern("[A-Z]{5}[0-9]{4}[A-Z]{1}")]);
    }
    else if (value === 'voterid') {
      this.panOrVoterIdString = constants.VOTERID
      this.detailsForm.controls['panVotNumber'].setValidators([Validators.required, Validators.pattern("^[A-Z]{3}[0-9]{7}$")]);
    }

  }


  verifyPanOrVoterIdNTB(value) {
    this.isKA = false;
    this.isMP = false;
    this.isUP = false;
    sessionStorage.setItem('rtItemPath', 'ftrwrefsh');
    sessionStorage.setItem('freshORresume', 'fresh');
    console.log(value);
    let verifyData = {
      type:this.panOrVoterIdString
    }

    if(this.panOrVoterIdString == constants.PAN){
      verifyData['panNumber'] = this.detailsForm.controls['panVotNumber'].value
    }else if(this.panOrVoterIdString == constants.VOTERID){
      verifyData['voterId'] = this.detailsForm.controls['panVotNumber'].value
    }
    console.log(verifyData)
    this.panVotNumber = this.detailsForm.controls['panVotNumber'].value

    // let encDataToApi = this.encryptDecryptApiData(verifyData, "enc");
    verifyData['aadhaarVerificationId'] = sessionStorage.getItem('aadhaarVerificationId')
    // verifyData['aadhaarVerificationId'] = "658d42f0822630400f7486d7";
    // sessionStorage.setItem('aadhaarVerificationId',"658d42f0822630400f7486d7")
    verifyData['mobileVerificationId'] = sessionStorage.getItem('mobileVerificationId')

     this.loading = true;
    this.applicant.verifyPANvoterID(verifyData).subscribe((resp) => {

      if (resp && resp.success) {
        this.loading = false;
        this.showPanOrVoterTick = true;
        console.log(resp);
        // let decData = this.encryptDecryptApiData(resp.data, "panOrVoterIdDec");
        if (resp && resp.success) {
          this.disableIfVerified = true;
          let dialog =
            this.showDialog(
              "auto",
              "auto",
              // `आपका ${this.panOrVoterIdString == "PAN" ? "पैन" : "वोटर आईडी"}पैन सफलतापूर्वक सत्यापित हो गया है`,
              "",
              `Your ${this.panOrVoterIdString} is verified successfully`,
              "OK",
              ''
            );
          // debugger
          dialog.afterClosed().subscribe(resp => {
            if (this.state == "MP") {
              this.isMP = true;
            } else if (this.state == "KA") {
              this.isKA = true;
            } else if (this.state == "UP") {
              this.isUP = true;
            }
          })
          this.panVotDone()

        } else {
          this.showDialog(
            "auto",
            "auto",
            `आपका ${this.panOrVoterIdString == "PAN" ? "पैन" : "वोटर आईडी"}सत्यापन विफल रहा।`,
            `Your ${this.panOrVoterIdString} is verification failed`,
            "OK",
            this.applicant.moreInfo(resp)
          );
        }

      } else {
        // console.log(resp, this.decrypt(resp.data, this.secretKey));
        this.loading = false;
        this.showDialog(
          "auto",
          "auto",
          `आपका ${this.panOrVoterIdString == "PAN" ? "पैन" : "वोटर आईडी"}सत्यापन विफल रहा।`,
          `Your ${this.panOrVoterIdString} is verification failed`,
          "OK",
          this.applicant.moreInfo(resp)
        );
      }

    }, (err) => {
      this.loading = false;
      this.showDialog(
        "auto",
        "auto",
        `आपका ${this.panOrVoterIdString == "PAN" ? "पैन" : "वोटर आईडी"}सत्यापन विफल रहा।`,
        `Your ${this.panOrVoterIdString} verification failed`,
        "OK",
        ''
      );
    })
  }

  panVotDone(){
    let data = {}
    data['type'] = this.panOrVoterIdString;
    data['number'] = this.panVotNumber
    this.value.emit(data);
  }

  showDialog(
    width: any,
    height: any,
    content1: string,
    content2: string,
    buttonText: string,
    errorText: string,
  ) {
    let dialogRef = this.dialog.open(OpenDialogYesOrNo, {
      width: width,
      height: height,
      data: {
        content1: content1,
        content2: content2,
        button1Text: buttonText,
        errorText: errorText
      },
    });

    return dialogRef;
  }

  encryptDecryptApiData(data:any, encryptOrDecrypt:any) {
    console.log(data, encryptOrDecrypt)
    if (encryptOrDecrypt == "enc") {
      const myArray = new Uint32Array(1);
      //generate random number
      let rand = crypto.getRandomValues(myArray)['0'];
      this.rand = JSON.stringify(rand);
      console.log(this.rand)
      //setting token for future improvements
      // this.TokenServiceService.setToken(this.rand);
      //encrypting random string with env key
      this.encRand = this.encrypt(JSON.stringify(this.rand), this.secretKey);
      //add encrpted random string to data
      data['keyValue'] = this.encRand;
      console.log("Data with keyvalue", data)
      //encrypt the data with env key
      let encData = this.encrypt(JSON.stringify(data), this.secretKey);
      return encData;
    } else if (encryptOrDecrypt == "dec") {
      //create dec key with custId
      let custIddecKey = this.rand + '_' + sessionStorage.getItem('custId');
      console.log("custIddecKey", custIddecKey)
      //decrypt
      let decData = this.decrypt(data, custIddecKey);
      return decData;
    } else if (encryptOrDecrypt == "mobDec") {
      //create dec key with custId
      let mobNodecKey = this.rand + "_" + this.detailsForm.controls['mobileNo'].value;
      console.log("custIddecKey", mobNodecKey);
      //decrypt
      let decData = this.decrypt(data, mobNodecKey);
      return decData;
    } else if (encryptOrDecrypt == "panOrVoterIdDec") {
      // debugger
      let panVot = this.detailsForm.controls['panVotNumber'].value;
      let decKey = this.rand + "_" + panVot;
      let decData = this.decrypt(data, decKey)
      return decData;
    }

  }

  secretKey = ENV.shareKey;
  encrypt(value: string, key:string): string {
    return CryptoJS.AES.encrypt(value, key.trim()).toString();
  }
  decrypt(textToDecrypt: string, key:string) {
    return JSON.parse(CryptoJS.AES.decrypt(textToDecrypt, key.trim()).toString(CryptoJS.enc.Utf8));
  }
}
