export const environment = {
  production: true,
    displayStatuses:"none",
    nodeUrl:"https://api-bharatmandi.whatsloan.com",
    shareKey:"OHYEAHBABY",
    congratsUrlSubDom:"kccnew",
    imageUrl:"",
    disableInput:false,
    sseUrl:"https://api-bharatmandi.whatsloan.com/v1/assist-mode/checkApplicantCompletionStatus?mobileNumber="
};
