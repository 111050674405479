import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { BaseApi } from 'src/app/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class RenewalService {

  constructor(private api:BaseApi,) { }



  STATES = {
    "MP": {
      "POSSIBILITIES": ["madya pradesh", "madhya pradesh", "mp", "madyapradesh"],
      "ENABLED": true,
      // "KEY":"xmpHUKBVNVSFG61XnJAzKOQ32EWjSdE1"
    },
    "KA": {
      "POSSIBILITIES": ["ka", "kar", "karnataka"],
      "ENABLED": true,
      // "KEY":"KDw2nS6vb5cJ286KJ3JwtGKGnnnLO93Y"
    },
    "UP": {
      "POSSIBILITIES": ["up", "uttar pradesh", "uttarpradesh"],
      "ENABLED": false,
      // "KEY":"Uq4YVODcoCruRMHy8lCFRPFbAf8d4uP8"
    },
    "MH": {
      "POSSIBILITIES": ["mh", "maharastra", "maha rastra", "maharashtra", "maha rashtra"],
      "ENABLED": true
    },
    "AD": {
      "POSSIBILITIES": ["ad", "andra", "andhara", "andra pradesh"],
      "ENABLED": true
    },
    "TL": {
      "POSSIBILITIES": ["telangana"],
      "ENABLED": true
    }
  }



  generateDocs(data){
    return this.api.postData('/v1/renewals/generateRenewalLoanDocuments', data)
    .pipe(
      map(res => {
        return res;
      })
    )
  }
}
