import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LandingRoutingModule } from './landing-routing.module';
import { StartPageComponent } from './start-page/start-page.component';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
//import { TaxModule } from './tax/tax.module';

import { FreshOrResumeComponent } from './start-page/fresh-or-resume/fresh-or-resume.component';
import { BankOrBcLoginComponent } from './bc-login/bank-or-bc-login/bank-or-bc-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OpenDialogYesOrNo } from './dialog-yes-or-no/dialog-yes-or-no.component';
import { BifurcationComponent } from './bifurcation/bifurcation.component';
import { FreshCaseOtpDialogComponent } from './fresh-otp-dialog/otp-dialog.component';
import { OtpDialogComponent } from './otp-dialog/otp-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { CoreModule } from '../core/core.module';
import { LandingComponent } from './landing.component';
import { StepperComponent } from './stepper/stepper.component';
import { BrowserModule } from '@angular/platform-browser';
import { KeysPipe } from '.././../app/pipes/keys.pipe';

import { MobileVerificationComponent } from './mobile-verification/mobile-verification.component';
import { ApplicantService } from '../applicant.service';
import { AadhaarVerificationComponent } from './aadhaar-verification/aadhaar-verification.component';
import { PanVotVerificationComponent } from './pan-vot-verification/pan-vot-verification.component';
import { LandRecordsApComponent } from './land-records/land-records-ap/land-records-ap.component';
import { CroppingPatternComponent } from './cropping-pattern/cropping-pattern.component';
import { LoanAmountComponent } from './loan-amount/loan-amount.component';
import { LoanSuccessfulComponent } from './loan-successful/loan-successful.component';
import { KeyfactComponent } from './bifurcation/keyfact/keyfact.component';
import { LoginMethodComponent } from '../login-method/login-method.component';
import { BcBaseComponent } from './bc-login/bank-or-bc-login/bc-base/bc-base.component';
import { AssistedBifurcationComponent } from './assisted-bifurcation/assisted-bifurcation.component';
import { LinkGenerationComponent } from './assisted-bifurcation/link-generation/link-generation.component';
import { InitiateAssistedComponent } from './assisted-bifurcation/initiate-assisted/initiate-assisted.component';
import {  LandRecordsTelComponent } from './land-records/land-records-tel/land-records-tel.component';
import { LandRecordsKaComponent } from './land-records/land-records-ka/land-records-ka.component';
import { KaLandVerifyComponent } from './land-records/land-records-ka/ka-land-verify/ka-land-verify.component';
import { RenewalFlowComponent } from './renewal-flow/renewal-flow.component';
import { RenewalConsentComponent } from './renewal-flow/renewal-consent/renewal-consent.component';
import { RenewalStepsComponent } from './renewal-flow/renewal-steps/renewal-steps.component';
import { RenewalLoanCardComponent } from './renewal-flow/renewal-loan-card/renewal-loan-card.component';







@NgModule({
  declarations: [
    KeysPipe,
    StartPageComponent,
    LandingComponent,
    FreshOrResumeComponent,
    BankOrBcLoginComponent,
    OpenDialogYesOrNo,
    BifurcationComponent,
    FreshCaseOtpDialogComponent,
    OtpDialogComponent,
    StepperComponent,
    MobileVerificationComponent,
    AadhaarVerificationComponent,
    PanVotVerificationComponent,
    LandRecordsApComponent,
    CroppingPatternComponent,
    LoanAmountComponent,
    LoanSuccessfulComponent,
    KeyfactComponent,
    LoginMethodComponent,
    BcBaseComponent,
    AssistedBifurcationComponent,
    LinkGenerationComponent,
    InitiateAssistedComponent,
    LandRecordsTelComponent,
    LandRecordsKaComponent,
    KaLandVerifyComponent,
    RenewalFlowComponent,
    RenewalConsentComponent,
    RenewalStepsComponent,
    RenewalLoanCardComponent,
   
  ],
  imports: [
      CommonModule,
    LandingRoutingModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    CoreModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatRadioModule,
    


  ],
  exports: [
    FreshOrResumeComponent, MobileVerificationComponent, AadhaarVerificationComponent
  ],
  entryComponents:[
    OpenDialogYesOrNo, KaLandVerifyComponent
  ],
  providers:[ApplicantService,KeysPipe]
})
export class LandingModule { }
