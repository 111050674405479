import { Injectable } from '@angular/core';
import * as constants from './constant'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  getConstants(languageCode: string) {
    switch (languageCode) {
      case constants.LANGUAGES.ENGLISH:
        return this.english;
      case constants.LANGUAGES.TELUGU:
        return this.telugu;
        case constants.LANGUAGES.KANNADA:
          return this.kannada;
         case constants.LANGUAGES.TAMIL:
            return this.tamil;
          case constants.LANGUAGES.HINDI:
          return this.hindi;
      // Add cases for other languages
      default:
        return this.empty; // Fallback to English
    }
  }

  english = {
    CUSTOMER_ID_DETAILS: "Customer ID Details",
    SELECT_ID_TYPE:"Select ID Type",
    VERIFY:"Verify",
    CROPS_PROPOSED_TO_BE_GROWN:"Crops Proposed To Be Grown",
    KHARIF:"Kharif",
    RABI:"Rabi",
    SUMMER:"Summer",
    SUBMIT_DETAILS:"Submit Details",
    CHOOSE_A_CROP:"Choose a Crop",
    LOAN_AMOUNT_DETAILS:"Loan Amount Details",
    ESTIMATED_LOAN_AMOUNT:"Estimated Loan Amount",
    DIFFERENT_LOAN_AMOUNT:" Would you like to request for a different loan amount?",
    YES: "Yes",
    NO: "No",
    REQUESTED_LOAN_AMOUNT:"Requested Loan Amount",
    I_PROVIDE_CONSENT_TO_BANK:"I provide my consent to Bank to hold on selected operative account towards recovery of dues.",
    I_PROVIDE_CONSENT_FOR_EXTRACTION:"I provide my consent for extraction of creditinformation report from CIC's & also to disclose the information relating to credit facilities availed / to be availed."
  }

  empty = {

  }

  telugu = {
   
    We_have_compiled_all_the_necessary_information_to_help_you_get_started: "మీ సహాయం కోసం అవసరమైన మొత్తం సమాచారం సంకలనం చేయబడింది.",
    Bank_visits_and_No_physical_document_submission_required: "1,60,000 వరకు రుణం పొందండి. బ్యాంకుకు వెళ్లాల్సిన అవసరం లేదు లేదా ఎలాంటి పత్రాలను సమర్పించాల్సిన అవసరం లేదు.",
    No_processing_fee_required: "ఎలాంటి ప్రాసెసింగ్ ఫీజు లేకుండా.",
    Disbursement_at_comfort_of_your_home: "మీ ఇంటి సౌలభ్యం నుండి పంపిణీ.",
    Apply_Now: "వెంటనే దరఖాస్తు చేసుకోండి",



    Type_of_Loan: "లోన్ రకం",
    KISAN_CREDIT_CARD : "కిసాన్ క్రెడిట్ కార్డ్ (యూనియన్ గ్రీన్ కార్డ్ - యుజిసి)",
    Purpose:"లక్ష్యం",
    Short_Term_Crop_Production: "స్వల్పకాలిక పంట ఉత్పత్తి",
    Maximum_Loan_Amount:"గరిష్ట రుణం",
    Margin:"మార్జిన్",
    NIL:"సున్నా",
    Repayment:"చెల్లింపు",
    Repayment_is_as_per_anticipated_harvesting_and_marketing_period_for_the_crops_for_which_loan_is_granted: "రుణం ఇచ్చిన పంటల కోసం ఆశించిన పంట మరియు అమ్మకాల వ్యవధి ప్రకారం తిరిగి చెల్లింపు జరుగుతుంది.",
    Moratorium: "తాత్కాలిక నిషేధం",
    Moratorium_NIL:"రుణ మారటోరియం లేదు ",
    Security:"భద్రత",
    Hypothecation : "ప్రతిపాదిత/ఉన్న పంట యొక్క హైపోథెకేషన్",
    Rate_of_Interest:"వడ్డీ రేటు",
    from: "ఋణం",
    to: "వరకు",
    MCLR:"ఎమ్ సి ఎల్ ఆర్",
    at_present:"ప్రస్తుత ధర",
    from_more_than:"మరియు కంటే ఎక్కువ",
    till_profit:"లక్షల వరకు ఉంటుంది",
    Processing_Charges:"ప్రసంస్కరణ ప్రభార్",
    Documentation:"డాక్యుమెంటేషన్",
    Option_available_for_execution_of_documents: "పత్రాల అమలు కోసం ఎంపికలు అందుబాటులో ఉన్నాయి",
    Manually_by_visiting_the_Branch_or: "బ్యాంక్ శాఖ ద్వారా - శాఖను సందర్శించడం ద్వారా",
    Digitally_Aadhar_OTP_Based_to_be_done_Online: "డిజిటల్‌గా – ఆధార్ ఓటిపి ఆధారిత ఆన్‌లైన్ పద్ధతి",
    Documentation_Charges: "డాక్యుమెంటేషన్ ఛార్జీలు",
    Digital_Convenience_Fee:"డిజిటల్ సౌలభ్యం రుసుము",
    GST : "జీ ఎస్ టీ",
    Non_Refundable: "తిరిగి చెల్లించలేని లేదా తిరిగి చెల్లించలేని",
    Annual_Percentage_Rate: "వార్షిక శాతం రేటు",
    on_loan_of:"యొక్క రుణంపై",
    Other_Charges: "ఇతర ఆరోపణలు",
    Crop_insurance_charge: "వర్తించే పంట బీమా ఛార్జీలను రుణగ్రహీత చెల్లించాలి మరియు అవసరాన్ని బట్టి ఖాతా నుండి డెబిట్ చేయాలి.",
    Stamping_charges:"రాష్ట్ర చట్టం ప్రకారం స్టాంప్ డ్యూటీ",
    Personal_Accident_Insurance_Scheme: "న్యూ ఇండియా అస్యూరెన్స్ లిమిటెడ్ జారీ చేసిన స్కీమ్ కోసం దరఖాస్తు చేసుకున్నట్లయితే, వ్యక్తిగత ప్రమాద బీమా పథకం (పి ఎ ఐ ఎస్) ఛార్జీలను రుణగ్రహీత భరించాలి. ప్రస్తుతం రుణగ్రహీత సహకారం రూ.6.00.",
    Penal_Interest: "జరిమానా వడ్డీ - ఏదైనా ఉంటే మీరిన మొత్తంపై 2.00% వసూలు చేయబడుతుంది",
    Disbursement: "संवितरण",
    Loan_Amount_will_be_disbursed_to_SB_Account: "రుణం మొత్తం రుణగ్రహీత యొక్క పొదుపు ఖాతాలోకి పంపిణీకి ముందు విజయవంతమైన శ్రద్ధ/తనిఖీ తర్వాత పంపిణీ చేయబడుతుంది.",
    Cooling_off_Lookup_period: "గ్రేస్ పీరియడ్/డార్మాంట్ పీరియడ్‌ని ఉపయోగించడం",
    Cooling_off_Lookup_period_1:"చెయ్యవచ్చు",
    Cooling_off_Lookup_period_available_to_farmers: "ముందస్తు చెల్లింపు కోసం రైతులకు 3 రోజుల నిద్రాణమైన కాలం అందుబాటులో ఉంటుంది. అదనపు ఛార్జీలు చెల్లించబడవు, మధ్యంతర కాలానికి వడ్డీ మాత్రమే చెల్లించాలి. ",
    Recovery_of_Bank_Dues: "బ్యాంకు బకాయిల రికవరీ",
    Recovery_proceedings_of_Bank_dues: "బ్యాంకుకు చెల్లించాల్సిన మొత్తాన్ని రికవరీ చేయడానికి బ్యాంక్ అధీకృత అధికారి చర్య తీసుకుంటారు.",
    Name_Details_of_Banks_Grievance_Redressal_Officer:"బ్యాంక్ గ్రీవెన్స్ రిడ్రెసల్ ఆఫీసర్ (డిజిటల్) పేరు మరియు",
    contact:"సంప్రదించండి.",
    Accept: "అంగీకరించండి",





    Mobile_Verification:"మొబైల్ ధృవీకరణ" ,
    Aadhaar_verification: "ఆధార్ ధృవీకరణ",
    PAN_or_VoterID: "పాన్ లేదా ఓటరు ఐడ్",
    Credit_Report: "క్రెడిట్ రిపోర్ట్" ,
    Credit_Score: "క్రెడిట్ స్కోర్",
    Land_Verification: "భూమి ధృవీకరణ" ,
    Crop_Selection: "పంట ఎంపిక",
    Sanction: "మంజూరు",


    CUSTOMER_ID_DETAILS:"కస్టమర్ ఐడ్ వివరాలు",
    SELECT_ID_TYPE:"ఐడ్ రకాన్ని ఎంచుకోండి",
    VERIFY:"ధృవీకరించండి",
    CROPS_PROPOSED_TO_BE_GROWN:" ప్రతిపాదిత పంటలు",
    KHARIF:"ఖరీఫ్",
    RABI:"రబీ",
    SUMMER:"వేసవి",
    SUBMIT_DETAILS:"వివరాలను సమర్పించండి",
    CHOOSE_A_CROP:"పంటను ఎంచుకోండి",
    LOAN_AMOUNT_DETAILS:"లోన్ మొత్తం వివరాలు",
    ESTIMATED_LOAN_AMOUNT:"అంచనా వేయబడిన లోన్ మొత్తం",
    DIFFERENT_LOAN_AMOUNT:" మీరు వేరే రుణ మొత్తాన్ని అభ్యర్థించాలనుకుంటున్నారా?",
    YES: "అవును",
    NO: "కాదు",
    REQUESTED_LOAN_AMOUNT:"అభ్యర్థించిన లోన్ మొత్తం",
    I_PROVIDE_CONSENT_TO_BANK:"బకాయిల రికవరీ కోసం ఎంచుకున్న ఆపరేటివ్ ఖాతాను కలిగి ఉండటానికి నేను బ్యాంక్‌కి నా సమ్మతిని అందజేస్తాను.",
    I_PROVIDE_CONSENT_FOR_EXTRACTION:"నుండి క్రెడిట్ ఇన్ఫర్మేషన్ రిపోర్టును సంగ్రహించడానికి మరియు అందుబాటులో ఉన్న / పొందవలసిన క్రెడిట్ సౌకర్యాలకు సంబంధించిన సమాచారాన్ని బహిర్గతం చేయడానికి నా సమ్మతిని అందజేస్తున్నాను."
  }

  kannada = {

    We_have_compiled_all_the_necessary_information_to_help_you_get_started: "ನಿಮ್ಮ ಸಹಾಯಕ್ಕಾಗಿ ಎಲ್ಲಾ ಅಗತ್ಯ ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸಲಾಗಿದೆ.",
    Bank_visits_and_No_physical_document_submission_required: "1,60,000 ವರೆಗೆ ಸಾಲ ಪಡೆಯಿರಿ. ಬ್ಯಾಂಕ್‌ಗೆ ಹೋಗುವ ಅಗತ್ಯವಿಲ್ಲ ಅಥವಾ ಯಾವುದೇ ದಾಖಲೆಗಳನ್ನು ಸಲ್ಲಿಸುವ ಅಗತ್ಯವಿಲ್ಲ.",
    No_processing_fee_required: "ಯಾವುದೇ ಸಂಸ್ಕರಣಾ ಶುಲ್ಕವಿಲ್ಲದೆ.",
    Disbursement_at_comfort_of_your_home: "ನಿಮ್ಮ ಮನೆಯ ಸೌಕರ್ಯದಿಂದ ವಿತರಣೆ.",
    Apply_Now: "ಈಗ ಅರ್ಜಿ ಸಲ್ಲಿಸಿ",




    Type_of_Loan: "ಸಾಲದ ಪ್ರಕಾರ",
    KISAN_CREDIT_CARD : "ಕಿಸಾನ್ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ (ಯೂನಿಯನ್ ಗ್ರೀನ್ ಕಾರ್ಡ್ - ಯುಜಿಸಿ)",
    Purpose:"ಉದ್ದೇಶ",
    Short_Term_Crop_Production: "ಅಲ್ಪಾವಧಿ ಬೆಳೆ ಉತ್ಪಾದನೆ",
    Maximum_Loan_Amount:"ಗರಿಷ್ಠ ಸಾಲ",
    Margin:"ಅಂಚು",
    NIL:"ಶೂನ್ಯ",
    Repayment:"ಪಾವತಿ",
    Repayment_is_as_per_anticipated_harvesting_and_marketing_period_for_the_crops_for_which_loan_is_granted: "ಸಾಲವನ್ನು ನೀಡಿದ ಬೆಳೆಗಳಿಗೆ ನಿರೀಕ್ಷಿತ ಕಟಾವು ಮತ್ತು ಮಾರಾಟದ ಅವಧಿಯ ಪ್ರಕಾರ ಮರುಪಾವತಿ ಮಾಡಲಾಗುತ್ತದೆ.",
    Moratorium: "ನಿಷೇಧ",
    Moratorium_NIL:"ನಿಷೇಧವಿಲ್ಲ",
    Security:"ಭದ್ರತೆ",
    Hypothecation : "ಪ್ರಸ್ತಾವಿತ/ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಬೆಳೆಯ ಹೈಪೋಥಿಕೇಶನ್",
    Rate_of_Interest:"ಬಡ್ಡಿ ದರ",
    from: "ಸಾಲ",
    to: "ತನಕ",
    MCLR:"ಎಂಸಿಎಲ್ಆರ್",
    at_present:"ಈಗಿನ ದರ",
    from_more_than:"ಮತ್ತು ಹೆಚ್ಚು",
    till_profit:"ಲಕ್ಷಗಳವರೆಗೆ",
    Processing_Charges:"ಸಂಸ್ಕರಣಾ ಶುಲ್ಕಗಳು",
    Documentation:"ದಾಖಲೀಕರಣ",
    Option_available_for_execution_of_documents: "ದಾಖಲೆಗಳ ಕಾರ್ಯಗತಗೊಳಿಸಲು ಆಯ್ಕೆಗಳು ಲಭ್ಯವಿದೆ",
    Manually_by_visiting_the_Branch_or: "ಬ್ಯಾಂಕ್ ಶಾಖೆಯ ಮೂಲಕ - ಶಾಖೆಗೆ ಭೇಟಿ ನೀಡುವ ಮೂಲಕ",
    Digitally_Aadhar_OTP_Based_to_be_done_Online: "ಡಿಜಿಟಲ್ - ಆಧಾರ್ ಒಟಿಪಿ ಆಧಾರಿತ ಆನ್‌ಲೈನ್ ವಿಧಾನ",
    Documentation_Charges: "ದಾಖಲಾತಿ_ಶುಲ್ಕಗಳು",
    Digital_Convenience_Fee:"ಡಿಜಿಟಲ್ ಅನುಕೂಲಕರ ಶುಲ್ಕ",
    GST : "ಜಿಎಸ್ಟಿ",
    Non_Refundable: "ಮರುಪಾವತಿಸಲಾಗುವುದಿಲ್ಲ",
    Annual_Percentage_Rate: "ವಾರ್ಷಿಕ ಶೇಕಡಾವಾರು ದರ",
    on_loan_of:"ಸಾಲದ ಮೇಲೆ",
    Other_Charges: "ಇತರ ಶುಲ್ಕಗಳು",
    Crop_insurance_charge: "ಅನ್ವಯವಾಗುವಂತೆ ಬೆಳೆ ವಿಮೆ ಶುಲ್ಕಗಳನ್ನು ಸಾಲಗಾರ ಪಾವತಿಸಬೇಕಾಗುತ್ತದೆ ಮತ್ತು ಅಗತ್ಯವಿರುವಂತೆ ಖಾತೆಯಿಂದ ಡೆಬಿಟ್ ಮಾಡಲಾಗುತ್ತದೆ.",
    Stamping_charges:"ರಾಜ್ಯದ ಕಾನೂನಿನ ಪ್ರಕಾರ ಸ್ಟ್ಯಾಂಪ್ ಡ್ಯೂಟಿ",
    Personal_Accident_Insurance_Scheme: "ನ್ಯೂ ಇಂಡಿಯಾ ಅಶ್ಯೂರೆನ್ಸ್ ಲಿಮಿಟೆಡ್ ನೀಡಿದ ಯೋಜನೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದರೆ ವೈಯಕ್ತಿಕ ಅಪಘಾತ ವಿಮಾ ಯೋಜನೆ (ಪಿಎಐಎಸ್) ಶುಲ್ಕಗಳನ್ನು ಸಾಲಗಾರನು ಭರಿಸಬೇಕಾಗುತ್ತದೆ. ಪ್ರಸ್ತುತ ಸಾಲಗಾರನ ಕೊಡುಗೆ ರೂ.6.00 ಆಗಿದೆ.",
    Penal_Interest: "ದಂಡದ ಬಡ್ಡಿ – ಯಾವುದಾದರೂ ಇದ್ದರೆ ಮಿತಿಮೀರಿದ ಮೊತ್ತಕ್ಕೆ 2.00% ವಿಧಿಸಲಾಗುತ್ತದೆ",
    Disbursement: "ವಿತರಣೆ",
    Loan_Amount_will_be_disbursed_to_SB_Account: "ಸಾಲದ ಮೊತ್ತವನ್ನು ಸಾಲಗಾರನ ಉಳಿತಾಯ ಖಾತೆಗೆ ವಿತರಣಾ ಮೊದಲು ಯಶಸ್ವಿ ಜಾಗರೂಕತೆ/ಪರಿಶೀಲನೆಯ ನಂತರ ವಿತರಿಸಲಾಗುತ್ತದೆ.",
    Cooling_off_Lookup_period: "ಗ್ರೇಸ್ ಅವಧಿ/ಸುಪ್ತ ಅವಧಿಯನ್ನು ಬಳಸುವುದು",
    Cooling_off_Lookup_period_available_to_farmers: "ಪೂರ್ವಪಾವತಿಗಾಗಿ ರೈತರಿಗೆ 3 ದಿನಗಳ ಸುಪ್ತ ಅವಧಿ ಲಭ್ಯವಿದೆ. ಯಾವುದೇ ಹೆಚ್ಚುವರಿ ಶುಲ್ಕಗಳನ್ನು ಪಾವತಿಸಲಾಗುವುದಿಲ್ಲ, ಮಧ್ಯಂತರ ಅವಧಿಯ ಬಡ್ಡಿಯನ್ನು ಮಾತ್ರ ಪಾವತಿಸಬೇಕು.",
    Recovery_of_Bank_Dues: "ಬ್ಯಾಂಕ್ ಬಾಕಿಗಳ ವಸೂಲಾತಿ",
    Recovery_proceedings_of_Bank_dues: "ಬ್ಯಾಂಕ್‌ಗೆ ಬರಬೇಕಾದ ಮೊತ್ತವನ್ನು ವಸೂಲಿ ಮಾಡುವ ಕ್ರಮವನ್ನು ಬ್ಯಾಂಕಿನ ಅಧಿಕೃತ ಅಧಿಕಾರಿ ತೆಗೆದುಕೊಳ್ಳುತ್ತಾರೆ.",
    Name_Details_of_Banks_Grievance_Redressal_Officer:"ಬ್ಯಾಂಕಿನ ಕುಂದುಕೊರತೆ ನಿವಾರಣಾ ಅಧಿಕಾರಿಯ (ಡಿಜಿಟಲ್) ಹೆಸರು ಮತ್ತು",
    contact:"ಸಂಪರ್ಕ.",
    Accept: "ಸ್ವೀಕರಿಸಿ",





    Mobile_Verification:"ಮೊಬೈಲ್ ಪರಿಶೀಲನೆ" ,
    Aadhaar_verification: "ಆಧಾರ್ ಪರಿಶೀಲನೆ",
    PAN_or_VoterID: "ಪ್ಯಾನ್/ವೋಟರ್",
    Credit_Report: "ಕ್ರೆಡಿಟ್ ವರದಿ" ,
    Land_Verification: "ಭೂಮಿ ಪರಿಶೀಲನೆ" ,
    Crop_Selection: "ಬೆಳೆ ಆಯ್ಕೆ",
    Sanction: "ಮಂಜೂರಾತಿ",
    


    CUSTOMER_ID_DETAILS: "ಗ್ರಾಹಕ ಐಡಿ ವಿವರಗಳು",
    SELECT_ID_TYPE:"ಐಡಿ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    VERIFY:"ಪರಿಶೀಲಿಸಿ",
    Credit_Score: "ಕ್ರೆಡಿಟ್ ಸ್ಕೋರ್",
    CROPS_PROPOSED_TO_BE_GROWN:"ಬೆಳೆಯಲು ಪ್ರಸ್ತಾಪಿಸಲಾದ ಬೆಳೆಗಳು",
    KHARIF:"ಶರತ್ಕಾಲ",
    RABI:"ರಬಿ",
    SUMMER:"ಬೇಸಿಗೆ",
    SUBMIT_DETAILS:"ವಿವರಗಳನ್ನು ಸಲ್ಲಿಸಿ",
    CHOOSE_A_CROP:"ಬೆಳೆ ಆಯ್ಕೆಮಾಡಿ",
    LOAN_AMOUNT_DETAILS:"ಸಾಲದ ಮೊತ್ತದ ವಿವರಗಳು",
    ESTIMATED_LOAN_AMOUNT:"ಅಂದಾಜು ಸಾಲದ ಮೊತ್ತ",
    DIFFERENT_LOAN_AMOUNT:" ಬೇರೆ ಸಾಲದ ಮೊತ್ತಕ್ಕಾಗಿ ನೀವು ವಿನಂತಿಸಲು ಬಯಸುವಿರಾ",
    YES: "ಹೌದು",
    NO: "ಇಲ್ಲ",
    REQUESTED_LOAN_AMOUNT:"ವಿನಂತಿಸಿದ ಸಾಲದ ಮೊತ್ತ",
    I_PROVIDE_CONSENT_TO_BANK:"ಬಾಕಿಗಳ ವಸೂಲಾತಿಗಾಗಿ ಆಯ್ದ ಆಪರೇಟಿವ್ ಖಾತೆಯನ್ನು ಹಿಡಿದಿಟ್ಟುಕೊಳ್ಳಲು ನಾನು ಬ್ಯಾಂಕಿಗೆ ನನ್ನ ಒಪ್ಪಗೆಯನ್ನು ನೀಡುತ್ತೇನೆ.",
    I_PROVIDE_CONSENT_FOR_EXTRACTION:"ಸಿ ಐ ಸಿ ಯಿಂದ ಕ್ರೆಡಿಟ್ ಮಾಹಿತಿಯ ವರದಿಯನ್ನು ಹೊರತೆಗೆಯಲು ಮತ್ತು ಪಡೆದಿರುವ / ಪಡೆಯಲಿರುವ ಕ್ರೆಡಿಟ್ ಸೌಲಭ್ಯಗಳಿಗೆ ಸಂಬಂಧಿಸಿದ ಮಾಹಿತಿಯನ್ನು ಬಹಿರಂಗಪಡಿಸಲು ನಾನು ನನ್ನ ಒಪ್ಪಿಗೆಯನ್ನು ನೀಡುತ್ತೇನೆ."
  }

  hindi={
    We_have_compiled_all_the_necessary_information_to_help_you_get_started: "आपकी सहायता हेतु सभी आवश्यक जानकारियाँ संकलित की गयी हैं ।",
    Bank_visits_and_No_physical_document_submission_required: "रु 1,60,000 तक ऋण प्राप्त करें । ना बैंक जाने की आवश्यकता ना ही कोई दस्तावेज जमा करने की जरूरत ।",
    No_processing_fee_required: "बिना किसी प्रक्रिया शुल्क के ।",
    Disbursement_at_comfort_of_your_home: "घर पर बैठे आराम से संवितरण ।",
    Apply_Now: "तत्काल आवेदन करें",



    Type_of_Loan: "ऋण का प्रकार",
    KISAN_CREDIT_CARD : "किसान क्रेडिट कार्ड (यूनियन ग्रीन कार्ड - यूजीसी)",
    Purpose:"उद्देश्य",
    Short_Term_Crop_Production: "अल्पावधि फसल उत्पादन",
    Maximum_Loan_Amount:"अधिकतम ऋण",
    Margin:"मार्जिन",
    NIL:"शून्य",
    Repayment:"अदायगी",
    Repayment_is_as_per_anticipated_harvesting_and_marketing_period_for_the_crops_for_which_loan_is_granted: "अदायगी उन फसलों के लिए प्रत्याशित कटाई और विक्रय अवधि के अनुसार है जिसके लिए ऋण दिया गया है।",
    Moratorium: "ऋणस्थगन",
    Moratorium_NIL:"कोई ऋणस्थगन नहीं ",
    Security:"प्रतिभूति",
    Hypothecation : "प्रस्तावित / मौजूदा फसल का दृष्टिबंधक",
    Rate_of_Interest:"ब्याज की दर",
    from: "ऋण",
    to: "तक",
    MCLR:"एमसीएलआर",
    at_present:"वर्तमान दर",
    from_more_than:"से अधिक एवं",
    till_profit:"लाख तक",
    Processing_Charges:"प्रसंस्करण प्रभार",
    Documentation:"दस्तावेजीकरण",
    Option_available_for_execution_of_documents: "दस्तावेजों के क्रियान्वयन हेतु उपलब्ध विकल्प",
    Manually_by_visiting_the_Branch_or: "बैंक शाखा द्वारा - शाखा में सम्पर्क (विजिट) करके",
    Digitally_Aadhar_OTP_Based_to_be_done_Online: "डिजिटली - ऑनलाइन तरीके से आधार ओटीपी आधारित",
    Documentation_Charges: "दस्तावेजीकरण प्रभार",
    Digital_Convenience_Fee:"डिजिटल सुविधा शुल्क",
    GST : "जीएसटी",
    Non_Refundable: "अप्रतिदेय या गैर वापसी",
    Annual_Percentage_Rate: "वार्षिक प्रतिशत दर",
    on_loan_of:"के ऋण पर",
    Other_Charges: "अन्य प्रभार",
    Crop_insurance_charge: "फसल बीमा प्रभार जो भी लागू है उधारकर्ता द्वारा भुगतान किया जाना है और आवश्यकतानुसार खाते से डेबिट किया जाना है।",
    Stamping_charges:"राज्य के कानून अनुसार स्टाम्प प्रभार",
    Personal_Accident_Insurance_Scheme: "व्यक्तिगत दुर्घटना बीमा योजना (पीएआईएस) प्राभर उधारकर्ता द्वारा वहन किया जाना है यदि योजना के लिए आवेदन किया गया है जो न्यू इंडिया एश्योरेंस लिमिटेड द्वारा जारी की गयी है। वर्तमान में उधारकर्ता का योगदान Rs.6.00 है।",
    Penal_Interest: "दंड स्वरूप ब्याज – अतिदेय राशि, यदि कोई है पर 2.00% लगाया जाएगा",
    Disbursement: "संवितरण",
    Loan_Amount_will_be_disbursed_to_SB_Account: "ऋण राशि संवितरण से पहले सफल ड्यू डिलिजेंस/निरीक्षण के बाद उधारकर्ता के बचत खाते में वितरित की जाएगी।",
    Cooling_off_Lookup_period: "उपशमन अवधि /निष्क्रिय समयावधि का प्रयोग कर",
    Cooling_off_Lookup_period_1:"सकते हैं",
    Cooling_off_Lookup_period_available_to_farmers: "किसानों को पूर्व भुगतान के लिए 3 दिनों की निष्क्रिय अवधि उपलब्ध है। कोई अतिरिक्त शुल्क देय नहीं, केवल अंतरिम अवधि का ब्याज दिया जाना है। ",
    Recovery_of_Bank_Dues: "बैंक की देय राशि की वसूली",
    Recovery_proceedings_of_Bank_dues: "बैंक की देय राशि की वसूली की कार्यवाही बैंक के प्राधिकृत आधिकारी द्वारा की जाएगी।",
    Name_Details_of_Banks_Grievance_Redressal_Officer:"बैंक के शिकायत निवारण अधिकारी (डिजिटल) का नाम एवं",
    contact:"संपर्क।",
    Accept: "स्वीकार करें",


    Mobile_Verification : "मोबाइल सत्यापन",
    Aadhaar_verification: "आधार सत्यापन",
    PAN_or_VoterID: "पीएएन या_मतदाता पहचान पत्र",
    Credit_Report: "क्रेडिट रिपोर्ट" ,
    Land_Verification: "भूमि सत्यापन" ,
    Crop_Selection: "फसल चयन",
    Sanction: "मंजूरी",



    CUSTOMER_ID_DETAILS: "ग्राहक आईडी विवरण",
    SELECT_ID_TYPE:"आईडी प्रकार चुनें",
    VERIFY:"सत्यापित करें",
    Credit_Score: "श्रेय अंक",
    CROPS_PROPOSED_TO_BE_GROWN:"फसलें उगाने का प्रस्ताव",
    KHARIF:"ख़रीफ़",
    RABI:"रबी",
    SUMMER:"गर्मी",
    SUBMIT_DETAILS:"विवरण जमा करें",
    CHOOSE_A_CROP:"एक फसल चुनें",
    LOAN_AMOUNT_DETAILS:"ऋण राशि विवरण",
    ESTIMATED_LOAN_AMOUNT:"अनुमानित ऋण राशि",
    DIFFERENT_LOAN_AMOUNT:"क्या आप भिन्न ऋण राशि के लिए अनुरोध करना चाहते हैं?",
    YES: "हाँ",
    NO: "नहीं",
    REQUESTED_LOAN_AMOUNT:"अनुरोधित ऋण राशि",
    I_PROVIDE_CONSENT_TO_BANK:"मैं बकाए की वसूली के लिए चयनित ऑपरेटिव खाते को रखने के लिए बैंक को अपनी सहमति देता हूं।",
    I_PROVIDE_CONSENT_FOR_EXTRACTION:"मैं सीआईसी से क्रेडिट सूचना रिपोर्ट निकालने और प्राप्त/प्राप्त की जाने वाली क्रेडिट सुविधाओं के बारे में जानकारी का खुलासा करने के लिए अपनी सहमति देता हूं।"
  }

  tamil = {

    
    Mobile_Verification : "मोबाइल सत्यापन",
    Aadhaar_verification: "आधार सत्यापन",
    PAN_or_VoterID: "पीएएन या_मतदाता पहचान पत्र",
    Credit_Report: "क्रेडिट रिपोर्ट" ,
    Land_Verification: "भूमि सत्यापन" ,
    Crop_Selection: "फसल चयन",
    Sanction: "मंजूरी",


  }
}
