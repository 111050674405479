<div style="padding: 1rem;" class="row">

  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">

  <form [formGroup]="LandForm" novalidate>

   <!-- <div class="row ">-->
      <div  style="padding-top: 10px !important;text-align: center;">
        <label>ಮಾಲೀಕರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ (ಭೂ ದಾಖಲೆಯ ಪ್ರಕಾರ) <br>
          Enter owner name (as per the Land record)</label>
      </div>
      <div  >
        <br><br>
        <mat-form-field style="width: 100%;" appearance="outline">
          <input type="text" matInput placeholder="Enter the name of the owner in english" formControlName="nameFromLr" class="center-placeholder">
        </mat-form-field>
      </div>
   <!-- </div>-->

    <!--<div class="row">-->
      <div  style="padding-top: 10px !important;text-align: center;">
        <label>ನ್ಯಾಯಾಲಯದಲ್ಲಿ ಯಾವುದೇ ಪ್ರಕರಣವಿದೆಯೇ? <br>
          Is there any court case  present?</label>
      </div>
      <div  [ngStyle]="{
        'text-align':textAlign
      }">
        <br>
        <mat-radio-group formControlName="isCourtCase">
          <mat-radio-button value="true">Yes</mat-radio-button>
          <mat-radio-button value="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
    <!--</div>-->

   <!--<div class="row">-->
      <div  style="padding-top: 10px !important;text-align: center;">
        <label>ಓಬ್ಬ ಮಾಲೀಕರು<br>
          Single Owner</label>
      </div>
      <div  [ngStyle]="{
        'text-align':textAlign
      }">
        <mat-radio-group formControlName="ownerShare">
          <mat-radio-button value="true">Yes</mat-radio-button>
          <mat-radio-button value="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
   <!--</div>-->

    <!--<div class="row">-->
      <div  style="padding-top: 10px !important;text-align: center;">
        <label>ಅಡಮಾನ<br>
          Mortgaged</label>
      </div>
      <div  [ngStyle]="{
        'text-align':textAlign
      }">
        <mat-radio-group formControlName="isMortgaged">
          <mat-radio-button value="true">Yes</mat-radio-button>
          <mat-radio-button value="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
   <!-- </div>-->

    <!--<div class="row">-->
      <div  style="padding-top: 10px !important;text-align: center;">
        <label>ನೀರಾವರಿ <br>
          Irrigated</label>
      </div>
      <div  [ngStyle]="{
        'text-align':textAlign
      }">
        <mat-radio-group formControlName="isIrrigated">
          <mat-radio-button value="true">Yes</mat-radio-button>
          <mat-radio-button value="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
   <!-- </div>-->

   <!-- <div class="row">-->
      <div  style="padding-top: 10px !important;text-align: center;">
        <label>ಭೂ ಪ್ರದೇಶ - Land Area</label>
      </div>
      <div >
        <mat-form-field style="width: 100%;" appearance="outline">
          <input type="text" matInput placeholder="ಭೂ ಪ್ರದೇಶವನ್ನು ನಮೂದಿಸಿ - Enter Land Area" formControlName="landArea" class="center-placeholder" >
        </mat-form-field>
      </div>
  <!--  </div>-->

    <div style="text-align: center; width: 100%">
      <br>
      <button (click)="submit(LandForm.value)" class="verifyButton" [disabled]="LandForm.invalid">Verify</button>
    </div>
    <mat-progress-bar *ngIf="showWaiting" mode="buffer">Please Wait</mat-progress-bar>


  
  </form>
</div>
  <div  class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
    <app-view-pdf ></app-view-pdf>
  </div>
  
  
</div>
