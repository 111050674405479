import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';


@Injectable()
export class InterceptorService implements HttpInterceptor {
  token: string;
  state: string;
  route: string;
  refID: string;
  journeyType: string;
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    console.log(req)
    //https://geolocation-db.com/json/
    //req.url == "https://api-bharatmandi.whatsloan.com/v1/ipTracking/getGeoLocation"
    if (req.url.includes("/v1/ipTracking/getGeoLocation")) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          // Handle HTTP errors or other rejections here
          console.error('Interceptor Error:', error);
          // Optionally handle specific error codes, if needed
          if (error.status === 404) {
            // Handle 404 errors
          }
          return throwError(error);
        })
      );
    } else {
      let tokenizedReq
      let stateHeaderObj = sessionStorage.getItem('stateHeaderObj') && sessionStorage.getItem('stateHeaderObj')!="undefined" ?JSON.parse(sessionStorage.getItem('stateHeaderObj')): null;
      console.log(stateHeaderObj, sessionStorage.getItem('stateHeaderObj'))
      if(stateHeaderObj){
        console.log(stateHeaderObj)
         tokenizedReq = req.clone({ headers: req.headers
          .set('Authorization', `Bearer ${sessionStorage.getItem('token')}`)
          .set('X-State-Code',stateHeaderObj.stateCode)
          .set('X-State', stateHeaderObj.state)
          .set('X-State-Identifier', stateHeaderObj.stateIdentifier)
          .set('X-Source','ac5d8e47-8ee2-4fca-951e-911747bf84d7')
         });
      }else{
         tokenizedReq = req.clone({ headers: req.headers.set('Authorization', `Bearer ${sessionStorage.getItem('token')}`) });
      }

      //             return next.handle(tokenizedReq);

      console.log(tokenizedReq);

      return next.handle(tokenizedReq).pipe(
        catchError((error: HttpErrorResponse) => {
          // Handle HTTP errors or other rejections here
          console.error('Interceptor Error:', error);
          // Optionally handle specific error codes, if needed
          if (error.status === 404) {
            // Handle 404 errors
          }
          return throwError(error);
        })
      );
    }



  }
}
