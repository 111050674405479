import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { FreshCaseOtpDialogComponent } from '../../fresh-otp-dialog/otp-dialog.component';
import * as constants from "../../../constant";
import { DynamicModalComponent } from 'src/app/dynamic-modal/dynamic-modal.component';
import { ApplicantService } from 'src/app/applicant.service';
import { OpenDialogYesOrNo } from '../../dialog-yes-or-no/dialog-yes-or-no.component';
import * as HindiText from "../../../mp-popup-message";
import { JourneyType } from '../../enum';

@Component({
  selector: 'app-renewal-consent',
  templateUrl: './renewal-consent.component.html',
  styleUrls: ['./renewal-consent.component.scss'],
})
export class RenewalConsentComponent {
  mobileNumber: any;
  showSpinner: boolean;
  showSpinnerWithMessage: boolean;
  loading: boolean;
  subText: string;
  isChecked1: boolean;
  isChecked2: boolean;
  isChecked3: boolean;
  checkForm:FormGroup;
  check1: boolean;
  check2: boolean;
  check3: boolean;
  disableOnSubmit: boolean;
  name: any;

  constructor(private route: ActivatedRoute,
    private snack: MatSnackBar,
    private fb: FormBuilder,
    private dialogRef:MatDialog,
    private applicant:ApplicantService) {
    this.checkForm = this.fb.group({
      checkbox1: ['', Validators.required],
      checkbox2: ['', Validators.required],
      checkbox3: ['', Validators.required]
  })
  }
  ngOnInit() {
    sessionStorage.setItem('jrnyType',JourneyType.RENEWAL);
    this.route.queryParams.subscribe(params => {
      // Extract mobileNumber from query params
      if(params['mobileNumber']){
        this.mobileNumber = params['mobileNumber'];
        this.name = params['name'];
        sessionStorage.setItem('applicantId',params['applicantId'])
      }else{
        this.snack.open("Invalid URL.Please try with the correct link.", "OK")
      }
    });

    this.getLocation();

    this.getIPaddress();
  }

  submitConsent(){
    console.log("submitted")
    const dialogRef = this.dialogRef.open(
      FreshCaseOtpDialogComponent,
      {
        disableClose: true,
        height: "auto",
        width: "auto",
        data: {
          type: constants.MOBILE_NUM,
          mobile_no: this.mobileNumber,
        }
      }
    )
    dialogRef.afterClosed().subscribe((resp)=>{
      if (resp && !resp.success) {
        const dialogRef = this.showDialog(
          "auto",
          "auto",
          "",
          resp.message,
          "OK",
          this.applicant.moreInfo(resp)
        );
      } else if (resp && resp.message == constants.MESSAGE_SUCCESS_VERIFIED) {
        this.disableOnSubmit = true;

        const dialogRef = this.showDialog(
          "auto",
          "auto",
          "",
          "Your consent has been updated successfully. Please contact the Branch for further process.",
          "OK",
          this.applicant.moreInfo(resp)
        );
        dialogRef.afterClosed().subscribe((resp)=>{
          window.close()
        })

      } else if (resp && resp.message == constants.INVALID_OTP) {
        this.showDialog(
          "auto",
          "auto",
          HindiText.INVALID_OTP.hindi,
          HindiText.INVALID_OTP.english,
          "OK",
          this.applicant.moreInfo(resp)
        );
      } else if (resp && resp.message == constants.OTP_EXPIRED) {
        this.showDialog(
          "auto",
          "auto",
          HindiText.OTP_EXPIRED.hindi,
          HindiText.OTP_EXPIRED.english,
          "OK",
          this.applicant.moreInfo(resp)
        );
      } else if (resp && resp.message == constants.MOBILE_NUM_INVALID) {
        this.showDialog(
          "auto",
          "auto",
          HindiText.MOBILE_NUM_INVALID.hindi,
          HindiText.MOBILE_NUM_INVALID.english,
          "OK",
          this.applicant.moreInfo(resp)
        );
      } else {
        this.showDialog(
          "auto",
          "auto",
          HindiText.SOMETHING_WENT_WRONG.hindi,
          HindiText.SOMETHING_WENT_WRONG.english,
          "OK",
          this.applicant.moreInfo(resp)
        );
      }
    })
  }

  changeEventOne($event) {
    console.log($event.target.value, $event)
    this.check1 = $event.target.checked;
  }
  changeEventTwo($event) {
    console.log($event.target.value)
    this.check2 = $event.target.checked;
  }
  changeEventThree($event) {
    console.log($event.target.value)
    this.check3 = $event.target.checked;
  }

  getIPaddress() {
    this.applicant.getGeoLocation().subscribe(resp => {
      console.log(resp)
      if (resp && resp.data.ipv4) {
        sessionStorage.setItem('ipv4', resp.data.ipv4);
        // let locationFromBrowser = this.getLocation();
        this.getLocation()
          .then((position) => {
            // Handle successful position retrieval
            resp.latitude = position['coords']['latitude'];
            resp.longitude = position['coords']['longitude'];
            console.log('Position:', position);
            sessionStorage.setItem('geo-loc-n-ip',JSON.stringify(resp))
          })
          .catch((error) => {
            // Handle errors
            console.error('Error:', error);
            this.dialogRef.open(DynamicModalComponent,{disableClose:true,data:{divContent:"<p style'padding:1rem'>&nbsp;&nbsp;&nbsp;Please enable location access and reload the page&nbsp;&nbsp;&nbsp;<p>"}})
          });


      } else {
        sessionStorage.setItem('ipv4', "IP not captured");
      }
    }, (err) => {
      sessionStorage.setItem('ipv4', "IP not captured");
    })
  }

  getLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const longitude = position.coords.longitude;
            const latitude = position.coords.latitude;
            console.log(longitude, latitude);
            sessionStorage.setItem('coordinates', JSON.stringify({ lon: longitude, lat: latitude }));
            sessionStorage.setItem('geo-loc', JSON.stringify(position));
            resolve(position);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        console.log('No support for geolocation');
        reject('Geolocation not supported');
      }
    });
  }

  showDialog(
    width: any,
    height: any,
    content1: string,
    content2: string,
    buttonText: string,
    errorText: string,
  ) {
    let dialogRef = this.dialogRef.open(OpenDialogYesOrNo, {
      width: width,
      height: height,
      data: {
        content1: content1,
        content2: content2,
        button1Text: buttonText,
        errorText: errorText
      },
    });

    return dialogRef;
  }
}
