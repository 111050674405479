
<div  style="width: 100%; background-color:white;" id="footer" style="padding-bottom: 5px;">
  <ul  class="progressBar">

    <li class="active" style="font-weight: bold;"  [ngClass]="{'focus': stepper == 1, 'active': stepper > 1}"><span class="hideJourneyText" style="padding-bottom: 5px;"> Mobile Verification <br> {{LOCAL_LANGUAGE.Mobile_Verification}}  </span></li>
    <li  style="font-weight: bold;" [ngClass]="{'focus': stepper == 2, 'active': stepper > 2}"><span style="font-size: 12px" class="hideJourneyText">Aadhaar verification <br> {{LOCAL_LANGUAGE.Aadhaar_verification}} <br></span></li>
    <li  style="font-weight: bold;" [ngClass]="{'focus': stepper == 3, 'active': stepper > 3}"><span style="font-size: 12px" class="hideJourneyText">PAN or VoterID <br> {{LOCAL_LANGUAGE.PAN_or_VoterID}} <br></span></li>
  <li  style="font-weight: bold;" [ngClass]="{'focus': stepper == 4, 'active': stepper > 4}"><span style="font-size: 12px" class="hideJourneyText">Credit Report <br> {{LOCAL_LANGUAGE.Credit_Report}} <br></span></li>
    <li style="font-weight: bold;" [ngClass]="{'focus': stepper == 5, 'active': stepper > 5}"><span  class="hideJourneyText">Land Verification  <br> {{LOCAL_LANGUAGE.Land_Verification}} <br></span></li>
    <li  style="font-weight: bold;" [ngClass]="{'focus': stepper == 6,'active': stepper >6}"><span  class="hideJourneyText">Crop Selection <br> {{LOCAL_LANGUAGE.Crop_Selection}} <br> </span></li>
    <li  style="font-weight: bold;" [ngClass]="{'focus': stepper == 7,'active': stepper > 7}"><span  class="hideJourneyText">Sanction <br>{{LOCAL_LANGUAGE.Sanction}} <br></span></li>
  </ul>
</div>
