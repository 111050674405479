import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Bharat Mandi';
  constructor(){
    sessionStorage.setItem('avadaKedavra',localStorage.getItem('avadaKedavra'));
    sessionStorage.setItem("token",localStorage.getItem("djZangoUnchai7ned"));

  }
}
