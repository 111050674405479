<div [ngStyle]="{
    'background-image': 'url(' + bgImage + ')',
  }" class="background-container-new">
   <mat-card style="background: none;">

  <div class="row" style="vertical-align: middle; text-align: center;justify-content: center;">

    <div style="display:flex;flex-flow: column;align-items: center;z-index: 2;">
      <button class="mybuttonWhite" (click)="selectSelfAssisted()"> Self </button>
      <button class="mybuttonWhite" (click)="selectBCassisted()"> BC Assisted</button>

     <!-- <button class="mybuttonWhite" (click)="openModal()"> Open Modal</button>-->
    </div>
  </div>

  <!-- <app-land-records-ka style="z-index: 2;"></app-land-records-ka> -->
  <!-- <app-land-records-tel style="z-index: 2;"></app-land-records-tel> -->

  <!-- </mat-card> -->
  <!-- <div class="container" style="position: absolute;z-index: 5;top:4.25rem;left:0rem;">
    <div class="row">
      <div class="col-2" style="background-color: white; padding-top: 3rem;">
        <app-vertical-stepper [JourneyType]="'NTB'"></app-vertical-stepper>
      </div>
    </div>
  </div> -->



  <!--<div class="modal" [style.display]="modalOpen ? 'block' : 'none'">
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <iframe [src]="sanitizedPdfUrl" style="height: 80vh;"></iframe>
    </div>
  </div>

</div> -->
