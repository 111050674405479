<!-- <p fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign.xs="start start" *ngIf="value == 0 || value">
  <strong [ngClass.xs]="'small-screen'" fxFlex="30">{{label}}</strong>
  <span fxFlex="50">{{value}}</span>
</p> -->

<!-- <p class="row" *ngIf="value == 0 || value">
  <strong [ngClass.xs]="'small-screen'" class="col-sm-4 col-md-4 col-lg-4">{{label}}</strong>
  <span class="col-sm-8 col-md-8 col-lg-8 text-center">{{value}}</span>
</p> -->

<div class="container">
  <div class="row" style="padding-top: 8px;padding-bottom: 8px;">
    <div class="col-4">
      <strong><label>{{label}}</label></strong>
    </div>
    <div class="col-5">
      <span>{{value}}</span>
    </div>
  </div>
</div>

<!-- <link rel="stylesheet" href="../../../assets/bootstrap-grid.css"> -->
