<!-- 
<mat-toolbar color="info" style="position: fixed;">

  <div class="empty-space"></div>
  <p fxHide.xs style="font-size:small;margin:0 !important">Powered by</p>
  <img class="logo" src="../../assets/whatsloan.png" alt="WhatsLoan" fxHide.xs="true" style="height:32px;margin:0 16px 0 0">

</mat-toolbar> -->

<mat-toolbar color="info" style="position: fixed;z-index: 998;">
  <img class="bmlogo" src="../../assets/bm-small.webp" alt="BharatMandi" style="height:3rem;margin:0 16px 0 15px" *ngIf="isMobile"/>

  <img class="bmlogo" src="../../assets/bharatmandi-logo.svg" alt="BharatMandi" style="height:2rem;margin:0 0 0 0"/>
  <!-- <h1 style="font-weight: bold;padding-top: 1rem;">WhatsLoan Marketplace</h1> -->

  <div class="empty-space"></div>
  <p style="font-size:small;margin:0 !important;" *ngIf="!isMobile">Powered by</p>
  <p style="font-size:6px;position: fixed;right:5%;top:-0.5%;" *ngIf="isMobile">Powered by</p>
  <img class="logo" src="../../assets/wl-small.png" alt="WhatsLoan" style="height:2rem;margin:0 0 0 0;position: fixed;right:5%;" *ngIf="isMobile"/>

  <img class="logo" src="../../assets/whatsloan.png" alt="WhatsLoan" style="height:2rem;margin:0 16px 0 0" *ngIf="!isMobile"/>

</mat-toolbar>



  <router-outlet class="class1"></router-outlet>



