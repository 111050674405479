import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicantService } from '../applicant.service';
import { DynamicModalComponent } from '../dynamic-modal/dynamic-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { KaLandVerifyComponent } from '../landing/land-records/land-records-ka/ka-land-verify/ka-land-verify.component';
import { ViewPdfComponent } from '../core/view-pdf/view-pdf.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-login-method',
  templateUrl: './login-method.component.html',
  styleUrls: ['./login-method.component.scss']
})
export class LoginMethodComponent {
  pdfBlobUrl: string;
  sanitizedPdfUrl: SafeResourceUrl;


  constructor(private router:Router, private applicant:ApplicantService, private dialog: MatDialog,private sanitizer:DomSanitizer){}
  bgImage: string = "https://c.pxhere.com/images/45/4f/a2773d2017237529ef32297bb125-1455421.jpg!d";
  selectSelfAssisted(){
    sessionStorage.setItem("journeyType", "S");
    this.router.navigateByUrl('/landing/start');
  }

  selectBCassisted(){
    sessionStorage.setItem("journeyType", "BC");
    this.router.navigateByUrl('/landing/bc-login');
  }

  ngOnInit() {
    // this.tickerText = constants.MPLRS_DOWNTIME_TEXT;
    sessionStorage.clear();
    this.getLocation();

    // history.pushState(null, null, location.href);

  //   this.subscription = fromEvent(window, 'popstate').subscribe(_ => {
  //     history.pushState(null, null, location.href);
  //     //  this.openModal(`You can't make changes or go back at this time.`, 'Okay');

  //   });
    this.getIPaddress()

    // this.openModal()
  }

  // openModal(){
  //   let dialogRef = this.dialog.open(KaLandVerifyComponent, {
  //     disableClose: true,
  //     height: "auto",
  //     width: "auto",
  //     data: {
  //       "test":"test"
  //       // dataToApi: dataToApi,
  //       // // rand: this.rand,
  //       // type: constants.AADHAAR,
  //       // // custId: sessionStorage.getItem("custId"),
  //       // aadhaarNumber: value.aadhaar,
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe(async (res) => {
  //   })
  // }

  // openModal(){
  //   let dialogRef = this.dialog.open(ViewPdfComponent, {
  //     disableClose: true,
  //     height: "auto",
  //     width: "auto",
  //     data: {
  //       "test":"test"
  //       // dataToApi: dataToApi,
  //       // // rand: this.rand,
  //       // type: constants.AADHAAR,
  //       // // custId: sessionStorage.getItem("custId"),
  //       // aadhaarNumber: value.aadhaar,
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe(async (res) => {
  //   })
  // }


  //modalOpen: boolean = false;

  //openModal() {
    //sessionStorage.setItem('applicantId','6639d3a87a7452152b2ff475');
    //this.applicant.getLoanDocument({type:"annexureTwo"}).subscribe((resp: Blob)=>{
     // const file = new Blob([resp], { type: 'application/pdf' });
      //const fileURL = URL.createObjectURL(file);

      //this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      // this.pdfBlobUrl = this.sanitizedPdfUrl;
      // this.loading = false;
      // window.open(fileURL); // Open the PDF in a new tab/window
    //},(err)=>{
      // this.loading = false;
      // this.snackbar.open("Unable to open the document","OK",{duration:3000})
    //})
    //this.modalOpen = true;
  //}

  //closeModal() {
    //this.modalOpen = false;
  //}


  getIPaddress() {
    this.applicant.getGeoLocation().subscribe(resp => {
      console.log(resp)
      if (resp && resp.data.ipv4) {
        sessionStorage.setItem('ipv4', resp.ipv4);
        // let locationFromBrowser = this.getLocation();
        this.getLocation()
          .then((position) => {
            // Handle successful position retrieval
            resp.latitude = position['coords']['latitude'];
            resp.longitude = position['coords']['longitude'];
            console.log('Position:', position);
            sessionStorage.setItem('geo-loc-n-ip',JSON.stringify(resp))
          })
          .catch((error) => {
            // Handle errors
            console.error('Error:', error);
            this.dialog.open(DynamicModalComponent,{disableClose:true,data:{divContent:"<p style'padding:1rem'>&nbsp;&nbsp;&nbsp;Please enable location access and reload the page&nbsp;&nbsp;&nbsp;<p>"}})
          });


      } else {
        sessionStorage.setItem('ipv4', "IP not captured");
      }
    }, (err) => {
      sessionStorage.setItem('ipv4', "IP not captured");
    })
  }

  getLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const longitude = position.coords.longitude;
            const latitude = position.coords.latitude;
            console.log(longitude, latitude);
            sessionStorage.setItem('coordinates', JSON.stringify({ lon: longitude, lat: latitude }));
            sessionStorage.setItem('geo-loc', JSON.stringify(position));
            resolve(position);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        console.log('No support for geolocation');
        reject('Geolocation not supported');
      }
    });
  }
}
