<app-spinner-overlay [showSpinner]="showSpinner"></app-spinner-overlay>
<app-spinner-overlay [showSpinner]="showSpinnerWithMessage" [subText]="subText"></app-spinner-overlay>
<div class="loading-overlay" *ngIf="loading">
  <div class="spinner"></div>
</div>

<div style="margin-top: 5rem;">
  <app-link-generation (value)="kycDone($event)" [resumeLinkGenerationInfo]="resumeLinkGenerationInfo"></app-link-generation>
</div>

<!-- showPanOrVoterIdField -->
<div class="cardStyles">
  <app-pan-vot-verification (value)="panVotSuccess($event)" *ngIf="showPanVotCard"
    [panOrVoterInfo]="panOrVoterInfo"></app-pan-vot-verification>

</div>

<div class="cardStyles" *ngIf="showCreditScoreCard">
  <wl-card heading="Credit Score" [subheading1]="showCreditWhitetick">
    <div style="width: 100%; font-weight: bold;font-size: 1.0rem; text-align: center;">
      Credit Score: {{creditScore}}
    </div>
    <br>
    <div style="width: 100%; font-weight: bold;font-size: 1.0rem; text-align: center;">
      <button class="mybutton" (click)="openCreditReport()">View Credit Report</button>
    </div>
  </wl-card>
</div>

<!-- andhra land record -->

<div class="cardStyles">
  <land-records-ap *ngIf="showAndhraCard" (value)="landRecordsDone($event)"
    [landRecordsList]="landRecordsList"  [isResume]="isResume"></land-records-ap>
</div>

<div class="cardStyles">
  <app-land-records-ka *ngIf="showKACard" [landRecordsList]="landRecordsList" (value)="landRecordsDone($event)"  [isResume]="isResume" [state]="state"></app-land-records-ka>
</div>

<div class="cardStyles">
  <app-land-records-tel *ngIf="showTelenganaCard" [landRecordsList]="landRecordsList" (value)="landRecordsDone($event)" [isResume]="isResume"></app-land-records-tel>
</div>

<!-- showCroppingPattern-->
<div class="cardStyles">
  <app-cropping-pattern *ngIf="showCropCard" (value)="loanAmountCalculationDone($event, false)"
    [cropList]="cropList"></app-cropping-pattern>
</div>

<div class="cardStyles">
  <app-loan-amount *ngIf="showLoanAmtCard" [loanAmountInfo]="loanAmountInfo"
    (value)="loanAmountSubmitted($event)"></app-loan-amount>
</div>

<div class="cardStyles">
  <app-loan-successful *ngIf="showLoanSuccessfulCard" [finalLoanAmounts]="finalLoanAmounts"></app-loan-successful>
</div>
