import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { ApplicantService } from '../../app/applicant.service';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'wl-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  demo: any;
  public screenWidth: any;

  public screenHeight: any;
  screenBreak: any = false;
  currentRoute: any;
  showToolBar: boolean = true;
  isMobile: boolean = false;
  private breakpointSubscription: Subscription;


  constructor(private applicant: ApplicantService, private breakpointObserver: BreakpointObserver) {
    // sessionStorage.setItem('avadaKedavra',localStorage.getItem('avadaKedavra'));
    // sessionStorage.setItem("token",localStorage.getItem("djZangoUnchai7ned"));
    // localStorage.removeItem('avadaKedavra');
    // localStorage.removeItem('djZangoUnchai7ned');
    // Detect changes in screen size and adjust sidenav mode accordingly
    this.breakpointSubscription = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((state) => {
        console.log(state)
        this.isMobile = state.matches;
        if (this.isMobile) {

        }


      });

  }

  ngOnInit() {

    this.screenWidth = window.innerWidth;

    this.screenHeight = window.innerHeight;
    console.log(this.screenWidth);
    if (this.screenWidth < 500) {
      this.screenBreak = true;
    }

    this.applicant.showToolBar.subscribe((data: any) => {
      if (data == false) {
        this.showToolBar = false;
      } else {

        this.showToolBar = true;
      }
    })


  }

}
