import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector) { }

  handleError(err) {
    const snackbar = this.injector.get(MatSnackBar);
    // console.log('Snackbar: ', snackbar);
    let message: string;
    if(err instanceof GeolocationPositionError){
      console.log(err)
    }else{
      if (typeof err === typeof 's') {
        message = err
      } else {
        message = err.message || 'Some Error Occured. Please Try Again Later'
      }
      console.log('Error: ', err);
      snackbar.open(message, 'OK', { duration: 5000, panelClass:'error' });
      // console.error('GLOBAL ERROR HANDLER: ', err);
      // throw err;
    }

  }

}
