import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { environment as ENV } from '../../../environments/environment'

@Component({
  selector: 'wl-user-status',
  templateUrl: './user-status.component.html',
})
export class UserStatusComponent implements OnInit {

  @Input() label: string;
  @Input() message: string;
  @Input() validity: string;
  @Input() verified: boolean;
  @Input() file: string;
  @Input() isAdmin: boolean;
  @Input() type: string;
  @Input() detail: any;
  @Output() selected = new EventEmitter<string>();
  @Output() sharefile = new EventEmitter<string>();
  buttonLabel: string;
  isIncome: boolean;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.label == 'Form 16' || this.label == 'ITR') {
      this.isIncome = true;
    }
    if (this.file) {
      this.file = ENV.imageUrl + this.file;
    }
    this.getButtonLable()
  }

  viewPartDetail(type) {
    this.selected.emit(type);
  }

  shareDoc(file) {
    console.log(file);
    this.sharefile.emit(file);
  }

  getButtonLable() {
    switch (this.label) {
      case 'Aadhaar': this.buttonLabel = 'View Input'; break;
      case 'PAN': this.buttonLabel = 'View Report'; break;
      case 'Driving License': this.buttonLabel = 'View Report'; break;
      case 'Passport': this.buttonLabel = 'View Report'; break;
      case 'NREGA': this.buttonLabel = 'View Report'; break;
      case 'Voter ID': this.buttonLabel = 'View Report'; break;

      case 'LPG': this.buttonLabel = 'View Report'; break;
      case 'Electricity Bill': this.buttonLabel = 'View Report'; break;
      case 'Telephone Bill': this.buttonLabel = 'View Report'; break;

      case 'Vehicle RC': this.buttonLabel = 'View Report'; break;

      case 'Personal Details': this.buttonLabel = 'View Input'; break;
      case 'Residential Details': this.buttonLabel = 'View Input'; break;
      case 'Other Loan Details': this.buttonLabel = 'View Input'; break;

      case 'Work Details': this.buttonLabel = 'View Input'; break;
      case 'ESIC': this.buttonLabel = 'View Report'; break;
      case 'EPF': this.buttonLabel = 'View Input'; break;

      case 'Bank Statement': this.buttonLabel = 'View Input'; break;
      case 'ITR': this.buttonLabel = 'View Input'; break;
      case 'Form 16': this.buttonLabel = 'View Input'; break;
      case 'Credit Score': this.buttonLabel = 'View Input'; break;
    }
  }

  navigateTo(path) {

    switch (path) {
      case 'Aadhaar': this.router.navigate(['laas/identity/id-proof/aadhaar']); break;
      case 'PAN': this.router.navigate(['laas/identity/id-proof/pan']); break;
      case 'Driving License': this.router.navigate(['laas/identity/id-proof/dl']); break;
      case 'Passport': this.router.navigate(['laas/identity/id-proof/passport']); break;
      case 'NREGA': this.router.navigate(['laas/identity/id-proof/nrega']); break;
      case 'Voter ID': this.router.navigate(['laas/identity/id-proof/epic']); break;

      case 'LPG': this.router.navigate(['laas/identity/address-proof/lpg']); break;
      case 'Electricity Bill': this.router.navigate(['laas/identity/address-proof/electricity']); break;
      case 'Telephone Bill': this.router.navigate(['laas/identity/address-proof/telephone']); break;

      case 'Personal Details': this.router.navigate(['laas/identity/personal-detail/basic']); break;
      case 'Residential Details': this.router.navigate(['laas/identity/personal-detail/residential']); break;
      case 'Other Loan Details': this.router.navigate(['laas/identity/personal-detail/other']); break;

      case 'Work Details': this.router.navigate(['laas/identity/employment/work']); break;
      case 'ESIC': this.router.navigate(['laas/identity/employment/esic']); break;
      case 'EPF': this.router.navigate(['laas/identity/employment/epf']); break;

      case 'Bank Statement': this.router.navigate(['laas/financial/bank']); break;
      case 'ITR': this.router.navigate(['laas/financial/itr']); break;
      case 'Form 16': this.router.navigate(['laas/financial/form16']); break;
      case 'Credit Score': this.router.navigate(['laas/financial/credit-score']); break;
    }
  }

}
