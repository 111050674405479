// animations.ts
import { trigger, style, animate, transition, state } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  state('in', style({ opacity: 1 })),
  transition('void => *', [
    style({ opacity: 0 }),
    animate(1000) // Adjust the duration (300ms in this example)
  ]),
  transition('* => void', [
    animate(1000 , style({ opacity: 0 })) // Adjust the duration (300ms in this example)
  ])
]);

export const zoomInOut = trigger('zoomInOut', [
  state('normal', style({ transform: 'scale(.99)' })),
  state('hovered', style({ transform: 'scale(1)' })),
  transition('normal => hovered', animate('500ms ease-in')),
  transition('hovered => normal', animate('500ms ease-out')),
])
