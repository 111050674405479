<form novalidate [formGroup]="multiCropDetailsForm" [style.display]="showCroppingPattern ? 'block' : 'none' ">
  <wl-card heading="{{LOCAL_LANGUAGE.CROPS_PROPOSED_TO_BE_GROWN}} - Crops Proposed To Be Grown" [subheading1]="cropSelectionTick"  [@fadeInOut]>

    <div *ngIf="!isResume || !cropList" id="kharif" #croppingPatternLayout>
      <h4 class="seasonHeaddings">1.{{LOCAL_LANGUAGE.KHARIF}} (Kharif)</h4>
      <div class="cropDivOuter" formArrayName="cropArrayKharif">
        <div class="innerDiv" *ngFor="
                  let cropArray of cropArrayKharif().controls;
                  let i = index
                " [formGroupName]="i">

          <div style="text-align: center">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
                style="padding-top: 20px !important;">
                <p *ngIf="!croppingPatternDisabled" style="text-align: center;">{{LOCAL_LANGUAGE.CHOOSE_A_CROP}} - Choose a Crop</p>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <mat-form-field appearance="outline" style="width: 100%; ">
                  <mat-select placeholder="{{LOCAL_LANGUAGE.CHOOSE_A_CROP}}" formControlName="kharif"
                    (selectionChange)="kharifSelectedEvent($event, i)" [disabled]="croppingPatternDisabled">
                    <mat-option *ngFor="let opt of kharifCropListNew2" [value]="opt.cropName">{{ opt.cropName | titleCase }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>


          <div style="text-align: right; margin-right: 2rem" *ngIf="!croppingPatternDisabled">
            <img class="deleteIcon" style="height: 30px; position: relative; left:98%"
              src="../../../assets/fontawesome-web/svgs/regular/trash-can.svg" (click)="removeRowKharif(i)" />
          </div>
        </div>
        <div style="
                  text-align: center;
                  margin-top: 1.5rem;
                  margin-bottom: 1.5rem;
                " *ngIf="!croppingPatternDisabled && cropArrayKharif().controls.length < 2">
          <img class="deleteIcon" style="height: 30px;position: relative; left:calc(50% - 15px);margin-bottom:4rem"
            src="../../../assets/fontawesome-web/svgs/regular/square-plus.svg" (click)="addKharifCrop(i)" />
        </div>
      </div>
    </div>

    <div *ngIf="!isResume || !cropList" id="rabi">
      <h4 class="seasonHeaddings">2. {{LOCAL_LANGUAGE.RABI}} (Rabi)</h4>
      <div class="cropDivOuter" formArrayName="cropArrayRabi">
        <div class="innerDiv" *ngFor="
                  let cropArray of cropArrayRabi().controls;
                  let i = index
                " [formGroupName]="i">

          <div style="text-align: center">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
                style="padding-top: 20px !important;">
                <p *ngIf="!croppingPatternDisabled" style="text-align: center;">{{LOCAL_LANGUAGE.CHOOSE_A_CROP}} Choose a Crop</p>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <mat-form-field appearance="outline" style="width: 100%; ">
                  <mat-select placeholder="{{LOCAL_LANGUAGE.CHOOSE_A_CROP}}" formControlName="rabi" (selectionChange)="rabiSelectedEvent($event, i)"
                    [disabled]="croppingPatternDisabled">
                    <mat-option *ngFor="let opt of rabiCropListNew2" [value]="opt.cropName">{{ opt.cropName | titleCase}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>


          <div style="text-align: right; margin-right: 2rem" *ngIf="!croppingPatternDisabled">
            <img class="deleteIcon" style="height: 30px; position: relative; left:98%;"
              src="../../../assets/fontawesome-web/svgs/regular/trash-can.svg" (click)="removeRowRabi(i)" />
          </div>
        </div>
        <div style="
                  text-align: center;
                  margin-top: 1.5rem;
                  margin-bottom: 1.5rem;
                " *ngIf="!croppingPatternDisabled && cropArrayRabi().controls.length < 2">
          <img class="deleteIcon" style="height: 30px;position: relative; left:calc(50% - 15px);margin-bottom:4rem"
            src="../../../assets/fontawesome-web/svgs/regular/square-plus.svg" (click)="addRabiCrop(i)" />
        </div>
      </div>
    </div>

    <!----SUMMER SEASON CODE-->
    <div *ngIf="!isResume || !cropList" id="summer">
      <h4 class="seasonHeaddings">3. {{LOCAL_LANGUAGE.SUMMER}}(Summer Season)</h4>
      <div class="cropDivOuter" formArrayName="cropArraySummer">
        <div class="innerDiv" *ngFor="
                  let cropArray of cropArraySummer().controls;
                  let i = index
                " [formGroupName]="i">

          <div style="text-align: center">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
                style="padding-top: 20px !important;">
                <p *ngIf="!croppingPatternDisabled" style="text-align: center;">{{LOCAL_LANGUAGE.CHOOSE_A_CROP}} Choose a Crop</p>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <mat-form-field appearance="outline" style="width: 100%; ">
                  <mat-select placeholder="{{LOCAL_LANGUAGE.CHOOSE_A_CROP}}" formControlName="summer"
                    (selectionChange)="summerSelectedEvent($event, i)" [disabled]="croppingPatternDisabled">
                    <mat-option *ngFor="let opt of summerCropListNew2" [value]="opt.cropName">{{ opt.cropName | titleCase}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div style="text-align: right; margin-right: 2rem" *ngIf="!croppingPatternDisabled">
            <img class="deleteIcon" style="height: 30px;position: relative; left:98%;"
              src="../../../assets/fontawesome-web/svgs/regular/trash-can.svg" (click)="removeRowSummer(i)" />
          </div>
        </div>
        <div style="
                  text-align: center;
                  margin-top: 1.5rem;
                  margin-bottom: 1.5rem;
                " *ngIf="!croppingPatternDisabled && cropArraySummer().controls.length < 2">
          <img class="deleteIcon" style="height: 30px;position: relative; left:calc(50% - 15px);margin-bottom:4rem"
            src="../../../assets/fontawesome-web/svgs/regular/square-plus.svg" (click)="addSummerCrop(i)" />
        </div>
      </div>
    </div>

    <!----SUMMER SEASON CODE-->

    <div *ngIf="!isResume || !cropList" style="width: 100%; text-align: center; padding-top: 2rem;">
      <button class="mybutton" (click)="getProposedLoanAmount(multiCropDetailsForm.value)" [disabled]="
                (!isKharifSelected && !isRabiSelected && !isSummerSelected) ||
                sameValueDisable || disableSubmitCrops|| sameValueDisableKharif || sameValueDisableRabi || sameValueDisableSummer
              ">
        {{LOCAL_LANGUAGE.SUBMIT_DETAILS}} (Submit Details)
      </button>
      <!-- <p>{{(!isKharifSelected && !isRabiSelected && !isSummerSelected)+'_'+sameValueDisable+'_'+disableSubmitCrops+'_'+sameValueDisableKharif+'_'+
      sameValueDisableRabi+'_'+
      sameValueDisableSummer}}</p> -->
    </div>

    <!-- (multiCropDetailsForm.controls['cropArrayKharif'].invalid && multiCropDetailsForm.controls['cropArrayRabi'].invalid) -->

    <table *ngIf="isResume && cropList" style="border: 1px solid black; width: 100%">
      <thead>
        <tr>
          <th style="padding:3px;border: 1px solid black; text-align: center">Season</th>
          <th style="padding:3px;border: 1px solid black; text-align: center">Crops</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding:3px;border: 1px solid black; text-align: center">Kharif</td>
          <td style="padding:3px;border: 1px solid black; text-align: center">{{cropList?.kharifCrops[0]?cropList.kharifCrops[0]:", "+cropList.kharifCrops[1]?cropList.kharifCrops[1]:""}}</td>
        </tr>
        <tr>
          <td style="padding:3px;border: 1px solid black; text-align: center">Rabi</td>
          <td style="padding:3px;border: 1px solid black; text-align: center">{{cropList?.rabiCrops[0]?cropList.rabiCrops[0]:", "+cropList.rabiCrops[1]?cropList.rabiCrops[1]:""}}</td>
        </tr>
        <tr>
          <td style="padding:3px;border: 1px solid black; text-align: center">Summer</td>
          <td style="padding:3px;border: 1px solid black; text-align: center">{{cropList?.summerCrops[0]?cropList.summerCrops[0]:", "+cropList.summerCrops[1]?cropList.summerCrops[1]:""}}</td>
        </tr>

      </tbody>
    </table>

    <div class="loading-overlay" *ngIf="showSpinner">
      <div class="spinner"></div>
    </div>

  </wl-card>
</form>



