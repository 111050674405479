import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// import { MaterialModule, MdNativeDateModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import 'hammerjs';
import { MatNativeDateModule } from '@angular/material/core';

import { environment as ENV } from '../../environments/environment';

// import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ButtonComponent } from './button/button.component';
import { CardComponent } from './card/card.component';
// import { DatepickerComponent } from './datepicker/datepicker.component';
// import { DigilockerComponent } from './digilocker/digilocker.component';
// import { FileUploadComponent } from './file-upload/file-upload.component';
import { InputComponent } from './input/input.component';
import { LabelComponent } from './label/label.component';
import { ListComponent } from './list/list.component';
// import { OcrUploadComponent } from './ocr-upload/ocr-upload.component';
import { ProgressComponent } from './progress/progress.component';
// import { SliderComponent } from './slider/slider.component';
import { UserHistoryComponent } from './user-history/user-history.component';
import { UserStatusComponent } from './user-status/user-status.component';

// import { DigilockerService } from './digilocker/digilocker.service';
// import { FileUploadService } from './file-upload/file-upload.service';
import { TitleCasePipe } from './title-case.pipe';


import { ButtonNoLabelComponent } from './button-no-label/button-nolabel.component';

import { InputNoLabelComponent } from './input-nolabel/input-nolabel.component';
import { InputNoLabelwmComponent } from './input-nolabelwm/input-nolabelwm.component';
// import { AutocompleteNoLabelComponent } from './autocomplete-NoLabel/autocomplete-nolabel.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerOverlayComponent } from './spinner-wrapper/spinner-overlay-wrapper.component';
// import { AutocompleteBiComponent } from './autocomplete-bilingual/autocomplete.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
// import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { VerticalStepperComponent } from './vertical-stepper/vertical-stepper.component';
import { ViewPdfComponent } from './view-pdf/view-pdf.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // MaterialModule,
    MatNativeDateModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCardModule,
    MatAutocompleteModule,
    NgxExtendedPdfViewerModule

  ],
  declarations: [
    // AutocompleteComponent,
    ButtonComponent,
    CardComponent,
    // DatepickerComponent,
    // DigilockerComponent,
    // FileUploadComponent,
    InputComponent,
    InputNoLabelComponent,
    InputNoLabelwmComponent,
    LabelComponent,
    ListComponent,
    // OcrUploadComponent,
    ProgressComponent,
    // SliderComponent,
    UserHistoryComponent,
    UserStatusComponent,
    TitleCasePipe,
    ButtonNoLabelComponent,
    // AutocompleteNoLabelComponent,
    SpinnerComponent,
    SpinnerOverlayComponent,
    VerticalStepperComponent,
    ViewPdfComponent,

    // AutocompleteBiComponent
  ],
  exports: [
    // AutocompleteComponent,
    ButtonComponent,
    CardComponent,
    // DatepickerComponent,
    // DigilockerComponent,
    // FileUploadComponent,
    InputComponent,
    InputNoLabelComponent,
    InputNoLabelwmComponent,
    LabelComponent,
    ListComponent,
    // OcrUploadComponent,
    ProgressComponent,
    // SliderComponent,
    UserHistoryComponent,
    UserStatusComponent,
    TitleCasePipe,
    ButtonNoLabelComponent,
    // AutocompleteNoLabelComponent,
    SpinnerComponent,
    SpinnerOverlayComponent,
    VerticalStepperComponent,
    ViewPdfComponent
    // AutocompleteBiComponent
  ],
  providers: [
    // DigilockerService,
    // FileUploadService,

  ],
  entryComponents: [],
})
export class CoreModule { }
