<wl-card heading="Consent for Renewal">

  <app-spinner-overlay [showSpinner]="showSpinner"></app-spinner-overlay>
  <app-spinner-overlay [showSpinner]="showSpinnerWithMessage" [subText]="subText"></app-spinner-overlay>
  <div class="loading-overlay" *ngIf="loading">
    <div class="spinner"></div>
  </div>

  <form [formGroup]="checkForm">
    <div style="display: grid;justify-content: space-evenly;">
      <h2>Hi, {{this.name}}</h2>
      <label style="padding-top: 0;margin-top: 0rem;"><input formControlName="checkbox1" type="checkbox"
          (change)="changeEventOne($event)" style="height: 1rem;width: 1rem;font-size: 1rem;margin-right: .5rem;"
          [checked]="isChecked1" />
        I provide my consent to Bank to hold on selected operative account towards recovery of dues.
      </label>

      <label style="margin-top: 1rem"><input type="checkbox" formControlName="checkbox2"
          (change)="changeEventTwo($event)" style="height: 1rem;width: 1rem;font-size: 1rem;margin-right: .5rem;"
          [checked]="isChecked2" />
        I provide my consent for extraction of credit information report from CIC's & also to disclose the information
        relating to credit facilities availed / to be availed.
      </label>

      <label style="margin-top: 1rem"><input type="checkbox" formControlName="checkbox3"
          (change)="changeEventThree($event)" style="height: 1rem;width: 1rem;font-size: 1rem;margin-right: .5rem;"
          [checked]="isChecked3" />
        I provide my consent to Bank for auto-renewal of my ongoing KCC loan.
      </label>
      <br>
      <button class="verifyButton" [disabled]="!check1 || !check2 || !check3 || disableOnSubmit" (click)="submitConsent()">Submit
        Consent</button>
    </div>
  </form>

</wl-card>
