import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { BaseApi } from 'src/app/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class KaService {
  applicantId:string;
  constructor(private api:BaseApi) { }

  login() {
    var data = {
      appId: this.applicantId
    }
    return this.api.postData('/loans/login', data).pipe(map(resp => {
      console.log("loan doc" + JSON.stringify(resp));
      return resp;
    }))
  }

  getDistricts(){
    return this.api.getComplt(`/v1/land/irtc/getDistricts?requestId=${sessionStorage.getItem('applicantId')}`).pipe(map(resp => {
      console.log("dist" + JSON.stringify(resp));
      return resp;
    }))
  }

  getTaluks(district){
    return this.api.getComplt(`/v1/land/irtc/getTaluk?district=${district}&requestId=${sessionStorage.getItem('applicantId')}`).pipe(map(resp => {
      console.log("taluk" + JSON.stringify(resp));
      return resp;
    }))
  }

  getHoblis(district,taluk){
    return this.api.getComplt(`/v1/land/irtc/getHobli?district=${district}&taluk=${taluk}&requestId=${sessionStorage.getItem('applicantId')}`).pipe(map(resp => {
      console.log("taluk" + JSON.stringify(resp));
      return resp;
    }))
  }

  getVillages(district,taluk,hobli){
    return this.api.getComplt(`/v1/land/irtc/getVillage?district=${district}&taluk=${taluk}&hobli=${hobli}&requestId=${sessionStorage.getItem('applicantId')}`).pipe(map(resp => {
      console.log("taluk" + JSON.stringify(resp));
      return resp;
    }))
  }

  getHissas(district,taluk,hobli,village,surveyno){
    return this.api.getComplt(`/v1/land/irtc/getHissa?district=${district}&taluk=${taluk}&hobli=${hobli}&village=${village}&surveyNo=${surveyno}&requestId=${sessionStorage.getItem('applicantId')}`).pipe(map(resp => {
      console.log("taluk" + JSON.stringify(resp));
      return resp;
    }))
  }
  getPeriods(district,taluk,hobli,village,surveyno,hissa){
    return this.api.getComplt(`/v1/land/irtc/getPeriod?district=${district}&taluk=${taluk}&hobli=${hobli}&village=${village}&surveyNo=${surveyno}&hissaNo=${hissa}&requestId=${sessionStorage.getItem('applicantId')}`).pipe(map(resp => {
      console.log("taluk" + JSON.stringify(resp));
      return resp;
    }))
  }

  fetchLandRecord(district,taluk,hobli,village,surveyno,hissa,period){
    return this.api.postData(`/v1/land/irtc/getLandRecords`,{
      "applicantId": sessionStorage.getItem('applicantId'),
      "district": district,
      "taluk": taluk,
      "hobli": hobli,
      "village": village,
      "surveyNo": surveyno,
      "hissaNo": hissa,
      "period": period,
      //"landArea":landArea,
      "requestId":sessionStorage.getItem('applicantId')
    }).pipe(map(resp => {
      console.log("taluk" + JSON.stringify(resp));
      return resp;
    }))
  }

  submitKABreInfo(nameFromLr,isCourtCase,ownerShare,isMortgaged,isIrrigated, storageID,landArea){
    return this.api.postData(`/v1/land/irtc/landBREChecks`,{
      "applicantId": sessionStorage.getItem('applicantId'),
      "storageId":storageID,
      "requestId":sessionStorage.getItem('applicantId'),
      "isCourtCase":isCourtCase,
      "isIrrigated":isIrrigated,
      "nameFromLr":nameFromLr,
      "ownerShare":ownerShare,
      "isMortgaged":isMortgaged,
      "landArea":landArea,
  }).pipe(map( resp => {
    return resp;
  }))
  }


  getLoanDocument(storageID){
    return this.api.getBlob(`/v1/land/irtc/viewDownloadLandRecords?applicantId=${sessionStorage.getItem('applicantId')}&storageId=${storageID}`)
    .pipe(map(resp => {
      console.log("loan doc" + JSON.stringify(resp));
      return resp;
    }))
  }

  deleteLandRecord(storageId){
    return this.api.deleteWithPayload('/v1/land/irtc/deleteLandRecord', {applicantId:sessionStorage.getItem('applicantId'),requestId:sessionStorage.getItem('applicantId'),storageId}).pipe(map(resp => {
      console.log("loan doc" + JSON.stringify(resp));
      return resp;
    }))
  }

  submitLR(){
    return this.api.postData('/v1/land/irtc/submitLandRecord', {applicantId:sessionStorage.getItem('applicantId')}).pipe(map(resp => {
      console.log("loan doc" + JSON.stringify(resp));
      return resp;
    }))
  }

}
