import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment as ENV } from '../../../environments/environment';

@Component({
  selector: 'wl-list',
  templateUrl: './list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListComponent),
      multi: true
    },
  ]
})
export class ListComponent implements ControlValueAccessor {

  @Input() label: string="";
  @Input() id: string;
  @Input() placeholder: string = this.label;
  @Input() required: boolean = false;
  @Input() options: any[];
  @Input() multiple: boolean = false;
  @Input() disabled : boolean;

  // disabled: boolean = true;

  private _text: any;
  get text(): any { return this._text; }
  set text(v: any) { this._text = v; this.onChange(v); }

  constructor() {
    this.disabled = ENV.disableInput;
  }

  private onChange = (_) => { };

  private onTouched = () => { };

  writeValue(value: any) { this.text = value }

  registerOnChange(fn) { this.onChange = fn }

  registerOnTouched(fn) { this.onTouched = fn }

  setDisabledState(isDisabled) { this.disabled = isDisabled }

}
