import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormGroupDirective, FormArray } from '@angular/forms';
import { ApplicantService } from '../../../applicant.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OpenDialogYesOrNo } from '../../dialog-yes-or-no/dialog-yes-or-no.component';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap, startWith } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { fadeInOut } from '../../animation';
@Component({
  selector: 'land-records-ap',
  templateUrl: './land-records-ap.component.html',
  styleUrls: ['./land-records-ap.component.scss'],
  animations: [fadeInOut]
})
export class LandRecordsApComponent implements OnInit {

  landRecordForm: FormGroup;
  reportLink: any;
  dist: any;
  mand: any;
  vlg: any;
  survey: any;
  districts: any = [];
  mandals: any = [];
  villages: any = [];
  surveyNumbers: any = [];
  mandalDisable: boolean = true;
  villageDisable: boolean = true;
  surveyDisable: boolean = true;
  loading: boolean = false;
  loadingNgoninit: boolean = false;
  ownershipForm: FormGroup;
  noOfDocumentsNew: boolean = false;
  disableSubmitLandRecords: boolean = false;
  mappingData: any;
  breArray: any[] = [];
  isLandBreFailure: boolean = false;
  stateCode: string;
  acreOrHectare: string;
  noOfDocuments: any;
  feedbackflag: boolean;
  retryCount: number = 0;
  repeatGetCreditScoreAPICount = 1;
  getDistrictName: any;
  getMandalName: any;
  getVillageName: any;
  isClicked: boolean;
  originalArray: any[] = [];
  existingDataArray: any[] = [];
  matchedArrays: any[];
  isMatch: boolean;
  public hideSubmitButton: boolean;
  showDelete: boolean;
  filteredDistricts: Observable<string[]>;
  fullDistricts: any = [];
  selectedDistrict: any;
  fullMandals: any = [];
  selectedMandal: any;
  fullVillages: any = [];
  selectedVillage: any;
  shouldSurveyApiBeCalled: boolean = true;
  landDetailsArray: any = [];
  currentLandRecord: any;
  showLandVerifiedTick: boolean;
  @Output() value = new EventEmitter();
  districtReadOnly: boolean;
  surveyReadOnly: boolean;
  villageReadOnly: boolean;
  mandalReadOnly: boolean;
  surveyNumberSearch: any;
  disableVerify: boolean;
  @Input() landRecordsList: any;
  @Input() isResume: boolean;

  constructor(private fb: FormBuilder, private applicant: ApplicantService,
    private snackbar: MatSnackBar, private dialog: MatDialog, private router: Router, private changeDetection: ChangeDetectorRef) {
    this.landRecordForm = this.fb.group({
      district: ['', Validators.required],
      mandal: ['', Validators.required],
      village: ['', Validators.required],
      surveyNumber: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.landRecordForm.controls['mandal'].disable();
    this.landRecordForm.controls['village'].disable();
    this.landRecordForm.controls['surveyNumber'].disable();
    this.getDistricts()
    this.landRecordForm.get('surveyNumber').valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((search) => search != ''),
        filter((search) => search != null),

      ).subscribe((search)=>{
        this.surveyNumberSearch = this.surveyNumbers.filter((item)=>
          // console.log(item)
           item['name'].startsWith(search)
        )
      })
      console.log(this.surveyNumberSearch)

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['landRecordsList']) {
      console.log('Data updated:', changes['landRecordsList']);
      console.log(this.landRecordsList)
      this.updateLandsTable()
    }
  }

  updateLandsTable(){
    if(this.landRecordsList && this.landRecordsList.length && this.isResume){
      let formattedLRArray:[] = this.landRecordsList.map((item)=>{
        let land = {};
        land['district'] = item.userSelections.dist_name;
        land['mandal'] = item.userSelections.mand_name;
        land['village'] = item.userSelections.village_name;
        land['surveyNo'] = item.userSelections.surveyno;
        land['surveyNoArea'] = item.userSelections.surveynoarea;
        return land;
      })
      this.landDetailsArray = formattedLRArray;
      this.showLandVerifiedTick = true;
      this.districtReadOnly = true;
    }
  }


  ngAfterViewInit() {
    this.landRecordForm.controls['district'].valueChanges.subscribe((resp) => {
      console.log(resp)
      this.districts = this.fullDistricts.filter(s => new RegExp(`^${resp}`, 'gi').test(s.name));
      this.changeDetection.detectChanges()
    })
    this.landRecordForm.controls['mandal'].valueChanges.subscribe((resp) => {
      console.log(resp)
      this.mandals = this.fullMandals.filter(s => new RegExp(`^${resp}`, 'gi').test(s.name));
      this.changeDetection.detectChanges()
    })
    this.landRecordForm.controls['village'].valueChanges.subscribe((resp) => {
      console.log(resp)
      this.villages = this.fullVillages.filter(s => new RegExp(`^${resp}`, 'gi').test(s.name));
      this.changeDetection.detectChanges()
    })
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log(this.districts.filter(option => option.toLowerCase().includes(filterValue)))
    return this.districts.filter(option => option.toLowerCase().includes(filterValue));
  }

  getDistricts() {
    this.loading = true;

    console.log('district method');
    this.applicant.getDistrictsAP().subscribe(resp => {
      console.log('inside api');
      this.loading = false;
      if (resp && resp.success && resp.data && resp.data.length) {
        this.loading = false;
        console.log(resp.data)
        this.dist = resp.data
        console.log('andra', this.dist)

        let newDist = this.dist.map(obj => {
          let objct = {}

          objct['id'] = obj.DistrictCode;
          objct['name'] = obj.DistrictName;
          return objct
        })
        this.districts = newDist;
        this.fullDistricts = this.districts;
        // this.districts = ["George","Shashi","Naveen"]
        // this.filteredDistricts = new Observable(this.districts);
        console.log('map districts ', this.districts)
      }
      else if (resp && resp.data && !resp.data.length) {
        this.loading = false;
        this.snackbar.open('OOPS!! Data not found', 'OK', { duration: 3000 });

      }
      else if ((resp.status != 200)) {
        this.loading = false;
        this.snackbar.open('OOPS!! Something went wrong', 'OK', { duration: 3000 });

      } else {
        this.loading = false;
        const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
          width: 'auto', height: 'auto',
          data: {
            content: "District is not found",
            button1Text: "OK",
          }
        });
      }
    },
      (error) => {
        this.loading = false;
        this.retryCount++
        if (this.retryCount <= 6) {
          this.getDistricts();
        } else {
          this.loading = false;
          this.snackbar.open('OOPS!! District not found, Try again', 'OK', { duration: 3000 });
        }


        console.log(error);
      })

  }

  onDistrictChange(event: MatAutocompleteSelectedEvent) {
    console.log(event)
    this.landRecordForm.controls['mandal'].enable();
    this.landRecordForm.controls['mandal'].reset();
    this.landRecordForm.controls['village'].reset();
    this.landRecordForm.controls['surveyNumber'].reset();
    this.selectedDistrict = this.fullDistricts.filter(option => option.name == event.option.value)[0]
    sessionStorage.setItem("selectedDistrict", JSON.stringify(this.selectedDistrict))
    console.log(this.selectedDistrict);
    this.getMandal(this.selectedDistrict.id)
  }

  getMandal(value) {
    console.log('getmandvalue', value);
    let sendDist = { "dist_code": value }
    this.applicant.getMandalAP(sendDist).subscribe(resp => {
      this.mandalDisable = false;
      console.log('get mandal', resp)
      if (resp && resp.success && resp.data && resp.data.length) {
        this.loading = false;
        // console.log(resp.mandals)
        var filtered = resp.data.filter(function (el) { return el.MandalCode != "99"; });
        console.log('filter', filtered);
        this.mand = filtered;
        console.log('this,mand', this.mand);
        let newDist = this.mand.map(obj => {
          let objct = {}

          objct['id'] = obj.MandalCode;
          objct['name'] = obj.MandalName;
          return objct
        })
        this.mandals = newDist;
        this.fullMandals = this.mandals;
        console.log('get mandal', this.mandals)
      } else if (resp && resp.data && !resp.data.length) {
        this.loading = false;
        this.snackbar.open('OOPS!! Data not found', 'OK', { duration: 3000 });
      }
      else if ((resp.status != 200)) {
        this.loading = false;
        this.snackbar.open('OOPS!! Somthing went wrong', 'OK', { duration: 3000 });
      } else {
        this.loading = false;
        const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
          width: 'auto', height: 'auto',
          data: {
            content: "Mandal is not found",
            button1Text: "OK",
          }
        });

      }
    },
      (error) => {
        this.loading = false;
        console.log(error);
        this.snackbar.open('OOPS!! Internal server error', 'OK', { duration: 3000 });
      })
  }

  villageSelected(event: MatAutocompleteSelectedEvent) {
    console.log(event)
    this.landRecordForm.controls['surveyNumber'].enable();

    this.landRecordForm.controls['surveyNumber'].reset();
    this.selectedVillage = this.fullVillages.filter(option => option.name == event.option.value)[0]
    console.log(this.selectedMandal);
    this.getSurveyNos()
  }

  getSurveyNos(){
    let mandal = this.selectedMandal.id;
    let village = this.selectedVillage.id;
    let district = this.selectedDistrict.id;
    console.log(mandal);
    console.log(village);
    console.log(district);

    let value = { dist_code: district,mand_code: mandal, village_code: village  }

    this.applicant.getSurveyNumberAP(value).subscribe((resp)=>{
      console.log("surrrr",resp)

      if (resp && resp.success && resp.data && resp.data.length) {
        this.loading = false;
        this.surveyDisable = false;
        console.log(resp.data)
        this.survey = resp.data
        console.log('andra', this.survey)

        let newDist = this.survey.map(obj => {
          let objct = {}
          objct['name'] = obj.survey_no || obj.mNumber;
          return objct
        })
        this.surveyNumbers = newDist;
        this.surveyNumberSearch = this.surveyNumbers
        console.log('map survey no ', this.surveyNumbers)
      }
      else if (resp && resp.data && !resp.data.length) {
        this.loading = false;
        this.snackbar.open('OOPS!! Data not found', 'OK', { duration: 3000 });
      }
      else if (resp.status && (resp.status != 200)) {
        this.loading = false;
        this.snackbar.open('OOPS!! Somthing went wrong', 'OK', { duration: 3000 });
      } else {
        // debugger
        this.loading = false;
        // const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
        //   width: 'auto', height: 'auto',
        //   data: {
        //     content: "Survey number not found",
        //     button1Text: "OK",
        //   }
        // });
      }

    },
    (error) => {
      this.loading = false;
      this.shouldSurveyApiBeCalled = true;
      console.log(error);
      this.snackbar.open('OOPS!! Internal server error', 'OK', { duration: 3000 });
    })

  }

  mandalSelected(event: MatAutocompleteSelectedEvent) {
    console.log(event)
    this.landRecordForm.controls['village'].enable();
    this.landRecordForm.controls['village'].reset();
    this.landRecordForm.controls['surveyNumber'].reset();
    this.selectedMandal = this.fullMandals.filter(option => option.name == event.option.value)[0]
    console.log(this.selectedMandal);
    this.getVillage()
  }

  getVillage() {
    let data = { "dist_code": this.selectedDistrict.id, "mand_code": this.selectedMandal.id }
    this.applicant.getVillageAP(data).subscribe(resp => {
      console.log('get village', resp)
      if (resp && resp.success && resp.data && resp.data.length) {
        this.loading = false;
        this.villageDisable = false;
        this.surveyDisable = false;
        console.log(resp.data)
        this.vlg = resp.data
        let newDist = this.vlg.map(obj => {
          let objct = {}

          objct['id'] = obj.VillageCode;
          objct['name'] = obj.VillageName;
          return objct
        })
        this.villages = newDist;
        this.fullVillages = this.villages;
        console.log('get villages', this.villages)
      } else if (resp && resp.data && !resp.data.length) {
        this.loading = false;
        this.snackbar.open('OOPS!! Data not found', 'OK', { duration: 3000 });
      }
      else if ((resp.status != 200)) {
        this.loading = false;
        this.snackbar.open('OOPS!! Somthing went wrong', 'OK', { duration: 3000 });
      }
      else {
        this.loading = false;
        const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
          width: 'auto', height: 'auto',
          data: {
            content: "Village is not found",
            button1Text: "OK",
          }
        });
      }
    },
      (error) => {
        this.loading = false;
        console.log(error);
        this.snackbar.open('OOPS!! Internal server error', 'OK', { duration: 3000 });
      })
  }

  surveySelected(event: MatAutocompleteSelectedEvent) {
    // this.shouldSurveyApiBeCalled = false;
    console.log(event)
  }

  landRecordUpdate(formDirective: FormGroupDirective) {
    let data = {
      "dist_code": this.selectedDistrict.id,
      "dist_name":this.selectedDistrict.name,
      "mand_code": this.selectedMandal.id,
      "mand_name": this.selectedMandal.name,
      "village_code": this.selectedVillage.id,
      "village_name":this.selectedVillage.name,
      "surveyno": this.landRecordForm.controls['surveyNumber'].value,
      "applicant_id": sessionStorage.getItem('applicantId')
    }
    // debugger
    if (this.landDetailsArray.length > 0) {
      let currentLand = { district: this.selectedDistrict.name, mandal: this.selectedMandal.name, village: this.selectedVillage.name, surveyNo: data.surveyno }
      let proceed = this.checkForDuplicateLr(currentLand)
      if (proceed) {
        this.snackbar.open("You cannot add duplicate land record", "OK", { duration: 3000 })
        return
      } else {

      }
    }
    this.loading = true;
    this.isClicked = false;
    let value = { ...this.landRecordForm.value };
    console.log('final value', value)

    this.applicant.getAdangalBySurveyNo(data).subscribe((resp) => {
      console.log(resp)
      this.loading = false;
      if (resp && resp.success && resp.data && resp.data.length) {
        this.currentLandRecord = resp.data[0];

        let land = { district: this.selectedDistrict.name, mandal: this.selectedMandal.name, village: this.selectedVillage.name, surveyNo: data.surveyno, surveyNoArea: this.currentLandRecord.Cultivatable_land }
        this.landDetailsArray.push(land)
        this.showLandVerifiedTick = true;
        this.askForMoreLandRecords()

        sessionStorage.setItem('landArray', JSON.stringify(this.landDetailsArray));
      } else if (resp && !resp.success && resp.message) {
        this.snackbar.open(resp.message, "OK", { duration: 3000 })
        this.discardLrAndProceed()
      } else if (resp && resp.message) {
        this.snackbar.open(resp.message, "OK", { duration: 3000 })
      }
    }, (err) => {
      this.loading = false;
      this.snackbar.open("Something went wrong Please try again.", "OK", { duration: 3000 })
    })
  }

  askForMoreLandRecords() {
    if (this.landDetailsArray.length < 5) {
      const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
        width: 'auto', height: 'auto',
        data: {
          content1: "Would you like to add another land records?",
          button1Text: "Yes",
          button2Text: "No"
        }
      });
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp == 'close') { //button1 yes
          //continue adding more
          //disable district
          this.districtReadOnly = true;
        } else if (resp == 'dontClose') { //button 2 no
          //continue to cropping pattern
          this.disableVerify = true;
          this.landRecordsDone()
        }
      })
    } else if (this.landDetailsArray == 5) {
      const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
        width: 'auto', height: 'auto',
        data: {
          content1: "You are only allowed to add a maximum of 5 land records.",
          button1Text: "OK",
          // button2Text:"No"
        }
      });
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp == 'close') { //button1 OK
          this.landRecordsDone()
        }
      })
    }

  }

  discardLrAndProceed() {
    const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
      width: 'auto', height: 'auto',
      data: {
        content1: "The provided land record failed at verification. Would you like to discard the record and proceed?",
        button1Text: "OK",
        // button2Text:"No"
      }
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp == 'close') { //button1 OK

      }
    })
  }

  checkForDuplicateLr(land) {
    return this.landDetailsArray.some((item) => land.district == item.district && land.mandal == item.mandal && land.village == item.village && land.surveyNo == item.surveyNo) //returns true if it is duplicate
  }

  landRecordsDone() {
    this.surveyReadOnly = true;
    this.villageReadOnly = true;
    this.mandalReadOnly = true;
    this.districtReadOnly = true;


    let data = {
      success: true,
      landRecords: this.landDetailsArray
    }
    this.value.emit(data)
  }

}
