import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private jwtHelper: JwtHelperService;

  constructor() {
    this.jwtHelper = new JwtHelperService();
  }

  validateToken(token: string): boolean {
    try {
      // Decode the token
      if (token) {
        const decodedToken = this.jwtHelper.decodeToken(token);

        // Check token expiration
        if (decodedToken && decodedToken.exp) {
          const expirationTime = decodedToken.exp * 1000; // Convert seconds to milliseconds
          const currentTime = Date.now();

          // Check if the token is expired
          if (expirationTime < currentTime) {
            // Token is expired, handle it (e.g., refresh token, redirect to login)
            console.warn('Token has expired');
            // Handle expiration (e.g., refresh token, redirect to login)
            return false;
          }
        } else {
          return true;
        }

        // Add additional validation checks if needed (issuer, audience, etc.)

        // Token is valid
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // Token validation failed
      console.error('JWT validation failed:', error.message);
      return false;
    }
  }


}