import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ApplicantService } from 'src/app/applicant.service';
import * as constants from "../../constant";
import * as HindiText from "../../mp-popup-message";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LanguageService } from 'src/app/language.service';
import { fadeInOut } from '../animation';
import { Milestone } from '../enum';

@Component({
  selector: 'app-loan-amount',
  templateUrl: './loan-amount.component.html',
  styleUrls: ['./loan-amount.component.scss'],
  animations: [fadeInOut]
})
export class LoanAmountComponent {
  showSpinner: boolean;
  showSpinnerWithMessage: any;
  subText: any;
  stepper: any = 7;
  showButtons: boolean;
  showLoanAmountTick: boolean;
  colU: any;
  showCustomerLoanAmount: any;
  disableSubmitButton: boolean;
  disableSubmitButtonForever: boolean;

  toggleSubmitLoanAmountCondOne: boolean = true;
  toggleSubmitLoanAmountCondTwo: boolean = true;
  loanDetailsForm: FormGroup;
  isChecked: boolean;
  iseventchecked : boolean = false;
  estimatedLoanAmount: number;
  isLoanAmtFarmerDisabled: boolean;
  DisabledFlagValue: string;
  @Input() loanAmountInfo: { estimatedAmount: number; loanAmount: number; success: boolean, isResume: boolean, milestone:string };
  LOCAL_LANGUAGE: any;
  submittedLoanAmounts: any;
  @Output() value = new EventEmitter();

  constructor(private applicant: ApplicantService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private languageservice: LanguageService,
    private snackbar: MatSnackBar) {
    this.loanDetailsForm = this.fb.group({
      // acressumnum: [""],
      loanAmtFarmer: ["", Validators.required],
      estimatedLoanAmt: ["", Validators.required],
      loanAmountSubmissionCheckOne: ["", Validators.required],
      loanAmountSubmissionCheckTwo: ["", Validators.required],
      // loanAmtDiffYes:[''],
      // loanAmtDiffNo:[''],
      loanAmtDiff: [""],
    });
  }

  ngOnInit() {
    let language = sessionStorage.getItem('lang')

    this.LOCAL_LANGUAGE = this.languageservice.getConstants(language)
    this.applicant.getApplicant().subscribe((resp) => {

      if(resp && resp.success && resp.data){
 
      
      this.loanDetailsForm.patchValue({'estimatedLoanAmt': resp.data.loanAmounts.proposedLoanAmount})
      

      let proposedLoanAmount = resp.data.loanAmounts.proposedLoanAmount;
      let finalLoanAmount = resp.data.loanAmounts.fifthYearLoanAmount;
      if( resp.data && proposedLoanAmount == finalLoanAmount){
           sessionStorage.setItem("LoanAmount", proposedLoanAmount);
               
                this.showCustomerLoanAmount = false;
                this.loanDetailsForm.patchValue({'loanAmtDiff': "0" , "loanAmountSubmissionCheckOne": true, loanAmountSubmissionCheckTwo: true});

      } else if( resp.data && proposedLoanAmount != finalLoanAmount ){
           
        sessionStorage.setItem("LoanAmount",finalLoanAmount );
        this.showCustomerLoanAmount = true;
        this.loanDetailsForm.patchValue({'loanAmtDiff': "1" , "loanAmountSubmissionCheckOne": true, loanAmountSubmissionCheckTwo: true,
        "loanAmtFarmer"  : finalLoanAmount
        });
  
}
      }
      else {}

    })
    

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['loanAmountInfo']) {
      console.log('Data updated:', changes['loanAmountInfo']);
      // Perform actions based on the updated value in the child component
      console.log(this.loanAmountInfo)
      this.updateCard()
    }
  }

  updateCard() {
    if (this.loanAmountInfo && this.loanAmountInfo.success && this.loanAmountInfo.estimatedAmount && this.loanAmountInfo.isResume) {

      this.loanDetailsForm.controls['estimatedLoanAmt'].patchValue(this.loanAmountInfo.estimatedAmount)

      this.estimatedLoanAmount = this.loanAmountInfo.estimatedAmount;
      if(this.loanAmountInfo.loanAmount){
        this.showCustomerLoanAmount = true;
        this.DisabledFlagValue = "true";
        this.loanDetailsForm.controls['loanAmtFarmer'].patchValue(this.loanAmountInfo.loanAmount?this.loanAmountInfo.loanAmount:0)
      }

      this.disableSubmitButton = true;

      this.isLoanAmtFarmerDisabled = true;
    } else if (this.loanAmountInfo && this.loanAmountInfo.success && this.loanAmountInfo.loanAmount && !this.loanAmountInfo.isResume) {
      this.loanDetailsForm.controls['estimatedLoanAmt'].patchValue(this.loanAmountInfo.loanAmount)
      // this.showCustomerLoanAmount = true;
    }

    if(this.loanAmountInfo.milestone === Milestone.LOAN_SANCTION_SUCCESS){
      this.DisabledFlagValue = "true";
      this.disableSubmitButtonForever = true;
      this.loanDetailsForm.controls['loanAmountSubmissionCheckOne'].disable()
      this.loanDetailsForm.controls['loanAmountSubmissionCheckTwo'].disable()
    }
  }


  changeEventOne(event) {
    console.log(event)
    if (event.target.checked) {
      this.toggleSubmitLoanAmountCondOne = false;
    } else {
      this.toggleSubmitLoanAmountCondOne = true;
    }
  }

  changeEventTwo(event) {
    console.log(event)
    if (event.target.checked) {
      this.toggleSubmitLoanAmountCondTwo = false;
    } else {
      this.toggleSubmitLoanAmountCondTwo = true;
    }
  }

  differentLoanAmount(event) {
    console.log(event);
    if (event == "Y") {
      this.showCustomerLoanAmount = true;
      this.disableSubmitButton = false;
      this.iseventchecked = true;
    } else if (event == "N") {
      this.showCustomerLoanAmount = false;
      this.disableSubmitButton = false;
      this.iseventchecked = true;
      this.loanDetailsForm.patchValue({ loanAmtFarmer: "" });
    }
    else{
      this.iseventchecked = false;
      this.disableSubmitButton = true;
    }
  }

  submitCropNLoan(value) {
    this.showSpinner = true;

    this.sendSpinnerData();
    this.submittedLoanAmounts = value;
    let data = {
      requestedLoanAmount: value.loanAmtFarmer ? value.loanAmtFarmer : value.estimatedLoanAmt,
      applicantId: sessionStorage.getItem('applicantId')
    }
    this.applicant.updateLoanAmount(data).subscribe((resp) => {
      console.log(resp);
      if (resp && resp.success) {
        this.snackbar.open("Loan Amount updated successfully", "OK", { duration: 3000 })
        sessionStorage.setItem('reqLoanAmt',data.requestedLoanAmount)
        this.generateDocs()
        this.updateFinalLoanAmount()
      } else {

        this.showSpinner = false;
        this.sendSpinnerData();
        this.snackbar.open("Failed to update Loan Amount", "OK", { duration: 3000 })
      }
    }, (err) => {

      this.showSpinner = false;
      this.sendSpinnerData();
      this.snackbar.open("Failed to update Loan Amount", "OK", { duration: 3000 })
    })
  }

  generateDocs() {
    let data = {
      applicantId: sessionStorage.getItem('applicantId'),
      docTypes: constants.DOC_TYPES_PDF
    }
    this.showSpinner = true;
    this.sendSpinnerData();
    this.applicant.generateDocs(data).subscribe((resp) => {
      if (resp && resp.success) {
        this.showSpinner = false;
        this.disableSubmitButton = true;
        this.showLoanAmountTick = true;
        this.stepper = 8;
        this.sendSpinnerData();
        this.snackbar.open("Documents generated successfully", "OK", { duration: 3000 })
      } else {

        this.showSpinner = false;
        this.stepper = 7;
        this.sendSpinnerData();
        this.snackbar.open("Documents generation failed", "OK", { duration: 3000 })
      }
    }, (err) => {

      this.showSpinner = false;
      this.stepper = 7;
      this.sendSpinnerData();
      this.snackbar.open("Documents generation failed", "OK", { duration: 3000 })
    })
  }

  sendSpinnerData() {
    let data = {
      showSpinner: this.showSpinner,
      spinnerWithMessage: this.showSpinnerWithMessage,
      subText: this.subText,
      stepper: this.stepper,
    };
    this.applicant.passValue(data);
  }

  updateFinalLoanAmount(){
    let data = this.submittedLoanAmounts
    this.value.emit(data)
  }



}
