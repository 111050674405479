export const NTB_JOURNEY = [
  {
    "ENGLISH_TEXT": "Mobile Verification",
    "STEPPER_VALUE": "1",
    "LOCAL_LANG_KEY": "Mobile_Verification",
    "SCROLL_ID":"mobile-component"
  },
  {
    "ENGLISH_TEXT": "Aadhaar verification",
    "STEPPER_VALUE": "2",
    "LOCAL_LANG_KEY": "Aadhaar_verification",
    "SCROLL_ID":"aadhaar-component"
  },
  {
    "ENGLISH_TEXT": "PAN/VoterID",
    "STEPPER_VALUE": "3",
    "LOCAL_LANG_KEY": "PAN_or_VoterID",
    "SCROLL_ID":"panvot-component"
  },
  {
    "ENGLISH_TEXT": "Credit Report",
    "STEPPER_VALUE": "4",
    "LOCAL_LANG_KEY": "Credit_Report",
    "SCROLL_ID":"credit-component"
  },
  {
    "ENGLISH_TEXT": "Land Verification",
    "STEPPER_VALUE": "5",
    "LOCAL_LANG_KEY": "Land_Verification",
    "SCROLL_ID":"land-component"
  },
  {
    "ENGLISH_TEXT": "Crop Selection",
    "STEPPER_VALUE": "6",
    "LOCAL_LANG_KEY": "Crop_Selection",
    "SCROLL_ID":"crop-component"
  },
  {
    "ENGLISH_TEXT": "Sanction",
    "STEPPER_VALUE": "7",
    "LOCAL_LANG_KEY": "Sanction",
    "SCROLL_ID":"sanction-component"
  },
]

export const RENEWAL_JOURNEY = [
  {
    "ENGLISH_TEXT": "Land Verification",
    "STEPPER_VALUE": "1",
    "LOCAL_LANG_KEY": "Land_Verification",
    "SCROLL_ID":"land-component"
  },
  {
    "ENGLISH_TEXT": "Credit Report",
    "STEPPER_VALUE": "2",
    "LOCAL_LANG_KEY": "Credit_Report",
    "SCROLL_ID":"credit-component"
  },
  {
    "ENGLISH_TEXT": "Crop Selection",
    "STEPPER_VALUE": "3",
    "LOCAL_LANG_KEY": "Crop_Selection",
    "SCROLL_ID":"crop-component"
  },
  {
    "ENGLISH_TEXT": "Sanction",
    "STEPPER_VALUE": "4",
    "LOCAL_LANG_KEY": "Sanction",
    "SCROLL_ID":"sanction-component"
  },
]


