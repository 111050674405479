import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-progress',
  templateUrl: './progress.component.html',
})
export class ProgressComponent {

  @Input() hidden: boolean;

  constructor() { }

}
