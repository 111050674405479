<wl-card heading="Customer Verification" [subheading1]="showWhiteTickMobileNos"  [@fadeInOut]  >
  <form [formGroup]="detailsForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <!-- <p style="text-align: center; padding-bottom: 0px;margin-bottom: 0px;">कृपया अपना मोबाइल नंबर दर्ज करें
        </p> -->

        <p style="text-align: center;  padding-top: 0px;margin-top: 0px;">Enter Customer Mobile No. </p>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field style="width: 100%;" appearance="outline">
          <input matInput type="text" formControlName="mobileNo" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" [readonly]="isMobileNoButtonDisabled" >
          <mat-error *ngIf="detailsForm.get('mobileNo').invalid">
            Please enter a valid phone number.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="text-align: center;">
        <button class="mybutton" (click)="generateLink(detailsForm.value)"
        [disabled]="detailsForm.controls['mobileNo'].invalid || isMobileNoButtonDisabled">Generate Link</button>

      </div>
    </div>
    <div class="loading-overlay" *ngIf="loading">
      <div class="spinner"></div>
      <div class="spinner-text">
        Waiting for Applicant to finish KYC.
      </div>
    </div>
  </form>

</wl-card>
