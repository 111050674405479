<div style="margin-top: 4rem;">
  <div class="cardStyles">
    <app-mobile-verification [mobileInfo]="mobileInfo" *ngIf="showMobileVerificationCard" (value)="mobileVerificationDone($event)"></app-mobile-verification>
  </div>


  <div class="cardStyles">
    <app-aadhaar-verification class="cardStyles" (value)="aadhaarVerificationDone($event)" *ngIf="showAadhaarCard" [aadhaarInfo]="aadhaarInfo"></app-aadhaar-verification>
  </div>

</div>
