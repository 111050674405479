import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { JourneyType } from './landing/enum';

@Injectable()
export class AgainInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    console.log(req)
    //https://geolocation-db.com/json/
    //req.url == "https://api-bharatmandi.whatsloan.com/v1/ipTracking/getGeoLocation"
    if (req.url.includes("/v1/ipTracking/getGeoLocation")) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          // Handle HTTP errors or other rejections here
          console.error('Interceptor Error:', error);
          // Optionally handle specific error codes, if needed
          if (error.status === 404) {
            // Handle 404 errors
          }
          return throwError(error);
        })
      );
    } else {
      let tokenizedReq;
      console.log(req)
      // debugger
      tokenizedReq = req.clone({
        headers:
          req.headers
            .set('x-journey-type', sessionStorage.getItem('jrnyType') || '')
            

      });
console.log(sessionStorage.getItem('jrnyType'))

      return next.handle(tokenizedReq).pipe(
        catchError((error: HttpErrorResponse) => {
          // Handle HTTP errors or other rejections here
          console.error('Interceptor Error:', error);
          // Optionally handle specific error codes, if needed
          if (error.status === 404) {
            // Handle 404 errors
          }
          return throwError(error);
        })
      );
    }



  }
}
