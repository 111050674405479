import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FreshCaseOtpDialogComponent } from '../fresh-otp-dialog/otp-dialog.component';
import * as constants from "../../constant";
import * as HindiText from "../../mp-popup-message";
import { OpenDialogYesOrNo } from '../dialog-yes-or-no/dialog-yes-or-no.component';
import { ApplicantService } from 'src/app/applicant.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DynamicModalComponent } from 'src/app/dynamic-modal/dynamic-modal.component';
import { fadeInOut, zoomInOut } from '../animation';
import { Milestone } from '../enum';

@Component({
  selector: 'app-mobile-verification',
  templateUrl: './mobile-verification.component.html',
  styleUrls: ['./mobile-verification.component.scss'],
  animations: [fadeInOut, zoomInOut]
})
export class MobileVerificationComponent {
  zoomState: string = 'normal';
  PanOrVoterid: boolean = false;
  showAadhaarField: boolean = false;
  isMobileNoButtonDisabled: boolean = false;
  showGreenTickMobileNos: boolean = false;
  showWhiteTickMobileNos: boolean = false;
  showWaitingVerify: boolean = false;
  detailsForm: FormGroup;
  @Output() value = new EventEmitter();
  mobileNumber: string = "";
  @Input() mobileInfo:any;

  @ViewChild('aadhaarLayout', { static: true }) aadhaarLayout!: ElementRef;
  userInputDiv: string;
  isResumable: any;
  isResumableResp: any;
  isInputReadOnly: boolean;

  constructor(private dialog: MatDialog,
    private applicant: ApplicantService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar) {
    this.detailsForm = this.fb.group({
      mobileNo: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]]
    });
  }


  ngOnInit() {
    console.log("mob verification component")
    this.userInputDiv = '<div style="background-color: light-grey; height:50px;width:200px;"><p style="color:red"><b>Please refresh the page and provide location access to proceed.</b></p></div>'
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mobileInfo']) {
      console.log('Data updated:', changes['mobileInfo']);
      // Perform actions based on the updated value in the child component
      console.log(this.mobileInfo)
      this.updateCard()
    }
  }

  updateCard(){
    if(this.mobileInfo){
      this.detailsForm.controls['mobileNo'].patchValue(this.mobileInfo.mobNo)
      if(this.mobileInfo.assisted){
        this.isInputReadOnly = true;
      }else{
        this.isMobileNoButtonDisabled = true;
      }


      // this.showWhiteTickMobileNos = true;
    }
  }

  openDynamicModal() {
    this.dialog.open(DynamicModalComponent, { disableClose: true, data: { divContent: this.userInputDiv } })
  }

  verifyMobileNoNTB(value) {
    console.log('to check', value);
    this.mobileNumber = value.mobileNo;

    let locationIpDetails = this.handleGeoLocation();


    const dialogRefOTP = this.dialog.open(FreshCaseOtpDialogComponent, {
      disableClose: true,
      height: "auto",
      width: "auto",
      data: {
        type: constants.MOBILE_NUM,
        mobile_no: value.mobileNo,
        panNo: value.pan,
      },
    });
    dialogRefOTP.afterClosed().subscribe(async (resp) => {
      if (resp && !resp.success) {
        const dialogRef = this.showDialog(
          "auto",
          "auto",
          "",
          resp.message,
          "OK",
          this.applicant.moreInfo(resp)
        );
      } else if (resp && resp.message == constants.MESSAGE_SUCCESS_VERIFIED) {
        this.showAadhaarField = true;
        this.isMobileNoButtonDisabled = true;
        this.showGreenTickMobileNos = true;
        this.showWhiteTickMobileNos = true;
        // this.snackbar.open("Mobile number is verified", "OK", { duration: 3000 })

        let isResumable = await this.checkIsResumable()
        if(isResumable && this.mobileInfo && this.mobileInfo.assisted){
          const dialogRef = this.showDialog(
            "auto",
            "auto",

            `The application of this mobile number is already in progress. ${this.getResumeMessage(this.isResumableResp.data.milestone)?("Current status:"+(this.getResumeMessage(this.isResumableResp.data.milestone))):""}`,
            "Please contact the BC to proceed.",
            "OK",

            this.applicant.moreInfo(resp)
          );
          dialogRef.afterClosed().subscribe((resp) => {

            // this.mobileVerificationDone()
            console.log("Assisted journey in progress already")

          })
        }else
        if (isResumable) {
          const dialogRef = this.showDialog(
            "auto",
            "auto",
            `The application of this mobile number is already in progress. ${this.getResumeMessage(this.isResumableResp.data.milestone)?("Current status:"+(this.getResumeMessage(this.isResumableResp.data.milestone))):""}`,
            "Please click on proceed to continue the journey.",
            "Proceed",

            this.applicant.moreInfo(resp)
          );
          dialogRef.afterClosed().subscribe((resp) => {

            this.mobileVerificationDone()

          })
        } else {

          this.mobileVerificationDone()

        };


        // this.initiateScroll(this.aadhaarLayout)
      } else if (resp && resp.message == constants.INVALID_OTP) {
        this.showDialog(
          "auto",
          "auto",
          HindiText.INVALID_OTP.hindi,
          HindiText.INVALID_OTP.english,
          "OK",
          this.applicant.moreInfo(resp)
        );
      } else if (resp && resp.message == constants.OTP_EXPIRED) {
        this.showDialog(
          "auto",
          "auto",
          HindiText.OTP_EXPIRED.hindi,
          HindiText.OTP_EXPIRED.english,
          "OK",
          this.applicant.moreInfo(resp)
        );
      } else if (resp && resp.message == constants.MOBILE_NUM_INVALID) {
        this.showDialog(
          "auto",
          "auto",
          HindiText.MOBILE_NUM_INVALID.hindi,
          HindiText.MOBILE_NUM_INVALID.english,
          "OK",
          this.applicant.moreInfo(resp)
        );
      } else {
        this.showDialog(
          "auto",
          "auto",
          HindiText.SOMETHING_WENT_WRONG.hindi,
          HindiText.SOMETHING_WENT_WRONG.english,
          "OK",
          this.applicant.moreInfo(resp)
        );
      }
    });
  }


  handleGeoLocation(){
    let locationIpDetails = JSON.parse(sessionStorage.getItem('geo-loc-n-ip'))

    if (locationIpDetails && !locationIpDetails.ipv4) {
      this.openDynamicModal()
      this.snackbar.open("Please refresh the page and provide location access to proceed.", "OK", { duration: 3000 })
      return
    } else if (locationIpDetails && !locationIpDetails.latitude) {
      this.openDynamicModal()
      this.snackbar.open("Please refresh the page and provide location access to proceed.", "OK", { duration: 3000 })
      return
    } else if (locationIpDetails && !locationIpDetails.longitude) {
      this.openDynamicModal()
      this.snackbar.open("Please refresh the page and provide location access to proceed.", "OK", { duration: 3000 })
      return
    }else {return locationIpDetails};
  }

  checkIsResumable() {
    return new Promise(async (resolve, reject) => {
      this.applicant.checkIsResumable(this.mobileNumber).subscribe((resp) => {
        console.log(resp)
        // debugger
        this.isResumableResp = resp;
        if (resp && resp.data && resp.data.isResumable) {
          resolve(true)
        } else {
          resolve( false)
        }
      }, (err) => {
        this.snackbar.open("API request failed, Please try again later", "OK")
      })
    })
  }

  getResumeMessage(mileStone) {
    return constants.MILE_STONE_RESUME_MESSAGE[mileStone]
  }

  mobileVerificationDone() {
    let data = {
      mobileNo: this.mobileNumber,
      success: true,
      resumeData: this.isResumableResp,
      isResumable: this.isResumableResp.data.isResumable
    }
    this.value.emit(data);
  }

  showDialog(
    width: any,
    height: any,
    content1: string,
    content2: string,
    buttonText: string,
    errorText: string,
  ) {
    let dialogRef = this.dialog.open(OpenDialogYesOrNo, {
      width: width,
      height: height,
      data: {
        content1: content1,
        content2: content2,
        button1Text: buttonText,
        errorText: errorText
      },
    });

    return dialogRef;
  }

  initiateScroll(el: ElementRef) {
    setTimeout(() => {
      console.log('---------')
      el.nativeElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 200)
  }

  openDialog() {
    this.dialog.open(DynamicModalComponent, {
      disableClose: true,
      data: { divContent: this.userInputDiv },
    });
  }
}
