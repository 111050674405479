import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LanguageService } from 'src/app/language.service';
import * as constants from "../../constant";
import { NTB_JOURNEY, RENEWAL_JOURNEY } from './stepper-constants';
import { JourneyType } from 'src/app/landing/enum';

@Component({
  selector: 'app-vertical-stepper',
  templateUrl: './vertical-stepper.component.html',
  styleUrls: ['./vertical-stepper.component.scss']
})
export class VerticalStepperComponent {
  langState: string;
  stepper: number = 3;
  LOCAL_LANGUAGE: any;
  stepperData: { ENGLISH_TEXT: string; STEPPER_VALUE: string; LOCAL_LANG_KEY: string; }[];
  @Input() JourneyType: string;
  @Output() scrollToSection = new EventEmitter<string>();


  constructor(private languageservice:LanguageService){

  }

  ngOnInit(){


    this.langState = localStorage.getItem('state1');

    console.log(this.langState);

    if (this.stepper >= 1) {
      if (this.langState == "Karnataka") {
        this.LOCAL_LANGUAGE = this.languageservice.getConstants(constants.LANGUAGES.KANNADA);
      }
      else if (this.langState == ("Andra Pradesh" || "Telangana")) {
        this.LOCAL_LANGUAGE = this.languageservice.getConstants(constants.LANGUAGES.TELUGU);
      }
    }

    if(this.JourneyType == "NTB"){
      this.stepperData = NTB_JOURNEY;
    }else if(this.JourneyType == "RENEWAL"){
      this.stepperData = RENEWAL_JOURNEY;
    }


  }

  onMilestoneClick(elementId: string): void {
    console.log(elementId)
    this.scrollToSection.emit(elementId);
  }
}
