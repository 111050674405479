import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from 'src/app/landing/dialog-yes-or-no/dialog-yes-or-no.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { KaService } from '../ka.service';

@Component({
  selector: 'app-ka-land-verify',
  templateUrl: './ka-land-verify.component.html',
  styleUrls: ['./ka-land-verify.component.scss']
})
export class KaLandVerifyComponent {

  @Input() inputData: any;
  
  LandForm: FormGroup;

  isCourtCaseDisabled: boolean;
  isOwnerShareDisabled: boolean;
  isIrrigatedDisabled: boolean;
  showWaiting: boolean;

  nameFromLr: any;
  isCourtCase: boolean;
  ownerShare: boolean;
  isMortgaged: boolean;
  isIrrigated; boolean;
  landArea: any;

  showSpinner: boolean;

  isMobile: boolean = false;

  private breakpointSubscription: Subscription;
  textAlign: string;

  breDetailsArray: any = [];


  constructor(private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private bhoomi: KaService,
    public dialogRef: MatDialogRef<KaLandVerifyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpointObserver: BreakpointObserver) {

    this.LandForm = this.fb.group({
      nameFromLr: ['', Validators.required],
      isCourtCase: ['', Validators.required],
      ownerShare: ['', Validators.required],
      isMortgaged: ['', Validators.required],
      isIrrigated: ['', Validators.required],
      landArea:  ['', Validators.required]
    })

    this.breakpointSubscription = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((state) => {
        console.log(state)
        this.isMobile = state.matches;
        if (this.isMobile) {
          this.textAlign = "center"
        } else {
          this.textAlign = "center"
        }


      });
  }

  ngOnInit() {
    console.log(this.data)
    
  }

  submit(data) {
    // let storageID = this.landDetailsArray[data].storageId;
    console.log(data)
    this.showSpinner = true;
    this.landArea = data.landArea;
    this.bhoomi.submitKABreInfo(this.nameFromLr, this.isCourtCase, this.ownerShare, this.isMortgaged, this.isIrrigated, this.data.storageId,this.landArea).subscribe((resp) => {
      this.showSpinner = false;
      if (resp && resp.success) {

        // let bre = {};
        // bre["nameFromLr"] = resp.data.nameFromLr;
        // bre["isCourtCase"] = resp.data.isCourtCase;
        // bre["ownerShare"] = resp.data.ownerShare;
        // bre["isMortgaged"] = resp.data.isMortgaged;
        // bre["isIrrigated"] = resp.data.isIrrigated;
        // bre['storageId'] = resp.data.storageId;
        // this.breDetailsArray.push(bre)
 sessionStorage.setItem("landArea",this.landArea)
        this.sendDataToParent(resp)
      }
    }), (err) => {
      this.snackbar.open("Failed to fetch Bre Status.", "OK")
      this.showSpinner = false;
      //console.log(data)
    }

  }

  sendDataToParent(resp): void {
    this.landArea = resp.data.landArea;
    // Send data back to the parent
    this.dialogRef.close(resp);
  }
}
