/** Created by Naveen on 24th Nov 2022
 * This file maintains the popup mesage both in english and hindi
 * Currently this file used only in @mp-fresh-case-component.ts file
 */
export const SELECT_DIFFERENT_CROP = {
    hindi: "कृपया कोई अन्य फसल चुनें।",
    english: "Please select a different crop.",
    kannada:"ದಯವಿಟ್ಟು ಬೇರೆ ಬೆಳೆ ಆಯ್ಕೆಮಾಡಿ."
};
export const INVALID_OTP = {
    hindi: "",
    english: "The OTP you have entered is invalid",
    kannada:"ನೀವು ನಮೂದಿಸಿದ ಒಟಿಪಿ ಅಮಾನ್ಯವಾಗಿದೆ"
};
export const OTP_EXPIRED = {
    hindi: "",
    english: "The OTP you have entered is expired",
    kannada:"ನೀವು ನಮೂದಿಸಿದ ಒಟಿಪಿ ಅವಧಿ ಮುಗಿದಿದೆ"
};

export const MOBILE_NUM_INVALID = {
    hindi: "",
    english: "The mobile number you have entered is invalid",
    kannada:"ನೀವು ನಮೂದಿಸಿದ ಮೊಬೈಲ್ ಸಂಖ್ಯೆ ಅಮಾನ್ಯವಾಗಿದೆ"
};
export const SOMETHING_WENT_WRONG = {
    hindi: "",
    english: "Something went wrong",
    kannada:"ಏನೋ ತಪ್ಪಾಗಿದೆ"
};
export const PLEASE_PROCEED_WITH_LAND_RECORDS = {
    hindi: "",
    english: "Please proceed with Land record details.",
    kannada:"ದಯವಿಟ್ಟು ಭೂ ದಾಖಲೆ ವಿವರಗಳೊಂದಿಗೆ ಮುಂದುವರಿಯಿರಿ."
};
export const PLEASE_PROCEED_WITH_LAND_RECORDS_SATSURE_FAILED = {
    hindi: "कृपया भूमि रिकॉर्ड विवरण के साथ आगे बढ़ें। पिछला भूमि रिकॉर्ड सतसुरे उत्पादन चरण में विफल रहा।",
    english: "Please proceed with Land record details. The previous land record failed at Satsure report generation stage.",
    kannada:"ದಯವಿಟ್ಟು ಭೂ ದಾಖಲೆ ವಿವರಗಳೊಂದಿಗೆ ಮುಂದುವರಿಯಿರಿ. ಹಿಂದಿನ ಭೂ ದಾಖಲೆಯು ಸತ್ಸೂರ್ ಉತ್ಪಾದನೆಯ ಹಂತದಲ್ಲಿ ವಿಫಲವಾಗಿದೆ."
};
export const SELECTED_LAND_UNAVAILABLE_SINGLE = {
    hindi:"क्षमा करें, चयनित भूमि के लिए फसल सूची वर्तमान में अनुपलब्ध है। कृपया अन्य भूमि का चयन करें या आगे की प्रक्रिया के लिए शाखा से संपर्क करें।",
    english:"Sorry, as the crop list for the selected land is currently unavailable, your application cannot be processed at the moment. Please contact the branch for further process.",
    kannada:"ಕ್ಷಮಿಸಿ, ಆಯ್ಕೆಮಾಡಿದ ಜಮೀನಿನ ಬೆಳೆ ಪಟ್ಟಿಯು ಪ್ರಸ್ತುತ ಲಭ್ಯವಿಲ್ಲದ ಕಾರಣ, ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕ್ಷಣದಲ್ಲಿ ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ. ಮುಂದಿನ ಪ್ರಕ್ರಿಯೆಗಾಗಿ ದಯವಿಟ್ಟು ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ."
}
export const SELECTED_LAND_UNAVAILABLE_MULTI = {
    hindi:"क्षमा करें, चयनित भूमि के लिए फसल सूची वर्तमान में अनुपलब्ध होने के कारण, इस समय आपके आवेदन पर कार्रवाई नहीं की जा सकती। कृपया आगे की प्रक्रिया के लिए शाखा से संपर्क करें।",
    english:"Sorry, the crop list for the selected land is currently unavailable. Please select another land or contact the branch for further process.",
    kannada:"ಕ್ಷಮಿಸಿ, ಆಯ್ಕೆ ಮಾಡಿದ ಜಮೀನಿನ ಬೆಳೆ ಪಟ್ಟಿ ಪ್ರಸ್ತುತ ಲಭ್ಯವಿಲ್ಲ. ದಯವಿಟ್ಟು ಇನ್ನೊಂದು ಭೂಮಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ ಅಥವಾ ಮುಂದಿನ ಪ್ರಕ್ರಿಯೆಗಾಗಿ ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ."
}
export const FAILED_TO_FETCH_CROP_DETAILS = {
    hindi: "सिस्टम आपकी भूमि के लिए फसल विवरण प्राप्त करने में विफल रहा है, जिसके कारण आप इस प्रक्रिया में आगे नहीं बढ़ सकते हैं कृपया आगे की प्रक्रिया के लिए शाखा से संपर्क करें।",
    english: "The system has failed to fetch the crop details for your land, hence you cannot proceed further. Kindly contact the Branch for further process.",
    kannada:"ನಿಮ್ಮ ಜಮೀನಿನ ಬೆಳೆ ವಿವರಗಳನ್ನು ಪಡೆಯಲು ವ್ಯವಸ್ಥೆಯು ವಿಫಲವಾಗಿದೆ, ಆದ್ದರಿಂದ ನೀವು ಮುಂದುವರಿಯಲು ಸಾಧ್ಯವಿಲ್ಲ. ಮುಂದಿನ ಪ್ರಕ್ರಿಯೆಗಾಗಿ ದಯವಿಟ್ಟು ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ"
};
export const FAILED_TO_DELETE_LAND_RECORDS = {
    hindi: "",
    english: "Something went wrong.Please contact the branch for proceeding further.",
    kannada:"ಏನೋ ತಪ್ಪಾಗಿದೆ. ದಯವಿಟ್ಟು ಮುಂದುವರಿಯಲು ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ."
};
export const FAILED_TO_FIND_LOAN_DATA = {
    hindi: "",
    english: "We were not able to find the selected crop information, Please re-submit the crop details.",
    kannada:"ಆಯ್ಕೆಮಾಡಿದ ಬೆಳೆ ಮಾಹಿತಿಯನ್ನು ಹುಡುಕಲು ನಮಗೆ ಸಾಧ್ಯವಾಗಲಿಲ್ಲ, ದಯವಿಟ್ಟು ಬೆಳೆ ವಿವರಗಳನ್ನು ಮರು-ಸಲ್ಲಿಸಿ."
};
export const LONG_TERM_CROP_LIST_FETCH_FAILED = {
    hindi: "",
    english: "The system has failed to fetch the long term crop list. Please try again later.",
    kannada:"ದೀರ್ಘಾವಧಿ ಬೆಳೆ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯುವಲ್ಲಿ ವ್ಯವಸ್ಥೆ ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ."
};
export const PROFILE_VERIFIED_SUCCESSFUL = {
    hindi: `आपकी प्रोफ़ाइल सफलतापूर्वक सत्यापित कर दी गई है। अपना आधार नंबर सत्यापित करने के लिए "ओके" दबाएं।`,
    english: `Your profile has been verified successfully. Press "OK" to verify your Aadhaar Number.`,
    kannada:"ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಪರಿಶೀಲಿಸಲಾಗಿದೆ. ನಿಮ್ಮ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ಪರಿಶೀಲಿಸಲು 'OK' ಒತ್ತಿರಿ."
};

export const NO_DATA_FOUND_RESUME = {
    hindi: `कोई डेटा नहीं मिला, कृपया आधार सत्यापन के साथ आगे बढ़ें।`,
    english: `No data found, Please proceed with Aadhaar verification.`,
    kannada:""
};

export const ID_VERIFIED_SUCCESSFUL = {
    hindi: `आईडी को सफलतापूर्वक सत्यापित किया गया है!`,
    english: `The ID has been verified Successfully`,
    kannada:"ಐಡಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಪರಿಶೀಲಿಸಲಾಗಿದೆ"
};
export const ID_NOT_FOUND = {
    hindi: `आईडी नहीं मिली। कृपया सही आईडी दर्ज करें`,
    english: `ID not found. Kindly enter correct ID`,
    kannada:"ಐಡಿ ಕಂಡುಬಂದಿಲ್ಲ. ದಯವಿಟ್ಟು ಸರಿಯಾದ ಐಡಿಯನ್ನು ನಮೂದಿಸಿ."
};
export const TECH_ERROR = {
    hindi:"बैंक से प्रासंगिक डेटा प्राप्त करने में समस्या हुई थी। कृपया कुछ समय बाद पुनः प्रयास करें। धन्यवाद।",
    english: "There was a problem in receiving the relevant data from the bank. Please retry after some time. Thank you.",
    kannada:"ಬ್ಯಾಂಕ್‌ನಿಂದ ಸಂಬಂಧಿತ ಡೇಟಾವನ್ನು ಸ್ವೀಕರಿಸುವಲ್ಲಿ ಸಮಸ್ಯೆ ಕಂಡುಬಂದಿದೆ. ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಮರುಪ್ರಯತ್ನಿಸಿ. ಧನ್ಯವಾದ."
};

export const ENC_ERROR = {
    hindi:"",
    english: "There was a problem technical problem in encryption of the data. Please retry after some time. Thank you.",
    kannada:"ಡೇಟಾ ಎನ್‌ಕ್ರಿಪ್ಶನ್‌ನಲ್ಲಿ ತಾಂತ್ರಿಕ ಸಮಸ್ಯೆ ಕಂಡುಬಂದಿದೆ. ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಮರುಪ್ರಯತ್ನಿಸಿ. ಧನ್ಯವಾದ."
};
export const NAME_APPEARS_IN_CFD = {
    hindi:"क्षमा करें, आपका नाम सीएफडी सूची में प्रतीत होता है। इसलिए आप इस प्रक्रिया में आगे नहीं बढ़ सकते।",
    english:"Sorry, Your name appears to be in the CFD List. Hence you cannot proceed further in this process.",
    kannada:"ಕ್ಷಮಿಸಿ, ನಿಮ್ಮ ಹೆಸರು ಸಿಎಫ್ ಡಿ ಪಟ್ಟಿಯಲ್ಲಿರುವಂತೆ ತೋರುತ್ತಿದೆ. ಆದ್ದರಿಂದ ನೀವು ಈ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಮುಂದುವರಿಯಲು ಸಾಧ್ಯವಿಲ್ಲ."
};
export const DATA_FETCH_FAILED = {
    hindi:"ग्राहक डेटा लाना विफल रहा. कृपया कुछ देर बाद प्रयास करें।",
    english:"Fetching of customer data failed. Please try after sometime.",
    kannada:"ಗ್ರಾಹಕರ ಡೇಟಾವನ್ನು ಪಡೆಯುವುದು ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ."
};
export const NAME_NOT_FOUND = {
    hindi:"ग्राहक का नाम उपलब्ध नहीं होने के कारण यात्रा जारी नहीं रख सकते।",
    english:"Can't proceed the journey as customer name is not available.",
    kannada:"ಗ್ರಾಹಕರ ಹೆಸರು ಲಭ್ಯವಿಲ್ಲದ ಕಾರಣ ಪ್ರಯಾಣವನ್ನು ಮುಂದುವರಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ."
};
export const AADHAR_DETAILS_ENTERED_SUCCESS = {
    // कृपया अपनी भूमि का विवरण दर्ज करें।" ದಯವಿಟ್ಟು ನಿಮ್ಮ ಜಮೀನಿನ ವಿವರಗಳನ್ನು ನಮೂದಿಸಿ.
    hindi: "आधार विवरण सफलतापूर्वक सत्यापित किया गया है।",
    english:"Aadhar details have been verified successfully.",
    kannada:"ಆಧಾರ್ ವಿವರಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಪರಿಶೀಲಿಸಲಾಗಿದೆ. "
};
export const FRUITS_POPUP = {
    hindi: "",
    english:"As per fruits portal there may be more land records corresponding to the given aadhar number, However only lands eligible for STP are displayed here.",
    kannada:"FRUITS ಪೋರ್ಟಲ್ ಪ್ರಕಾರ, ನೀಡಿರುವ ಆಧಾರ್ ಸಂಖ್ಯೆಗೆ ಅನುಗುಣವಾಗಿ ಹೆಚ್ಚಿನ ಭೂ ದಾಖಲೆಗಳು ಇರಬಹುದು, ಆದಾಗ್ಯೂ STP ಗೆ ಅರ್ಹವಾದ ಭೂ ದಾಖಲೆಗಳನ್ನು ಮಾತ್ರ ಇಲ್ಲಿ ಪ್ರದರ್ಶಿಸಲಾಗುತ್ತದೆ."
};
export const AGE_LIMIT = {
    hindi:"ग्राहक की आयु अनिवार्य रूप से 18 वर्ष से अधिक होनी चाहिए, आगे कार्यवाही नहीं कर सकते हैं। कृपया अपनी शाखा से संपर्क करें।",
    english:"The age of customer must be above 18 years, cannot proceed further. Please contact your Branch ",
    kannada:"ಗ್ರಾಹಕರ ವಯಸ್ಸು 18 ವರ್ಷಕ್ಕಿಂತ ಮೇಲ್ಪಟ್ಟಿರಬೇಕು, ಮುಂದೆ ಮುಂದುವರಿಯಲು ಸಾಧ್ಯವಿಲ್ಲ. ದಯವಿಟ್ಟು ನಿಮ್ಮ ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ"
};
export const OTP_INCORRECT = {
    hindi: "",
    english:"The OTP entered is incorrect",
    kannada:"ನಮೂದಿಸಿದ ಒಟಿಪಿ ತಪ್ಪಾಗಿದೆ"
}
export const AADHAR_NUM_DOES_NOT_MATCH = {
    hindi: "दर्ज की गयी आधार संख्या बैंक के साथ पंजीकृत आधार संख्या से मेल नहीं खाती है। कृपया सही आधार संख्या दर्ज करें।",
    english:"Aadhar Number entered does not match with the Aadhar number registered with Bank. Please enter correct Aadhar Number. ",
    kannada:"ನಮೂದಿಸಿದ ಆಧಾರ್ ಸಂಖ್ಯೆಯು ಬ್ಯಾಂಕ್‌ನಲ್ಲಿ ನೋಂದಾಯಿಸಲಾದ ಆಧಾರ್ ಸಂಖ್ಯೆಯೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ. ದಯವಿಟ್ಟು ಸರಿಯಾದ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ."
};
export const NO_RESPONSE_UIADI = {
    hindi:"यूआईडीएआई पोर्टल की ओर से कोई प्रतिक्रिया नहीं आई है। कृपया कुछ देर बाद प्रयास करें।",
    english:"There is no response from UIDAI Portal. Please try after sometime.",
    kannada:"UIDAI ಪೋರ್ಟಲ್‌ನಿಂದ ಯಾವುದೇ ಪ್ರತಿಕ್ರಿಯೆ ಇಲ್ಲ. ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ."
};
export const OTP_NOT_GEN = {
    hindi: "OTP जनरेट करते समय कोई समस्या उत्पन्न हुई है। कृपया कुछ समय बाद पुन: प्रयास करें।",
    english: "A problem has occurred while generating an OTP. Please try again after some time.",
    kannada:"ಒಟಿಪಿ ರಚಿಸುವಾಗ ಸಮಸ್ಯೆ ಸಂಭವಿಸಿದೆ. ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ."
};
export const EXIST_CUST = {
    hindi:`आप बैंक के मौजूदा ग्राहक हैं। कृपया "मौजूदा ग्राहक" यात्रा के माध्यम से ऋण के लिए आवेदन करें।`,
    english:`You are an existing customer to the bank. Kindly apply for the loan through "existing customer" journey`,
    kannada:"ನೀವು ಬ್ಯಾಂಕ್‌ಗೆ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಗ್ರಾಹಕರು. 'ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಗ್ರಾಹಕ' ಪ್ರಯಾಣದ ಮೂಲಕ ಸಾಲಕ್ಕಾಗಿ ದಯವಿಟ್ಟು ಅರ್ಜಿ ಸಲ್ಲಿಸಿ"
};
export const AADHAR_VERF_UNSUCCESS = {
    hindi: "आधार विवरण सत्यापन विफल। आगे की प्रक्रिया के लिए कृपया बैंक से संपर्क करें।",
    english: "Aadhaar Details Verification Unsuccessful. Kindly contact the bank for further process",
    kannada:"ಆಧಾರ್ ವಿವರಗಳ ಪರಿಶೀಲನೆ ವಿಫಲವಾಗಿದೆ. ಮುಂದಿನ ಪ್ರಕ್ರಿಯೆಗಾಗಿ ದಯವಿಟ್ಟು ಬ್ಯಾಂಕ್ ಅನ್ನು ಸಂಪರ್ಕಿಸಿ"
};
export const UNABLE_TO_GET_STATE_LAND_REC= {
    hindi:"हम राज्य भूमि अभिलेख विभाग से प्रतिक्रिया प्राप्त करने में असमर्थ हैं, आगे नहीं बढ़ सकते।",
    english: "We are unable to receive response from state land records department, cannot proceed further.",
    kannada:"ರಾಜ್ಯ ಭೂ ದಾಖಲೆಗಳ ಇಲಾಖೆಯಿಂದ ಪ್ರತಿಕ್ರಿಯೆ ಪಡೆಯಲು ನಮಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ, ಮುಂದೆ ಮುಂದುವರಿಯಲು ಸಾಧ್ಯವಿಲ್ಲ."
};
export const EXE_KCC_LOAN = {
    hindi:"ग्राहक का यूनियन बैंक ऑफ इंडिया में बकाया केसीसी ऋण है, आगे कार्यवाही नहीं कर सकते हैं। कृपया अपनी शाखा से संपर्क करें। ",
    english:"The customer has outstanding KCC Loan with Union Bank of India, cannot proceed further. Please contact your Branch",
    kannada:"ಗ್ರಾಹಕರು ಯೂನಿಯನ್ ಬ್ಯಾಂಕ್ ಆಫ್ ಇಂಡಿಯಾದಲ್ಲಿ KCC ಸಾಲವನ್ನು ಬಾಕಿ ಉಳಿಸಿಕೊಂಡಿದ್ದಾರೆ, ಮುಂದೆ ಮುಂದುವರಿಯಲು ಸಾಧ್ಯವಿಲ್ಲ. ದಯವಿಟ್ಟು ನಿಮ್ಮ ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ"
};
export const EXE_DORMAT_ACC = {
    hindi:"कृपया अपनी यूनियन बैंक ऑफ इंडिया की शाखा से संपर्क कर अपने खाते के विवरण को अपडेट करवाए",
    english:"Kindly contact your Branch and update your records to proceed further",
    kannada:"ದಯವಿಟ್ಟು ನಿಮ್ಮ ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ ಮತ್ತು ಮುಂದುವರಿಯಲು ನಿಮ್ಮ ದಾಖಲೆಗಳನ್ನು ನವೀಕರಿಸಿ"
};
export const EXE_NPA_ACC = {
    hindi:"ग्राहक का यूनियन बैंक ऑफ इंडिया में बकाया केसीसी ऋण है, आगे कार्यवाही नहीं कर सकते हैं। कृपया अपनी शाखा से संपर्क करें। ",
    english:"The customer has NPA account with Union Bank of India, cannot proceed further. Please contact your Branch",
    kannada:"ಗ್ರಾಹಕರು ಯೂನಿಯನ್ ಬ್ಯಾಂಕ್ ಆಫ್ ಇಂಡಿಯಾದಲ್ಲಿ NPA ಖಾತೆಯನ್ನು ಹೊಂದಿದ್ದಾರೆ, ಮುಂದೆ ಮುಂದುವರಿಯಲು ಸಾಧ್ಯವಿಲ್ಲ. ದಯವಿಟ್ಟು ನಿಮ್ಮ ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ"
};
export const AADHAR_NUM_DOES_NOT_MATCH_CBS = {
    hindi:"दी गई  आधार संख्या, उपरोकत ग्राहकसंख्या से लिंक नहीं है। कृपया अपनी यूनियन बैंक ऑफ इंडिया की शाखा से संपर्क करे",
    english:"Aadhar Number entered is not registered with Bank for the given customer ID/ Account Number. Cannot proceed further.",
    kannada:"ನೀಡಲಾದ ಗ್ರಾಹಕ ID/ ಖಾತೆ ಸಂಖ್ಯೆಗಾಗಿ ನಮೂದಿಸಿದ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ಬ್ಯಾಂಕ್‌ನಲ್ಲಿ ನೋಂದಾಯಿಸಲಾಗಿಲ್ಲ. ಮುಂದೆ ಮುಂದುವರೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ."
};
export const FAILED_TO_FIND_KHASRA_NUMBER = {
    hindi:"सिस्टम आपका खसरा नंबर खोजने में विफल रहा है, कृपया सही खसरा नंबर दर्ज करें।",
    english:"The system has failed to find your khasra number. Kindly enter the correct khasra number.",
    kannada:"ನಿಮ್ಮ ಖಸ್ರಾ ಸಂಖ್ಯೆಯನ್ನು ಹುಡುಕಲು ಸಿಸ್ಟಮ್ ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ಸರಿಯಾದ ಖಸ್ರಾ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ."
};
export const DOWN_TIME_LAND_REC_API = {
    hindi:"भूमि विवरण एपीआई में डाउनटाइम चल रहा है। कृपया कुछ देर बाद प्रयास करें।",
    english:"Downtime in Land Record API, Please try after sometime.",
    kannada:"ಲ್ಯಾಂಡ್ ರೆಕಾರ್ಡ್ ಎಪಿಐ ನಲ್ಲಿ ಡೌನ್‌ಟೈಮ್, ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ"
};
export const LOANAC_ALREADY_CREATED_4LAND_REC = {
    hindi:"दर्ज भूमि विवरण के लिए एक केसीसी ऋण राशि पहले से ही स्वीकृत है। अपनी ऋण आवेदन प्रक्रिया को पूरा करने के लिए कृपया आवेदन पत्र फिर से शुरू करें बटन का उपयोग करें।",
    english:"A KCC loan amount for the entered land details is already sanctioned. Please use the resume application button to complete your loan application process.",
    kannada:"ನಮೂದಿಸಿದ ಭೂಮಿಯ ವಿವರಗಳಿಗಾಗಿ KCC ಸಾಲದ ಮೊತ್ತವನ್ನು ಈಗಾಗಲೇ ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ. ನಿಮ್ಮ ಲೋನ್ ಅಪ್ಲಿಕೇಶನ್ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ದಯವಿಟ್ಟು ರೆಸ್ಯೂಮ್ ಅಪ್ಲಿಕೇಶನ್ ಬಟನ್ ಅನ್ನು ಬಳಸಿ."
};

export const LOANAC_ALREADY_CREATED_4LAND_REC_MULTIPLE_LAND = {
    hindi:"दर्ज भूमि विवरण के लिए एक केसीसी ऋण राशि पहले से ही स्वीकृत है। अपनी ऋण आवेदन प्रक्रिया को पूरा करने के लिए कृपया आवेदन पत्र फिर से शुरू करें बटन का उपयोग करें।",
    english:"A KCC loan amount for the entered land details is already sanctioned. Would you like to add another land details?",
    kannada:"ನಮೂದಿಸಿದ ಭೂಮಿಯ ವಿವರಗಳಿಗಾಗಿ KCC ಸಾಲದ ಮೊತ್ತವನ್ನು ಈಗಾಗಲೇ ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ. ನಿಮ್ಮ ಲೋನ್ ಅಪ್ಲಿಕೇಶನ್ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ದಯವಿಟ್ಟು ರೆಸ್ಯೂಮ್ ಅಪ್ಲಿಕೇಶನ್ ಬಟನ್ ಅನ್ನು ಬಳಸಿ."
};

export const LOAN_AMOUNT_CALCULATED = {
    hindi:"ऋण राशि की गणना सफलतापूर्वक कर ली गई है",
    english:"Loan amount calculated successfully.",
    kannada:"ಸಾಲದ ಮೊತ್ತವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಲೆಕ್ಕಹಾಕಲಾಗಿದೆ."
};
export const MULTIPLE_KHASRA_DISTRICT_RESTRICTION_MESSAGE = {
    hindi:"",
    english:"The selected land record should be under same District and Taluk. Would you like to select another Land Record?",
    kannada:"ಸಾಲದ ಮೊತ್ತವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಲೆಕ್ಕಹಾಕಲಾಗಿದೆ."
};

export const NO_DATA_FOUND_RBIH = {
    hindi:"दर्ज भूमि विवरण के लिए कोई डेटा नहीं मिला",
    english:"No data found for the entered land details",
    kannada:""
};

export const FRUITS_NAME_MISMATCH_MESSAGE = {
    hindi:"",
    english:"Do you want to enter the correct Aadhaar and proceed?",
    kannada:"ನೀವು ಸರಿಯಾದ ಆಧಾರ್ ಅನ್ನು ನಮೂದಿಸಿ ಮತ್ತು ಮುಂದುವರಿಯಲು ಬಯಸುವಿರಾ?"
};

export const FAILED_TO_CALCULATE_LA = {
    hindi:"सिस्टम ऋण राशि की गणना करने में विफल रहा है। कृपया विवरण पुनः सबमिट करें।",
    english:"The system has failed to calculate the loan amount. Kindly re-submit the details.",
    kannada:"ಸಾಲದ ಮೊತ್ತವನ್ನು ಲೆಕ್ಕಾಚಾರ ಮಾಡಲು ವ್ಯವಸ್ಥೆಯು ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ವಿವರಗಳನ್ನು ಪುನಃ ಸಲ್ಲಿಸಿ."
};
export const FAILED_TO_SUBMIT_LA = {
    hindi:"सिस्टम ऋण राशि जमा करने में विफल रहा है। कृपया विवरण पुनः सबमिट करें।",
    english:"The system has failed to submit the loan amount. Kindly re-submit the details.",
    kannada:"ವ್ಯವಸ್ಥೆಯು ಸಾಲದ ಮೊತ್ತವನ್ನು ಸಲ್ಲಿಸಲು ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ವಿವರಗಳನ್ನು ಪುನಃ ಸಲ್ಲಿಸಿ."
};
export const LOAN_DETAILS_SUBMITTED = {
    hindi: "आपका विवरण सफलतापूर्वक सबमिट कर दिया गया है! अब आप स्वीकृति पत्र और ऋण आवेदन पत्र डाउनलोड कर सकते हैं।",
    english:"Your details have been Submitted Successfully! You can now download the Sanction Letter and Loan application form. ",
    kannada:"ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ! ನಿಮ್ಮ ಸಾಲದ ಅರ್ಜಿಗಾಗಿ ನೀವು ಈಗ ಮಂಜೂರಾತಿ ಪತ್ರ ಮತ್ತು ಸಾಲದ ಅರ್ಜಿಯನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಬಹುದು."
};
export const LOAN_DETAILS_SUBMITTED_NO_DOWNLOAD = {
    hindi: "आपका विवरण सफलतापूर्वक सबमिट कर दिया गया है!",
    english:"Your details have been Submitted Successfully! ",
    kannada:"ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ! ನಿಮ್ಮ ಸಾಲದ ಅರ್ಜಿಗಾಗಿ ನೀವು ಈಗ ಮಂಜೂರಾತಿ ಪತ್ರ ಮತ್ತು ಸಾಲದ ಅರ್ಜಿಯನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಬಹುದು."
};
export const NO_BANK_BRANCH_FOUND = {
    hindi:"",
    english:"No Union Bank of India branches within 25 kilometers of the mentioned land area",
    kannada:"ಉಲ್ಲೇಖಿಸಲಾದ ಭೂಪ್ರದೇಶದ 25 ಕಿಲೋಮೀಟರ್‌ಗಳ ಒಳಗೆ ಯಾವುದೇ ಯೂನಿಯನ್ ಬ್ಯಾಂಕ್ ಆಫ್ ಇಂಡಿಯಾ ಶಾಖೆಗಳನ್ನು ಹೊಂದಿಲ್ಲ"
};
export const UNABLE_TO_PROCESS_ESIGN = {
    hindi:"प्लेटफ़ॉर्म Esign के लिए आगे कनेक्ट करने में असमर्थ है। कृपया कुछ देर बाद प्रयास करें।",
    english:"The  platform is unable to connect further for Esign. Please try after some time.",
    kannada:"Esign ಗೆ ಮತ್ತಷ್ಟು ಸಂಪರ್ಕಿಸಲು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ."
};

export const GENERATE_FORMS_FAILED_TRYAGAIN = {
    hindi:"सिस्टम ऋण दस्तावेज़ उत्पन्न करने में असमर्थ है। कृपया कुछ देर बाद प्रयास करें",
    english:"The system is unable to generate Loan Documents. Please try after sometime",
    kannada:"ಸಾಲದ ದಾಖಲೆಗಳನ್ನು ರಚಿಸಲು ಸಿಸ್ಟಮ್‌ಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ"
};

export const NO_OVDID_FOUND = {
    hindi:"रिकॉर्ड के लिए कोई ओवीडीआईडी ​​(पैन/वोटर आईडी/सीकेवाईसी नंबर) नहीं मिला",
    english:"No OVDID (PAN/Voter ID/CKYC No.) is found for the record",
    kannada:"ದಾಖಲೆಗಾಗಿ ಯಾವುದೇ OVDID (PAN/Voter ID/CKYC ಸಂಖ್ಯೆ) ಕಂಡುಬಂದಿಲ್ಲ"
};

export const LOAN_DOCS_SUB = {
    hindi:"आपके ऋण दस्तावेजों को सफलतापूर्वक निष्पादित किया गया है। कृपया ऋण खाता खोलने के लिए 'सबमिट' पर क्लिक करें।",
    englih:"Your Loan Documents have been successfully executed, please click on “Submit” to proceed for Loan account opening",
    kannada:"ನಿಮ್ಮ ಲೋನ್ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಕಾರ್ಯಗತಗೊಳಿಸಲಾಗಿದೆ, ಸಾಲದ ಖಾತೆ ತೆರೆಯಲು ಮುಂದುವರಿಯಲು ದಯವಿಟ್ಟು 'Submit' ಕ್ಲಿಕ್ ಮಾಡಿ"
};
export const THNK_ACC_OPEN = {
    hindi:"डिजिटल दस्तावेज़ निष्पादन को चुनने के लिए धन्यवाद, ई-साइन शुरू करने के लिए आपको एनईएसएल पेज पर पुनः निर्देशित किया जाएगा। कृपया दस्तावेज़ पर हस्ताक्षर करने से पहले उसे ध्यान से पढ़ें।",
    english:"Thanks for Opting for Digital Document Execution, You will be redirected to NeSL page for initiating e-Sign. Please read through the document before signing it.",
    kannada:"ಡಿಜಿಟಲ್ ಡಾಕ್ಯುಮೆಂಟ್ ಎಕ್ಸಿಕ್ಯೂಶನ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು, ಇ-ಸೈನ್ ಅನ್ನು ಪ್ರಾರಂಭಿಸಲು ನಿಮ್ಮನ್ನು NeSL ಪುಟಕ್ಕೆ ಮರುನಿರ್ದೇಶಿಸಲಾಗುತ್ತದೆ. ಡಾಕ್ಯುಮೆಂಟ್‌ಗೆ ಸಹಿ ಮಾಡುವ ಮೊದಲು ಅದನ್ನು ಓದಿ"
};
export const KINDLY_CONTACT = {
    hindi:"कृपया किसान क्रेडिट कार्ड ऋण हेतु यूनियन बैंक ऑफ इण्डिया की निकटतम शाखा से संपर्क करें।",
    english:"For KCC loan, kindly contact your branch for further process.",
    kannada:"KCC ಸಾಲಕ್ಕಾಗಿ, ಮುಂದಿನ ಪ್ರಕ್ರಿಯೆಗಾಗಿ ದಯವಿಟ್ಟು ನಿಮ್ಮ ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ."
};
export const CLICK_SUB_AGAIN = {
    hindi:"कृपया फिर से सबमिट बटन पर क्लिक करें।",
    english:"Please click on Submit button again",
    kannada:"ದಯವಿಟ್ಟು ಮತ್ತೆ Submit ಬಟನ್ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ"
};

export const LOAN_ACC_CREATION_UNDER_PROCESS = {
    hindi:"ऋण निर्माण के लिए आपका अनुरोध प्रक्रियाधीन है कृपया 5 मिनट बाद जांच करें।",
    english:"Your request for loan creation is under process please check after 5 minutes",
    kannada:"ಲೋನ್ ರಚನೆಗಾಗಿ ನಿಮ್ಮ ವಿನಂತಿಯು ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿದೆ ದಯವಿಟ್ಟು 5 ನಿಮಿಷಗಳ ನಂತರ ಪರಿಶೀಲಿಸಿ"
};

export const OOPS_WENT_WRONG = {
    hindi:"",
    english:"Oops!Something went wrong! Please click on Submit button again",
    kannada:"ಓಹ್! ಯಾವುದೋ ತಪ್ಪು ಸಂಭವಿಸಿದೆ! ದಯವಿಟ್ಟು ಮತ್ತೆ ಸಲ್ಲಿಸು ಬಟನ್ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ"
};
export const LOAN_AAC_NOT_CREATED = {
    hindi:"",
    english:"The loan account could not be created. Please contact the branch for further process.",
    kannada:"ಸಾಲದ ಖಾತೆಯನ್ನು ರಚಿಸಲಾಗಲಿಲ್ಲ. ಮುಂದಿನ ಪ್ರಕ್ರಿಯೆಗಾಗಿ ದಯವಿಟ್ಟು ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ."
};

export const LOAN_AAC_CREATED = {
    hindi:"आपका ऋण खाता सफलतापूर्वक बन गया है कृपया अपनी शाखा से विवरण जांचें।",
    english:"Your loan account is created successfully kindly check details with your branch.",
    kannada:"ನಿಮ್ಮ ಸಾಲದ ಖಾತೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ ದಯವಿಟ್ಟು ನಿಮ್ಮ ಶಾಖೆಯೊಂದಿಗೆ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಿ."
};

export const ONLY_TWO_CROPS_ALLOWD = {
    hindi:"प्रति मौसम केवल दो फसलों की अनुमति है।",
    english:"Only two crops allowed per season.",
    kannada:"ಪ್ರತಿ ಋತುವಿಗೆ ಎರಡು ಬೆಳೆಗಳಿಗೆ ಮಾತ್ರ ಅವಕಾಶ."
};
export const CADESTIAL_DATA_UNAVAILABLE = {
    hindi:"क्षमा करें, दर्ज किए गए भूमि विवरण के लिए कैडस्टियल डेटा अनुपलब्ध है। आगे की प्रक्रिया के लिए कृपया शाखा से संपर्क करें।",
    english: "Sorry, the cadestial data is unavailable for the land details entered. Kindly contact the branch for further process.",
    kannada:"ಕ್ಷಮಿಸಿ, ನಮೂದಿಸಿದ ಭೂಮಿಯ ವಿವರಗಳಿಗೆ ಕ್ಯಾಡೆಸ್ಟಿಯಲ್ ಡೇಟಾ ಲಭ್ಯವಿಲ್ಲ. ಮುಂದಿನ ಪ್ರಕ್ರಿಯೆಗಾಗಿ ದಯವಿಟ್ಟು ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ."
};
export const LAND_REC_NOT_AS_PER_BANK = {
    hindi: "हमें खेद है कि राज्य भू अभिलेख रिकॉर्ड्स  मे आपकी भूमि संबन्धित उपलब्ध जानकारी  बैंक मानकों के अनुरूप नहीं हैं।",
    english:"We are sorry to inform you that the information available regarding your land in the State Land Records is not as per the bank standards. Hence your application cannot be processed further",
    kannada:"ರಾಜ್ಯ ಭೂ ದಾಖಲೆಗಳಲ್ಲಿ ನಿಮ್ಮ ಭೂಮಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಲಭ್ಯವಿರುವ ಮಾಹಿತಿಯು ಬ್ಯಾಂಕ್ ಮಾನದಂಡಗಳಿಗೆ ಅನುಗುಣವಾಗಿಲ್ಲ ಎಂದು ನಿಮಗೆ ತಿಳಿಸಲು ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ. ಆದ್ದರಿಂದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮುಂದೆ ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ"
};
export const FAILED_UPDATE_LAND_DETAILS = {
    hindi:"सिस्टम आपके भूमि विवरण को सत्यापित करने में विफल रहा है। कृपया कुछ देर बाद प्रयास करें।",
    english:"The system has failed to verify your land details. Please try after some time.",
    kannada:"ನಿಮ್ಮ ಭೂಮಿಯ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಲು ಸಿಸ್ಟಮ್ ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ."
};
export const MESAGE_MOBILE_NUM_NOT_FOUND = {
    hindi:"कृपया अपनी यूनियन बैंक ऑफ इंडिया की शाखा से संपर्क कर अपने खाते के विवरण को अपडेट करवाए.",
    english:"Kindly contact your Branch and update your records to proceed further",
    kannada:"ದಯವಿಟ್ಟು ನಿಮ್ಮ ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ ಮತ್ತು ಮುಂದುವರಿಯಲು ನಿಮ್ಮ ದಾಖಲೆಗಳನ್ನು ನವೀಕರಿಸಿ"

};

export const CUST_ID_NOT_ELIGIBLE = {
    hindi:"दी गई ग्राहक आईडी डिजिटल केसीसी ऋण के लिए पात्र नहीं है। कृपया अपनी शाखा से संपर्क करें.",
    english:"The given Customer ID is not eligible for Digital KCC Loan. Please contact your Branch.",
    kannada:""

};

export const UPDATE_PAN_OR_VOTERID = {
    hindi:"कृपया अपनी शाखा से संपर्क करें और आगे बढ़ने के लिए पैन या वोटर-आईडी अपडेट करें।",
    english:"Kindly contact your branch and update PAN or Voter-ID to proceed further.",
    kannada:"ದಯವಿಟ್ಟು ನಿಮ್ಮ ಶಾಖೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ ಮತ್ತು ಮುಂದುವರಿಯಲು PAN ಅಥವಾ Voter-ID ಅನ್ನು ನವೀಕರಿಸಿ."
}

export const NO_VILLAGE_FOUND = {
    hindi:"",
    english:"No villages found for the selected Halka",
    kannada:"ಆಯ್ದ ಹಲ್ಕಾಗೆ ಯಾವುದೇ ಗ್ರಾಮಗಳು ಕಂಡುಬಂದಿಲ್ಲ"
};

export const INTERNAL_SERVER_ERROR = {
    hindi:"",
    english:"Internal server error. Please try again after some time",
    kannada:"ಆಂತರಿಕ ಸರ್ವರ್ ದೋಷ. ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ"
};

export const ESIGN_FAILED = {
    hindi:"ई-हस्ताक्षर प्रक्रिया सफल नहीं हुई, कृपया पुनः प्रयास करें",
    english:"The e-Sign process was not successful, Please try again",
    kannada:"ಇ-ಸೈನ್ ಪ್ರಕ್ರಿಯೆಯು ಯಶಸ್ವಿಯಾಗಿಲ್ಲ, ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ"
};

export const FINACLE_ERROR = {
    hindi:"",
    english:"Finacle System Error Occoured!!! Please contact System Administrator.",
    kannada:"ಫಿನಾಕಲ್ ಸಿಸ್ಟಮ್ ದೋಷ ಸಂಭವಿಸಿದೆ !!! ದಯವಿಟ್ಟು ಸಿಸ್ಟಂ ನಿರ್ವಾಹಕರನ್ನು ಸಂಪರ್ಕಿಸಿ."
};

export const FAILED_TO_FETCH_CREDIT = {
    hindi:"",
    english:"You may not proceed in the journey as your Credit History does not meet the Bank standards. Kindly contact the bank for further process.",
    kannada:"ನಿಮ್ಮ ಕ್ರೆಡಿಟ್ ಇತಿಹಾಸವು ಬ್ಯಾಂಕ್ ಮಾನದಂಡಗಳನ್ನು ಪೂರೈಸದ ಕಾರಣ ನೀವು ಪ್ರಯಾಣದಲ್ಲಿ ಮುಂದುವರಿಯುವಂತಿಲ್ಲ. ಮುಂದಿನ ಪ್ರಕ್ರಿಯೆಗಾಗಿ ದಯವಿಟ್ಟು ಬ್ಯಾಂಕ್ ಅನ್ನು ಸಂಪರ್ಕಿಸಿ."
};

export const FAILED_TO_GENERATE_LRN = {
    hindi:"ऋण संदर्भ संख्या उत्पन्न करने में विफल। कृपया बाद में पुन: प्रयास करें।",
    english:"Failed to generate Loan Reference Number. Please try again later.",
    kannada:"ಸಾಲದ ಉಲ್ಲೇಖ ಸಂಖ್ಯೆಯನ್ನು ರಚಿಸಲು ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ."
};

export const CFD_FAILED_ERROR = {
    hindi:"सिस्टम सीएफडी की जांच करने में असमर्थ है। कृपया बाद में पुन: प्रयास करें।",
    english:"System is unable to check for CFD. Please try again later.",
    kannada:"ಸಿಎಫ್‌ಡಿ ಪರೀಕ್ಷಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ."
};

export const FARMER_ID_NOT_FOUND = {
    hindi: "",
    english: "Could not find land data - Farmer ID does not exist",
    kannada:"ಭೂಮಿಯ ಡೇಟಾವನ್ನು ಕಂಡುಹಿಡಿಯಲಾಗಲಿಲ್ಲ - ರೈತ ID ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ"
};

export const FAILED_TO_FETCH_LAND_DATA = {
    hindi: "",
    english: "Failed to fetch land data",
    kannada:"ಭೂಮಿಯ ಡೇಟಾವನ್ನು ಪಡೆದುಕೊಳ್ಳಲು ವಿಫಲವಾಗಿದೆ"
};

export const FAILED_TO_FETCH_DATA = {
    hindi: "",
    english: "Failed to fetch data",
    kannada:"ಡೇಟಾವನ್ನು ಪಡೆದುಕೊಳ್ಳಲು ವಿಫಲವಾಗಿದೆ"
};

export const LAND_DATA_AADHAR_NOT_VALIDATED = {
    hindi: "",
    english: "Aadhaar Not Validated",
    kannada:"ಆಧಾರ್ ಮಾನ್ಯವಾಗಿಲ್ಲ"
};

export const BRE_STATUS_DUPLICATE_ID = {
    hindi: "",
    english: "Failed due to Duplicate Land Entry",
    kannada:"ನಕಲಿ ಭೂ ಪ್ರವೇಶದಿಂದಾಗಿ ವಿಫಲವಾಗಿದೆ"
};

export const Long_TEMR_CROP_ERROR_1 = {
    hindi: "",
    english: "The crop you have selected is a long-term crop. Hence you won't be allowed to select any other crop for other seasons. Do you confirm?",
    kannada:"ನೀವು ಆಯ್ಕೆ ಮಾಡಿದ ಬೆಳೆ ದೀರ್ಘಾವಧಿಯ ಬೆಳೆ. ಆದ್ದರಿಂದ ಇತರ ಋತುಗಳಿಗೆ ಬೇರೆ ಯಾವುದೇ ಬೆಳೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ನಿಮಗೆ ಅನುಮತಿಸಲಾಗುವುದಿಲ್ಲ. ನೀವು ದೃಢೀಕರಿಸುತ್ತೀರಾ?"
};

export const Long_TEMR_CROP_ERROR_2 = {
    hindi: "",
    english: "The crop you have selected is a long-term crop. Hence you won't be allowed to select any other crop for other seasons. Any selections previously made will be discarded. Do you confirm?",
    kannada:"ನೀವು ಆಯ್ಕೆ ಮಾಡಿದ ಬೆಳೆ ದೀರ್ಘಾವಧಿಯ ಬೆಳೆ. ಆದ್ದರಿಂದ ಇತರ ಋತುಗಳಿಗೆ ಬೇರೆ ಯಾವುದೇ ಬೆಳೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ನಿಮಗೆ ಅನುಮತಿಸಲಾಗುವುದಿಲ್ಲ. ಹಿಂದೆ ಮಾಡಿದ ಯಾವುದೇ ಆಯ್ಕೆಗಳನ್ನು ತಿರಸ್ಕರಿಸಲಾಗುತ್ತದೆ. ನೀವು ದೃಢೀಕರಿಸುತ್ತೀರಾ?"
};

export const FAILED_TO_FIND_KHASRA_NUMBER_NEW = {
    hindi:"सिस्टम आपका खसरा नंबर खोजने में विफल रहा है, कृपया सही खसरा नंबर दर्ज करें।",
    english:"The system has failed to find your khasra number. Would you like to discard it and enter another land details"
};

export const LAND_REC_NOT_AS_PER_BANK_NEW = {
    hindi: "हमें खेद है कि राज्य भू अभिलेख रिकॉर्ड्स  मे आपकी भूमि संबन्धित उपलब्ध जानकारी  बैंक मानकों के अनुरूप नहीं हैं।",
    english:"We are sorry to inform you that the information available regarding your land in the State Land Records is not as per the bank standards. Would you like to discard it and enter another land details"
};

export const LAND_REC_NOT_AS_PER_BANK_DISCRAD_ENTRY = {
    hindi: "हमें खेद है कि राज्य भू अभिलेख रिकॉर्ड्स  मे आपकी भूमि संबन्धित उपलब्ध जानकारी  बैंक मानकों के अनुरूप नहीं हैं।",
    english:"We are sorry to inform you that the information available regarding your land in the State Land Records is not as per the bank standards. Would you like to discard it and con?"
};

export const ENTER_ANOTHER_LAND_REC = {
    hindi: "",
    english:"Would you like to add another land details?"

};

export const LOAN_DISBURSED_SUCCESS = {
    hindi: "ऋण खाता सफलतापूर्वक वितरित कर दिया गया है",
    english:"The Loan Account has been disbursed Successfully",
    kannada:""
}
