import { Component, Input} from '@angular/core';

@Component({
  selector: 'wl-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent {

  @Input() color = 'primary';
  @Input() disabled = false;
  @Input() label: string;

  constructor() { }

}
