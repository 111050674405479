import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment as ENV } from '../../../environments/environment';

@Component({
  selector: 'wl-input',
  templateUrl: './input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    },
  ]
})
export class InputComponent implements ControlValueAccessor {

  @Input() label: string="";
  @Input() type: string;
  @Input() placeholder: string = this.label;
  @Input() required = false;
  @Input() error: string;
  @Input() disabled: boolean = true;
  @Input() readonly: boolean;

  // disabled = true; // Change this to enable input fields


  private _value: any;
  get value(): any { return this._value; }
  set value(v: any) { this._value = v; this.onChange(v); }

  constructor() {
    this.disabled = ENV.disableInput;
   }

  private onChange = (_) => { };

  private onTouched = () => { };

  writeValue(value: any) { this.value = value }

  registerOnChange(fn) { this.onChange = fn }

  registerOnTouched(fn) { this.onTouched = fn }

  setDisabledState(isDisabled) { this.disabled = isDisabled }

}
