import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApplicantService } from 'src/app/applicant.service';
import { fadeInOut } from '../../animation';


import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { OpenDialogYesOrNo } from '../../dialog-yes-or-no/dialog-yes-or-no.component';



export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-land-records-tel',
  templateUrl: './land-records-tel.component.html',
  styleUrls: ['./land-records-tel.component.scss'],
  animations: [fadeInOut],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})
export class LandRecordsTelComponent {

  landRecordForm: FormGroup;
  @Output() value = new EventEmitter();
  @Input() landRecordsList: any;
  landDetailsArray: any = [];
  showLandVerifiedTick: boolean;
  districtReadOnly: boolean;
  loading:boolean = false;
  disableVerify:boolean;
  ppbNumberReadOnly: boolean;
  showLands: boolean;
  surveyNumbersAndExtents:any;
  landArray: { districtName:any,subdistrictName: any,villageName:any,surveyNo: string, landExtent: number }[] = [];


  constructor(private fb: FormBuilder, private applicant: ApplicantService,
    private snackbar: MatSnackBar, private dialog: MatDialog, private router: Router, private changeDetection: ChangeDetectorRef, private datePipe: DatePipe) {
    this.landRecordForm = this.fb.group({
      ppbNumber: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['landRecordsList']) {
      console.log('Data updated:', changes['panOrVoterInfo']);
      console.log(this.landRecordsList)
      this.updateLandsTable()
    }
  }

  ngAfterViewInit() {
    // this.landRecordForm.controls['district'].valueChanges.subscribe((resp) => {
    //   console.log(resp)
    //   this.districts = this.fullDistricts.filter(s => new RegExp(`^${resp}`, 'gi').test(s.name));
    //   this.changeDetection.detectChanges()
    // })

  }

  updateLandsTable(){
    if(this.landRecordsList && this.landRecordsList.length){
      console.log(this.landRecordsList)
      let formattedLRArray:[] = this.landRecordsList.map((item)=>{
        let land = {};
        land['districtName'] = item['districtName']
        land['subdistrictName'] = item['subdistrictName']
        land['villageName'] = item['villageName']
        land['surveyNo'] = item.landIdentityInfo.surveyNumber
        land['landExtent'] = item['landExtent']
        return land;
      })
      this.landDetailsArray = formattedLRArray;
      console.log("land details", this.landDetailsArray)
      this.showLandVerifiedTick = true;
      this.districtReadOnly = true;
      sessionStorage.setItem('landArray', JSON.stringify(this.landDetailsArray));
    }
  }

  landRecordFetch(form: FormGroupDirective) {
    this.loading = true;
    console.log(form, JSON.stringify(this.landRecordForm.getRawValue()));
    let data = form.value;
    // data.applicantId = "65cde03747ecf0bd41a4f8db"
    data.applicantId = sessionStorage.getItem('applicantId')

    console.log(this.landRecordForm.get('start').value);
    console.log(this.landRecordForm.get('end').value);
// debugger
    data.startTime = new Date(this.landRecordForm.get('start').value).getTime();
    data.endTime = new Date(this.landRecordForm.get('end').value).getTime();
    // data.startTime = 1674648065000;
    // data.endTime = 1675252865000;
    delete data.start;
    delete data.end;
    this.applicant.getTelenganaLRlist(data).subscribe((resp)=>{
      this.loading = false;
      if(resp && resp.success){
        if(resp.data && resp.data.length){
          this.showLands = true;
          this.checkboxOptions = resp.data
        }else{
          this.snackbar.open("No Land Records found","OK")
        }
      }else{
        this.snackbar.open("No Land Records found","OK")
      }
    },(err)=>{
      this.snackbar.open("Failed to fetch Land Records","OK")
      this.loading = false;
    })
  }

  submitLands(){
    const uniqueIds = this.selectedCheckboxes.map(obj => obj.uniqueId);
    this.loading = true;
    this.applicant.submitTelenganaLrs(uniqueIds).subscribe((resp)=>{
      this.loading = false;
      if(resp && resp.success){
        //let formattedLRArray:[] = land[];
        
        this.landArray = resp.data.map((item) => ({

          districtName:item.districtName,
          subdistrictName: item.subdistrictName,
          villageName:item.subdistrictName,
          surveyNo: item.landIdentityInfo.surveyNumber,
          landExtent: item.landExtent
          
        }))
       console.log(this.landArray)
        
    
        //this.landDetailsArray.push(this.land)
        sessionStorage.setItem('selectedDistrict',JSON.stringify({name:this.landArray[0].districtName}))

        
       // this.landDetailsArray = formattedLRArray;
        this.snackbar.open("Land records submitted successfully","OK",{duration:5000})
        sessionStorage.setItem('landArray', JSON.stringify(this.landArray));
        this.landRecordsDone()
      }else{
        this.snackbar.open("Failed to submit Land records","OK",{duration:5000})
      }
    },(err)=>{
      this.loading = false;
      this.snackbar.open("Error while trying to submit Land records","OK",{duration:5000})
    })
  }



  checkboxOptions = [

  ];

  toggleCheckbox(value: string) {
    if (this.selectedCheckboxes.includes(value)) {
      this.selectedCheckboxes = this.selectedCheckboxes.filter(item => item !== value);
    } else {
      this.selectedCheckboxes.push(value);
    }
    console.log(this.selectedCheckboxes)
    this.changeDetection.detectChanges()
  }

  selectedCheckboxes: any = [];

  askForMoreLandRecords() {
    if (this.landDetailsArray.length < 5) {
      const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
        width: 'auto', height: 'auto',
        data: {
          content1: "Would you like to add another land records?",
          button1Text: "Yes",
          button2Text: "No"
        }
      });
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp == 'close') { //button1 yes
          //continue adding more
          //disable district
          this.districtReadOnly = true;
        } else if (resp == 'dontClose') { //button 2 no
          //continue to cropping pattern
          this.disableVerify = true;
          this.landRecordsDone()
        }
      })
    } else if (this.landDetailsArray == 5) {
      const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
        width: 'auto', height: 'auto',
        data: {
          content1: "You are only allowed to add a maximum of 5 land records.",
          button1Text: "OK",
          // button2Text:"No"
        }
      });
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp == 'close') { //button1 OK
          this.landRecordsDone()
        }
      })
    }



  }

  landRecordsDone() {
    this.ppbNumberReadOnly = true;


    let data = {
      success: true,
      landRecords: this.landDetailsArray
    }
   // sessionStorage.setItem('landArray', JSON.stringify(this.landDetailsArray));
    this.value.emit(data)
  }

}
