import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChanges,OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicantService } from 'src/app/applicant.service';
import { fadeInOut } from '../animation';

@Component({
  selector: 'app-loan-successful',
  templateUrl: './loan-successful.component.html',
  styleUrls: ['./loan-successful.component.scss'],
  animations: [fadeInOut]
})
export class LoanSuccessfulComponent implements OnInit {
  loanAmount: any;
  rateOfInterest: number = 8;
  @Input() finalLoanAmounts: any;
  loading: boolean;

  constructor(private applicant:ApplicantService, private snackbar:MatSnackBar, private changeDetection: ChangeDetectorRef){

  }

  

  ngOnChanges(changes: SimpleChanges) {
    if (changes['loanAmountInfo']) {
      console.log('Data updated:', changes['loanAmountInfo']);
      // Perform actions based on the updated value in the child component
      console.log(this.finalLoanAmounts)
      this.updateCard()
    }
  }
  updateCard() {
    // if (this.finalLoanAmounts && this.finalLoanAmounts.loanAmtFarmer) {
    //   this.loanAmount = Number(this.finalLoanAmounts.loanAmtFarmer);
    // } else if (this.finalLoanAmounts && this.finalLoanAmounts.estimatedLoanAmt) {
    //   this.loanAmount = Number(this.finalLoanAmounts.estimatedLoanAmt)
    // }
    // console.log(this.loanAmount)
    // this.changeDetection.detectChanges()
  }

  ngOnInit(){
    // this.loanAmount = Number(sessionStorage.getItem('reqLoanAmt'))
    // this.loanAmount = Number(this.finalLoanAmounts.finalAmount);
     this.loanAmount = Number(sessionStorage.getItem("LoanAmount"));
    console.log(this.loanAmount);
   
  }

  openSanctionLetter() {
    this.loading = true;
    this.applicant.getLoanDocument({type:"sanctionLetter"}).subscribe((resp: Blob)=>{
      const file = new Blob([resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      this.loading = false;
      window.open(fileURL); // Open the PDF in a new tab/window
    },(err)=>{
      this.loading = false;
      this.snackbar.open("Unable to open the document","OK",{duration:3000})
    })
  }

  openApplicationForm() {
    this.loading = true;
    this.applicant.getLoanDocument({type:"loanApplicationForm"}).subscribe((resp: Blob)=>{
      const file = new Blob([resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      this.loading = false;
      window.open(fileURL); // Open the PDF in a new tab/window
    },(err)=>{
      this.loading = false;
      this.snackbar.open("Unable to open the document","OK",{duration:3000})
    })
  }


}
