<div *ngIf="!isIncome">
  <p fxLayout="row" fxLayoutAlign="start center">

    <label fxFlex="15">{{label}}</label>

    <label fxFlex="15">{{detail}}</label>

    <span fxFlex="10">
      <md-icon *ngIf="verified" style="color:#05d016" md-svg-src="../../assets/svg/email-24px.svg"></md-icon>
      <md-icon *ngIf="!verified" style="color:#dc3232" md-svg-src="../../assets/svg/email-24px.svg"></md-icon>
    </span>

    <span fxFlex="15">
      <a *ngIf="file" md-button color="primary" target="_blank" href="{{file}}">View Doc</a>
    </span>

    <span fxFlex="15">
      <a *ngIf="file" md-button color="primary" href="{{file}}" download>Download</a>
    </span>

    <span fxFlex="15">
      <button *ngIf="verified && isAdmin"  md-button color="primary" (click)="viewPartDetail(type)">{{buttonLabel}}</button>
    </span>

    <span fxFlex="15">
      <button *ngIf="file"  md-button color="primary" (click)="shareDoc(file)">Share</button>
    </span>

  </p>
</div>

<div *ngIf="isIncome">
  <p fxLayout="row" fxLayoutAlign="start center">

    <label fxFlex="12">{{label}}</label>

    <span fxFlex="8">
      <md-icon *ngIf="verified" style="color:#05d016" md-svg-src="../../assets/svg/email-24px.svg"></md-icon>
      <md-icon *ngIf="!verified" style="color:#dc3232" md-svg-src="../../assets/svg/email-24px.svg"></md-icon>
    </span>

    <span fxFlex="15">
      <a *ngIf="file" md-button color="primary" target="_blank" href="{{file}}">View Doc</a>
    </span>

    <span fxFlex="15">
      <a *ngIf="file" md-button color="primary" href="{{file}}" download>Download</a>
    </span>

    <span fxFlex="10">
      <button *ngIf="verified && isAdmin"  md-button color="primary" (click)="viewPartDetail(type)">{{buttonLabel}}</button>
    </span>

    <span fxFlex="10">
      <button *ngIf="file"  md-button color="primary" (click)="shareDoc(file)">Share</button>
    </span>

    <label fxFlex="10">{{validity}}</label>
    <label fxFlex="20">{{message}}</label>

  </p>
</div>
