import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ApplicantService } from 'src/app/applicant.service';
import * as constants from "../../../constant";
import * as HindiText from "../../../mp-popup-message";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LanguageService } from 'src/app/language.service';
import { fadeInOut } from '../../animation';
import { Milestone } from '../../enum';
import { DOC_TYPES_RENEWAL_PDF } from '../../../constant';

@Component({
  selector: 'app-renewal-loan-card',
  templateUrl: './renewal-loan-card.component.html',
  styleUrls: ['./renewal-loan-card.component.scss'],
  animations: [fadeInOut]
})
export class RenewalLoanCardComponent {
  showSpinner: boolean;
  showSpinnerWithMessage: any;
  subText: any;
  stepper: any = 7;
  showButtons: boolean;
  showLoanAmountTick: boolean;
  colU: any;
  showCustomerLoanAmount: any;
  disableSubmitButton: boolean;
  disableSubmitButtonForever: boolean;

  toggleSubmitLoanAmountCondOne: boolean = true;
  toggleSubmitLoanAmountCondTwo: boolean = true;
  loanDetailsForm: FormGroup;
  isChecked: boolean;
  iseventchecked : boolean = false;
  estimatedLoanAmount: number;
  isLoanAmtFarmerDisabled: boolean;
  DisabledFlagValue: string;
  @Input() loanAmountInfo: { loanAmount: number; isResume: boolean, milestone:string };
  @Input() loanAmount:number;
  LOCAL_LANGUAGE: any;
  submittedLoanAmounts: any;
  @Output() value = new EventEmitter();
  disabledFinalSubmit: boolean;
  loading: boolean;
  loadDocsGenerated: boolean;

  constructor(private applicant: ApplicantService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private languageservice: LanguageService,
    private snackbar: MatSnackBar) {
    this.loanDetailsForm = this.fb.group({

      estimatedLoanAmt: ["", Validators.required],

    });
  }

  ngOnInit() {
    let language = sessionStorage.getItem('lang')

    this.LOCAL_LANGUAGE = this.languageservice.getConstants(language);
    this.loanDetailsForm.patchValue({estimatedLoanAmt:this.loanAmount})

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['loanAmountInfo']) {
      console.log('Data updated:', changes['loanAmountInfo']);
      // Perform actions based on the updated value in the child component
      console.log(this.loanAmountInfo)
      this.updateCard()
    }
  }

  updateCard() {
    if (this.loanAmountInfo  && this.loanAmountInfo.isResume && this.loanAmountInfo.loanAmount) {

      this.loanDetailsForm.controls['estimatedLoanAmt'].patchValue(this.loanAmountInfo.loanAmount)

      this.estimatedLoanAmount = this.loanAmountInfo.loanAmount;


      this.disableSubmitButton = true;

    } else if (this.loanAmountInfo  && this.loanAmountInfo.loanAmount && !this.loanAmountInfo.isResume) {
      this.loanDetailsForm.controls['estimatedLoanAmt'].patchValue(this.loanAmountInfo.loanAmount)
      // this.showCustomerLoanAmount = true;
    }

    if(this.loanAmountInfo.milestone === Milestone.LOAN_SANCTION_SUCCESS){
      this.DisabledFlagValue = "true";
      this.disableSubmitButtonForever = true;
      this.disableSubmitButton = true;
      this.showLoanAmountTick = true;
      this.loadDocsGenerated = true;

    }
  }


  submitCropNLoan(value) {

    this.submittedLoanAmounts = value;
    let data = {
      requestedLoanAmount: value.estimatedLoanAmt,
      applicantId: sessionStorage.getItem('applicantId')
    }
    this.disabledFinalSubmit = true;
    this.applicant.updateLoanAmount(data).subscribe((resp) => {
      console.log(resp);
      if (resp && resp.success) {
        this.snackbar.open("Loan Amount updated successfully", "OK", { duration: 3000 })
        sessionStorage.setItem('reqLoanAmt',data.requestedLoanAmount)
        this.generateDocs()
        this.updateFinalLoanAmount()
      } else {
        this.snackbar.open("Failed to update Loan Amount", "OK", { duration: 3000 })
      }
    }, (err) => {


      this.snackbar.open("Failed to update Loan Amount", "OK", { duration: 3000 })
    })
  }

  generateDocs() {
    this.loading = true;
    let data = {
      applicantId: sessionStorage.getItem('applicantId'),
      docTypes: constants.DOC_TYPES_RENEWAL_PDF
    }

    this.applicant.generateDocs(data).subscribe((resp) => {
      this.loading = false;
      if (resp && resp.success) {

        this.disableSubmitButton = true;
        this.showLoanAmountTick = true;
        this.loadDocsGenerated = true;

        this.snackbar.open("Documents generated successfully", "OK", { duration: 3000 })
      } else {

        this.snackbar.open("Documents generation failed", "OK", { duration: 3000 })
      }
    }, (err) => {

      this.loading = false;
      this.snackbar.open("Documents generation failed", "OK", { duration: 3000 })
    })
  }

  openAnnexure(type) {
    let docType;
    type == 2 ? docType = DOC_TYPES_RENEWAL_PDF[0]: type ==3 ?docType = DOC_TYPES_RENEWAL_PDF[1]:"";
    if(docType){
      this.loading = true;
      this.applicant.getLoanDocument({type:docType}).subscribe((resp: Blob)=>{
        const file = new Blob([resp], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        this.loading = false;
        window.open(fileURL); // Open the PDF in a new tab/window
      },(err)=>{
        this.loading = false;
        this.snackbar.open("Unable to open the document","OK",{duration:3000})
      })
    }else{
      this.snackbar.open("Invalid document type","OK")
    }

  }



  updateFinalLoanAmount(){
    let data = this.submittedLoanAmounts
    this.value.emit(data)
  }


}
