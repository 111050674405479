import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ApplicantService } from '../../applicant.service';

import * as constants from "../../constant";
import * as HindiText from "../../mp-popup-message";
import { JourneyType, Milestone } from "../enum"

import { OpenDialogYesOrNo } from '../dialog-yes-or-no/dialog-yes-or-no.component';

import { environment as ENV } from "../../../environments/environment";

import * as CryptoJS from 'crypto-js';
import { SpinnerData } from '../../applicant.service';
import { firstValueFrom, of } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FreshCaseOtpDialogComponent } from '../fresh-otp-dialog/otp-dialog.component';
//import { KaLandVerifyComponent } from '../land-records/land-records-ka/ka-land-verify/ka-land-verify.component';
import { CreditService } from '../credit-service/credit.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LanguageService } from 'src/app/language.service';

@Component({
  selector: 'wl-bifurcation',
  templateUrl: './bifurcation.component.html',
  styleUrls: ['./bifurcation.component.scss'],
})

export class BifurcationComponent implements OnInit {

  bgImage: string = "../../../assets/farming.jpg";
  showForm: boolean = false;
  showFreshOrResumeCard: boolean = false;
  resumeCase: boolean = false;
  showQuestion: boolean = false;
  // proceedDialog: MatDialogRef<ProceedPopUpComponent>;
  isNTB: boolean = false;
  detailsForm: FormGroup;
  showAadhaarField: boolean = false;
  isMobileNoButtonDisabled: boolean = false;
  showGreenTickMobileNos: boolean = false;
  showWaiting: boolean = false;
  showWaitingVerify: boolean = false;
  isButtonDisabled: boolean = false;
  showGreenTick: boolean = false;
  accountName: any;
  appliName: string | null | undefined;
  isPanAvailable: boolean = false;
  panNo: any;
  isAadhaarButtonDisabled: boolean = false;
  showWaitingAadhaar: boolean = false;
  showAadharGreentick: boolean = false;
  DataFromBiFur: any = {};
  isMP: boolean = false;

  showSpinner: boolean;
  showSpinnerWithMessage: boolean;
  subText: any;
  stepper: any = 0;


  isKA: boolean = false;

  showStepper: boolean;
  typeType: any = "password";
  showPassword: any;
  rand!: string;
  encRand!: string;
  encCust: any;
  freshResume: any;
  referenceId: any;
  referenceName: any;
  tokenForJourney: any;
  journeyType: any;
  PanOrVoterid: boolean = false;
  coordinates: any;
  ipAddress: any;
  showPanOrVoterIdField: boolean = false;
  panOrVoterIdSelected: boolean = false;
  panOrVoterIdString!: string;
  state: any;
  appStatusPresent: boolean = false;
  isUP: boolean = false;
  showCustomerIdTick: boolean = false;
  showAadharWhitetick: boolean = false;
  showWhiteTickMobileNos: boolean = false;
  showPanOrVoterTick: boolean = false;
  showModal: boolean = false;
  bottomReached: boolean = false;
  @ViewChild("aadhaarLayout")
  private aadhaarLayout!: ElementRef;
  @ViewChild('landLayout')
  landLayout!: ElementRef;
  @ViewChild('panVoterLayout')
  panVoterLayout!: ElementRef;
  isBypass: boolean = false;
  isBypassFlow: boolean = false;

  shouldStatusesBeDisplay: string = ENV.displayStatuses
  mobileVerificationStatus: string = constants.MESSAGE_PENDING;
  aadhaarVerificationStatus: string = constants.MESSAGE_PENDING;
  panVotVerificationStatus: string = constants.MESSAGE_PENDING;
  isMobileVerified: boolean;
  isPanOrVoterVerified: boolean;
  showMobileCard: any;
  showAadhaarCard: boolean;
  showPanVotCard: boolean;
  showAndhraCard: boolean;
  showTelenganaCard:boolean;
  showKACard:boolean;
  showCropCard: boolean;
  showLoanAmtCard: boolean;
  aadhaarInfo: any;
  panOrVoterInfo: { type: string; number: string; success: true };
  isResume: boolean;
  loanAmountInfo: { estimatedAmount: number; loanAmount: number; success: boolean,  isResume: boolean, milestone:string };
  showLoanSuccessfulCard: boolean;
  creditStatus: any;
  finalLoanAmounts: any;
  loading: boolean;
  landDetails: any;
  geoInfo: string;
  geoInfoNew:any;
  resumeData: any;
  resumeFullInfo: any;
  landRecordsList:[{}];
  cropList: {isResume:boolean,crops:object};
  currentMileStone: any;
  creditScore: any;
  showCreditScoreCard: boolean;
  LOCAL_LANGUAGE:any;
  finalAmount:any;
 
 language : string;


  constructor(
    private applicant: ApplicantService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    // private TokenServiceService: TokenServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private creditService: CreditService,
    private snackbar:MatSnackBar,
    private changeDetection:ChangeDetectorRef,
    private languageservice:LanguageService) {


    this.detailsForm = this.fb.group({
      mobileNo: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
      detail: [
        "",
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(16),
        ],
      ],
      aadhaar: ["", [Validators.required, Validators.pattern("^[0-9]{12}$")]],
      panOrVoterId: [Validators.required, Validators.pattern("[A-Z]{5}[0-9]{4}[A-Z]{1}")],
      district: ["", Validators.required],
      tehsil: ["", Validators.required],
      halka: ["", Validators.required],
      village: ["", Validators.required],
      khasra: ["", Validators.required],
      landAreaFromLR: ["", Validators.required],
      branches: ["", Validators.required],
      selectPanOrVoterId: ["", Validators.required],
      panVotNumber: ["", Validators.required]
    });

    if (sessionStorage.getItem('journey') == 'journey') {
      this.router.navigateByUrl('/landing/start')
    }

    this.stepper = 0

  }

  ngOnInit() {

    // this.loading = true;

    this.stepperInitiate()
    // this.stepperMobVerified()
    // this.stepperAadhaarVerified()
    // this.stepperPanOrVotVerified()
    // this.stepperCreditReportVerified()
    // this.stepperLandVerified()
    // this.stepperCropSubmitted()
    // this.stepperSanctioned()
    // debugger


    this.showMobileCard = true;
    // this.showAadhaarCard = true;
    // this.showPanVotCard = true;
    // this.showAndhraCard = true;
    // this.showCropCard = true;
    // this.showLoanAmtCard = true;
    // this.showLoanSuccessfulCard = true;



    if(sessionStorage.getItem("journeyType") == "BC"){
        this.router.navigateByUrl("/landing/accesstype")
    }

    let freshOrResume = sessionStorage.getItem('freshORresume');
    if (freshOrResume == "fresh") {
      this.isResume = false;
    } else if (freshOrResume == "resume") {
      this.isResume = true;
      // this.updateAadhaarInfo()
      // this.updatePanOrVoterInfo()
      // this.updateLoanAmountInfo()
    }

    localStorage.setItem('neslEncryptedData', '')
    this.tokenForJourney = sessionStorage.getItem('freshORresume')
    this.journeyType = sessionStorage.getItem('journeyType')
    this.coordinates = sessionStorage.getItem('coordinates');
    this.ipAddress = sessionStorage.getItem('ipv4');
    this.geoInfo = sessionStorage.getItem('geo-loc-n-ip')
    this.geoInfoNew = sessionStorage.getItem('geo-loc-n-ip1');
    sessionStorage.clear();

    sessionStorage.setItem('jrnyType',JourneyType.NTB);

    // { //hardcoded distid
       //sessionStorage.setItem('selectedDistrict', JSON.stringify({
    //     "DistrictCode": 23,
     //    "DistrictName": "narayanpet"
    //// "id":23,
    // "name":"tirupati - తిరుపతి"
    //  }))

     //this.showCropCard = true;
    // }
    //sessionStorage.setItem("stateHeaderObj", JSON.stringify("telangana"));
    sessionStorage.setItem('journey', 'journey');
    sessionStorage.setItem('freshORresume', this.tokenForJourney);
    sessionStorage.setItem('coordinates', this.coordinates)
    sessionStorage.setItem('geo-loc-n-ip', this.geoInfo)
    sessionStorage.setItem('geo-loc-n-ip1',this.geoInfoNew);
    // debugger
    this.freshResume = this.applicant.freshOrResume;
    this.referenceId = this.applicant.loginReferenceId;
    this.referenceName = this.applicant.loginReferenceName;
    if (this.referenceId) {
      sessionStorage.setItem('referenceId', this.referenceId)
    }
    console.log(this.freshResume, this.referenceId)
    // setTimeout(()=>{
    //   this.freshOrResume(this.freshResume)
    // },2000)

    this.showForm = false;
    // this.showFreshOrResumeCard = true;
    this.showSpinner = false

    this.applicant.stringSubject.subscribe((spinnerData: SpinnerData) => {
      console.log(spinnerData)
      this.showSpinner = (spinnerData['showSpinner']);
      this.showSpinnerWithMessage = spinnerData['spinnerWithMessage'];
      this.subText = spinnerData['subText'];
      this.stepper = spinnerData['stepper'];
    })

    document.addEventListener(
      "onbeforeunload",
      function (ev: Event) {
        console.log("test beforeunload")
        window.location.href = "localhost:4200/existing-customer";
        ev.preventDefault();
      }.bind(this)
    );

  }

  ngAfterViewInit() {
    // this.bgImage = "";
  }

  ngAfterContentInit() {
    // this.freshOrResume(this.freshResume);
    setTimeout(() => {
      this.freshOrResume(this.freshResume);
      this.confirmNotExisting()
    }, 200)

  }

  secretKey = ENV.shareKey;
  encrypt(value: string, key: string): string {
    return CryptoJS.AES.encrypt(value, key.trim()).toString();
  }
  decrypt(textToDecrypt: string, key: string) {
    return JSON.parse(CryptoJS.AES.decrypt(textToDecrypt, key.trim()).toString(CryptoJS.enc.Utf8));
  }

  encryptDecryptApiData(data: any, encryptOrDecrypt: any) {
    console.log(data, encryptOrDecrypt)
    if (encryptOrDecrypt == "enc") {
      const myArray = new Uint32Array(1);
      //generate random number
      let rand = crypto.getRandomValues(myArray)['0'];
      this.rand = JSON.stringify(rand);
      console.log(this.rand)
      //setting token for future improvements
      // this.TokenServiceService.setToken(this.rand);
      //encrypting random string with env key
      this.encRand = this.encrypt(JSON.stringify(this.rand), this.secretKey);
      //add encrpted random string to data
      data['keyValue'] = this.encRand;
      console.log("Data with keyvalue", data)
      //encrypt the data with env key
      let encData = this.encrypt(JSON.stringify(data), this.secretKey);
      return encData;
    } else if (encryptOrDecrypt == "dec") {
      //create dec key with custId
      let custIddecKey = this.rand + '_' + sessionStorage.getItem('custId');
      console.log("custIddecKey", custIddecKey)
      //decrypt
      let decData = this.decrypt(data, custIddecKey);
      return decData;
    } else if (encryptOrDecrypt == "mobDec") {
      //create dec key with custId
      let mobNodecKey = this.rand + "_" + this.detailsForm.controls['mobileNo'].value;
      console.log("custIddecKey", mobNodecKey);
      //decrypt
      let decData = this.decrypt(data, mobNodecKey);
      return decData;
    } else if (encryptOrDecrypt == "panOrVoterIdDec") {
      // debugger
      let panVot = this.detailsForm.controls['panVotNumber'].value;
      let decKey = this.rand + "_" + panVot;
      let decData = this.decrypt(data, decKey)
      return decData;
    }

  }

  freshOrResume(value) {
    console.log(value);

    if (value == "fresh") {
      this.resumeCase = false;
      sessionStorage.setItem('freshORresume', 'fresh');

      this.DataFromBiFur["resumeCase"] = false;
      // this.showForm = true
      this.showFreshOrResumeCard = false;
      this.showQuestion = true;
      // this.proceedDialog = this.dialog.open(ProceedPopUpComponent, {
      //   disableClose: true,
      //   height: "500px",
      //   width: "auto",
      // });
      this.showModal = true;
    } else {
      this.resumeCase = true;
      sessionStorage.setItem('freshORresume', 'resume');

      this.DataFromBiFur["resumeCase"] = true;
      // this.showForm = true
      this.showFreshOrResumeCard = false;
      this.showQuestion = true;
      // this.proceedDialog = this.dialog.open(ProceedPopUpComponent, {
      //   disableClose: true,
      //   height: "500px",
      //   width: "auto",
      // });
      this.showModal = true;
    }
  }

  confirmExisting() {
    this.showForm = true;
    this.isNTB = false;
    this.showQuestion = false;
    this.DataFromBiFur["isNTB"] = false;
    this.showStepper = true;
  }

  confirmNotExisting() {
    this.isNTB = true;
    this.showQuestion = false;
    this.showForm = true;
    this.DataFromBiFur["isNTB"] = true;
    this.showStepper = true;
  }

  showDialog(
    width: any,
    height: any,
    content1: string,
    content2: string,
    buttonText: string,
    errorText: string,
  ) {
    let dialogRef = this.dialog.open(OpenDialogYesOrNo, {
      width: width,
      height: height,
      data: {
        content1: content1,
        content2: content2,
        button1Text: buttonText,
        errorText: errorText
      },
    });

    return dialogRef;
  }

  getMobNo(number1) {
    let number2 = number1
      .toString()
      .split("")
      .splice(number1.toString().split("").length - 4, 4);
    let number3 = number2.unshift("******");
    return number2.join("")
  }

  mobileVerificationDone(value) {
    console.log(value);
    this.isMobileVerified = true;
    this.stepperMobVerified();
    this.mobileVerificationStatus = JSON.stringify(value)
    if (value && value.isResumable) {
      debugger
      this.resumeData = value.resumeData;
      sessionStorage.setItem('applicantId', this.resumeData.data.applicantId)
      this.doResume(value.resumeData.data.milestone)
    } else {
      //do nothing
    }
  }

  // doResume(mileStone) {
  //   // Mapping between milestones and corresponding functions
  //   const milestoneFunctions = {
  //     [Milestone.MOBILE_VERIFICATION_SUCCESS]: this.resumeMobileVerified.bind(this), // do nothing as there is nothing to resume
  //     [Milestone.AADHAAR_VERIFICATION_SUCCESS]: this.resumeAadhaarVerified.bind(this), // show * marks in aadhaar field - fill dummy
  //     [Milestone.PAN_VERIFICATION_SUCCESS]: this.stepperPanOrVotVerified.bind(this), // select drop down, fill pan or voter number, do credit check
  //     [Milestone.VOTER_VERIFICATION_SUCCESS]: this.stepperPanOrVotVerified.bind(this), // select drop down, fill pan or voter number, do credit check
  //     [Milestone.CIC_CHECK_SUCCESS]: this.stepperCreditReportVerified.bind(this), // show credit report download button
  //     [Milestone.LAND_DETAILS_SUBMITTED]: this.stepperLandVerified.bind(this), // fill land data table
  //     [Milestone.CROP_DETAILS_SUBMITTED]: this.stepperCropSubmitted.bind(this), // fill crops, call loan amount calculation, and fill loan amount card
  //     [Milestone.LOAN_AMOUNT_CALCULATED]: this.stepperCropSubmitted.bind(this), // fill loan amount card
  //     [Milestone.LOAN_SANCTION_SUCCESS]: this.stepperSanctioned.bind(this), // show popup that the journey is finished, maybe show documents download
  //   };

  //   const milestoneFunction = milestoneFunctions[mileStone];

  //   // Call the associated function if it exists
  //   if (milestoneFunction) {
  //     milestoneFunction();
  //   }
  // }

  async doResume(mileStone) {
    let shouldResumeProceed = await this.getResumeData();
    this.loading = false;
    this.isResume = true;
    this.changeDetection.detectChanges()
    this.setLandRecord()

    this.currentMileStone = mileStone;
    if (shouldResumeProceed) {
      this.resumeCase = true;
      // Define the order of milestones
      const milestonesInOrder = [
        Milestone.MOBILE_VERIFICATION_SUCCESS,
        Milestone.AADHAAR_VERIFICATION_SUCCESS,
        Milestone.PAN_VERIFICATION_SUCCESS,
        Milestone.VOTER_VERIFICATION_SUCCESS,
        Milestone.CIC_CHECK_SUCCESS,
        Milestone.LAND_DETAILS_SUBMITTED,
        Milestone.CROP_DETAILS_SUBMITTED,
        Milestone.LOAN_AMOUNT_CALCULATED,
        Milestone.LOAN_SANCTION_SUCCESS,
      ];

      // Find the index of the given milestone
      const currentIndex = milestonesInOrder.indexOf(mileStone);

      // Iterate through the milestones and call the corresponding functions
      for (let i = 0; i <= currentIndex; i++) {
        const currentMilestone = milestonesInOrder[i];
        const milestoneFunction = this.getMilestoneFunction(currentMilestone);

        // Call the associated function if it exists
        if (milestoneFunction) {
          milestoneFunction();
        }
      }
    } else {
      const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
        width: "auto",
        height: "auto",
        data: {
          content2: "Failed to fetch information to proceed the journey, Please try again later.",
          button1Text: "OK",
        },
      });
      dialogRef.afterClosed().subscribe((resp) => {

      })
    }

  }

  setLandRecord(){
    if(this.resumeFullInfo.landRecords && this.resumeFullInfo.landRecords.length){
      let newArray
      if(this.state == constants.STATE_LIST.KA){
        let landArray = this.resumeFullInfo.landRecords;
        debugger
        newArray = landArray.map((item)=>{
          console.log(item);
          let land = {};
         // item.landArea = this.kaVerify.landArea;
          land['district'] = item.districtName;
          land['talukName'] = item.talukName;
          land['hobliName'] = item.hobliName;
          land['villageName'] = item.villageName;
          land['surveyNo'] = item.surveyNo;
          land['surveyNoArea'] = item.landArea;
          land['hissaNo'] = item.hissaNo;
          land['period'] = item.period;
          land['storageId'] = item.storageId;
          land['verified'] = item.verified;

          return land;
        })
        sessionStorage.setItem("storageID",JSON.stringify(newArray.storageId))
      }else if(this.state == constants.STATE_LIST.AP){
        let landArray = this.resumeFullInfo.landRecords;

        newArray = landArray.map((item)=>{
          let land = {};
          land['district'] = item.dist_name;
          land['mandal'] = item.mand_name;
          land['village'] = item.village_name;
          land['surveyNo'] = item.surveyno;
          land['surveyNoArea'] = item.surveynoarea;
          return land;
        })
      }else if(this.state == constants.STATE_LIST.TEL){
        let landArray = this.resumeFullInfo.landRecords;

        newArray = landArray.map((item)=>{
          let land = {};
          land['districtName'] = item.districtName;
          land['subdistrictName'] = item.subdistrictName;
          land['villageName'] = item.villageName;
          land['surveyNo'] = item.landIdentityInfo.surveyNumber;
          land['landExtent'] = item.landExtent;
          return land;
        })
      }


      sessionStorage.setItem('landArray',JSON.stringify(newArray))
    }else{
      sessionStorage.setItem('landArray',JSON.stringify([]))
    }
  }

  getResumeData() {
    return new Promise(async (resolve, reject) => {
      this.loading = true;
      this.applicant.getResumeData(this.resumeData.data.applicantId).subscribe(async (resp) => {
        console.log(resp)
        this.loading = false;
        if (resp && resp.success) {
          if (resp.data) {
            this.resumeFullInfo = resp.data;
            this.state = resp.data.state;
            // sessionStorage.setItem('mobileVerificationId',resp.mobileVerificationId)
            sessionStorage.setItem('aadhaarVerificationId',resp.data.aadhaarVerificationId)
            await this.setState()
            resolve(true)
          } else {
            resolve(false)
          }
        } else {
          resolve(false)
        }
      },(err)=>{
        this.loading = false;
        resolve(false);
      })
    })
  }

  async setState(){
    if(this.resumeFullInfo && this.resumeFullInfo.state){
      let state = this.resumeFullInfo.state;
      sessionStorage.setItem('state',this.resumeFullInfo.state)
      if(state.toLowerCase() == constants.STATE_LIST.AP.toLowerCase()){
        this.state = constants.STATE_LIST.AP;
        sessionStorage.setItem("lang",constants.LANGUAGES.TELUGU);
        sessionStorage.setItem("state",constants.STATE_LIST.AP)
      }else if(state.toLowerCase() == constants.STATE_LIST.KA.toLowerCase()){
        this.state = constants.STATE_LIST.KA;
        sessionStorage.setItem("lang",constants.LANGUAGES.KANNADA);
        sessionStorage.setItem("state",constants.STATE_LIST.KA)
      }else if(state.toLowerCase() == constants.STATE_LIST.TEL.toLowerCase()){
        this.state = constants.STATE_LIST.TEL;
        sessionStorage.setItem("lang",constants.LANGUAGES.TELUGU);
        sessionStorage.setItem("state",constants.STATE_LIST.TEL)
      }
      // await firstValueFrom(this.applicant.getHeaders())
      await this.getHeaders();
    }
  }

  async getHeaders() {
    try {
      const resp = await firstValueFrom(this.applicant.getHeaders());

    } catch (error) {
      console.error("Error fetching headers:", error);
    }
  }

  getMilestoneFunction(milestone) {
    // Mapping between milestones and corresponding functions

    const milestoneFunctions = {
      [Milestone.MOBILE_VERIFICATION_SUCCESS]: this.resumeMobileVerified.bind(this), // do nothing as there is nothing to resume
      [Milestone.AADHAAR_VERIFICATION_SUCCESS]: this.resumeAadhaarVerified.bind(this), // show * marks in aadhaar field - fill dummy
      [Milestone.PAN_VERIFICATION_SUCCESS]: this.resumePanOrVoterVerified.bind(this), // select drop down, fill pan or voter number, do credit check
      [Milestone.VOTER_VERIFICATION_SUCCESS]: this.resumePanOrVoterVerified.bind(this), // select drop down, fill pan or voter number, do credit check
      [Milestone.CIC_CHECK_SUCCESS]: this.resumeCreditCheckDone.bind(this), // show credit report download button
      [Milestone.LAND_DETAILS_SUBMITTED]: this.resumeLandRecordsVerified.bind(this), // fill land data table
      // [Milestone.CROP_DETAILS_SUBMITTED]: this.stepperCropSubmitted.bind(this), // fill crops, call loan amount calculation, and fill loan amount card
      [Milestone.LOAN_AMOUNT_CALCULATED]: this.resumeLoanAmountCalculated.bind(this), // fill loan amount card
      [Milestone.LOAN_SANCTION_SUCCESS]: this.resumeSanctioned.bind(this), // show popup that the journey is finished, maybe show documents download
    };

    return milestoneFunctions[milestone];
  }


  resumeMobileVerified() {
    this.stepperMobVerified()
  }

  resumeAadhaarVerified() {
    this.aadhaarInfo = { success: true, aadhaarNo: "xxxxxxxxxxxx" };
    this.stepperAadhaarVerified();
  }

  resumePanOrVoterVerified() {

    if(this.resumeFullInfo && this.resumeFullInfo.panNumber){
      this.panOrVoterInfo = {
        type: "pan",
        number: this.resumeFullInfo.panNumber,
        success: true,
        // creditScore:420
      }
    }else if(this.resumeFullInfo && this.resumeFullInfo.voterNumber){
      this.panOrVoterInfo = {
        type: "voterid",
        number: this.resumeFullInfo.voterNumber,
        success: true,
        // creditScore:420
      }
    }

    this.stepperPanOrVotVerified();
    if(this.currentMileStone == Milestone.PAN_VERIFICATION_SUCCESS){
      this.creditCheck();
    }
  }

  resumeCreditCheckDone(){
    if(this.resumeFullInfo.landRecords && this.resumeFullInfo.landRecords.length){
      if(this.resumeFullInfo.creditScore){
        this.showCreditScoreCard = true;
        this.showPanOrVoterTick = true;
        this.creditScore = this.resumeFullInfo.creditScore
        this.stepperCreditReportVerified()
      }else if(!ENV.production){
        this.stepperCreditReportVerified()
      }else{
        this.resumePanOrVoterVerified();
      }

      // this.resumeLandRecordsVerified() // to view the land records and submit if there is any which is submitted
      this.resumeLandRecordsUpdatedButNotSubmitted()

    }else{
      if(this.resumeFullInfo.creditScore){
        this.showCreditScoreCard = true;
        this.showPanOrVoterTick = true;
        this.creditScore = this.resumeFullInfo.creditScore
        this.stepperCreditReportVerified()
      }else if(!ENV.production){
        this.stepperCreditReportVerified()
      }else{
        this.resumePanOrVoterVerified();
      }
    }
  }

  resumeLandRecordsUpdatedButNotSubmitted(){
    if(this.state == constants.STATE_LIST.AP){
      this.landRecordsList = this.resumeFullInfo.landRecords?this.resumeFullInfo.landRecords:[];
      let dist = {name:this.landRecordsList[0]['userSelections']['dist_name']}
      sessionStorage.setItem('selectedDistrict',JSON.stringify(dist))
      // this.stepperLandVerified()
    }else if(this.state == constants.STATE_LIST.KA){
      this.landRecordsList = this.resumeFullInfo.landRecords?this.resumeFullInfo.landRecords:[];
      let dist = {name:this.landRecordsList[0]['districtName']}
      sessionStorage.setItem('selectedDistrict',JSON.stringify(dist))
      // this.stepperLandVerified()
    }
 else if(this.state == constants.STATE_LIST.TEL){
        this.landRecordsList = this.resumeFullInfo.landRecords?this.resumeFullInfo.landRecords:[];
        let dist = {name:this.landRecordsList[0]['districtName']}
        sessionStorage.setItem('selectedDistrict',JSON.stringify(dist))
         this.stepperLandVerified()
    }
  } 

  resumeLandRecordsVerified(){
    if(this.state == constants.STATE_LIST.AP){
      this.landRecordsList = this.resumeFullInfo.landRecords?this.resumeFullInfo.landRecords:[];
      let dist = {name:this.landRecordsList[0]['userSelections']['dist_name']}
      sessionStorage.setItem('selectedDistrict',JSON.stringify(dist))
      this.stepperLandVerified()
    }else if(this.state == constants.STATE_LIST.KA){
      this.landRecordsList = this.resumeFullInfo.landRecords?this.resumeFullInfo.landRecords:[];
      if (this.landRecordsList.length > 0) {
      let dist = {name:this.landRecordsList[0]['districtName']}
      sessionStorage.setItem('selectedDistrict',JSON.stringify(dist))
      this.stepperLandVerified()
    }else if(this.state == constants.STATE_LIST.TEL){
      this.landRecordsList = this.resumeFullInfo.landRecords?this.resumeFullInfo.landRecords:[];
      let dist = {name:this.landRecordsList[0]['districtName']}
      sessionStorage.setItem('selectedDistrict',JSON.stringify(dist))
      this.stepperLandVerified()
    }
    else{
      this.snackbar.open("Land Record list is", "OK", { duration: 3000 });
    }
  }
  }

  resumeLoanAmountCalculated(){
    this.stepperCropSubmitted();
    // this.cropList['isResume'] = true;
    this.cropList = this.resumeFullInfo.crops?this.resumeFullInfo.crops:{};
    let loanInfo = this.resumeFullInfo.loanAmounts
    this.loanAmountInfo = { estimatedAmount: loanInfo.proposedLoanAmount, loanAmount: loanInfo.requestedLoanAmount,success: true, isResume: true, milestone:this.currentMileStone }
  }

  resumeSanctioned(){
    let loanInfo = this.resumeFullInfo.loanAmounts
    this.finalLoanAmounts = {estimatedAmount: loanInfo.proposedLoanAmount, loanAmtFarmer: loanInfo.requestedLoanAmount,finalAmount: loanInfo.firstYearLoanAmount}
   this.stepperSanctioned();

  }



  creditCheck() {
    let data = {}
    this.loading = true;
    this.creditService.getCreditData(data).subscribe((resp) => {
      this.creditStatus = resp;
      if(resp && resp.success && resp.data  && resp.data && resp.data.SCORE){
        debugger
        this.creditScore = resp.data.SCORE;
        this.showCreditScoreCard = true;
        this.showPanOrVoterTick = true;

      }
      this.loading = false;
      if (this.creditStatus && this.creditStatus.success) {
        this.stepperCreditReportVerified();
      } else {
        const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
          width: "auto",
          height: "auto",
          data: {
            content2: "Failed at credit check",
            button1Text: "OK",
          },
        });
      }
    }, (err) => {
      // this.creditStatus = false;
      this.showSpinner = false;
    })
  }

  updateLoanAmountInfo() {
    this.loanAmountInfo = {
      estimatedAmount: 100000,
      loanAmount: 50000,
      success: true,
      isResume:false,
      milestone:null
    }
    this.stepperSanctioned();
  }

  aadhaarVerificationDone(value) {
    console.log(value);
    this.stepperAadhaarVerified();
    this.state = value.state;
    this.aadhaarVerificationStatus = JSON.stringify(value);
  }
  panVotSuccess(value) {
    console.log(value)
    this.isPanOrVoterVerified = true;
    this.stepperPanOrVotVerified();
    this.panVotVerificationStatus = JSON.stringify(value);
    this.creditCheck();
  }

  landRecordsDone(value) {
    console.log(value);
    this.stepperLandVerified();
    this.landDetails = value
  }

  loanAmountCalculationDone(value, isResume) {
    console.log(value);
    this.stepperCropSubmitted();
    value.isResume = isResume;
    this.loanAmountInfo = value;
  }

  loanAmountSubmitted(value) {
    console.log(value);
    this.stepperSanctioned()
    this.finalLoanAmounts = value;
  }




  togglePassword() {
    this.typeType == "password"
      ? (this.typeType = "text")
      : (this.typeType = "password");
    let password = this.showPassword;
    this.showPassword = !password;
  }

  scrollToBottom(): void {
    console.log('inside scroll--------->');
    window.scrollTo(0, document.body.scrollHeight);
  }



  closeDialog() {
    this.showModal = false;
  }

  initiateScroll(el: ElementRef) {
    setTimeout(() => {
      console.log('---------')
      el.nativeElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 200)
  }

  //below are journey tracker updates
  sendSpinnerData() {
    let data = {
      showSpinner: this.showSpinner,
      spinnerWithMessage: this.showSpinnerWithMessage,
      subText: this.subText,
      stepper: this.stepper,
    };
    this.applicant.passValue(data);
  }

  stepperInitiate() {
    this.stepper = 1;
    this.sendSpinnerData()
  }

  stepperMobVerified() {
    this.stepper = 2;
    this.sendSpinnerData()
    //let message = "Mobile Number Verified Successfully!"
    let message = constants.MESSAGE_SUCCESS_MOBILE
    if(!this.resumeCase){
      this.showDonePopup(message)
    }
    this.showAadhaarCard = true;
  }

  stepperAadhaarVerified() {
    this.stepper = 3;
    this.sendSpinnerData();
    //let message = "Aadhaar Verified Successfully!"
     let message = constants.MESSAGE_SUCCESS_AADHAR
    // this.showDonePopup(message)
    if(!this.resumeCase){
      this.showDonePopup(message)
     // this.openAadharReport()
    }
    this.language = sessionStorage.getItem('lang')
       console.log(this.language);
     this.LOCAL_LANGUAGE = this.languageservice.getConstants(this.language); 
    this.showPanVotCard = true;
  }

  stepperPanOrVotVerified() {
    this.stepper = 4;
    // let message = "ID Verified Successfully!"
    // this.showDonePopup(message)
    
    this.sendSpinnerData();

  }

  stepperCreditReportVerified() {
    this.stepper = 5;
    this.sendSpinnerData();
    if(this.state == constants.STATE_LIST.AP){
      this.showAndhraCard = true;
    }else if(this.state == constants.STATE_LIST.KA){
      this.showKACard = true;
    }else if(this.state == constants.STATE_LIST.TEL){
      this.showTelenganaCard = true;
    }

  }

  stepperLandVerified() {
    this.stepper = 6;
    this.sendSpinnerData();
   //let message = "Land Record Verfified Successfully!"
   let message = constants.MESSAGE_SUCCESS_LANDRECORDS
    if(!this.resumeCase){
      this.showDonePopup(message)
    }
    this.showCropCard = true;
  }

  stepperCropSubmitted() {
    console.log(this)
    this.stepper = 7;
    this.sendSpinnerData();
   //let message = "Loan Amount Calculated Successfully!"
   let message = constants.MESSAGE_SUCCESS_LOANAMOUNT
    if(!this.resumeCase){
      this.showDonePopup(message)
    }
    this.showLoanAmtCard = true;
  }

  stepperSanctioned() {
    this.stepper = 8;
    this.sendSpinnerData();
    // let message = "Loan Amount Sanctioned Successfully!"
    let message = constants.MESSAGE_SUCCESS_LOANAMOUNT_SANC
    if(!this.resumeCase){
      this.showDonePopup(message)
    }
    this.showLoanSuccessfulCard = true;
  }

  showDonePopup(message) {
    const dialogRef = this.showDialog(
      "auto",
      "auto",
      "",
      message,
      "OK",
      this.applicant.moreInfo(message)
    );
  }

  openCreditReport() {
    this.loading = true;
    this.applicant.getLoanDocument({ type: "creditReport" }).subscribe((resp: Blob) => {
      const contentType = resp.type; // Get the content type of the blob

      // Check if the content type indicates it's a PDF file
      if (contentType && contentType === 'application/pdf') {
        const file = new Blob([resp], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        this.loading = false;
        window.open(fileURL); // Open the PDF in a new tab/window
      } else {
        this.loading = false;
        this.snackbar.open("Received content is not a PDF file", "OK", { duration: 3000 });
      }
    }, (err) => {
      this.loading = false;
      this.snackbar.open("Unable to open the document", "OK", { duration: 3000 });
    });
  }


  openAadharReport() {
    this.loading = true;
    this.applicant.getLoanDocument({ type: "aadharReport" }).subscribe((resp: Blob) => {
      const contentType = resp.type; // Get the content type of the blob
          console.log(resp)
      // Check if the content type indicates it's a PDF file
      if (contentType && contentType === 'application/pdf') {
        const file = new Blob([resp], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        this.loading = false;
        window.open(fileURL); // Open the PDF in a new tab/window
      } else {
        this.loading = false;
        this.snackbar.open("Received content is not a PDF file", "OK", { duration: 3000 });
      }
    }, (err) => {
      this.loading = false;
      this.snackbar.open("Unable to open the document", "OK", { duration: 3000 });
    });
  }

  openGst(){
    
   // this.router.navigateByUrl('/landing/tax');
  }
}
