import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BankOrBcLoginComponent } from '../bank-or-bc-login.component';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-bc-base',
  templateUrl: './bc-base.component.html',
  styleUrls: ['./bc-base.component.scss']
})
export class BcBaseComponent {

  private breakpointSubscription: Subscription;

  bgImage: string = "https://c.pxhere.com/images/45/4f/a2773d2017237529ef32297bb125-1455421.jpg!d";
  referenceId: any;
  isMobile: boolean;
  currentRoute: string;


  constructor(public dialog: MatDialog, public router:Router, private breakpointObserver: BreakpointObserver){
    this.currentRoute = this.router.url;
    console.log(this.currentRoute)
  }
  ngOnInit(){
    this.breakpointSubscription = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .subscribe((state) => {
      console.log(state)
      this.isMobile = state.matches;

      if(this.currentRoute == "/landing/bc-login"){
        const dialogRef = this.dialog.open(BankOrBcLoginComponent, {
          width: "350px",
          height: "auto",
          data: { type:"BC" },
        });
        dialogRef.afterClosed().subscribe((resp)=>{
          this.referenceId = resp;
          if(resp){
            console.log(resp)
            sessionStorage.setItem("journeyType","BC");
            this.router.navigateByUrl('/landing/assisted-journey');
          }

        })
      }

    });


  }
}
