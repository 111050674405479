<div class="loading-overlay" *ngIf="loading">
  <div class="spinner"></div>
</div>
<div class="container" style="position: absolute;z-index: 5;top:4.25rem;left:0rem;">
  <div class="row">
    <div class="col-2" style="background-color: white; padding-top: 3rem;">
      <app-vertical-stepper [JourneyType]="'RENEWAL'" (scrollToSection)="scrollTo($event)"></app-vertical-stepper>
    </div>

    <div class="col-10">
      <div class="cardStyles" style="padding-top: 4rem;" *ngIf="showKACard" id="land-component">
        <app-land-records-ka  [landRecordsList]="landRecordsListKA" (value)="landRecordsDone($event)" [isResume]="isResume" [milestone]="milestone"></app-land-records-ka>
      </div>

      <div class="cardStyles" *ngIf="showCreditScoreCard" id="credit-component">
        <wl-card heading="Credit Score">
          <div class="loading-overlay" *ngIf="loadingCredit">
            <div class="spinner"></div>
          </div>
          <div style="width: 100%; font-weight: bold;font-size: 1.0rem; text-align: center;">
            Credit Score: {{creditScore}}
          </div>
          <br>
          <div style="width: 100%; font-weight: bold;font-size: 1.0rem; text-align: center;">
            <button class="mybutton" (click)="openCreditReport()">View Credit Report</button>
          </div>
        </wl-card>
      </div>

      <div class="cardStyles" *ngIf="showCropCard" id="crop-component">
        <app-cropping-pattern (value)="loanAmountCalculationDone($event, false)" [cropList]="cropList"></app-cropping-pattern>
      </div>

      <div class="cardStyles" *ngIf="showLoanCard" id="sanction-component">
        <app-renewal-loan-card [loanAmount]="loanAmountInfo.loanAmount"></app-renewal-loan-card>
      </div>
    </div>
  </div>
</div>

<!-- <div class="cardStyles" style="padding-top: 4rem;" *ngIf="showKACard" id="land-component">
  <app-land-records-ka  [landRecordsList]="landRecordsListKA" (value)="landRecordsDone($event)" [isResume]="isResume" [milestone]="milestone"></app-land-records-ka>
</div> -->

<!-- <div class="cardStyles" *ngIf="showCreditScoreCard" id="credit-component">
  <wl-card heading="Credit Score">
    <div class="loading-overlay" *ngIf="loadingCredit">
      <div class="spinner"></div>
    </div>
    <div style="width: 100%; font-weight: bold;font-size: 1.0rem; text-align: center;">
      Credit Score: {{creditScore}}
    </div>
    <br>
    <div style="width: 100%; font-weight: bold;font-size: 1.0rem; text-align: center;">
      <button class="mybutton" (click)="openCreditReport()">View Credit Report</button>
    </div>
  </wl-card>
</div>

<div class="cardStyles" *ngIf="showCropCard" id="crop-component">
  <app-cropping-pattern (value)="loanAmountCalculationDone($event, false)" [cropList]="cropList"></app-cropping-pattern>
</div>

<div class="cardStyles" *ngIf="showLoanCard" id="sanction-component">
  <app-renewal-loan-card [loanAmount]="loanAmountInfo.loanAmount"></app-renewal-loan-card>
</div> -->
