import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'wl-page-not-found',
  templateUrl: './page-not-found.component.html',
  styles: [`
    .text-center {
      text-align: center
    }
  `]
})
export class PageNotFoundComponent {

  constructor(private location: Location) { }

  goBack() {
    this.location.back();
  }

}
