<!-- <wl-card heading="ग्राहक विवरण - Customer Details" [subheading1]="showWhiteTickMobileNos"> -->
  <wl-card heading="Customer Details" [subheading1]="showWhiteTickMobileNos"  [@fadeInOut]  >
  <form [formGroup]="detailsForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <!-- <p style="text-align: center; padding-bottom: 0px;margin-bottom: 0px;">कृपया अपना मोबाइल नंबर दर्ज करें
        </p> -->

        <p style="text-align: center;  padding-top: 0px;margin-top: 0px;">Enter your Mobile No. </p>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field style="width: 100%;" appearance="outline">
          <input matInput type="text" formControlName="mobileNo" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" [readonly]="isMobileNoButtonDisabled || isInputReadOnly" >
          <mat-error *ngIf="detailsForm.get('mobileNo').invalid">
            Please enter a valid phone number.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="text-align: center;">
        <button class="mybutton" (click)="verifyMobileNoNTB(detailsForm.value)"
        [disabled]="detailsForm.controls['mobileNo'].invalid || isMobileNoButtonDisabled">Verify</button>
        <!-- <button class="mybutton" (click)="verifyMobileNoNTB(detailsForm.value)"
          [disabled]="detailsForm.controls['mobileNo'].invalid || isMobileNoButtonDisabled">जांच
          करे (Verify)</button> -->
        <!-- <img style="display: none; height:24px; width:24px; margin-left: 5px; margin-bottom:-10px"
          src="../../../assets/icons/check_circle.png" *ngIf="showGreenTickMobileNos"> -->
      </div>
    </div>

    <div class="row justify-content-center" *ngIf="showWaitingVerify">
      <div class="col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5"
        style="text-align: center; margin-left: 30%; ">
        <mat-spinner style="height:25px; margin-top: 5px; "></mat-spinner>
      </div>
    </div>

    <div class="row justify-content-center" *ngIf="showWaitingVerify">
      <div class="col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5"
        style="text-align: center; margin-left: 30%; ">
        <mat-spinner style="height:25px; margin-top: 5px; "></mat-spinner>
      </div>
    </div>
  </form>

</wl-card>
