import { Component, Input} from '@angular/core';

@Component({
  selector: 'wl-button-nolabel',
  templateUrl: './button-nolabel.component.html',
})
export class ButtonNoLabelComponent {

  @Input() color = 'primary';
  @Input() disabled = false;
  @Input() label: string;

  constructor() { }

}
