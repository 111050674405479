import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fadeInOut, zoomInOut } from '../../animation';
import { ApplicantService } from 'src/app/applicant.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SseService } from './sse.service';
import { Subscription } from 'rxjs';
import { environment as ENV } from 'src/environments/environment';

@Component({
  selector: 'app-link-generation',
  templateUrl: './link-generation.component.html',
  styleUrls: ['./link-generation.component.scss'],
  animations: [fadeInOut, zoomInOut],
  providers: [SseService]
})
export class LinkGenerationComponent {
  detailsForm: FormGroup;
  isMobileNoButtonDisabled: boolean;
  loading: boolean;
  showWhiteTickMobileNos: boolean;

  sseSubscription: Subscription;
  sseData: any[] = [];
  @Output() value = new EventEmitter();
  @Input() resumeLinkGenerationInfo: any;

  sseUrl: string = ENV.sseUrl
  mobileNo: any;
  isProgress: boolean;

  constructor(private fb: FormBuilder, private applicant: ApplicantService, private snackbar: MatSnackBar, private sseService: SseService,
    private cdr: ChangeDetectorRef
  ) {
    this.detailsForm = this.fb.group({
      mobileNo: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]]
    });
  }

  ngOnInit() {
    // this.loading = true
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['resumeLinkGenerationInfo'] && this.resumeLinkGenerationInfo) {
      console.log('Data updated:', changes['mobileInfo']);
      // Perform actions based on the updated value in the child component
      console.log(this.resumeLinkGenerationInfo)
      this.updateCard()
    }
  }

  updateCard(){
    this.detailsForm.patchValue({mobileNo:this.resumeLinkGenerationInfo.mobileNumber});
    this.isMobileNoButtonDisabled = true;
    let value = {mobileNo:this.resumeLinkGenerationInfo.mobileNumber}
    this.generateLink(value);
  }



  generateLink(value) {
    console.log(value)
    this.loading = true;
    this.mobileNo = value.mobileNo;
    this.applicant.generateApplicantSMS(value.mobileNo).subscribe((resp) => {
      console.log(resp)
      // this.loading = false;
      if (resp.success) {
        this.sseSubscription = this.sseService.getSSE(this.sseUrl + value.mobileNo).subscribe(
          (event: MessageEvent) => {
            console.log(JSON.parse(event.data))
            this.sseData.push(event);
            if (JSON.parse(event.data).success === true) {
              // Unsubscribe from SSE events
              let data = JSON.parse(event.data)
              if (data && data.data && data.data.aadhaarVerificationId && data.data.mobileVerificationId) {
                sessionStorage.setItem('aadhaarVerificationId', data.data.aadhaarVerificationId)
                sessionStorage.setItem('mobileVerificationId', data.data.mobileVerificationId)
                sessionStorage.setItem('applicantId', data.data.applicantId)
                this.sseSubscription?.unsubscribe();
                this.loading = false;
                this.isMobileNoButtonDisabled = true;
                if (resp && resp.data && resp.data.isProgress) {
                  this.isProgress = true;
                }
                this.kycDone()
                this.cdr.detectChanges();
                console.log('Unsubscribed from SSE events');

              }else if(!data.data.aadhaarVerificationId){
                this.snackbar.open("Aadhaar Verification Details Mismatch","OK",{duration:3000})
              }else if(!data.data.mobileVerificationId){
                this.snackbar.open("Mobile Verification Details Mismatch","OK",{duration:3000})
              } else {
                this.loading = false;
                this.snackbar.open("Failed to fetch Information", "OK", { duration: 3000 })
              }
            }
          },
          (error) => {
            console.error('Error in SSE connection:', error);
            this.loading = false;
          }
        );


      } else {
        this.loading = false;
        this.snackbar.open(resp.message ? resp.message : "Something went wrong", "OK")
      }

    }, (err) => {
      this.snackbar.open("Something went wrong!", "OK", { duration: 3000 })
      this.loading = false;
    })
  }

  kycDone() {
    this.showWhiteTickMobileNos = true;
    let data = {
      mobileNo: this.mobileNo,
      isProgress: this.isProgress
    }
    this.value.emit(data)
  }

  ngOnDestroy() {
    // Clean up resources when the component is destroyed
    if (this.sseSubscription) {
      this.sseSubscription.unsubscribe();
    }
  }
}
