import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment as ENV } from '../../../environments/environment'

@Component({
  selector: 'wl-user-history',
  templateUrl: './user-history.component.html',
})
export class UserHistoryComponent {

  @Input() label: string;
  @Input() message: string;
  @Input() validity: string;
  @Input() verified: boolean;
  @Input() file: string;
  @Input() isAdmin: boolean;

  constructor(
    private router: Router,

  ) { }

  navigateTo(path) {
    console.log(path);

    switch (path) {
      case 'Aadhaar': this.router.navigate(['laas/identity/id-proof/aadhaar']); break;
      case 'PAN': this.router.navigate(['laas/identity/id-proof/pan']); break;
      case 'Driving License': this.router.navigate(['laas/identity/id-proof/dl']); break;
      case 'Passport': this.router.navigate(['laas/identity/id-proof/passport']); break;
      case 'NREGA': this.router.navigate(['laas/identity/id-proof/nrega']); break;
      case 'Voter ID': this.router.navigate(['laas/identity/id-proof/epic']); break;

      case 'LPG': this.router.navigate(['laas/identity/address-proof/lpg']); break;
      case 'Electricity Bill': this.router.navigate(['laas/identity/address-proof/electricity']); break;
      case 'Telephone Bill': this.router.navigate(['laas/identity/address-proof/telephone']); break;

      case 'Personal Details': this.router.navigate(['laas/identity/personal-detail/basic']); break;
      case 'Residential Details': this.router.navigate(['laas/identity/personal-detail/residential']); break;
      case 'Other Loan Details': this.router.navigate(['laas/identity/personal-detail/other']); break;

      case 'Work Details': this.router.navigate(['laas/identity/employment/work']); break;
      case 'ESIC': this.router.navigate(['laas/identity/employment/esic']); break;
      case 'EPF': this.router.navigate(['laas/identity/employment/epf']); break;

      case 'Bank Statement': this.router.navigate(['laas/financial/bank']); break;
      case 'ITR': this.router.navigate(['laas/financial/itr']); break;
      case 'Form 16': this.router.navigate(['laas/financial/form16']); break;
      case 'Credit Score': this.router.navigate(['laas/financial/credit-score']); break;
    }
  }

}
