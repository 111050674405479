import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LanguageService } from 'src/app/language.service';
import { LANGUAGES } from 'src/app/constant';

@Component({
  selector: 'wl-fresh-or-resume',
  templateUrl: './fresh-or-resume.component.html',
  styles: []
})
export class FreshOrResumeComponent implements OnInit {
  @Output() value = new EventEmitter();
  bgImage: string = "https://c.pxhere.com/images/45/4f/a2773d2017237529ef32297bb125-1455421.jpg!d";
  LOCAL_LANGUAGE:any;
   langState:any;
  constructor(private languageService:LanguageService) { }
   


  ngOnInit() {
    this.langState = localStorage.getItem('state1');
    //this.langState = "Telangana";
    // this.langState1 = sessionStorage.getItem("state");
    const telugu = ["andhra pradesh","telangana"];
    const hindi = ["madhya pradesh"];

    console.log(this.langState);
    if(this.langState == "Karnataka")
      {
        this.LOCAL_LANGUAGE = this.languageService.getConstants(LANGUAGES.KANNADA);
        console.log(LANGUAGES.KANNADA);
      }
      else if(telugu.includes(this.langState.toLowerCase()))
      {
        this.LOCAL_LANGUAGE = this.languageService.getConstants(LANGUAGES.TELUGU);
      }
      else if(hindi.includes(this.langState.toLowerCase()))
      {
        this.LOCAL_LANGUAGE = this.languageService.getConstants(LANGUAGES.HINDI);
      }
      else{
        this.LOCAL_LANGUAGE = this.languageService.getConstants(LANGUAGES.HINDI);
      }


  }

  fresh(){
    this.value.emit("fresh");
  }

  resume(){
    this.value.emit("resume");
  }
}
