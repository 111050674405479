import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as constants from "../../constant";

export interface DialogData {
  height: any;
  width: any;
  heading: string;
  content1: string;
  content2: string;
  errorText: string;
  button1Text: string;
  button2Text: string;
  showWaiting: boolean;
  showBuffering: boolean;
}

@Component({
  selector: 'app-dialog-yes-or-no',
  templateUrl: 'dialog-yes-or-no.component.html',

  styles: [`
  .icon {
    width: 120px;
    height: 120px;
  }

  .flex-gap {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  .ring{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:150px;
    height:150px;
    background:transparent;
    border:3px solid #62b9ff;
    border-radius:50%;
    text-align:center;
    line-height:150px;
    font-family:sans-serif;
    font-size:20px;
    color:#00569d;
    letter-spacing:4px;
    text-transform:uppercase;
    text-shadow:0 0 10px #00569d;
    box-shadow:0 0 20px #00569d;
  }
  .ring:before
  {
    content:'';
    position:absolute;
    top:-3px;
    left:-3px;
    width:100%;
    height:100%;
    border:3px solid transparent;
    border-top:3px solid #00569d;
    border-right:3px solid #00569d;
    border-radius:50%;
    animation:animateC 2s linear infinite;
  }
  span
  {
    display:block;
    position:absolute;
    top:calc(50% - 2px);
    left:50%;
    width:50%;
    height:4px;
    background:transparent;
    transform-origin:left;
    animation:animate 2s linear infinite;
  }
  span:before
  {
    content:'';
    position:absolute;
    width:16px;
    height:16px;
    border-radius:50%;
    background:#00569d;
    top:-6px;
    right:-8px;
    box-shadow:0 0 20px #00569d;
  }
  @keyframes animateC
  {
    0%
    {
      transform:rotate(0deg);
    }
    100%
    {
      transform:rotate(360deg);
    }
  }
  @keyframes animate
  {
    0%
    {
      transform:rotate(45deg);
    }
    100%
    {
      transform:rotate(405deg);
    }
  }
  `]
})

export class OpenDialogYesOrNo {
  imageType: any;
  iconString: string = '';
  outerDiv: any;

  constructor(
    public dialogRef: MatDialogRef<OpenDialogYesOrNo>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.imageType = sessionStorage.getItem(constants.POPUP_IMG_TYPE);
    if (this.imageType == constants.POPUP_IMG_TYPE_PROFILE) {
      this.iconString = "../../../assets/popup-icons/id-verification.gif";
      sessionStorage.setItem(constants.POPUP_IMG_TYPE, "")
    } else if (this.imageType == constants.POPUP_IMG_TYPE_AADHAR) {
      this.iconString = "../../../assets/popup-icons/aadhaar.gif";
      sessionStorage.setItem(constants.POPUP_IMG_TYPE, "")
    } else if (this.imageType == constants.POPUP_IMG_TYPE_NESL) {
      this.iconString = "../../../assets/popup-icons/nesl.gif";
      sessionStorage.setItem(constants.POPUP_IMG_TYPE, "")
    } else if (this.imageType == constants.POPUP_IMG_TYPE_ACC_OPENING) {
      this.iconString = "../../../assets/popup-icons/document-sucess.gif";
      sessionStorage.setItem(constants.POPUP_IMG_TYPE, "")
    }

  }

  ngOnInit(){
    this.outerDiv = {
      'width':this.data.width,
      'height':this.data.height,
      'text-align':'center',
      'padding':'1rem'
    }
  }

  showError: boolean = false;
  more: string = constants.MESSAGE_MORE_INFO

  onOne(): void {
    this.dialogRef.close("close");
  }
  onTwo(): void {
    this.dialogRef.close("dontClose");
  }

  toggleError() {
    this.showError = !this.showError;
    if (this.showError == true) {
      this.more = constants.MESSAGE_HIDE_INFO
    } else {
      this.more = constants.MESSAGE_MORE_INFO
    }
  }

}
