// sse.service.ts

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn:'root'})
export class SseService {

  constructor(private http: HttpClient) { }

  getEventSource(url: string): EventSource {
    // const eventSourceInitDict = {
    //   // withCredentials: true,  // If you need to include credentials in the request (cookies, HTTP authentication)
    //   // headers: {
    //   //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
    //   // },
    // };
    return new EventSource(url);
  }

  getSSE(url: string): Observable<MessageEvent> {
    const eventSource = this.getEventSource(url);
    return new Observable(observer => {


      eventSource.onmessage = (event: MessageEvent) => {
        console.log(event)
        observer.next(event);
      };

      // eventSource.onerror = (error) => {
      //   observer.error(error);
      // };

      // Clean up resources when the Observable is unsubscribed
      return () => {
        eventSource.close();
      };
    });
  }
}
