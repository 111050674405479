import { Component } from '@angular/core';

@Component({
  selector: 'app-renewal-flow',
  templateUrl: './renewal-flow.component.html',
  styleUrls: ['./renewal-flow.component.scss']
})
export class RenewalFlowComponent {

  loading: boolean;
  subText: string;
  showSpinnerWithMessage:boolean;
  showSpinner:boolean;

}
