import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authservice: JwtService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("Auth guard is checking");

    const queryParams = route.queryParams;
    console.log(queryParams)
    if (queryParams['checkAssistedValidity'] && queryParams['checkAssistedValidity'] == 'true') {

      if (queryParams['avdaKedvra']) {
        sessionStorage.setItem('avdaKedvra', queryParams['avdaKedvra'])
      }
      if (queryParams['xpctoPtrnm']) {
        console.log(atob(queryParams['xpctoPtrnm']))
        sessionStorage.setItem('token', atob(queryParams['xpctoPtrnm']))
      }
    }else if(queryParams['checkRenewalValidity'] && queryParams['checkRenewalValidity'] == 'true'){
      if (queryParams['avdaKedvra']) {
        sessionStorage.setItem('avdaKedvra', queryParams['avdaKedvra'])
      }
      if (queryParams['xpctoPtrnm']) {
        console.log(atob(queryParams['xpctoPtrnm']))
        sessionStorage.setItem('token', atob(queryParams['xpctoPtrnm']))
      }
    }
    if (this.authservice.validateToken(sessionStorage.getItem("token"))) {
      console.log("User is authenticated");
      return true;
    } else {
      console.log("User is authenticated");
      sessionStorage.clear()
      return this.router.parseUrl("/landing/accesstype");
    }
  }

}
