<!--<p fxLayout="row" fxLayoutAlign="start center">
  <label fxFlex="30" fxShow fxHide.xs>{{label}}</label>
   <span fxFlex.gt-xs="50" fxFlex.xs="100">
  <button fxFlex.xs="100" fxFlex.gt-xs="none" class="mybutton" [disabled]="disabled"><ng-content></ng-content></button>
   </span>
</p>-->

<div class="container">
  <div class="row">
    <div class="col-4">
      <label></label>
    </div>
    <div class="col-5">
      <button class="mybutton" [disabled]="disabled"><ng-content></ng-content></button>
    </div>
  </div>
</div>


<!-- <p fxLayout="row" fxLayoutAlign="start center">
  <label fxFlex="30" fxShow fxHide.xs>{{label}}</label>
  <button fxFlex.xs="100" fxFlex.gt-xs="none" md-raised-button [color]="color" [disabled]="disabled"><ng-content></ng-content></button>
</p> -->

<!-- <link rel="stylesheet" href="../../../assets/bootstrap-grid.css"> -->
