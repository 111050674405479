/** Created by Naveen on 24th Nov 2022
 * This file maintains the API response message and other constant values
 * Currently this file used only in @mp-fresh-case-component.ts file
 */

export const AADHAAR = "aadhaar";
export const PAN = "PAN";
export const VOTERID = "VoterID";
export const ID = "id";
 export const MOBILE_NUM_NTB = "mobileNoNTB";
 export const MOBILE_NUM = "mobileNo";
 export const panVotToken = "boyJu5wTytBpOzYt2jV0dt6MnWOYpf8d"
 export const AADHAAR_NUM_NTB = "aadhaarNTB";
export const MESSAGE_SUCCESS = "Success";
export const MESSAGE_PENDING = "pending";
export const MESSAGE_SUCCESS_MOBILE = "Mobile Number Verified Successfully!"
export const MESSAGE_SUCCESS_OTP = "OTP generated successfully"
export const MESSAGE_SUCCESS_AADHAR = "Aadhaar Verified Successfully!"
export const MESSAGE_SUCCESS_LANDRECORDS = "Land Record Verified Successfully!"
export const MESSAGE_SUCCESS_LOANAMOUNT = "Loan Amount Calculated Successfully!"
export const MESSAGE_SUCCESS_LOANAMOUNT_SANC = "Loan Amount Sanctioned Successfully!"
export const MESSAGE_SUCCESS_VERIFIED = "OTP has been verified"
 export const INVALID_OTP = "Invalid OTP";
 export const GENERATE_OTP = "Generate OTP";
 export const OTP_EXPIRED = "OTP Expired";
 export const MOBILE_NUM_INVALID = "Invalid mobile number";
export const MILE_STONE = {
    LOAN_REF: "loanRefNoGenerated",
    SATSURE_REPORT: "satsureReportGenerated",
    SATSURE_FAILED:"satsureReportFailed",
    CROP_PATTERN:"cropPatternSubmitted",
    LOAN_AMOUNT:"loanAmountCalculated",
    DOCS_EXEC:"docsExecuted",
    DOCS_EXEC_REQ: "docsExecutedRequest",
    LOAN_DISBURSED:"loanDisbursed"
};
export const PLEASE_USE_RESUME = "A record for the entered  Customer ID/ Account Number/ Aadhaar Number already exists. Please use the resume application button to complete your loan application process."
 export const MPLRS_DOWNTIME_TEXT = "एमपीएलआरएस द्वारा सेवाएँ उपलब्ध न होने के कारण मध्य प्रदेश राज्य के लिए डिजिटल केसीसी सेवाएँ दिनांक 09.04.2023 तक उपलब्ध नहीं रहेंगी। अतः केसीसी ऋण स्वीकृति हेतु कृपया अपनी शाखा से संपर्क करें। असुविधा के लिए खेद है।    Due to non-availability of services from MPLRS, the Digital KCC services will not be available for Madhya Pradesh State up to 09/04/2023. Kindly contact your Branch for your KCC loan sanction. Inconvenience caused is regretted. ";
 export const CREDIT_FETCH_FAIL = "Failed to fetch credit report";
 export const CREDIT_FETCH_FAIL_HINDI = "क्रेडिट रिपोर्ट लाने में विफल";
export const TECH_ERROR ="Technical Error";
export const MESSAGE_FAILURE = "Failure";
export const MESSAGE_INVALID_REQUEST = "Invalid Request";
export const MESSAGE_NO_RES_UIADI = "No Response From UIDAI";
export const MESSAGE_OTP_NOT_GEN = "Could not generate and send OTP";
export const MESSAGE_EXISTING_CUST = "Failure as existing customer is from ETB";
export const MESSAGE_EXIST_KCC_LOAN = "ERR005 - Customer id has outstanding KCC Loan.";
export const MESSAGE_NPA_ACCOUNT = "ERR004 - Customer id has NPA Account.";
export const MESSAGE_DORMAT_ACCOUNT = "ERR054 - Customer having dormant saving accounts.";
 export const MESSAGE_INVALID_AADHAR = "Invalid aadhaar number/Virtual Id";
 export const MESSAGE_ALREADY_EXISTS = "Already exists";
 export const MESSAGE_INVALID_MOB_NO  ="Invalid mobileNo";
 export const MESSAGE_AADHAR_DOE_NOT_MATCH = "The entered Aadhaar Number and the Aadhaar number as per the Bank Records Do Not Match. Kindly Contact the Branch for the further process.";
export const MESAGE_MOBILE_NUM_NOT_FOUND = "Kindly contact your Branch and update your records to proceed further";
 export const AGRI_NESL = "AGRL";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const MESSAGE_MORE_INFO = "Click here for more info";
export const MESSAGE_HIDE_INFO = 'Click here to hide info';
export const UNAUTHORIZED_ACCESS = "Unauthorized Access";
 export const MESSAGE_INTERNAL_SERVER_ERRRO = "Internal Server Error";
export const MESSAGE_FINACLE_ERROR = "Finacle System Error Occoured!!! Please contact System Administrator.";
export const MINOR_ILLITERATE = "ERR006 - Customer with Customer Status - Minor, illiterate, Lunatic, Blind and Deceased are not eligible for Digital KCC STP journey."
 export const FRUITS_API_STATUS_TEXT = {
     FARMER_ID_NOT_FOUND: "Farmer Id Not exists",
     AADHAR_NOT_VALIDATED: "Aadhaar Not Validated"
 };
 export const STATE_LIST = {
    MP: 'Madhya Pradesh',
    KA: 'Karnataka',
    AP:'Andhra Pradesh',
    TEL:'Telangana',
    TN: 'Tamil Nadu'
 }
 export const STATE_CODES = {
  MP:"MP",
  KA:"KA",
  AP:"AP",
  TEL:"TG",
  TN:"TN"
 }
 export const BRE_STATUS_DUPLICATE_ID = "Failed due to Duplicate Khasra No Entry"
 export const DUPLI_KHASRA_FAIL = "Failed due to Duplicate Khasra No Entry"

 export const MISSING_MESSAGE = {
     mobile:"Mobile number",
     mobile_hindi:"",
     name:"Applicant Name",
     name_hindi:"",
     sanctionedAmt:"Sanctioned Loan Amount",
     sanctionedAmt_hindi:"",
     referenceNo:"Loan Reference Number",
     referenceNo_hindi:""
 }

 export const INVALID_SNCTNNO = "Invalid snctnno"
 export const CHECK_STATUS = "Check Account Status"
 export const CHECK_STATUS_KD = "ಖಾತೆಯ ಸ್ಥಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಿ"
 export const CHECK_STATUS_HINDI = "खाता स्थिति जांचें"
 export const FIVE_MINUTES_IN_MS = 300000
 export const ONE_MINUTE_IN_MS = 60000

export const POPUP_IMG_TYPE = "popUpmageType"
export const POPUP_IMG_TYPE_PROFILE = "profileVerified"
export const POPUP_IMG_TYPE_AADHAR = "aadharVerified"
export const POPUP_IMG_TYPE_NESL = "neslVerified"
export const POPUP_IMG_TYPE_ACC_OPENING = "submitForLoanAccount"

export const DOC_TYPES_PDF =  [
  "loanApplicationForm",
  "hypothecation",
  "declaration",
  "interestAgreement",
  "negative",
  "demand",
  "sanctionLetter",
  "undertaking",
  "continuity",
  "digitalProcessNote"
]

export const DOC_TYPES_RENEWAL_PDF = [
  "annexureTwo",
  "annexureThree",
]

export const MILE_STONE_RESUME_MESSAGE = {

  MOBILE_VERIFICATION_SUCCESS:"Mobile Verification Successful",

  AADHAAR_VERIFICATION_SUCCESS:"Aadhaar Verification Successful",

  PAN_VERIFICATION_SUCCESS:"Pan Verification Successful",

  VOTER_VERIFICATION_SUCCESS:"Voter Verification Successful",

  CIC_CHECK_SUCCESS:"Credit Report Check Successful",

  LAND_DETAILS_SUBMITTED:"Land Verification Successful",

  CROP_DETAILS_SUBMITTED:"Cropping Pattern Submitted",

  LOAN_AMOUNT_CALCULATED:"Estimated Loan Amount Calculated",

  LOAN_SANCTION_SUCCESS:"Loan Sanction Successful",

}

export const LANGUAGES = {
  TELUGU:"telugu",
  KANNADA:"kannada",
  ENGLISH:"english",
  HINDI:"hindi",
  TAMIL:"tamil"
}
