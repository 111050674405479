<div [ngStyle]="outerDiv">
  <h1 *ngIf="data.heading">{{data.heading}}</h1>
  <img [src]="iconString" alt="Icon" class="icon" *ngIf="iconString">
  <p *ngIf="data.content1">{{data.content1}}</p>
  <p *ngIf="data.content2">{{data.content2}}</p>

<p *ngIf="data.errorText" style="text-decoration: underline;color:blue;font-size: 1rem; text-align: center; cursor: pointer;" (click)="toggleError()">{{more}}</p>

  <p *ngIf="showError" style="color:red;font-size: 1rem;text-align: center;">{{data.errorText}}</p>
  <!-- <p *ngIf="showError" style="color:red;font-size: 1rem;text-align: center;">Internal Server Error</p> -->
  <br>
  <div class="flex-gap" style="padding-bottom: 20px;">
    <button *ngIf="data.button1Text" (click)="onOne()" class="yesOrNoButton">{{data.button1Text}}</button>
    <button *ngIf="data.button2Text" (click)="onTwo()" class="yesOrNoButton">{{data.button2Text}}</button>
  </div>
  <div class="ring" *ngIf="data.showBuffering">Loading
    <span></span>
  </div>
  <mat-progress-bar *ngIf="data.showWaiting" mode="buffer">Please Wait</mat-progress-bar>

</div>
