<wl-card heading="ల్యాండ్ రికార్డ్ - Land Record" [subheading1]="showLandVerifiedTick" [@fadeInOut]>
  <form novalidate [formGroup]="landRecordForm" class="formWidth" #formDirective="ngForm" [formGroup]="landRecordForm">

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <p style="text-align: center;  padding-top: 0px;margin-top: 0px;">Enter your PPB Number </p>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field style="width: 100%;" appearance="outline">
          <input matInput type="text" formControlName="ppbNumber" [readonly]="ppbNumberReadOnly">
          <!-- <mat-error *ngIf="detailsForm.get('mobileNo').invalid">
            Please enter a valid phone number.
          </mat-error> -->
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <p style="text-align: center;  padding-top: 0px;margin-top: 0px;">Select date range </p>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field appearance="outline">
          <!-- <mat-label>Enter a date range</mat-label> -->
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="landRecordForm.controls['start'].hasError('matStartDateInvalid')">Invalid start
            date</mat-error>
          <mat-error *ngIf="landRecordForm.controls['end'].hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>

      </div>
    </div>


    <div style="text-align: center;">
      <button class="mybutton"
        [disabled]="!landRecordForm.valid || disableVerify || landDetailsArray.length >=5 || ppbNumberReadOnly" (click)="landRecordFetch(formDirective)">భూమి
        రికార్డులను పొందండి
        (Fetch Land records)</button>
    </div>

    <br>
    <br>


    <div class="row" *ngIf="showLands">

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <p style="text-align: center;  padding-top: 0px;margin-top: 0px;">Select lands </p>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <div *ngFor="let option of checkboxOptions">
          <label class="checkbox-label">
            <input type="checkbox" [value]="option" [checked]="selectedCheckboxes.includes(option)"
              (change)="toggleCheckbox(option)">
            <span class="checkbox-custom"></span>
            {{ option.districtName +"-"+
            option.subdistrictName +"-"+
            option.clusterName +"-"+
            option.villageName+ "-"+
            option.landIdentityInfo.baseSurveyNumber +"-"+
            option.landIdentityInfo.surveyNumber }}
          </label>
        </div>

      </div>

    </div>

    <div *ngIf="showLands" style="text-align: center;">

      <button class="mybutton" [disabled]="disableSubmitLands" (click)="submitLands()">భూమి రికార్డులను సమర్పించండి
        (Submit Land records)</button>
    </div>

    <br>
    <div style="width: 100%" *ngIf="landDetailsArray.length > 0">
      <div style="
        width: 100%;
        padding-top: 3rem;
        display: grid;
        justify-content: center;
      ">
        <table style="border: 1px solid black; width: 100%">
          <tr>
            <th style="padding:3px;border: 1px solid black; text-align: center">
              District
            </th>
            <th style="padding:3px;border: 1px solid black; text-align: center">
              Sub District
            </th>
            <th style="padding:3px;border: 1px solid black; text-align: center">
              Village
            </th>
            <th style="padding:3px;border: 1px solid black; text-align: center">
              Survey No.
            </th>
            <th style="padding:3px;border: 1px solid black; text-align: center">
              Land area (in Acres)
            </th>
          </tr>
          <tr *ngFor="let totalLandRecords of landDetailsArray">
            <td style="padding:3px;border: 1px solid black; text-align: center">
              {{ totalLandRecords.districtName }}
            </td>
            <td style="padding:3px;border: 1px solid black; text-align: center">
              {{ totalLandRecords.subdistrictName }}
            </td>
            <td style="padding:3px;border: 1px solid black; text-align: center">
              {{ totalLandRecords.villageName }}
            </td>
            <td style="padding:3px;border: 1px solid black; text-align: center">
              {{ totalLandRecords.surveyNo }}
            </td>
            <td style="padding:3px;border: 1px solid black; text-align: center">
              {{ totalLandRecords.landExtent }}
            </td>
          </tr>
        </table>
      </div>
    </div>


    <div class="loading-overlay" *ngIf="loading">
      <div class="spinner"></div>
    </div>


  </form>
</wl-card>
