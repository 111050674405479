import { ChangeDetectorRef, Component } from '@angular/core';
import { CreditService } from '../credit-service/credit.service';
import { OpenDialogYesOrNo } from '../dialog-yes-or-no/dialog-yes-or-no.component';
import { MatDialog } from '@angular/material/dialog';
import { ApplicantService } from 'src/app/applicant.service';
import { JourneyType, Milestone } from "../enum";
import { MatSnackBar } from '@angular/material/snack-bar';
import * as constants from "../../constant";
import { environment as ENV } from 'src/environments/environment';

import { firstValueFrom, of } from 'rxjs';

@Component({
  selector: 'app-assisted-bifurcation',
  templateUrl: './assisted-bifurcation.component.html',
  styleUrls: ['./assisted-bifurcation.component.scss']
})
export class AssistedBifurcationComponent {
  mobileNo: any;
  isPanOrVoterVerified: boolean;
  panVotVerificationStatus: string;
  loading: boolean;
  creditStatus: any;
  showSpinner: boolean;
  showSpinnerWithMessage: boolean;
  subText: string;
  landDetails: any;

  showPanVotCard: boolean;
  showAndhraCard: boolean;
  showKACard:boolean;
  showCropCard: boolean;
  showLoanAmtCard: boolean;
  showLoanSuccessfulCard: boolean;
  showLandVerifiedTick:boolean;

  loanAmountInfo: any;
  finalLoanAmounts: any;
  creditScore: any;
  showCreditScoreCard: boolean;

  resumeData: any;
  resumeFullInfo: any;
  currentMileStone: any;
  resumeCase: boolean;
  panOrVoterInfo: { type: string; number: any; success: boolean; };
  landRecordsList: any;
  cropList: any;
  stepper: number;
  aadhaarInfo: { success: boolean; aadhaarNo: string; };
  resumeLinkGenerationInfo: any;
  state: string;
  isResume: boolean;
  showTelenganaCard: boolean;
  showCreditWhitetick: boolean = false;
  cropSelectionTick:boolean = false;


  constructor(private creditService: CreditService, private dialog: MatDialog, private applicant:ApplicantService, private changeDetection:ChangeDetectorRef,
    private snackbar:MatSnackBar) {

  }

  ngOnInit(){
    this.resumeLinkGenerationInfo = sessionStorage.getItem('avdaKedvra')?JSON.parse(atob(sessionStorage.getItem('avdaKedvra'))):"";
    sessionStorage.setItem('jrnyType',JourneyType.NTB);



  }

  kycDone(value) {

    this.mobileNo = value.mobileNo;
    // debugger
    if(value && value.isProgress){
      //do resume
      this.doResume()
    }else{
      this.showPanVotCard = true;
    }
  }

  async doResume() {
    let shouldResumeProceed = await this.getResumeData();
    this.isResume = true;
    this.loading = false;
    this.changeDetection.detectChanges()
    this.setLandRecord()
    this.setState()

    // sessionStorage.setItem("lang",this.resumeFullInfo.stateCode == "AP"?"telugu":"")

    this.currentMileStone = this.resumeFullInfo.milestone;

    this.creditScore = this.resumeFullInfo.creditScore?this.resumeFullInfo.creditScore:"";

    if (shouldResumeProceed) {
      this.resumeCase = true;
      // Define the order of milestones
      const milestonesInOrder = [
        Milestone.MOBILE_VERIFICATION_SUCCESS,
        Milestone.AADHAAR_VERIFICATION_SUCCESS,
        Milestone.PAN_VERIFICATION_SUCCESS,
        Milestone.VOTER_VERIFICATION_SUCCESS,
        Milestone.CIC_CHECK_SUCCESS,
        Milestone.LAND_DETAILS_SUBMITTED,
        Milestone.CROP_DETAILS_SUBMITTED,
        Milestone.LOAN_AMOUNT_CALCULATED,
        Milestone.LOAN_SANCTION_SUCCESS,
      ];

      // Find the index of the given milestone
      const currentIndex = milestonesInOrder.indexOf(this.resumeFullInfo.milestone);

      // Iterate through the milestones and call the corresponding functions
      for (let i = 0; i <= currentIndex; i++) {
        const currentMilestone = milestonesInOrder[i];
        const milestoneFunction = this.getMilestoneFunction(currentMilestone);

        // Call the associated function if it exists
        if (milestoneFunction) {
          milestoneFunction();
        }
      }
    } else {
      const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
        width: "auto",
        height: "auto",
        data: {
          content2: "Failed to fetch information to proceed the journey, Please try again later.",
          button1Text: "OK",
        },
      });
      dialogRef.afterClosed().subscribe((resp) => {

      })
    }

  }

  getResumeData() {
    return new Promise(async (resolve, reject) => {
      this.loading = true;
      this.applicant.getResumeData(sessionStorage.getItem('applicantId')).subscribe(async (resp) => {
        console.log(resp)
        // debugger
        this.loading = false;
        if (resp && resp.success) {
          if (resp.data) {
            this.resumeFullInfo = resp.data;
            this.state = resp.data.state;
            await this.setState()
            resolve(true)
          } else {
            resolve(false)
          }
        } else {
          resolve(false)
        }
      },(err)=>{
        this.loading = false;
        resolve(false);
      })
    })
  }

  setLandRecord(){

    if(this.resumeFullInfo.landRecords && this.resumeFullInfo.landRecords.length){
      let newArray;

      if(this.state == constants.STATE_LIST.KA){
        let landArray = this.resumeFullInfo.landRecords;
        debugger
        newArray = landArray.map((item)=>{
          let land = {};
          land['district'] = item.districtName;
          land['talukName'] = item.talukName;
          land['hobliName'] = item.hobliName;
          land['villageName'] = item.villageName;
          land['surveyNo'] = item.surveyNo;
          land['surveyNoArea'] = item.landArea;
          land['hissaNo'] = item.hissaNo;
          land['period'] = item.period;

          return land;
        })
      }else if(this.state == constants.STATE_LIST.AP){
        let landArray = this.resumeFullInfo.landRecords;

        newArray = landArray.map((item)=>{
          let land = {};
          land['district'] = item.dist_name;
          land['mandal'] = item.mand_name;
          land['village'] = item.village_name;
          land['surveyNo'] = item.surveyno;
          land['surveyNoArea'] = item.surveynoarea;
          return land;
        })
      }else if(this.state == constants.STATE_LIST.TEL){
        let landArray = this.resumeFullInfo.landRecords;

        newArray = landArray.map((item)=>{
          let land = {};
          land['districtName'] = item.districtName;
          land['subdistrictName'] = item.subdistrictName;
          land['villageName'] = item.villageName;
          land['surveyNo'] = item.landIdentityInfo.surveyNumber;
          land['cropArea'] = item.cropArea;
          return land;
        })
      }


      sessionStorage.setItem('landArray',JSON.stringify(newArray))
    }else{
      sessionStorage.setItem('landArray',JSON.stringify([]))
    }

  }

  async setState(){
    if(this.resumeFullInfo && this.resumeFullInfo.state){
      let state = this.resumeFullInfo.state;
      sessionStorage.setItem('state',this.resumeFullInfo.state)
      if(state.toLowerCase() == constants.STATE_LIST.AP.toLowerCase()){
        this.state = constants.STATE_LIST.AP;
        sessionStorage.setItem("lang",constants.LANGUAGES.TELUGU);
        sessionStorage.setItem("state",constants.STATE_LIST.AP)
      }else if(state.toLowerCase() == constants.STATE_LIST.KA.toLowerCase()){
        this.state = constants.STATE_LIST.KA;
        sessionStorage.setItem("lang",constants.LANGUAGES.KANNADA);
        sessionStorage.setItem("state",constants.STATE_LIST.KA)
      }else if(state.toLowerCase() == constants.STATE_LIST.TEL.toLowerCase()){
        this.state = constants.STATE_LIST.TEL;
        sessionStorage.setItem("lang",constants.LANGUAGES.TELUGU);
        sessionStorage.setItem("state",constants.STATE_LIST.TEL)
      }
      await this.getHeaders();
    }
  }

  async getHeaders() {
    try {
      const resp = await firstValueFrom(this.applicant.getHeaders());

    } catch (error) {
      console.error("Error fetching headers:", error);
    }
  }


  getMilestoneFunction(milestone) {
    // Mapping between milestones and corresponding functions

    const milestoneFunctions = {
    //   [Milestone.MOBILE_VERIFICATION_SUCCESS]: this.resumeMobileVerified.bind(this), // do nothing as there is nothing to resume
      [Milestone.AADHAAR_VERIFICATION_SUCCESS]: this.resumeAadhaarVerified.bind(this), // show * marks in aadhaar field - fill dummy
      [Milestone.PAN_VERIFICATION_SUCCESS]: this.resumePanOrVoterVerified.bind(this), // select drop down, fill pan or voter number, do credit check
      [Milestone.VOTER_VERIFICATION_SUCCESS]: this.resumePanOrVoterVerified.bind(this), // select drop down, fill pan or voter number, do credit check
      [Milestone.CIC_CHECK_SUCCESS]: this.resumeCreditCheckDone.bind(this), // show credit report download button
      [Milestone.LAND_DETAILS_SUBMITTED]: this.resumeLandRecordsVerified.bind(this), // fill land data table
      // [Milestone.CROP_DETAILS_SUBMITTED]: this.stepperCropSubmitted.bind(this), // fill crops, call loan amount calculation, and fill loan amount card
      [Milestone.LOAN_AMOUNT_CALCULATED]: this.resumeLoanAmountCalculated.bind(this), // fill loan amount card
      [Milestone.LOAN_SANCTION_SUCCESS]: this.stepperSanctioned.bind(this), // show popup that the journey is finished, maybe show documents download
    };

    return milestoneFunctions[milestone];
  }

  resumeAadhaarVerified() {
    this.aadhaarInfo = { success: true, aadhaarNo: "xxxxxxxxxxxx" };
    this.stepperAadhaarVerified();
  }

  resumePanOrVoterVerified() {

    if(this.resumeFullInfo && this.resumeFullInfo.panNumber){
      this.panOrVoterInfo = {
        type: "pan",
        number: this.resumeFullInfo.panNumber,
        success: true,
        // creditScore:420
      }
    }else if(this.resumeFullInfo && this.resumeFullInfo.voterNumber){
      this.panOrVoterInfo = {
        type: "voterid",
        number: this.resumeFullInfo.voterNumber,
        success: true,
        // creditScore:420
      }
    }

    this.stepperPanOrVotVerified();
    if(this.currentMileStone == Milestone.PAN_VERIFICATION_SUCCESS){
      this.creditCheck();
    }
  }

  resumeCreditCheckDone(){
    if(this.resumeFullInfo.landRecords && this.resumeFullInfo.landRecords.length){
      if(this.resumeFullInfo.creditScore){
        this.showCreditScoreCard = true;
        this.creditScore = this.resumeFullInfo.creditScore
        this.showCreditWhitetick = true;
        this.stepperCreditReportVerified()
      }else if(!ENV.production){
        this.stepperCreditReportVerified()
      }else{
        this.resumePanOrVoterVerified();
      }

      // this.resumeLandRecordsVerified() // to view the land records and submit if there is any which is submitted
      this.resumeLandRecordsUpdatedButNotSubmitted()
    }else{
      if(this.resumeFullInfo.creditScore){
        this.showCreditScoreCard = true;
        this.creditScore = this.resumeFullInfo.creditScore
        this.showCreditWhitetick = true;
        this.stepperCreditReportVerified()
      }else if(!ENV.production){
        this.stepperCreditReportVerified()
      }else{
        this.resumePanOrVoterVerified();
      }
    }
  }

  resumeLandRecordsUpdatedButNotSubmitted(){
    if(this.state == constants.STATE_LIST.AP){
      this.landRecordsList = this.resumeFullInfo.landRecords?this.resumeFullInfo.landRecords:[];
      let dist = {name:this.landRecordsList[0]['userSelections']['dist_name']}
      sessionStorage.setItem('selectedDistrict',JSON.stringify(dist))
      // this.stepperLandVerified()
    }else if(this.state == constants.STATE_LIST.KA){
      this.landRecordsList = this.resumeFullInfo.landRecords?this.resumeFullInfo.landRecords:[];
      let dist = {name:this.landRecordsList[0]['districtName']}
      sessionStorage.setItem('selectedDistrict',JSON.stringify(dist))
      // this.stepperLandVerified()
    }
  }

  resumeLandRecordsVerified(){
    if(this.state == constants.STATE_LIST.AP){
      this.landRecordsList = this.resumeFullInfo.landRecords?this.resumeFullInfo.landRecords:[];
      let dist = {name:this.landRecordsList[0]['userSelections']['dist_name']}
      sessionStorage.setItem('selectedDistrict',JSON.stringify(dist))
      this.stepperLandVerified()
    }else if(this.state == constants.STATE_LIST.KA){
      this.landRecordsList = this.resumeFullInfo.landRecords?this.resumeFullInfo.landRecords:[];
      let dist = {name:this.landRecordsList[0]['districtName']}
      sessionStorage.setItem('selectedDistrict',JSON.stringify(dist))
      this.stepperLandVerified()
    }else if(this.state == constants.STATE_LIST.TEL){
      this.landRecordsList = this.resumeFullInfo.landRecords?this.resumeFullInfo.landRecords:[];
      let dist = {name:this.landRecordsList[0]['districtName']}
      sessionStorage.setItem('selectedDistrict',JSON.stringify(dist))
      this.stepperLandVerified()
    }
  }

  resumeLoanAmountCalculated(){
    this.stepperCropSubmitted();
    // this.cropList['isResume'] = true;
    this.cropList = this.resumeFullInfo.crops?this.resumeFullInfo.crops:{};
    let loanInfo = this.resumeFullInfo.loanAmounts
    this.loanAmountInfo = { estimatedAmount: loanInfo.proposedLoanAmount, loanAmount: loanInfo.requestedLoanAmount,success: true, isResume: true, milestone:this.currentMileStone }
  }

  resumeSanctioned(){
    let loanInfo = this.resumeFullInfo.loanAmounts
    this.finalLoanAmounts = {estimatedAmount: loanInfo.proposedLoanAmount, loanAmtFarmer:loanInfo.fifthYearLoanAmount}
   this.stepperSanctioned();

  }

  panVotSuccess(value) {
    console.log(value)
    this.isPanOrVoterVerified = true;
    // this.stepperPanOrVotVerified();
    this.panVotVerificationStatus = JSON.stringify(value);
    this.creditCheck();
  }
  creditCheck() {
    let data = {}
    this.loading = true;
    this.creditService.getCreditData(data).subscribe((resp) => {
      this.creditStatus = resp;
      if(resp && resp.success && resp.data  && resp.data && resp.data.SCORE ){
        debugger
        this.creditScore = resp.data.SCORE;
        this.showCreditScoreCard = true;
        this.showCreditWhitetick = true;

      }
      this.loading = false;
      if (this.creditStatus && this.creditStatus.success) {
        this.stepperCreditReportVerified();
      } else {
        const dialogRef = this.dialog.open(OpenDialogYesOrNo, {
          width: "auto",
          height: "auto",
          data: {
            content2: "Failed at credit check",
            button1Text: "OK",
          },
        });
      }
    }, (err) => {
      // this.creditStatus = false;
      this.showSpinner = false;
    })
  }

  landRecordsDone(value) {
    console.log(value);
    this.stepperLandVerified();
    this.landDetails = value
    this.showCropCard = true;
  }

  loanAmountCalculationDone(value, isResume) {
    console.log(value);
    this.stepperCropSubmitted();
    value.isResume = isResume;
    this.loanAmountInfo = value;
  }

  loanAmountSubmitted(value) {
    console.log(value);
    this.stepperSanctioned()
    this.finalLoanAmounts = value;
  }

  stepperAadhaarVerified() {
    this.stepper = 3;
    this.sendSpinnerData();
    //let message = "Aadhaar Verified Successfully!"
    let message = constants.MESSAGE_SUCCESS_AADHAR
    // this.showDonePopup(message)
    if(!this.resumeCase){
      this.showDonePopup(message)
    }
    this.showPanVotCard = true;
  }

  stepperPanOrVotVerified() {
    this.stepper = 4;
    // let message = "ID Verified Successfully!"
    // this.showDonePopup(message)
    this.  sendSpinnerData();

  }

  stepperCreditReportVerified() {
    this.stepper = 5;
    this.sendSpinnerData();
    if(this.state == constants.STATE_LIST.AP){
      this.showAndhraCard = true;
    }else if(this.state == constants.STATE_LIST.KA){
      this.showKACard = true;
    }else if(this.state == constants.STATE_LIST.TEL){
      this.showTelenganaCard = true;
    }
  }

  stepperLandVerified() {
    this.stepper = 6;
    this.sendSpinnerData();
    //let message = "Land Record Verfified Successfully!"
     let message = constants.MESSAGE_SUCCESS_LANDRECORDS
     this.showLandVerifiedTick = true;
    if(!this.resumeCase){
      this.showDonePopup(message)
    }
    this.showCropCard = true;
  }

  stepperCropSubmitted() {
    console.log(this)
    this.stepper = 7;
    this.sendSpinnerData();
    //let message = "Loan Amount Calculated Successfully!"
     let message = constants.MESSAGE_SUCCESS_LOANAMOUNT
     this.cropSelectionTick = true;
    if(!this.resumeCase){
      this.showDonePopup(message)
    }
    this.showLoanAmtCard = true;
  }

  stepperSanctioned() {
    this.stepper = 8;
    this.sendSpinnerData();
   // let message = "Loan Amount Sanctioned Successfully!"
     let message = constants.MESSAGE_SUCCESS_LOANAMOUNT_SANC
    if(!this.resumeCase){
      this.showDonePopup(message)
    }
    this.showLoanSuccessfulCard = true;
  }

  sendSpinnerData() {
    let data = {
      showSpinner: this.showSpinner,
      spinnerWithMessage: this.showSpinnerWithMessage,
      subText: this.subText,
      stepper: this.stepper,
    };
    this.applicant.passValue(data);
  }

  showDonePopup(message) {
    const dialogRef = this.showDialog(
      "auto",
      "auto",
      "",
      message,
      "OK",
      this.applicant.moreInfo(message)
    );
  }

  showDialog(
    width: any,
    height: any,
    content1: string,
    content2: string,
    buttonText: string,
    errorText: string,
  ) {
    let dialogRef = this.dialog.open(OpenDialogYesOrNo, {
      width: width,
      height: height,
      data: {
        content1: content1,
        content2: content2,
        button1Text: buttonText,
        errorText: errorText
      },
    });

    return dialogRef;
  }

  openCreditReport() {
    this.loading = true;
    this.applicant.getLoanDocument({type:"creditReport"}).subscribe((resp: Blob)=>{
      const file = new Blob([resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      this.loading = false;
      window.open(fileURL); // Open the PDF in a new tab/window
    },(err)=>{
      this.loading = false;
      this.snackbar.open("Unable to open the document","OK",{duration:3000})
    })
  }


}

