<mat-card style="box-shadow: none; background: rgba(0,0,0,0);box-shadow: none;vertical-align: middle;padding-left: .5rem;padding-right:.5rem">
  <br><br>
  <!-- <p  style="font-size: 2em;line-height:0px;font-weight: bold;margin-bottom: 5px;padding-bottom: 2rem;">क्रेडिट
    कार्ड </p> -->
  <!-- <p  style="font-size: 2em;line-height:0px;font-weight: bold;color: white;z-index: 2;">Bharat Mandi</p> -->
  <div style="
  display: flex;
  justify-content: center;
  align-items: center;margin-top:8vh;">
    <div class="accDetailsBox-new">
    <p style="margin-left: 15px;margin-bottom: 30px;">{{LOCAL_LANGUAGE.We_have_compiled_all_the_necessary_information_to_help_you_get_started}}
      <br>We have compiled all the necessary information to help you get started.</p>
      <ul class="custom-list">
        <li class="bullet-item1">{{LOCAL_LANGUAGE.Bank_visits_and_No_physical_document_submission_required}}<br>
          Get a loan of upto ₹1,60,000. No Bank visits and No physical document submission required.</li>
        <li class="bullet-item2">{{LOCAL_LANGUAGE.No_processing_fee_required}}<br>No processing fee required.</li>
        <li class="bullet-item3">{{LOCAL_LANGUAGE.Disbursement_at_comfort_of_your_home}}<br> Disbursement at comfort of your home.</li>
      </ul>
    </div>
  </div>

  <div class="verticalAlign">
    <button class="freshButton" (click)="fresh()">{{ LOCAL_LANGUAGE.Apply_Now }}<br>Apply Now</button>
    <!-- <button class="freshButton" (click)="resume()">आवेदन पुनः प्रारम्भ करें<br> Resume Application</button> -->
  </div>
</mat-card>
