import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-label',
  templateUrl: './label.component.html',
  styles: [`
      .small-screen {
        text-transform: uppercase;
      }
  `]
})
export class LabelComponent {

  @Input() label: string;
  @Input() value: any;

  constructor() { }

}
