<div class="modal-background" *ngIf="showModal">
  <div class="modal-content-kfs" (scroll)="handleScroll($event)">
    <a href="#here" id="toClick" style="display: hidden;"></a>
    <div style="  width: 90%;
  padding: 10px 0px 0px 0px;
  border-radius:10px;
  margin:auto;">
      <p style="text-align: center; font-size: 20px;font-weight: bold;">Key Fact Statement Sheet</p>
    </div>
    <div style="text-align: center;  padding: 0px 0px 20px 0px;">
      <div class="KFSline"></div>
    </div>

    <div class="KFtable">
      <table class="custom-table" style="margin-top: -7px;">
        <thead>
          <tr>
            <th style="text-align: center;"><span class="heading">Particulars</span></th>
            <th colspan="4" style="text-align: center;"><span class="heading">Details</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="right-border">{{LOCAL_LANGUAGE.Type_of_Loan}} Type of Loan</td>
            <td colspan="4" class="bluetextKFS">{{LOCAL_LANGUAGE.KISAN_CREDIT_CARD}} <br>
              KISAN CREDIT CARD </td>
          </tr>
          <tr class="active-row">
            <td class="right-border">{{LOCAL_LANGUAGE.Purpose}} Purpose
            </td>
            <td colspan="4" class="bluetextKFS">{{LOCAL_LANGUAGE.Short_Term_Crop_Production}} Short Term Crop Production
            </td>
          </tr>
          <tr>
            <td class="right-border">{{LOCAL_LANGUAGE.Maximum_Loan_Amount}} Maximum Loan Amount
            </td>
            <td colspan="4" class="bluetextKFS">Rs.1.60 lakh
            </td>
          </tr>
          <tr class="active-row">
            <td class="right-border">{{LOCAL_LANGUAGE.Margin}} Margin
            </td>
            <td colspan="4" class="bluetextKFS">{{LOCAL_LANGUAGE.NIL}} NIL
            </td>
          </tr>
          <tr>
            <td class="right-border">{{LOCAL_LANGUAGE.Repayment}} Repayment</td>
            <td colspan="4" class="bluetextKFS">{{LOCAL_LANGUAGE.Repayment_is_as_per_anticipated_harvesting_and_marketing_period_for_the_crops_for_which_loan_is_granted}} <br>
              Repayment is as per anticipated harvesting and marketing period for the crops for which loan is granted.
            </td>
          </tr>
          <tr class="active-row">
            <td class="right-border">{{LOCAL_LANGUAGE.Moratorium}} Moratorium
            </td>
            <td colspan="4" class="bluetextKFS">{{LOCAL_LANGUAGE.Moratorium_NIL}} NIL
            </td>
          </tr>
          <tr>
            <td class="right-border"> {{LOCAL_LANGUAGE.Security}} Security</td>
            <td colspan="4" class="bluetextKFS">{{LOCAL_LANGUAGE.Hypothecation}} <br>
              Hypothecation of Proposed/ Standing Crops
            </td>
          </tr>
          <tr class="active-row">
            <td class="right-border" rowspan="2">{{LOCAL_LANGUAGE.Rate_of_Interest}} Rate of Interest </td>
            <td class="right-border rightBordertd bluetextKFS">{{LOCAL_LANGUAGE.from}} Rs.50,000/- {{LOCAL_LANGUAGE.to}} <br> Loans upto Rs.50,000/-
            </td>
            <td class="right-border rightBordertd" style="font-weight:normal ;">
              <span class="bluetextKFS">{{LOCAL_LANGUAGE.MCLR}} MCLR+ 0.10% = 8.80% p.a. ({{LOCAL_LANGUAGE.at_present}}/ at present)</span>
            </td>
            <td rowspan="2"><span class="bluetextKFS">Interest subvention/
                prompt
                repayment
                available as
                per norms</span></td>
          </tr>
          <tr>
            <td class="right-border rightBordertd bluetextKFS" style="font-weight:normal ;">
              <span class="bluetextKFS">{{LOCAL_LANGUAGE.from}} Rs.50,000/- {{LOCAL_LANGUAGE.from_more_than}} Rs.1.60/-  {{LOCAL_LANGUAGE.till_profit}} <br> Loans above Rs. 50,000/- upto
                Rs. 1.6 Lakhs</span>
            </td>
            <td class="right-border rightBordertd bluetextKFS">
              {{LOCAL_LANGUAGE.MCLR}} MCLR+ 0.65% = 9.35% p.a. ({{LOCAL_LANGUAGE.at_present}} / at present)
            </td>
          </tr>
          <tr>
            <td class="right-border"> {{LOCAL_LANGUAGE.Processing_Charges}} Processing Charges
            </td>
            <td colspan="4" class="bluetextKFS">{{LOCAL_LANGUAGE.NIL}} NIL</td>
          </tr>
          <tr class="active-row">
            <td class="right-border">{{LOCAL_LANGUAGE.Documentation}} Documentation
            </td>
            <td colspan="4" class="bluetextKFS">{{LOCAL_LANGUAGE.Option_available_for_execution_of_documents}} Option available for
              execution of documents <br>
              a) {{LOCAL_LANGUAGE.Manually_by_visiting_the_Branch_or}} Manually - by visiting the Branch or <br>
              b) {{LOCAL_LANGUAGE.Digitally_Aadhar_OTP_Based_to_be_done_Online}} Digitally- Aadhar OTP Based to be done Online
            </td>
          </tr>
          <tr>
            <td class="right-border"> {{LOCAL_LANGUAGE.Documentation_Charges}} Documentation Charges
            </td>
            <td colspan="4" class="bluetextKFS">{{LOCAL_LANGUAGE.NIL}} NIL</td>
          </tr>
          <tr class="active-row">
            <td class="right-border">{{LOCAL_LANGUAGE.Digital_Convenience_Fee}} Digital Convenience Fee
            </td>
            <td colspan="4" class="bluetextKFS">Rs. 500 + {{LOCAL_LANGUAGE.GST}} GST (i.e Rs.590 at present, {{LOCAL_LANGUAGE.Non_Refundable}} Non-
              Refundable)
            </td>
          </tr>
          <tr>
            <td class="right-border">{{LOCAL_LANGUAGE.Annual_Percentage_Rate}} Annual Percentage Rate</td>
            <td colspan="4" class="bluetextKFS">Rs. 50,000 {{LOCAL_LANGUAGE.on_loan_of}} / For Loan of Rs.50,000 = 9.93% p.a. ({{LOCAL_LANGUAGE.at_present}} /
              at present)​ <br>
              Rs.1,60,000 {{LOCAL_LANGUAGE.on_loan_of}} / For Loan of Rs.1,60,000 = 9.67% p.a. ({{LOCAL_LANGUAGE.at_present}} / at present)</td>
          </tr>
          <tr>
            <td class="right-border"> {{LOCAL_LANGUAGE.Other_Charges}} Other Charges
            </td>
            <td colspan="4" class="bluetextKFS">
              <ol class="b">
                <li>{{LOCAL_LANGUAGE.Crop_insurance_charge}} <br>
                  Crop insurance charges as applicable to be paid by borrower as & when debited to the account.</li>
                <li>{{LOCAL_LANGUAGE.Stamping_charges}} Stamping charges as per State Laws.<br></li>
                <li>{{LOCAL_LANGUAGE.Personal_Accident_Insurance_Scheme}}
                  <br>
                  Personal Accident Insurance Scheme( PAIS) charges to be borne by borrower if applied for scheme which
                  is
                  floated by New India Assurance ltd. At present, borrower contribution is Rs.6.00.
                </li>
                <li>
                  {{LOCAL_LANGUAGE.Penal_Interest}}
                  <br>
                  Penal Interest - 2.00 % will be levied on the overdue amount if any.
                </li>
              </ol>
            </td>
          </tr>
          <tr class="active-row">
            <td class="right-border">{{LOCAL_LANGUAGE.Disbursement}} Disbursement
            </td>
            <td colspan="4" class="bluetextKFS">{{LOCAL_LANGUAGE.Loan_Amount_will_be_disbursed_to_SB_Account}} <br>
              Loan Amount will be disbursed to SB Account of Borrower after successful Due Diligence / Inspection before
              disbursement.
            </td>
          </tr>
          <tr>
            <td class="right-border"> {{LOCAL_LANGUAGE.Cooling_off_Lookup_period}} <br>
              {{LOCAL_LANGUAGE.Cooling_off_Lookup_period_1}} Cooling-off/ Lookup period
            </td>
            <td colspan="4" class="bluetextKFS">{{LOCAL_LANGUAGE.Cooling_off_Lookup_period_available_to_farmers}} <br>
              Cooling-off/ Lookup period available to farmers for pre-payment. No additional charges / pre-payment
              penalty, only interim period
              interest to be serviced.
            </td>
          </tr>
          <tr>
            <td class="right-border">{{LOCAL_LANGUAGE.Recovery_of_Bank_Dues}} <br>
              Recovery of Bank Dues</td>
            <td colspan="4" class="bluetextKFS">
              {{LOCAL_LANGUAGE.Recovery_proceedings_of_Bank_dues}} <br>
              Recovery proceedings of Bank dues shall be undertaken by Bank’s authorized officials.
            </td>
          </tr>
          <tr>
            <td class="right-border">{{LOCAL_LANGUAGE.Name_Details_of_Banks_Grievance_Redressal_Officer}} <br>
              {{LOCAL_LANGUAGE.contact}} Name & Contact Details of Banks Grievance Redressal Officer (Digital)</td>
            <td colspan="4" class="bluetextKFS">
              <!-- Name : श्री दि शशीधर Mr. D. Sasidhar
              (Grievance Redressal Officer- FinTech / Digital Lending) <br>
              Address:Union Bank of India, Digitization Vertical, Central Office, 10th Floor, Naman Centre, Plot
              No.C-31,‘G’ Block, BKC, Bandra (E), Mumbai-400 051<br>
              Ph.No. : 022-40122400 ext. 909<br>
              Email : dgro@unionbankofindia.bank -->
            </td>
          </tr>
        </tbody>
      </table>

      <div id="tobottom" #landLayout style="height: 120px !important;"></div>
    </div>



    <div class="buttonDiv" style="text-align:center; position: fixed;">
      <br>
      <p class="scrollText" (click)="scrollOnClick()" style="cursor: pointer;">Scroll Down to Accept</p>
      <button class="mybutton" (click)="closeDialog()" [disabled]="!bottomReached">{{LOCAL_LANGUAGE.Accept}}&nbsp;/&nbsp;Accept</button>
      <br>
    </div>
  </div>
</div>
