<!-- <div class="row">
  <div class="col-8">
  </div>
  <div class="col-1" style="text-align: right;">
    <button style="background-color: none; border:none; position:fixed; margin-left: 3rem;margin-right: 1rem;margin-top: 1rem;font-size: 1.25rem;"(click)="closeDialog()">&#10006;</button>
  </div>
</div> -->

<h3 style="text-align: center; padding-top: 1rem;">Login for {{ data.type }}</h3>

<form novalidate [formGroup]="otpForm">
  <br>
  <div style="text-align: center; width: 100%;">
    <mat-form-field appearance="outline">
      <input matInput minlength="4" maxlength="9" type="text" placeholder="Enter ID" label="ID" formControlName="id"
        (keypress)="onKeyPress($event)">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input matInput type="password" placeholder="Enter Password" label="Password" formControlName="password"
        (keypress)="onKeyPressPassword($event)">
    </mat-form-field>
  </div>
  <br>
  <br>
  <div style="display: flex; justify-content: space-evenly;">
    <button class="myBigbutton" (click)="verify(otpForm.value)" [disabled]="otpForm.invalid">Submit</button>
  </div>
  <br>
</form>
