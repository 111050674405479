import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, pipe, map } from 'rxjs';
import { environment as ENV } from '../environments/environment';
import * as constants from "./constant";
// import 'url-search-params-polyfill';


@Injectable()
export class BaseApi {

  // private satSureUrl:string = ENV.satSureBaseUrl
  // private endPoints = ['adhaarauth', 'dlauthNew', 'nrega', 'panauth', 'panocr', 'passportocr', 'voterauth', 'voterOcr', 'ebillauth', 'lpgId', 'lpgAuth', 'tbillauth', 'epfUan', 'epfAuth', 'esic', 'formauth', 'formocr', 'itrAuth', 'itrOcr', 'vehicle-rc', 'fda', 'fssai', 'iec', 'shops', 'udhyog', 'icai', 'icsi', 'icwaif', 'icwaim', 'gst/auth', 'gst/identity', 'st', 'vat'];
  node_url: any;


  constructor(
    private https: HttpClient,
    // private snackbar: MdSnackBar,
  ) {
    // this.applicantId = this.getApplicantID() || localStorage.getItem('wl-applicant-id');
    // this.adminId = localStorage.getItem('wl-admin-id');
    this.node_url = ENV.nodeUrl
  }

  postData(endPoint: string, payload: any): Observable<any> {

    const url = `${this.node_url}${endPoint}`;
    return this.https.post(url, payload,).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  postDataWithTokenPanVoter(endPoint: string, payload: any): Observable<any> {

    const url = `${this.node_url}${endPoint}`;

    return this.https.post(url, payload,
      {
        headers:
          new HttpHeaders()
            .set('token', constants.panVotToken),
        withCredentials: false,
      },
      ).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  newGetIP(): Observable<any> {
    const p = new URLSearchParams();
    //const url = `https://geolocation-db.com/json/`;
    const url = `https://api-bharatmandi.whatsloan.com/v1/ipTracking/getGeoLocation`;
    // return Observable.throw('Simlulated error');
    // throw Error('Simulated Error');

    return this.https.get(url).pipe(
      map(res => {
        return res;
    })
    )

  }

    getComplt(endPoint: string, payload?: any): Observable<any> {
    const p = new URLSearchParams();

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        p.set(key, p[key]);
      }
    }

    const url = `${this.node_url}${endPoint}`;
    // return Observable.throw('Simlulated error');
    // throw Error('Simulated Error');

    return this.https.get(url, payload).pipe(
      map(res => {
        return res;
      })
    )
    // .catch((err, caught) => this.handleError(err, caught));
  }

  getBlob(endPoint): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json'); // Set other headers if needed
    const url = `${this.node_url}${endPoint}`;
    return this.https.get(url, {
      headers: headers,
      responseType: 'blob' // Set the response type to 'blob'
    }).pipe(
      map(resp => {
        console.log("loan doc" + JSON.stringify(resp));
        return resp;
      })
    );
  }

  deleteWithPayload(endPoint: string, payload: any): Observable<any> {
    const url = `${this.node_url}${endPoint}`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: payload
    };

    return this.https.delete<any>(url, options);
  }


  // getNodeURL(){
  //   return this.nodeUrl;
  // }

  // postSatSureLogin(endPoint: string, payload: any): Observable<any> {
  //   const url = `${this.satSureUrl}${endPoint}`;
  //   return this.https.post(url, payload)
  //     .map(res => { return res });
  // }

  // postSatSureFarm(endPoint: string, payload: any): Observable<any> {
  //   const url = `${this.satSureUrl}${endPoint}`;
  //   return this.https.post(url, payload,
  //     {
  //       headers:
  //         new HttpHeaders()
  //           .set('Content-Type', 'application/json').set('Authorization',localStorage.getItem('satTkn')).set('Access-Control-Allow-Headers','*'),
  //       withCredentials: false,
  //     })
  //     .map(res => { return res });
  // }

  // putSatSure(endPoint: string, payload: any): Observable<any> {
  //   const url = `${this.nodeUrl}${endPoint}`;

  //   return this.https.put(url, payload)
  //     .map(res => {
  //       const result = res['result'];
  //       if (res['success']) {
  //         return result;
  //       } else {
  //         throw res;
  //       }
  //     })
  // }

  // satSureGet(endPoint: string, payload?: any): Observable<any> {
  //   const p = new URLSearchParams();

  //   for (const key in payload) {
  //     if (payload.hasOwnProperty(key)) {
  //       p.set(key, p[key]);
  //     }
  //   }

  //   const url = `${this.satSureUrl}${endPoint}`;
  //   // return Observable.throw('Simlulated error');
  //   // throw Error('Simulated Error');


  //   return this.https.get(url, payload)
  //     .map(res => {
  //       const result = res;
  //       if (res) {
  //         return result;
  //       } else {
  //         throw res['error'];
  //       }
  //     })
  // }

  // satSurePdfGet(endPoint: string, payload?: any): Observable<any> {
  //   const p = new URLSearchParams();

  //   for (const key in payload) {
  //     if (payload.hasOwnProperty(key)) {
  //       p.set(key, p[key]);
  //     }
  //   }

  //   const url = `${this.nodeUrl}${endPoint}`;
  //   // return Observable.throw('Simlulated error');
  //   // throw Error('Simulated Error');
  //   let headers =  new HttpHeaders()
  //   .set('appId',localStorage.getItem('applicantId')).set('Access-Control-Allow-Headers','*');

  //   return this.https.get(url, {'headers':headers})
  //     .map(res => {
  //       const result = res;
  //       if (res && res['data']) {
  //         return result;
  //       } else {
  //         throw res['message'];
  //       }
  //     })
  // }

  // post(endPoint: string, payload: any, isResult = false): Observable<any> {
  //   const url = `${this.baseUrl}/${endPoint}`;
  //   let ind = this.endPoints.indexOf(endPoint);
  //   if (ind != -1) {
  //     // payload.applicantId = this.applicantId;
  //     payload.adminId = this.adminId
  //   }


  //   return this.https.post(url, payload).map(res => {

  //     if (res['error'] || res['err']) {
  //       return res['error'] || res['err'];
  //     }

  //     if (res['message']) { // for timeout error
  //       return 'Server Unreachable. Please try after sometime';
  //     }

  //     if (res['ContentError']) {
  //       return res['ContentError'];
  //     }


  //     const json = isResult ? res : res['result'];

  //     if (!json) {
  //       const err = new Error(json.message || 'No Data Available. Server Error');
  //       err.name = 'JsonError';
  //       throw err;
  //     }

  //     let errKey;
  //     for (const key in json) {
  //       if (json.hasOwnProperty(key) && key.toLowerCase().endsWith('error')) {
  //         errKey = key;
  //       }
  //     }

  //     return errKey ? json[errKey] : json;

  //   }, (err: HttpErrorResponse) => {
  //     if (err.error instanceof Error) {
  //       // A client-side or network error occurred. Handle it accordingly.
  //     } else {
  //       // The backend returned an unsuccessful response code.
  //       // The response body may contain clues as to what went wrong,
  //     }
  //   })
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // myPost(endPoint: string, payload: any): Observable<any> {

  //   const url = `${this.nodeUrl}${endPoint}`;


  //   return this.https.post(url, payload)
  //     .map(res => this.parseResponse(res))
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // postRes(endPoint: string, payload: any): Observable<any> {

  //   const url = `${this.nodeUrl}${endPoint}`;


  //   return this.https.post(url, payload)
  //     .map(res => { return res });

  // }

  // postLocal(endPoint: string, payload: any): Observable<any> {

  //   const url = `${endPoint}`;


  //   return this.https.post(url, payload)
  //     .map(res => { return res });

  // }



  // postResMpWebhookTest(endPoint: string, payload: any): Observable<any> {

  //   const url = `${this.mpWebhookUrl}${endPoint}`;




  //   return this.https.post(url, payload, {observe: 'response'})
  //     .map(res => {
  //       return res });

  // }

  // test504():Observable<any>{
  //   let url = 'http://localhost:8080';
  //   return this.https.get(url)
  //     .map(res => { return res }).catch((err, caught) => {return Observable.throw(err)});

  // }

  // postResMpWebhookNesl(endPoint: string, payload: any): Observable<any> {

  //   const url = `${this.mpWebhookUrl}${endPoint}`;


  //   return this.https.post(url, payload,
  //     {
  //       headers:
  //         new HttpHeaders()
  //           .set('Content-Type', 'application/json'),
  //       withCredentials: false,
  //     }
  //     )
  //     .map(res => { return res });

  // }

  // postResMpWebhookNTB(endPoint: string, payload: any): Observable<any> {

  //   const url = `${this.mpWebhookUrl}${endPoint}`;

  //   let timeInMs = new Date().getTime()
  //   let token = payload.token
  //   delete payload.token
  //   return this.https.post(url, payload,
  //     {
  //       headers:
  //         new HttpHeaders()
  //           .set('Content-Type', 'application/json')
  //           .set('token',token),
  //       withCredentials: false,
  //     }
  //     )
  //     .map(res => { return res });

  // }

  // postNesl( payload: any): Observable<any> {

  //   // const url = `${this.mpWebhookUrl}${endPoint}`;
  //   const url = "https://stg.nesl.co.in/DDE_IU_Registration_V3/IUREGs"

  //   return this.https.post(url, payload,
  //     // {
  //     //   headers:
  //     //     new HttpHeaders()
  //     //       .set('Content-Type', 'application/x-www-form-urlencoded'),
  //     //   withCredentials: true,
  //     // }
  //     )
  //     .map(res => { return res });

  // }

  // postRespIrtc(endPoint: string, payload: any): Observable<any> {
  //   const url = `${this.irtcUrl}${endPoint}`;
  //   return this.https.post(url, payload,
  //     {
  //       headers:
  //         new HttpHeaders()
  //           .set('Content-Type', 'application/json'),
  //       withCredentials: true,
  //     })
  //     .map(res => {
  //       return res });
  // }

  // postResp(endPoint: string, payload: any): Observable<any> {
  //   const url = `${this.nodeUrl}${endPoint}`;
  //   return this.https.post(url, payload,
  //     {
  //       headers:
  //         new HttpHeaders()
  //           .set('Content-Type', 'application/json'),
  //       withCredentials: true,
  //     })
  //     .map(res => { return res });
  // }

  // postRespfalse(endPoint: string, payload: any): Observable<any> {
  //   const url = `${this.nodeUrl}${endPoint}`;
  //   return this.https.post(url, payload,
  //     {
  //       headers:
  //         new HttpHeaders()
  //           .set('Content-Type', 'application/json'),
  //       withCredentials: false,
  //     })
  //     .map(res => { return res });
  // }

  // myGetReps(endPoint: string): Observable<any> {

  //   const url = `${this.nodeUrl}${endPoint}`;
  //   // return Observable.throw('Simlulated error');
  //   // throw Error('Simulated Error');

  //   return this.https.get(url, {
  //     headers:
  //       new HttpHeaders()
  //         .set('Content-Type', 'application/json'),
  //     withCredentials: true,
  //   })
  //     .map(res => {
  //       // const result = res['result'];
  //       // if (res['success']) {
  //       return res;
  //       // } else {
  //       //   throw res;
  //       // }
  //     })
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // postRespHeader(endPoint: string): Observable<any> {
  //   const url = `${this.nodeUrl}${endPoint}`;
  //   return this.https.post(url,
  //     {
  //       headers:
  //         new HttpHeaders()
  //           .set('Content-Type', 'application/x-www-form-urlencoded'),
  //       withCredentials: true,
  //     })
  //     .map(res => { return res });
  // }

  // newPost(endPoint: string, payload: any, isResult = false): Observable<any> {

  //   const url = `${this.nodeUrl}${endPoint}`;


  //   // payload.getAll('file')

  //   return this.https.post(url, payload)
  //     .map(res => {
  //       if (res['success'] && isResult) {
  //         return res;
  //       } else if (res['success'] && !isResult) {
  //         return res['result'] || res['results']
  //       } else {
  //         console.error('ERROR: ', res['message']);
  //         this.snackbar.open(res['message'], 'OK', { duration: 3000 });
  //         throw res;
  //       }
  //     })
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // // TODO: replace post with newPost once auth/ocr apis are migrated to node.

  // sanpost(endPoint: string, payload: any, isResult = false): Observable<any> {
  //   // let headers = new HttpHeaders().set('token', this.getToken());
  //   const url = `${this.nodeUrl}/${endPoint}`;


  //   return this.https.post(url, payload)
  //     .map(res => {

  //       if (res['error'] || res['err']) {
  //         return res['error'] || res['err'];
  //       }

  //       if (res['message'] == 'No Previous Address Found' && res['result'] == 0) {
  //         return -1
  //       }

  //       if (res['message'] == 'Address did not match' && res['result'] == 0) {
  //         return 0
  //       }

  //       if (res['message'] == 'Address matched') {
  //         return 1
  //       }

  //       if (res['message'] == 'Profile Name Not Match' && res['result'] == 0) {
  //         return 0
  //       }

  //       if (res['message'] == 'Profile Name Match') {
  //         return 1
  //       }

  //       if (res['message']) { // for timeout error
  //         return 'Server Unreachable. Please try after sometime';
  //       }

  //       if (res['ContentError']) {
  //         return res['ContentError'];
  //       }

  //       const json = isResult ? res : res['result'];

  //       if (!json) {
  //         const err = new Error(json.message || 'No Data Available. Server Error');
  //         err.name = 'JsonError';
  //         throw err;
  //       }

  //       let errKey;
  //       for (const key in json) {
  //         if (json.hasOwnProperty(key) && key.toLowerCase().endsWith('error')) {
  //           errKey = key;
  //         }
  //       }

  //       return errKey ? json[errKey] : json;

  //     }, (err: HttpErrorResponse) => {
  //       if (err.error instanceof Error) {
  //         // A client-side or network error occurred. Handle it accordingly.
  //       } else {
  //         // The backend returned an unsuccessful response code.
  //         // The response body may contain clues as to what went wrong,
  //       }
  //     })
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }



  // get(url) {
  //   return this.https.get(url)
  // }

  // newGetIrtc(endPoint: string, payload?: any): Observable<any> {
  //   const p = new URLSearchParams();

  //   for (const key in payload) {
  //     if (payload.hasOwnProperty(key)) {
  //       p.set(key, p[key]);
  //     }
  //   }

  //   const url = `${this.irtcUrl}${endPoint}`;
  //   // return Observable.throw('Simlulated error');
  //   // throw Error('Simulated Error');

  //   return this.https.get(url, payload)
  //     .map(res => {
  //       const result = res['result'];
  //       if (res['success']) {
  //         return result;
  //       } else {
  //         throw res;
  //       }
  //     })
  // }


  // newGet(endPoint: string, payload?: any): Observable<any> {
  //   const p = new URLSearchParams();

  //   for (const key in payload) {
  //     if (payload.hasOwnProperty(key)) {
  //       p.set(key, p[key]);
  //     }
  //   }

  //   const url = `${this.nodeUrl}${endPoint}`;
  //   // return Observable.throw('Simlulated error');
  //   // throw Error('Simulated Error');

  //   return this.https.get(url, payload)
  //     .map(res => {
  //       const result = res['result'];
  //       if (res['success']) {
  //         return result;
  //       } else {
  //         throw res;
  //       }
  //     })
  // }



  // newGetMp(endPoint: string, payload?: any): Observable<any> {
  //   const p = new URLSearchParams();

  //   for (const key in payload) {
  //     if (payload.hasOwnProperty(key)) {
  //       p.set(key, p[key]);
  //     }
  //   }

  //   const url = `${this.mpUrl}${endPoint}`;
  //   // return Observable.throw('Simlulated error');
  //   // throw Error('Simulated Error');

  //   return this.https.get(url, payload)
  //     .map(res => {
  //       const result = res['result'];
  //       if (res['success']) {
  //         return result;
  //       } else {
  //         throw res;
  //       }
  //     })
  // }

  // getBranchPost(endPoint: string, payload?: any): Observable<any> {
  //   const p = new URLSearchParams();

  //   for (const key in payload) {
  //     if (payload.hasOwnProperty(key)) {
  //       p.set(key, p[key]);
  //     }
  //   }

  //   const url = `${this.mpUrl}${endPoint}`;
  //   // return Observable.throw('Simlulated error');
  //   // throw Error('Simulated Error');

  //   return this.https.post(url, payload)
  //     .map(res => {
  //       const result = res['result'];
  //       if (res['success']) {
  //         return result;
  //       } else {
  //         throw res;
  //       }
  //     })
  // }

  // myGet(endPoint: string, payload?: any): Observable<any> {
  //   const p = new URLSearchParams();

  //   for (const key in payload) {
  //     if (payload.hasOwnProperty(key)) {
  //       p.set(key, p[key]);
  //     }
  //   }

  //   const url = `${this.nodeUrl}${endPoint}`;
  //   // return Observable.throw('Simlulated error');
  //   // throw Error('Simulated Error');

  //   return this.https.get(url, payload)
  //     .map(res => {
  //       const result = res['result'];
  //       // if (res['success']) {
  //       return result;
  //       // } else {
  //       //   throw res;
  //       // }
  //     })
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // docGet(endPoint: string, payload?: any): Observable<any> {
  //   let headers = new HttpHeaders().set('X-Auth-Token', 'rgMcSjpuUqTMm9E4aJqhkflnMXICfb9c');
  //   // const p = new URLSearchParams();

  //   // for (const key in payload) {
  //   //   if (payload.hasOwnProperty(key)) {
  //   //     p.set(key, p[key]);
  //   //   }
  //   // }

  //   const url = `https://uat.leegality.com/api/v2.0${endPoint}`;
  //   // return Observable.throw('Simlulated error');
  //   // throw Error('Simulated Error');

  //   return this.https.get(url, { headers: headers })
  //     .map(res => {
  //       const result = res['data'];
  //       // if (res['success']) {
  //       return result;
  //       // } else {
  //       //   throw res;
  //       // }
  //     })
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // aGet(endPoint: string, payload?: any): Observable<any> {

  //   const url = `${this.nodeUrl}${endPoint}`;
  //   // return Observable.throw('Simlulated error');
  //   // throw Error('Simulated Error');

  //   return this.https.get(url, payload)
  //     .map(res => {
  //       const result = res['result'];
  //       if (res['success']) {
  //         return result;
  //       } else {
  //       }
  //     })
  // }

  // put(endPoint: string, payload: any): Observable<any> {
  //   const url = `${this.nodeUrl}${endPoint}`;

  //   return this.https.put(url, payload)
  //     .map(res => {
  //       const result = res['result'];
  //       if (res['success']) {
  //         return result;
  //       } else {
  //         throw res;
  //       }
  //     })
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // putIrtc(endPoint: string, payload: any): Observable<any> {
  //   const url = `${this.irtcUrl}${endPoint}`;

  //   return this.https.put(url, payload)
  //     .map(res => {
  //       const result = res['result'];
  //       if (res['success']) {
  //         return result;
  //       } else {
  //         throw res;
  //       }
  //     })
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // private parseResponse(json) {

  //   if (json.success) {
  //     return json.result || json.results;
  //   } else {
  //     console.error('ERROR: ', json.message);
  //     this.snackbar.open(json.message, 'OK', { duration: 3000 });
  //     throw json;
  //   }
  // }

  // // Not being used
  // private handleError(err, caught) {
  //   const message = `Unable to process your request. Try Again later`;
  //   this.snackbar.open(err.statusText || message, 'OK', { duration: 3000 });
  //   // return Observable.of(err.statusText);
  //   return Observable.empty();
  // }

  // getApplicantID() {
  //   return localStorage.getItem('wl-applicant-id');
  // }

  // getToken() {
  //   if (localStorage.getItem('wl-token')) {
  //     return localStorage.getItem('wl-token');
  //   } else {
  //     return null;
  //   }
  // }

  // pdfPost(endPoint: string, payload: any): Observable<any> {
  //   // TODO: Add headers
  //   // let headers = new Headers();
  //   // headers.append('Content-Type', 'application/json');
  //   // let options:RequestOptionsArgs = { headers: headers };

  //   let headers = new HttpHeaders();
  //   headers.set('token', this.getToken());
  //   headers.set('Content-Type', 'application/x-www-form-urlencoded');

  //   const url = `${this.nodeUrl}${endPoint}`;

  //   // const url = `http://whatsloan.com/default/index/convertbase64`;

  //   const body = new HttpParams()
  //     .set('html', payload);



  //   return this.https.post(url, body, {
  //     headers: new HttpHeaders()
  //       .set('token', this.getToken())
  //       .set('Content-Type', 'application/x-www-form-urlencoded')
  //   })
  //     .map(res =>
  //       this.parseResponsePdf(res)
  //     )
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // eSignPost(endPoint: string, payload: any): Observable<any> {
  //   // TODO: Add headers
  //   // let headers = new Headers();
  //   // headers.append('Content-Type', 'application/json');
  //   // let options:RequestOptionsArgs = { headers: headers };

  //   let headers = new HttpHeaders()
  //     .set('X-Auth-Token', 'rgMcSjpuUqTMm9E4aJqhkflnMXICfb9c');


  //   // const url = `${this.nodeUrl}${endPoint}`;
  //   const url = `https://uat.leegality.com/api/v2.0/sr/create`;

  //   //     const body = new HttpParams()
  //   // .set('html', payload);



  //   return this.https.post(url, payload, { headers: headers })
  //     .map(res =>
  //       this.parseResponsePdf(res))
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // private parseResponsePdf(json) {

  //   if (json.success || json.status) {
  //     return json.data;
  //   } else {
  //     console.error('ERROR: ', json.status);
  //     this.snackbar.open(json.status, 'OK', { duration: 3000 });
  //     throw json;
  //   }
  // }

  // postDigi(endPoint: string, payload: any): Observable<any> {

  //   // let headers = new HttpHeaders().set('token', this.getToken());
  //   // {headers: headers}


  //   const url = `${this.nodeUrl}${endPoint}`;


  //   return this.https.post(url, payload)
  //     .map(res => {
  //       return res
  //     });
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // postDigiUrlEncoded(endPoint: string, payload: any): Observable<any> {

  //   // let headers = new HttpHeaders().set('token', this.getToken());


  //   const url = `${this.nodeUrl}${endPoint}`;

  //   let headers = new HttpHeaders();
  //   // headers.set('token', this.getToken());
  //   headers.set('Content-Type', 'application/x-www-form-urlencoded');

  //   return this.https.post(url, payload, {
  //     headers: new HttpHeaders()
  //       .set('token', this.getToken())
  //   })
  //     .map(res => { return res });
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

  // postupdateDocsDate(endPoint: string, payload: any): Observable<any> {


  //   const url = `${this.baseUrl}${endPoint}`;

  //   let headers = new HttpHeaders();
  //   // headers.set('token', this.getToken());
  //   headers.set('Content-Type', 'application/x-www-form-urlencoded');

  //   return this.https.post(url, payload, {
  //     headers: new HttpHeaders()
  //       .set('token', this.getToken())
  //   })
  //     .map(res => { return res });
  //   // .catch((err, caught) => this.handleError(err, caught));
  // }

}
