import { Component, OnInit, Input ,OnChanges,SimpleChanges} from '@angular/core';
import { LanguageService } from 'src/app/language.service';
import * as constants from "../../constant";

@Component({
  selector: 'wl-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

  constructor(private languageservice: LanguageService) { }
  @Input() stepper = 0;
 LOCAL_LANGUAGE:any;
 langState:any;
language : string;
langState1:any;

    telugu:string[] = ["andhra pradesh","telangana"];
     hindi:string[] = ["madhya pradesh"];
  

  ngOnInit() {

  //  this.language = sessionStorage.getItem('lang')
    //    console.log(this.language);
    // this.LOCAL_LANGUAGE = this.languageservice.getConstants(this.language);
   
    
    this.langState = localStorage.getItem('state1');
    // this.langState1 = sessionStorage.getItem("state");
    // console.log(this.langState1)
    

if(this.stepper == 1){
  console.log(this.stepper)
  if(this.langState == "Karnataka")
  {
    this.LOCAL_LANGUAGE = this.languageservice.getConstants(constants.LANGUAGES.KANNADA);
  }
  else if(this.telugu.includes(this.langState.toLowerCase()))
  {
    this.LOCAL_LANGUAGE = this.languageservice.getConstants(constants.LANGUAGES.TELUGU);
  }
  else if(this.hindi.includes(this.langState.toLowerCase()))
  {
    this.LOCAL_LANGUAGE = this.languageservice.getConstants(constants.LANGUAGES.HINDI);
  }
  else if(this.langState == "Tamil Nadu")
    {
      this.LOCAL_LANGUAGE = this.languageservice.getConstants(constants.LANGUAGES.TAMIL);
    }
  else{
    this.LOCAL_LANGUAGE = this.languageservice.getConstants(constants.LANGUAGES.HINDI);
  }
} 





    
    }
  
    ngOnChanges(){
      if(this.stepper >= 3){
        console.log("stepper",this.stepper)
          this.langState1 = sessionStorage.getItem('state');
         //this.langState = "Telangana";
          console.log(this.langState1)
          if(this.langState1 == "Karnataka")
          {
            this.LOCAL_LANGUAGE = this.languageservice.getConstants(constants.LANGUAGES.KANNADA);
          }
          else if(this.telugu.includes(this.langState.toLowerCase()))
            {
              this.LOCAL_LANGUAGE = this.languageservice.getConstants(constants.LANGUAGES.TELUGU);
            }
            else if(this.hindi.includes(this.langState.toLowerCase()))
            {
              this.LOCAL_LANGUAGE = this.languageservice.getConstants(constants.LANGUAGES.HINDI);
            }
          else if(this.langState1 == "Tamil Nadu")
            {
              this.LOCAL_LANGUAGE = this.languageservice.getConstants(constants.LANGUAGES.TAMIL);
            }
        }
    }
  }


  

  


