import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApplicantService } from 'src/app/applicant.service';
import { DynamicModalComponent } from 'src/app/dynamic-modal/dynamic-modal.component';
import { JourneyType,Source } from '../../enum';

@Component({
  selector: 'app-initiate-assisted',
  templateUrl: './initiate-assisted.component.html',
  styleUrls: ['./initiate-assisted.component.scss']
})
export class InitiateAssistedComponent {
  [x: string]: any;
  geolocation: any;

  isMobile:boolean = false;
  private breakpointSubscription: Subscription;
  mobileInfo :any = {mobNo:null,assisted:true};
  aadhaarInfo:any = {assisted:true};
  showMobileVerificationCard:boolean;
  showAadhaarCard: boolean = false;
  mobileVerificationData:any;

  constructor(private snackbar:MatSnackBar,private breakpointObserver: BreakpointObserver, private route: ActivatedRoute, private dialog:MatDialog, private applicant:ApplicantService){
    // Detect changes in screen size and adjust sidenav mode accordingly
    this.breakpointSubscription = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .subscribe((state) => {
      console.log(state)
      this.isMobile = state.matches;
      if (this.isMobile) {

      }
    });

  }

  ngOnInit(){
    this.route.queryParams.subscribe(params => {
      // Extract mobileNumber from query params
      this.mobileInfo.mobNo = params['mobileNumber'];
      console.log(this.mobileInfo.mobNo);
    });

    this.showMobileVerificationCard = true;
    this.getLocation();

    this.getIPaddress()
    sessionStorage.setItem('jrnyType',JourneyType.NTB);
    sessionStorage.setItem('source',Source.MOBILE);

  }

  mobileVerificationDone(value){
    console.log(value);
    this.mobileVerificationData = value;
    this.showAadhaarCard = true;
  }

  aadhaarVerificationDone(value){
    console.log(value);

    if(value && value.success){
      this.applicant.updateAssisted(this.mobileVerificationData.mobileNo).subscribe((resp)=>{
        if(resp && resp.success){

        }else{
          this.snackbar.open("Something went wrong","OK")
        }
      },(err)=>{
        this.snackbar.open("Something went wrong","OK")
      })
    }

  }

  getIPaddress() {
    this.applicant.getGeoLocation().subscribe(resp => {
      console.log(resp)
      sessionStorage.setItem("state1",resp.data.state);
      if (resp && resp.data.ipv4) {
        sessionStorage.setItem('ipv4', resp.data.ipv4);
        // let locationFromBrowser = this.getLocation();
        this.getLocation()
          .then((position) => {
            // Handle successful position retrieval
            resp.latitude = position['coords']['latitude'];
            resp.longitude = position['coords']['longitude'];
            console.log('Position:', position);
              this.geolocation = {
              "country_code": resp.data.countryCode,
        "country_name": resp.data.country_name,
        "city": resp.data.city,
        "postal": resp.data.postal,
        "latitude": resp.data.latitude,
        "longitude": resp.data.longitude,
        "IPv4": resp.data.ipv4,
        "state": resp.data.state
         // "state":"Karnataka"
            }
            console.log(this.geolocation)
            console.log(resp)
            sessionStorage.setItem('geo-loc-n-ip',JSON.stringify(resp.data));
            sessionStorage.setItem('geo-loc-n-ip1',JSON.stringify(this.geolocation));
          })
          .catch((error) => {
            // Handle errors
            console.error('Error:', error);
            this.dialog.open(DynamicModalComponent,{disableClose:true,data:{divContent:"<p style'padding:1rem'>&nbsp;&nbsp;&nbsp;Please enable location access and reload the page&nbsp;&nbsp;&nbsp;<p>"}})
          });


      } else {
        sessionStorage.setItem('ipv4', "IP not captured");
      }
    }, (err) => {
      sessionStorage.setItem('ipv4', "IP not captured");
    })
  }

  getLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const longitude = position.coords.longitude;
            const latitude = position.coords.latitude;
            console.log(longitude, latitude);
            sessionStorage.setItem('coordinates', JSON.stringify({ lon: longitude, lat: latitude }));
            sessionStorage.setItem('geo-loc', JSON.stringify(position));
            resolve(position);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        console.log('No support for geolocation');
        reject('Geolocation not supported');
      }
    });
  }

}
