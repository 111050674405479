<wl-card heading="ల్యాండ్ రికార్డ్ - Land Record" [subheading1]="showLandVerifiedTick" [@fadeInOut]>
  <form novalidate [formGroup]="landRecordForm" class="formWidth" #formDirective="ngForm" [formGroup]="landRecordForm"
    (ngSubmit)="landRecordUpdate(formDirective)">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field class="fullWidth" appearance="outline">
          <!-- <mat-label>జిల్లాను ఎంచుకోండి - Select District</mat-label> -->
          <input type="text" matInput [matAutocomplete]="auto" placeholder="జిల్లాను ఎంచుకోండి - Select District" formControlName="district" [readonly]="districtReadOnly">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onDistrictChange($event)">
            <mat-option *ngFor="let option of districts" [value]="option.name">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix class="matIcon">arrow_drop_down</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field class="fullWidth" appearance="outline">
          <!-- <mat-label>మండల్ ఎంచుకోండి - Select Mandal</mat-label> -->
          <input type="text" matInput [matAutocomplete]="auto2" placeholder="మండల్ ఎంచుకోండి - Select Mandal" formControlName="mandal" [readonly]="mandalReadOnly">
          <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="mandalSelected($event)" >
            <mat-option *ngFor="let option of mandals" [value]="option.name">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix class="matIcon">arrow_drop_down</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">

      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field class="fullWidth" appearance="outline">
          <!-- <mat-label>గ్రామాన్ని ఎంచుకోండి - Select Village</mat-label> -->
          <input type="text" matInput [matAutocomplete]="auto3" placeholder="గ్రామాన్ని ఎంచుకోండి - Select Village" formControlName="village" [readonly]="villageReadOnly">
          <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="villageSelected($event)" >
            <mat-option *ngFor="let option of villages" [value]="option.name">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix class="matIcon">arrow_drop_down</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">

      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field class="fullWidth" appearance="outline">
          <!-- <mat-label>ఒక సర్వే/LP నంబర్‌ని నమోదు చేయండి - Enter One Survey/LP Number</mat-label> -->
          <input type="text" matInput [matAutocomplete]="auto4" placeholder="ఒక సర్వే/LP నంబర్‌ని నమోదు చేయండి - Enter One Survey/LP Number"
            formControlName="surveyNumber" [readonly]="surveyReadOnly">
          <mat-autocomplete #auto4="matAutocomplete" (optionSelected)="surveySelected($event)" >
            <mat-option *ngFor="let option of surveyNumberSearch" [value]="option.name">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix class="matIcon">arrow_drop_down</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="loading-overlay" *ngIf="loading">
      <div class="spinner"></div>
    </div>
    <div style="text-align: center;">
      <button class="mybutton" [disabled]="!landRecordForm.valid || disableVerify || landDetailsArray.length >=5">ధృవీకరించండి (Verify)</button>
    </div>

    <div style="width: 100%" *ngIf="landDetailsArray.length > 0">
      <div style="
        width: 100%;
        padding-top: 3rem;
        display: grid;
        justify-content: center;
      ">
        <table style="border: 1px solid black; width: 100%">
          <tr>
            <th style="padding:3px;border: 1px solid black; text-align: center">
              District
            </th>
            <th style="padding:3px;border: 1px solid black; text-align: center">
              Mandal
            </th>
            <th style="padding:3px;border: 1px solid black; text-align: center">
              Village
            </th>
            <th style="padding:3px;border: 1px solid black; text-align: center">
              Survey No.
            </th>
            <th style="padding:3px;border: 1px solid black; text-align: center">
              Land area (in Acres)
            </th>
          </tr>
          <tr *ngFor="let totalLandRecords of landDetailsArray">
            <td style="padding:3px;border: 1px solid black; text-align: center">
              {{ totalLandRecords.district }}
            </td>
            <td style="padding:3px;border: 1px solid black; text-align: center">
              {{ totalLandRecords.mandal }}
            </td>
            <td style="padding:3px;border: 1px solid black; text-align: center">
              {{ totalLandRecords.village }}
            </td>
            <td style="padding:3px;border: 1px solid black; text-align: center">
              {{ totalLandRecords.surveyNo }}
            </td>
            <td style="padding:3px;border: 1px solid black; text-align: center">
              {{ totalLandRecords.surveyNoArea }}
            </td>
          </tr>
        </table>
      </div>
    </div>

  </form>
</wl-card>
