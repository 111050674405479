import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { BaseApi } from 'src/app/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class CreditService {

  constructor(    private api: BaseApi,) { }

  getCreditData(data){
    data.aadhaarVerificationId = sessionStorage.getItem('aadhaarVerificationId')
    // return of(true) // bypassed and hardcoded true
    return this.api.postData(`/v1/generate/creditReport`, data)
    .pipe(map(resp => {
      console.log("generate credit score" + JSON.stringify(resp));
      // let shouldJourneyProceed;
      // if(resp && resp.success && resp.message.toLowerCase() == "report generated successfully"){
      //   shouldJourneyProceed = true;
      // }else{
      //   shouldJourneyProceed = false;
      // }
      // return shouldJourneyProceed;
      return resp;
    }))

  }

}
