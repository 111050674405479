import { Component, Input } from '@angular/core';
import { ApplicantService } from 'src/app/applicant.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { KaService } from 'src/app/landing/land-records/land-records-ka/ka.service';

@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent {
  @Input() pdfBlob: Blob;
  pdfBlobUrl: string;
  sanitizedPdfUrl: SafeResourceUrl;

  constructor(private applicant : ApplicantService,private sanitizer: DomSanitizer,private bhoomi: KaService) {}

  ngOnInit(){
    //sessionStorage.setItem('applicantId','6639d3a87a7452152b2ff475');
    //this.applicant.getLoanDocument({type:"annexureTwo"}).subscribe((resp: Blob)=>{
      //const file = new Blob([resp], { type: 'application/pdf' });
      //const fileURL = URL.createObjectURL(file);
      //this.pdfBlobUrl = fileURL;
      // this.loading = false;
      // window.open(fileURL); // Open the PDF in a new tab/window
    //},(err)=>{
      // this.loading = false;
      // this.snackbar.open("Unable to open the document","OK",{duration:3000})
      this.openModal();
    //})
  }

  ngOnChanges() {
    if (this.pdfBlob) {
      this.pdfBlobUrl = URL.createObjectURL(this.pdfBlob);
    }
  }

  modalOpen: boolean = false;

  openModal() {
    let storageID = sessionStorage.getItem('storageID');
    this.bhoomi.getLoanDocument(storageID).subscribe((resp: Blob)=>{
      const file = new Blob([resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      // this.pdfBlobUrl = this.sanitizedPdfUrl;
      // this.loading = false;
      // window.open(fileURL); // Open the PDF in a new tab/window
    },(err)=>{
      // this.loading = false;
      // this.snackbar.open("Unable to open the document","OK",{duration:3000})
    })
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
  }
}

