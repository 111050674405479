<div>
  <p fxLayout="row" fxLayoutAlign="start center">
    <label fxFlex="30">{{label}}</label>
    <span fxFlex="10" style="margin-left:10px">
      <md-icon *ngIf="verified" style="color:#05d016" md-svg-src="../../assets/svg/email-24px.svg"></md-icon>
      <md-icon *ngIf="!verified" style="color:#dc3232" md-svg-src="../../assets/svg/email-24px.svg"></md-icon>
    </span>

    <label *ngIf="message" fxFlex="25">{{message}}</label>
    <label *ngIf="validity" fxFlex="25">{{validity}}</label>
    <!-- <a *ngIf="file" md-button color="primary" target="_blank" href="{{file}}">View Document</a> -->
    <button *ngIf="!verified && !isAdmin" fxFlex="10" md-button color="primary" (click)="navigateTo(label)">Verify</button>
  </p>
</div>
