import { Component, Input } from '@angular/core';
import { zoomInOut } from 'src/app/landing/animation';

@Component({
  selector: 'wl-card',
  templateUrl: './card.component.html',
  styles: [`
  mat-card {
    margin: 5px;
    padding: 0;
    padding-bottom:1rem;
    border-radius: 0;
  }
  mat-card-content {
    padding: 30px;
  }
  h3 {
    margin: 0;
    background-color: #3493D8;
    background: linear-gradient(90deg, #db2016 0%, #00569d 100%);
    padding: 16px;
    color: #fff;
  }
`],
  // styles: [`
  //   md-card {
  //     margin: 16px;
  //   }
  // `]
  animations: [zoomInOut]
})
export class CardComponent {

  @Input() heading: string;
  @Input() subheading1: boolean;
  whiteTickMark = '\u2714';
  zoomState: string = ".99";

  constructor() { }

  onHover() {
    this.zoomState = 'hovered';
  }

  onMouseLeave() {
    this.zoomState = 'normal';
  }

}
