import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ApplicantService } from './applicant.service';
import { BaseApi } from './base-api.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { CoreModule } from './core/core.module';
import { InterceptorService } from './interceptor';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { DynamicModalComponent } from './dynamic-modal/dynamic-modal.component';
import { InitiateAssistedComponent } from './landing/assisted-bifurcation/initiate-assisted/initiate-assisted.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LandingModule } from './landing/landing.module';
import { AgainInterceptor } from './again.interceptor';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    PageNotFoundComponent,
    DynamicModalComponent,
  //  GstComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatProgressBarModule,
    CommonModule,
    MatSelectModule,
    CoreModule,
    MatSnackBarModule,
    MatToolbarModule,
    LandingModule,
    NgxExtendedPdfViewerModule,
    // TaxModule,
    // TaxRoutingModule





  ],
  providers: [ApplicantService,BaseApi,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    {provide:HTTP_INTERCEPTORS, useClass:AgainInterceptor, multi:true},
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
  ],

  bootstrap: [AppComponent],
  entryComponents:[DynamicModalComponent]
})
export class AppModule { }
