<mat-card style="margin: 5px;" [@fadeInOut]>
  <br>
  <br>
  <div class="row">
    <div class="col-12" style="text-align: center;">
      <p style="text-align: center; color:#0091ff; font-size: 2rem;font-weight: bold;">Congratulations!</p>
    </div>
  </div>
  <div class="divOuter">
    <div class="container">
      <img src="../../../assets/tick.gif" class="tick">
    </div>

    <div style="margin-top:10px; padding-bottom: 18px;">
      <h4 style="color: #0091ff;font-weight: bold; ">Your Loan has been Sanctioned</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-2 col-sm-2 col-md-4 col-lg-4 col-xl-4 col-xxl-4">

    </div>
    <div class="col-xs-8 col-sm-8 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
      <div id="info">
        <div class="textWithBgColor">Loan Amount: &nbsp;Rs.<span>{{loanAmount}}</span></div>
        <div class="textWithBgColor">Rate of Interest: <span>{{rateOfInterest}}</span> p.a. (Effective interest is 7%
          p.a.
          after interest subvention)</div>
        <div class="textWithBgColor">Digital Convenience fee: &nbsp;Rs. 500 + GST (i.e Rs.590 at present, Non-
          Refundable)
        </div>
        <div class="textWithBgColor" style=" font-weight: normal; font-size: 1em;"> (Will be collected upfront from KCC
          Loan
          A/c)</div>
      </div>
    </div>
    <div class="col-xs-2 col-sm-2 col-md-4 col-lg-4 col-xl-4 col-xxl-4">

    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-xs-2 col-sm-2 col-md-4 col-lg-4 col-xl-4 col-xxl-4">

    </div>
    <div class="col-xs-8 col-sm-8 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
      <div class="row">
        <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;padding: 1rem;">
          <button class="mybutton" (click)="openSanctionLetter()">Open Sanction Letter</button>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style="text-align: center;padding: 1rem;">
          <button class="mybutton" (click)="openApplicationForm()">Open Application Form</button>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12" style="text-align: center;padding: 1rem;">
          <button class="mybutton" (click)="openSanctionLetter()">View Sanction Letter</button>
        </div>

      </div>
    </div>
  </div>

  <div class="loading-overlay" *ngIf="loading">
    <div class="spinner"></div>
  </div>

  <br><br>
</mat-card>
