<!--<ngx-extended-pdf-viewer [src]="pdfBlobUrl"></ngx-extended-pdf-viewer>-->
<div class="pdf">

<!--<div class="row" style="vertical-align: middle; text-align: center;justify-content: center;">-->

    <div style="display:flex;flex-flow: column;align-items: center;z-index: 2;">
      
     <!-- <button class="mybuttonWhite" (click)="openModal()"> Open Modal</button>-->
    </div>
 <!--</div>--> 

<div class="modal" [style.display]="modalOpen ? 'flex' : 'none'">
   <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <iframe [src]="sanitizedPdfUrl" style="height: 80vh;"></iframe>
    </div>
  </div>
</div>

