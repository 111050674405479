<form [formGroup]="loanDetailsForm">
  <wl-card heading="{{LOCAL_LANGUAGE.LOAN_AMOUNT_DETAILS}}- Loan Amount Details" [subheading1]="showLoanAmountTick" [@fadeInOut]>

    <div class="row" style="width: 100%">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="padding-top: -20px !important">
        <p style="
        text-align: center;
        padding-bottom: 0px;
        margin-bottom: 0px;
      ">
          {{LOCAL_LANGUAGE.ESTIMATED_LOAN_AMOUNT}}
        </p>
        <p style="text-align: center; padding-top: 0px; margin-top: 0px">
          (Estimated Loan Amount)
        </p>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field style="width: 100%;margin-top: 10px;margin-left: 4vw" appearance="outline">
          <input type="text" matInput placeholder="Loan Amount" formControlName="estimatedLoanAmt" maxlength="10"
            [readonly]="true">
        </mat-form-field>
      </div>
    </div>

    <div class="row" style="width: 100%" #customerLoanAmountCardLayout>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="text-align: center">
        {{LOCAL_LANGUAGE.DIFFERENT_LOAN_AMOUNT}} <br />
        Would you like to request for a different loan amount?
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
        style="padding-top: 15px; text-align: center">

        <div class="row" style="padding-left: 4vw">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <div >
              <input style="border:solid 1px grey" class="form-check-input" type="radio" name="loanAmtDiff" formControlName="loanAmtDiff"
                id="inlineRadio1" value="1" (change)="differentLoanAmount('Y')"
                [attr.disabled]="DisabledFlagValue ? '' : null" />
              <label class="form-check-label" for="inlineRadio1">&nbsp;{{LOCAL_LANGUAGE.YES}} - Yes</label>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <div >
              <input style="border:solid 1px grey" class="form-check-input" type="radio" name="loanAmtDiff" formControlName="loanAmtDiff"
                id="inlineRadio2" value="0" (change)="differentLoanAmount('N')"
                [attr.disabled]="DisabledFlagValue ? '' : null"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" />
              <label class="form-check-label" for="inlineRadio2">&nbsp;{{LOCAL_LANGUAGE.NO}} - No</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row" style="width: 100%" *ngIf="showCustomerLoanAmount ">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="text-align: center;">
        <p style="
        text-align: center;
        padding-bottom: 0px;
        margin-bottom: 0px;
      ">
          {{LOCAL_LANGUAGE.REQUESTED_LOAN_AMOUNT}}
        </p>
        <p style="text-align: center; padding-top: 0px; margin-top: 0px">
          (Requested Loan Amount)
        </p>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <mat-form-field style="width: 100%; margin-top: 10px; margin-left: 4vw" appearance="outline">
          <input matInput type="text" formControlName="loanAmtFarmer" [readonly]="isLoanAmtFarmerDisabled"
            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" />
          <mat-error style="text-align: center; font-size: 11px; color: red">
            <!-- *कृपया {{ estimatedLoanAmount }} से कम या
            इसके
            बराबर की मान्य ऋण राशि दर्ज करें<br /> -->
            *Please enter a valid loan amount value less than or equal to
            {{loanDetailsForm.controls['estimatedLoanAmt'].value}} <br>
          </mat-error>
    
        </mat-form-field>
    <div  style="text-align: center;font-size: 12px;color: red;margin-left: 9em;"><span *ngIf="(loanDetailsForm.controls['estimatedLoanAmt'].value < loanDetailsForm.controls['loanAmtFarmer'].value)">Please enter an amount lesser than or equal to {{loanDetailsForm.controls['estimatedLoanAmt'].value}}</span></div>   
      </div>
    </div>

    <div style="display: grid">
      <label style="margin-top: 4rem"><input formControlName="loanAmountSubmissionCheckOne" type="checkbox"
          id="loanAmountSubmissionCheckOneID" (change)="changeEventOne($event)" [checked]="isChecked" />
        {{LOCAL_LANGUAGE.I_PROVIDE_CONSENT_TO_BANK}} I provide my
        consent to Bank to hold on selected operative account towards
        recovery of dues.</label>

      <label style="margin-top: 1rem"><input type="checkbox" formControlName="loanAmountSubmissionCheckTwo"
          id="loanAmountSubmissionCheckTwoID" (change)="changeEventTwo($event)" [checked]="isChecked" />
        {{LOCAL_LANGUAGE.I_PROVIDE_CONSENT_FOR_EXTRACTION}} I provide my consent for extraction of
        creditinformation report from CIC's & also to disclose the
        information relating to credit facilities availed / to be
        availed.</label>
    </div>
    <div style="width: 100%; text-align: center; margin-top: 1rem;">
      <button class="mybutton" (click)="submitCropNLoan(loanDetailsForm.value)" [disabled]="
      (loanDetailsForm.controls['loanAmtFarmer'].invalid &&
        showCustomerLoanAmount) ||
      loanDetailsForm.controls['estimatedLoanAmt'].invalid ||
      disableSubmitButton ||
      disableSubmitButtonForever ||
      !iseventchecked||
      toggleSubmitLoanAmountCondOne ||
       toggleSubmitLoanAmountCondTwo || (loanDetailsForm.controls['estimatedLoanAmt'].value < loanDetailsForm.controls['loanAmtFarmer'].value)"> 

        {{LOCAL_LANGUAGE.SUBMIT_DETAILS}} (Submit Details)
      </button>

    
    </div>
  </wl-card>

</form>
