<mat-progress-bar mode="buffer" *ngIf="onloading">
</mat-progress-bar>
<h4 *ngIf="onloading">Generating OTP, Please wait</h4>
<div *ngIf="!onloading" class="container-div">
  <h3 class="heading3">Verify OTP:</h3>
  <form novalidate>
    <h5 *ngIf="showMobileMessage" style="text-align:center">
      OTP sent to your mobile number {{ data.mobile_no }} registered with
      Bank, please enter OTP for validation. <br />
      बैंक में पंजीकृत आपके मोबाइल नंबर {{ data.mobile_no }} पर एक ओटीपी भेजा
      गया है। सत्यापित करने के लिए कृपया ओटीपी दर्ज करें।
    </h5>

    <wl-otp-dialog (onInputChange)="onOtpChange($event)" [config]="{length: otpBoxLength}"
      style="text-align: center"></wl-otp-dialog>

    <mat-progress-bar *ngIf="isOtpVerifying" mode="buffer"></mat-progress-bar>

    <div style="display: flex; justify-content: space-evenly; margin-top: 20px">
      <button class="mybutton" (click)="closeDialog($event)" style="margin-right: 10px;">Close</button>
      <button class="mybutton" (click)="resend()" [disabled]="disableResend || showMaxRetries"
        style="margin-right: 10px;">
        Resend
      </button>
      <button class="mybutton" [disabled]="disableVerify" (click)="verify()">
        Verify
      </button>
    </div>
    <div style="margin-top:10px">
      <p style="text-align: center;" *ngIf="disableResend">
        Please wait for <b>{{ secondsTimer }}</b> seconds before re-sending
        OTP
      </p>
      <p *ngIf="!disableResend"></p>
      <p *ngIf="showMaxRetries" style="text-align: center;">
        Maximum number of retries reached. Please try again later
      </p>
      <mat-progress-bar mode="buffer" *ngIf="showWaiting">
        Please Wait</mat-progress-bar>
    </div>
  </form>
</div>
