

import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-spinner-overlay',
  templateUrl: './spinner-overlay-wrapper.component.html',
  styleUrls: ['./spinner-overlay-wrapper.component.scss']
})
export class SpinnerOverlayComponent implements OnInit {


  @Input() showSpinner = '';
  @Input() subText = '';

  constructor() { }

  ngOnInit() {
  }
}