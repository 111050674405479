export enum Milestone {
  INIT = "INIT",

  MOBILE_VERIFICATION_OTP_SENT = "MOBILE_VERIFICATION_OTP_SENT",
  MOBILE_VERIFICATION_OTP_SEND_FAILED = "MOBILE_VERIFICATION_OTP_SEND_FAILED",
  MOBILE_VERIFICATION_SUCCESS = "MOBILE_VERIFICATION_SUCCESS",
  MOBILE_VERIFICATION_FAILED = "MOBILE_VERIFICATION_FAILED",

  AADHAAR_VERIFICATION_OTP_SENT = "AADHAR_VERIFICATION_OTP_SENT",
  AADHAAR_VERIFICATION_OTP_SEND_FAILED = "AADHAAR_VERIFICATION_OTP_SEND_FAILED",
  AADHAAR_VERIFICATION_SUCCESS = "AADHAAR_VERIFICATION_SUCCESS",
  AADHAAR_VERIFICATION_FAILED = "AADHAAR_VERIFICATION_FAILED",

  PAN_VERIFICATION_SUCCESS = "PAN_VERIFICATION_SUCCESS",
  PAN_VERIFICATION_FAILED = "PAN_VERIFICATION_FAILED",
  VOTER_VERIFICATION_SUCCESS = "VOTER_VERIFICATION_SUCCESS",
  VOTER_VERIFICATION_FAILED = "VOTER_VERIFICATION_FAILED",

  CIC_CHECK_SUCCESS = "CIC_CHECK_SUCCESS",
  CIC_CHECK_FAILED = "CIC_CHECK_FAILED",

  LAND_DETAILS_SUBMITTED = "LAND_DETAILS_SUBMITTED",
  LAND_DETAILS_SUBMISSION_FAILED = "LAND_DETAILS_SUBMISSION_FAILED",

  CROP_DETAILS_SUBMITTED = "CROP_DETAILS_SUBMITTED",
  CROP_DETAILS_SUBMISSION_FAILED = "CROP_DETAILS_SUBMISSION_FAILED",

  LOAN_AMOUNT_CALCULATED = "LOAN_AMOUNT_CALCULATED",
  LOAN_AMOUNT_CALCULATION_FAILED = "LOAN_AMOUNT_CALCULATION_FAILED",

  NEW_LOAN_AMOUNT_REQUESTED = "NEW_LOAN_AMOUNT_REQUESTED",
  LOAN_SANCTION_SUCCESS = "LOAN_SANCTION_SUCCESS",
  LOAN_SANCTION_FAILED = "LOAN_SANCTION_FAILED",
}


export enum JourneyType {
  ETB = "etb",
  NTB = "ntb",
  RENEWAL = "renewal"
}

export enum Source {
  MOBILE = "mobile",
  WEB = "web",
}

