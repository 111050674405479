import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { BaseApi } from './base-api.service';
import { environment as ENV } from '../environments/environment';
import { BehaviorSubject, Observable, Subject, map, tap } from 'rxjs';
import { BaseApi } from './base-api.service';
import * as constants from "./constant";
import { JourneyType } from './landing/enum';
export interface SpinnerData {
  showSpinner: boolean;
  spinnerWithMessage: boolean;
  subText: string;
  stepper: number;
}

@Injectable()
export class ApplicantService {

  // private applicantId: string;
  // private baseUrl: string;
  // private custId: string;
  dataObj: any;

  private neslDataEncrypted = new BehaviorSubject<any>('');
  neslDataShared = this.neslDataEncrypted.asObservable()
  // getBaseUrl: string;

  public stringSubject = new Subject<SpinnerData>();
  public showToolBar = new Subject<string>();
  freshOrResume: any;
  loginReferenceId: any;
  loginReferenceName: any;

  constructor(
    private api: BaseApi,
    private http: HttpClient,
  ) {
    // this.applicantId = this.getApplicantID();
    // this.coapplicantId = localStorage.getItem('wl-cust-applicant-id');
    // this.custId = this.getCustId();
    // this.baseUrl = `/applicants/${this.applicantId}`;
    // // this.baseUrlCust = `/applicants/${this.applicantId}`;
    // this.getBaseUrl = `/applicants/${this.applicantId}/assets/bhoomi`;
  }

  checkIsResumable(mobileNumber){
    return this.api.getComplt(`/v1/applicant/isResumable?mobileNumber=${mobileNumber}&mobileVerificationId=${sessionStorage.getItem('mobileVerificationId')}`).pipe(
      map(resp => {
        return resp;
      })
    )
  }

  getResumeData(appId){
    return this.api.getComplt(`/v1/applicant/getApplicantRecord/${appId}`).pipe(
      map(resp => {
        return resp;
      })
    )
  }

  generateApplicantSMS(mobNo){
    return this.api.getComplt(`/v1/assist-mode/generateApplicantSmsLink?mobileNumber=${mobNo}`)
  }

  login(username,password){
    return this.api.postData('/v1/dashboard/login',{username,password}).pipe(
      map(login => {
        return login;
      })
    )
  }

  getApplicant(){

    let applicantId = sessionStorage.getItem('applicantId');
    return this.api.getComplt(`/v1/applicant/getApplicantRecord/${applicantId}`).pipe(map( resp => {
      return resp;
    }))
  }

  moreInfoOld(res: any) {
    console.log('check rest', res)
    if (res && res.moreInfo != null && res.moreInfo != "" && res.moreInfo != undefined) {
      if (res.message == 'Success' || res.message == 'success') {
        return res.moreInfo.errorid + "-" + res.moreInfo.errormsg
      }
      else if (res && res.message != 'Success' && res.moreInfo && res.moreInfo.errDetail && res.moreInfo.errDetail.errorid) {
        let error2 = res.moreInfo.url ? res.moreInfo.url : res.moreInfo.err;
        return res.message + " - " + res.moreInfo.errDetail.errorid + " - " + res.moreInfo.errDetail.errormsg + "\n" + " - " + error2;
      }
      else if (res && res.message != 'Success' && res.moreInfo && res.moreInfo.errorid) {
        return res.message + " - " + res.moreInfo.errorid + " - " + res.moreInfo.errormsg
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }

  getTelenganaLRlist(data){
    return this.api.postData('/v1/land/telangana/getLandRecords',data).pipe(
      map(admin => {
        return admin;
      })
    )

  }

  getDistrictsAP() {
    return this.api.getComplt('/v1/land/meebhoomi/getDistricts').pipe(
      map(admin => {
        return admin;
      })
    )

  }

  getMandalAP(data) {
    return this.api.postData('/v1/land/meebhoomi/getMandals', data).pipe(
      map(res => {
        return res;
      })
    )
  }

  getVillageAP(data) {
    return this.api.postData('/v1/land/meebhoomi/getVillages', data)
      .pipe(
        map(res => {
          return res;
        })
      )
  }

  getSurveyNumberAP(data) {
    return this.api.postData('/v1/land/meebhoomi/getSurveyNumbers', data)
      .pipe(
        map(res => {
          return res;
        })
      )
  }

  getAdangalBySurveyNo(data){
    return this.api.postData('/v1/land/meebhoomi/getLandRecords', data)
    .pipe(
      map(res => {
        return res;
      })
    )
  }

  getCrops(data){
    return this.api.postData('/v1/crops/getCrops', data)
    .pipe(
      map(res => {
        return res;
      })
    )
  }

  // getHeaders(){
  //   let states = this.getStates().subscribe((resp)=>{
  //     if(resp && resp.success){
  //       if(resp.data && resp.data.length){
  //         console.log(sessionStorage.getItem('state'))
  //         let sessionState = sessionStorage.getItem('state')?sessionStorage.getItem('state').toLowerCase():"";
  //         let currentState = resp.data.find((obj)=>{
  //           console.log(obj.state)
  //           // debugger
  //           return (obj.state.toLowerCase() == sessionState)})
  //         console.log(currentState)
  //         sessionStorage.setItem("stateHeaderObj",JSON.stringify(currentState))
  //       }
  //     }
  //   })
  // }

  // async getHeaders(): Promise<void> {
  //   try {
  //     const resp = await this.getStates().toPromise();
  //     if (resp && resp.success && resp.data && resp.data.length) {
  //       const sessionState = sessionStorage.getItem('state') ? sessionStorage.getItem('state').toLowerCase() : "";
  //       const currentState = resp.data.find(obj => obj.state.toLowerCase() === sessionState);
  //       sessionStorage.setItem("stateHeaderObj", JSON.stringify(currentState));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching headers:", error);
  //     throw error; // Rethrow the error to propagate it
  //   }
  // }

  getHeaders(): Observable<any> {
    return new Observable((observer) => {
      this.getStates().subscribe((resp) => {
        if (resp && resp.success && resp.data && resp.data.length) {
          // debugger
          const sessionState = sessionStorage.getItem('state') ? sessionStorage.getItem('state').toLowerCase() : "";
          const currentState = resp.data.find(obj => obj.state.toLowerCase() === sessionState);
          sessionStorage.setItem("stateHeaderObj", JSON.stringify(currentState));
        }
        observer.next(); // Emitting a value to complete the observable
        observer.complete(); // Completing the observable
      }, (error) => {
        console.error("Error fetching headers:", error);
        observer.error(error); // Emitting an error if one occurs
      });
    });
  }

  getProposedLoanAmount(data){
    return this.api.postData('/v1/loan/getProposedLoanAmount', data)
    .pipe(
      map(res => {
        return res;
      })
    )
  }

  updateAssisted(mobileNumber){
    return this.api.postData('/v1/assist-mode/updateAssistedJourney', {mobileNumber})
    .pipe(
      map(res => {
        return res;
      })
    )
  }

  generateDocs(data){
    return this.api.postData('/v1/loan/generateLoanDocuments', data)
    .pipe(
      map(res => {
        return res;
      })
    )
  }

  moreInfo(res: any) {
    console.log('check rest', res)
    if (res && res.moreInfo != null && res.moreInfo != "" && res.moreInfo != undefined) {
      if (res.message == 'Success' || res.message == 'success') {
        return res.moreInfo.errormsg
      }
      else if (res && res.message != 'Success' && res.moreInfo && res.moreInfo.errDetail) {
        let error2 = res.moreInfo.err ? "-" + res.moreInfo.err : "";
        return res.message + " - " + res.moreInfo.errDetail.errormsg + "\n" + error2;
      }
      else if (res && res.message != 'Success' && res.moreInfo) {
        return res.message + " - " + res.moreInfo.errormsg
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
  }

  isAgreed(consent: string) {
    const token = sessionStorage.setItem('agree', consent);
    return token;
  }

  updateDocsData(data) {
    // return this.api.postupdateDocsDate('nesl/upDocsData', data)
    return this.api.postData(`/v1/nesl/upDocsData`, data).pipe(
      map(resp => {
        console.log("updateDocsData" + JSON.stringify(resp));
        return resp;
      })
    )

  }

  callDigiProcessNote(appId, distid, type) {
    return this.api.postData('/users/digitalprocessnote', { applicantId: appId, distid: distid, type })
      .pipe(map(res => {
        return res;
      }));
  }

  getLoanAmount(data) {
    return this.api.postData('/users/getProposedLoanAmount', data)
      .pipe(
        map(res => {
          return res;
        })
      )
  }

  submitMpData(data) {
    return this.api.postData('/users/updatemploandata', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateReferenceID(type) {
    return this.api.postData(`/v1/nesl/convertimage_new`, { appId: sessionStorage.getItem('mpApplicantId'), docType: type })
      .pipe(map(resp => {
        console.log("create account resp" + JSON.stringify(resp));
        return resp;
      }))
  }

  passValue(data) {
    //passing the data as the next observable\
    console.log("********** spinner data =>", data)
    this.stringSubject.next(data);
  }

  getUserConsentStatus() {
    const token1 = sessionStorage.getItem('agree');
    return token1;
  }

  getUserVerified(userid: any, type: string) {
    return this.api.postData('/users/getUserVerified', { userid, type }).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getIP() {
    return this.api.newGetIP();
  }

  verifyID(data: any) {

    return this.api.postData('/webhook/kccaccountfetch_cbs', data).pipe(
      map(res => {
        return res;
      })
    )
  }

  verifyIDResume(data: any) {
    return this.api.postData('/webhook/resumekccjourney', data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateStatus() {
    return this.api.postData('/users/updatenStatus', { appId: sessionStorage.getItem('mpApplicantId') })
      .pipe(map(resp => {
        console.log("updateStatus" + JSON.stringify(resp));
        return resp;
      }))
  }

  generateAadharOtp(data: any) {
    return this.api.postData('/v1/auth/aadhaar/generateAadhaarOtp', data).pipe(
      map(res => {
        return res;
      })
    )
  }

  generateOTPNTB(data) {
    return this.api.postData(`/webhook/generatentbkccotp`, data)
      .pipe(map(resp => {
        console.log("generate OTP NTB" + JSON.stringify(resp));
        return resp;
      }))
  }

  generateOTPMobileNo(data) {

    if(sessionStorage.getItem('jrnyType')==JourneyType.RENEWAL){
      data.referenceId = sessionStorage.getItem('applicantId')
    }
    return this.api.postData(`/v1/auth/mobile/generateMobileOtp`, data)
      .pipe(map(resp => {
        console.log("generate OTP" + JSON.stringify(resp));
        return resp;
      }))
  }

  verifyOTPMobileNo(data) {

    if(sessionStorage.getItem('jrnyType')==JourneyType.RENEWAL){
     data.referenceId = sessionStorage.getItem('applicantId')
    }

    return this.api.postData(`/v1/auth/mobile/verifyMobileOtp`, data)
      .pipe(map(resp => {
        console.log("verify OTP" + JSON.stringify(resp));
        return resp;
      }))
  }

  verifyPANvoterID(data) {
    let endpoint = "";
  if(data.type == "PAN"){
    endpoint = "/v1/auth/pan/panVerification"
  }else if(data.type == "VoterID"){
    endpoint = "/v1/auth/voter/voterVerification"
  }
  delete data.type;
    return this.api.postDataWithTokenPanVoter(`${endpoint}`, data)
      .pipe(map(res => {
        return res;
      }))
  }

  generateAadharOtpNTB(data) {
    return this.api.postData('/webhook/generateNTBAadharOtp', data)
      .pipe(
        map(res => {
          return res;
        }))
  }

  generateKCCOtp(data) {
    return this.api.postData('/webhook/generatekccotp', data)
      .pipe(
        map(res => {
          return res;
        }))
  }

  verifyAadharOtp(data) {
    return this.api.postData('/v1/auth/aadhaar/verifyAadhaarOtp', data).pipe(
      map(res => {
        return res;
      })
    )


  }

  verifyAadharOtpNTB(data) {
    return this.api.postData('/webhook/verifyNTBAadharOtp', data)
      .pipe(
        map(res => {
          return res;
        }))
  }

  verifyKCCOtp(data) {
    return this.api.postData('/webhook/verifykccotp', data)
      .pipe(
        map(res => {
          return res;
        })
      )
  }

  verifyOTPNTB(data) {
    return this.api.postData(`/webhook/verifyntbkccotp`, data)
      .pipe(map(resp => {
        console.log("verify OTP NTB" + JSON.stringify(resp));
        return resp;
      }))
  }

  updateLoanAmount(data){
    return this.api.postData(`/v1/loan/updateLoanAmount`,data)
    .pipe(map(resp => {
      console.log("loan amount update" + JSON.stringify(resp));
      return resp;
    }))
  }

  getLoanDocument(data){
    return this.api.getBlob(`/v1/loan/getLoanDocuments/${sessionStorage.getItem('applicantId')}/${data.type}`)
    .pipe(map(resp => {
      console.log("loan doc" + JSON.stringify(resp));
      return resp;
    }))
  }

  getCreditReport(data){
    return this.api.getBlob(`/v1/loan/getLoanDocuments/${sessionStorage.getItem('applicantId')}/${data.type}`)
    .pipe(map(resp => {
      console.log("loan doc" + JSON.stringify(resp));
      return resp;
    }))
  }

  // getStates(){
  //   return this.api.getComplt('/v1/states/getStates').pipe(map(resp => {
  //     console.log("loan doc" + JSON.stringify(resp));
  //     return resp;
  //   }))
  // }

  getStates(): Observable<any> {
    return this.api.getComplt('/v1/states/getStates').pipe(
      tap(resp => console.log("states" + JSON.stringify(resp)))
    );
  }

  submitTelenganaLrs(selectedLandRecordIds){
    return this.api.postData('/v1/land/telangana/saveLandRecords',{selectedLandRecordIds,applicantId:sessionStorage.getItem('applicantId')}).pipe(
      tap(resp => console.log("states" + JSON.stringify(resp)))
    );
  }


  getGstDetails(data){
    return this.api.postData('/v1/gst/getGstDetails',data).pipe(map(res => {
      console.log(res)
return res;
    }
    ))
  }


  gstVerify(gst){
    return this.api.postData("/v1/gst/gstVerify",gst).pipe(map(res => {
  console.log(res)

  return res;
    }))
  }

  gstRequestOtp(gst){
    return this.api.postData("/v1/gst/gstVerify",gst).pipe(map(res => {
  console.log(res)

  return res;
    }))
  }
  gstValidateOtp(gst){
    return this.api.postData("/v1/gst/gstVerify",{
      appId: sessionStorage.getItem('applicantId'), gst: gst
    }).pipe(map(res => {
  console.log(res)

  return res;
    }))
  }


  getGeoLocation(): Observable<any> {
    return this.api.getComplt('/v1/ipTracking/getGeoLocation').pipe(
      tap(resp => console.log("Location" + JSON.stringify(resp)))
    );
  }

  
  // passValue(data) {
  //   //passing the data as the next observable\
  //   console.log("********** spinner data =>", data)
  //   this.stringSubject.next(data);
  // }

  // sendShowToolBarStatus(data) {
  //   //passing the data as the next observable\
  //   console.log("********** spinner data =>", data)
  //   this.showToolBar.next(data);
  // }



  // sendData(name: string) {
  //   this.neslDataEncrypted.next(this.neslDataShared);
  // }


  // getBranchDetails() {
  //   return this.api.postRes('/login/getBranchDetails', { adminId: localStorage.getItem('wl-admin-id') })
  // }

  // getImage(image) {
  //   return this.api.getComplt(image).map(result => {
  //     return result
  //   })
  // }

  // expiryLinkpost(fileName) {

  //   return this.api.postRes('/users/getSignedUrl', { 'fileName': fileName, 'type': 0 })
  //     .map(res => {
  //       return res;
  //     });
  // }









  // getLandRecordDataUsingFruitsAPI(data) {
  //   return this.api.postResMpWebhook('/webhook/getFruitDetails', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getNameFromCbs(appId) {
  //   return this.api.postResMp('/users/getApplicantData', { appId: appId })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // callDigiProcessNote(appId, distid, type) {
  //   return this.api.postResMp('/users/digitalprocessnote', { applicantId: appId, distid: distid, type })
  //     .map(res => {
  //       return res;
  //     });
  // }



  // getApplicationStatus() {
  //   return this.api.postResMp('/users/appStatus', { appId: sessionStorage.getItem('mpApplicantId') })
  //     .map(res => {
  //       return res;
  //     });
  // }


  // getDistricts() {

  //   return this.api.postResMp('/land-records/getDistricts', {})
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getTehsil(id) {

  //   return this.api.postResMp('/land-records/getTehsils', { distid: id })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getHalka(id) {
  //   return this.api.postResMp('/land-records/getHalkas', { distid: id.distid, tehid: id.tehid })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getVillage(id) {
  //   return this.api.postResMp('/land-records/getVillages', { distid: id.distid, tehid: id.tehid, halkaid: id.halkaid, })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getKhasraDetails(lgdcode) {
  //   return this.api.postResMpWebhook('/webhook/getKhasraList', { lgdcode })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getKhasraLandDetails(data) {
  //   return this.api.postResMpWebhook('/webhook/getLandRecords', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getKhasraOwnerDetails(data) {
  //   return this.api.postResMp('/land-records/getKhasraOwnerDetails', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getBREStatus(data) {
  //   return this.api.postResMp('/land-records/getBreStatus', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getBREStatusKA(data) {
  //   return this.api.postResMp('/land-records/getBreStatusKA', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // callSatsure(data) {
  //   return this.api.postResMp('/land-records/getSatsureDetails', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getSatsureStatus() {
  //   return this.api.postResMp('/land-records/satSureStatus', { appId: sessionStorage.getItem('mpApplicantId') })
  //     .map(res => {
  //       return res;
  //     });
  // }


  // getKhasraLandOwnerDetails(data) {
  //   return this.api.postResMp('/land-records/getKhasraLandOwnerDetails', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getCropsList(data) {
  //   return this.api.postResMp('/users/getCropList', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getLongTermCropsListKA() {
  //   return this.api.postResMp('/users/getlngtrmcrp', { "crop_term": "long term" })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getLoanAmount(data) {
  //   return this.api.postResMp('/users/getProposedLoanAmount', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // submitMpData(data) {
  //   return this.api.postResMp('/users/updatemploandata', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getLoanReferenceNo(id) {
  //   return this.api.postResMp('/users/getreferenceNo', { appId: id })
  //     .map(res => {
  //       return res;
  //     });
  // }



  // callExperian(count, env) {
  //   return this.api.postResMp('/score/getcreditdata', { appId: sessionStorage.getItem('mpApplicantId'), "state": "MP", type: count, env })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // callExperianKA(count, env) {
  //   return this.api.postResMp('/score/getcreditdata', { appId: sessionStorage.getItem('mpApplicantId'), "state": "KA", type: count, env })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // callCFD(cfddata) {
  //   return this.api.postResMpWebhook('/webhook/checkForCFD', { pan_no: cfddata.pan_no, appId: cfddata.appId })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getOverAllStatus(appId) {
  //   return this.api.postRes('/users/getOverallStatus', { appId: appId })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // expiryLinkpost2(fileName) {

  //   return this.api.postRes('/users/getSignedUrl', { 'fileName': fileName, 'type': 1 })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getApplicantID() {
  //   return localStorage.getItem('applicantId');
  // }

  // getCustId() {
  //   return localStorage.getItem('wl-custId');
  // }

  // getAdminID() {
  //   return localStorage.getItem('wl-admin-id');
  // }

  // getEverythingReport(id) {
  //   return this.api.newGet(`/applicants/${id}`);
  // }



  // getProfile() {
  //   return this.api.newGet(`${this.baseUrl}/profile`).map(profile => {
  //     console.log("profile app " + JSON.stringify(profile))
  //     // profile.img = (profile && profile.img) ? ENV.imageUrl.slice(0, -1) + profile.img : null;
  //     return profile;
  //   });
  // }

  // updateProfile(profile) {
  //   return this.api.put(`${this.baseUrl}/profile`, profile);
  // }

  // uploadProfilePic(fd: FormData) {
  //   fd.append('applicantId', this.applicantId);
  //   const file = fd.get('file');

  //   fd.delete('file');
  //   fd.set('file', file);

  //   console.log(fd.getAll('file'))

  //   return this.api.newPost(`/users/profileUpload`, fd).map(result => {
  //     return result;
  //   });
  // }

  // getVerificationStatus() {
  //   return this.api.newGet(`/users/validation/${this.applicantId}/history`);
  // }

  // getEverything() {
  //   return this.api.newGet(this.baseUrl);
  // }

  // getCoApplicantData(coapp) {
  //   return this.api.newGet(`/applicants/` + coapp);
  // }

  // getReport() {
  //   // return this.api.newGet(`/pdf/${this.applicantId}`);pdf/getpdftestubi/61b49e1323e6183600bc0679
  //   // return this.api.newGet(`/pdf/getpdf/${this.custId}`);getpdftestubiv1
  //   // return this.api.newGet(`/pdf/getpdftestubi/${this.applicantId}`);
  //   return this.api.newGet(`/pdf/getpdftestubiv1/${this.applicantId}`);


  // }

  // getConsentReport() {
  //   // return this.api.newGet(`/pdf/${this.applicantId}`);pdf/getpdftestubi/61b49e1323e6183600bc0679
  //   // return this.api.newGet(`/pdf/getpdf/${this.custId}`);
  //   return this.api.newGet(`/pdf/getpdfconsentformubi/${this.applicantId}`);

  // }


  // getAadharReport() {
  //   return this.api.newGet(`/aadhaar-applicants/pdf/${this.applicantId}`);
  // }

  // getLoans() {
  //   return this.api.newGet(`${this.baseUrl}/loans`);
  // }

  // share(share) {
  //   return this.api.newPost(`/pdf/shareLoanKey`, share);
  // }

  // uploadDocument(fd: FormData, docType: string) {
  //   return this.api.newPost(`/users/upload/${this.applicantId}/${docType}`, fd).map(result => {
  //     console.log('Document Upload Res Service: ', result);
  //     result = ENV.imageUrl + result;
  //     return result;
  //   })
  // }

  // updateFormData(bankName, data) {
  //   const appData = {
  //     appData: data
  //   };

  //   console.log("appData " + appData);
  //   return this.api.newPost(`/loan-app`, { applicantId: this.applicantId, bankName: bankName, appData: data }).map(res => {
  //     console.log('Update Form Data : ', res);

  //     return res;
  //   })
  // }

  // getFormData(bankName) {
  //   return this.api.newGet(`/loan-app/getAppData?applicantId=` + this.applicantId + '&bankName=' + bankName);
  //   // return this.api.newGet(`/loan-app/getAppData?applicantId=`+ "5ac76a235c578b12c8e55b85" + '&bankName=' + 'axis');
  //   // .map(res => {"5ac76a235c578b12c8e55b85"
  //   // console.log('Update Form Data : ', res);

  //   // return res;
  //   // })
  // }

  // sanitization(name) {
  //   const payload = { applicantId: this.applicantId, name1: name }

  //   return this.api.sanpost('matchName', payload).map(res => {
  //     console.log('Match Name: ', res)
  //     return res;
  //   })
  // }

  // matchPan(pan) {
  //   const payload = { applicantId: this.applicantId, pan1: pan }

  //   return this.api.post('matchPan', payload).map(res => {
  //     console.log('Match PAN: ', res);
  //     return res;
  //   })
  // }

  // getAutofill() {
  //   return this.api.newPost(`/users/autofill`, { applicantId: this.applicantId }).map(autofill => {
  //     console.log('Autofill Service: ', autofill);
  //     if (autofill.gender == 'M') { autofill.gender = 'Male' }
  //     else if (autofill.gender == 'F') { autofill.gender = 'Female' }
  //     return {
  //       basicAuto: {
  //         name: autofill.Name,
  //         fatherName: autofill.fatherName,
  //         gender: autofill.gender,
  //         mobileNo: autofill.ApplicantMobileNo
  //       },
  //       creditAuto: {
  //         firstName: autofill.panName,
  //         gender: autofill.gender,
  //         dateOfBirth: autofill.aadhaarDob,
  //         flatno: autofill.address && autofill.address.substring(0, 40),
  //         pan: autofill.panNo,
  //         pincode: autofill.aadhaarPinCode,
  //         email: autofill.email,
  //         city: autofill.city,
  //         // state: autofill.state,
  //       }
  //     }
  //   })
  // }

  // getCreditHistory() {
  //   return this.api.newGet(`${this.baseUrl}/creditReport`);
  // }

  // convertToPdf(htmlObj) {
  //   return this.api.pdfPost('/ekyc/convertbase64', htmlObj).map(res => {
  //     console.log("pdf print " + res)
  //     return res;
  //   });
  // }

  // esignDoc(payload) {
  //   return this.api.eSignPost('', payload).map(res => {
  //     console.log("raw json in app " + res);
  //     return res;
  //   })
  // }

  // getSignedDoc(documentId) {
  //   return this.api.docGet(`/sr/details?documentId=` + documentId + '&verificationDetails=false').map(res => {
  //     console.log("api res " + res);
  //     return res;
  //   })
  // }

  // uploadPdfToS3(files, bankName, formType) {

  //   var fd = new FormData();
  //   fd.append('base64Stream', files);
  //   fd.append('applicantId', this.applicantId);
  //   fd.append('formType', formType);
  //   fd.append('bankName', bankName);
  //   // const pdfObj = {
  //   //   base64Stream : files,
  //   //   applicantId : this.applicantId,
  //   //   formType : formType,
  //   //   bankName : bankName
  //   // }

  //   return this.api.newPost(`/ekyc/uploadFormToS3`, fd).map(res => {
  //     return res;
  //   });
  // }

  // getAccessToken() {

  //   return this.api.postRes('/users/getAccessToken', { applicantId: this.applicantId })
  //     .map(res => {
  //       return res
  //     });
  // }

  // getDigidata() {

  //   return this.api.postRes('/users/getDigidata', { applicantId: this.applicantId })
  //     .map(res => {
  //       return res
  //     });
  // }

  // getUserInfo(code) {
  //   var authObj = {
  //     appId: this.applicantId,
  //     clientId: ENV.digilocker.clientId,
  //     code: code,
  //     clientSecret: ENV.digilocker.clientSecret,
  //     redirectUri: ENV.digilocker.redirect_Uri
  //   }
  //   return this.api.postRes('/users/accessToken', authObj)
  //     .map(res => {
  //       return res
  //     });
  // }

  // logout(token, auth) {
  //   return this.api.postRes('/users/revokeToken', { token: token, auth: auth })
  //     .map(res => {
  //       return res
  //     });
  // }

  // getIssueDocuments(token) {
  //   return this.api.postRes('/users/getissueddoc', { token: token })
  //     .map(res => {
  //       return res
  //     });

  // }

  // getFile(fileUri, token) {
  //   return this.api.postDigi('/users/filefromuri', { uri: fileUri, token: token })
  //     .map(res => {
  //       // return res
  //       console.log("responseeee " + JSON.stringify(res));
  //       return res;
  //     });
  // }

  // updateDigiData(path, data, type) {

  //   console.log("update digi data in applicant service");

  //   if (type === "data") {
  //     this.dataObj = {
  //       applicantId: this.applicantId,
  //       digidata: data,
  //       type: type,
  //       index: null,
  //       accesspath: "",
  //     };
  //   } else if (type === "access_path") {
  //     this.dataObj = {
  //       applicantId: this.applicantId,
  //       digidata: data,
  //       type: type,
  //       index: 0,
  //       accesspath: path,
  //     };
  //   }

  //   return this.api.postDigi('/users/updatedigidata', this.dataObj)
  //     .map(res => {
  //       // return res
  //       console.log("response in Applicant Service " + JSON.stringify(res));
  //       return res;
  //     });
  // }

  // registerDigi(aadharData) {

  //   return this.api.postDigiUrlEncoded('/users/registerDigilocker', aadharData)
  //     .map(res => {
  //       // return res
  //       console.log("response in Applicant Service " + JSON.stringify(res));
  //       return res;
  //     });

  // }

  // verifydigimob(aadharData) {
  //   return this.api.postDigiUrlEncoded('/users/verifyDigiMob', aadharData)
  //     .map(res => {
  //       // return res
  //       console.log("response in Applicant Service " + JSON.stringify(res));
  //       return res;
  //     });
  // }

  // getAppliType() {
  //   return this.api.postRes('/users/getCustType', { appId: this.applicantId })
  //     .map(res => {
  //       return res;
  //     });

  // }

  // consentformSubmit(data) {
  //   data.applicantId = this.getApplicantID();
  //   data.consent_updated_by = this.getAdminID();
  //   return this.api.postRes('/users/updateApplicantData', data)
  //     .map(res => {
  //       return res;
  //     });
  // }


  // consentformannexureSubmit(data) {
  //   data.applicantId = this.getApplicantID();
  //   return this.api.postRes('/users/updateApplicantFinalAnnexureData', data)
  //     .map(res => {
  //       return res;
  //     });
  // }


  // consentyesornoForm(data) {
  //   return this.api.postRes('/users/ackloanrenewalsms', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getconsentformdata(data) {
  //   return this.api.postRes('/users/getApplicantLoanData', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getApplicantLoanFinalData(data) {
  //   return this.api.postRes('/users/getApplicantLoanFinalData', data)
  //     .map(res => {
  //       return res;
  //     });
  // }

  // getBreStatusMPOverall(appId) {
  //   return this.api.newGet(`/users/getBreStatus?applicantId=${appId}`);
  // }

  // getcropsdata() {
  //   return this.api.newGet('/crops/getcropslist');
  // }

  // getcropsdataRenewalConsent(data) {
  //   return this.api.newGet(`/crops/getcropslist?distId=${data.distId}`);
  // }

  // getLandDetails() {
  //   return this.api.newGet(this.getBaseUrl);
  // }

  // getConsentStatus(data) {
  //   return this.api.postRes('/users/consentStatus', { applicantId: data })
  //     .map(res => {
  //       return res;
  //     });
  // }

  // consentOtp(data) {
  //   return this.api.postRes(`/users/otp`, data)
  //     .map(resp => {
  //       console.log("consent otp resp in service " + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // verifyConsentOtp(data) {
  //   return this.api.postRes(`/users/verifyotp`, data)
  //     .map(resp => {
  //       console.log("verify consent otp resp in service " + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // satSureLogin() {
  //   return this.api.postSatSureLogin(`/webhook/satSureLogin`, {
  //     "applicantId": localStorage.getItem('applicantId')
  //   }).map(resp => {
  //     console.log("Login satsure" + JSON.stringify(resp));
  //     return resp;
  //   })
  // }

  // satSureFarm(data) {
  //   return this.api.postSatSureFarm(`/webhook/seasonFarm`, {
  //     "farm_data": JSON.parse(data),
  //     "userId": localStorage.getItem('satId'), "token": localStorage.getItem('satTkn')
  //   })
  //     .map(resp => {
  //       console.log("Satsure farm" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // satSureFarmSave(data) {
  //   return this.api.postRes(`/users/satSure`, { appId: localStorage.getItem('applicantId'), farmId: data.farmId, data: data, type: 'input' })
  //     .map(resp => {
  //       console.log("Satsure farm save" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // // getCropsSatSure(){ //old geomapping report crops
  // //   return this.api.satSureGet(`/webhook/masterDataCrop?token=${localStorage.getItem('satTkn')}`)
  // //   .map(resp => {
  // //     console.log("Satsure dropdown list" + JSON.stringify(resp));
  // //     return resp;
  // //   });
  // // }

  // getSatsureReportPdf() {
  //   return this.api.satSurePdfGet(`/users/satSure`);
  // }

  // sendSmsManual(appId) {
  //   return this.api.postRes(`/users/sendrenewalsms`, { applicantId: appId })
  //     .map(resp => {
  //       console.log("Sent SMS" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // getDPnDM() {
  //   return this.api.postResMpWebhookNesl(`/v1/users/getDocs`, { appId: sessionStorage.getItem('mpApplicantId') })
  //     .map(resp => {
  //       console.log("Sent SMS" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // postSelectedBranch(value) {
  //   console.log(value.branch_name)
  //   return this.api.postResMpWebhookNesl(`/v1/users/updateBranchDetails`, { appId: sessionStorage.getItem('mpApplicantId'), branch_code: value.branch_code, branch_name: value.branch_name })
  //     .map(resp => {
  //       console.log("Sent SMS" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // getBranches(villageData: any) {
  //   return this.api.getBranchPost(`/users/getBranchList`, villageData)
  //     .map(resp => {
  //       console.log("branches" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // toggleStatus(appId, type, status) {
  //   return this.api.postRes(`/users/updateconsentStatus`, { applicantId: appId, updatetype: type, data: status })
  //     .map(resp => {
  //       console.log("Status change" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // encryptDataNesl(data) {
  //   return this.api.postResMpWebhookNesl(`/v1/nesl/neslDataDecEnc`, { data, type: "enc", appId: sessionStorage.getItem('mpApplicantId') })
  //     .map(resp => {
  //       console.log("NESL Enc" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }



  // getSignData(data) {

  //   return this.api.postResMpWebhookNesl(`/v1/nesl/nesleSignData`, { txnID: sessionStorage.getItem('txnID') })
  //     .map(resp => {
  //       console.log("pdf from nesl" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // getSignStatus() {
  //   return this.api.postResMpWebhookNesl(`/v1/nesl/neslStatus`, { appId: sessionStorage.getItem('mpApplicantId'), txnID: sessionStorage.getItem('txnID') })
  //     .map(resp => {
  //       console.log("NESL status" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // createAccount() {
  //   return this.api.postResMpWebhookNesl(`/v1/account/create`, { applicantId: sessionStorage.getItem('mpApplicantId') })
  //     .map(resp => {
  //       console.log("create account resp" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }

  // generateReferenceID(type) {
  //   return this.api.postResMpWebhookNesl(`/v1/nesl/convertimage_new`, { appId: sessionStorage.getItem('mpApplicantId'), docType: type })
  //     .map(resp => {
  //       console.log("create account resp" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }



  // updateDocsData(data) {
  //   // return this.api.postupdateDocsDate('nesl/upDocsData', data)
  //   return this.api.postResMpWebhookNesl(`/v1/nesl/upDocsData`, data)
  //     .map(resp => {
  //       console.log("updateDocsData" + JSON.stringify(resp));
  //       return resp;
  //     })
  // }


  // getLoanDocsToDownLaod() {
  //   return this.api.postResMp('/users/getLoanDocs', { appId: sessionStorage.getItem('mpApplicantId') }).map(resp => {
  //     console.log("updateStatus" + JSON.stringify(resp));
  //     return resp;
  //   })
  // }

  // getIrrigatedLandRec(data) {
  //   return this.api.postResMp('/land-records/getIrrigatedStatus', data).map(resp => {
  //     return resp;
  //   });
  // }

  // deleteFailureLandRecords(data) {
  //   return this.api.postResMp('/loansrecord/dellanddetails', data).map(resp => {
  //     return resp;
  //   });
  // }

  // //bypass stuf below

  // verifyIDTemp(data) {
  //   return this.api.postLocal('http://localhost:8081/webhook/kccaccountfetch_cbs', data)
  //     .map(res => {
  //       return res;
  //     });
  // }



  // getBypassDataLocal(custId) {
  //   return this.api.postLocal('http://localhost:8081/webhook/demo-metadata', { custId })
  // }

  // getBypassData(custId) {
  //   let url;
  //   if (ENV.envUrl == "dev") {
  //     return this.api.postLocal('http://localhost:8081/webhook/demo-metadata', { custId })
  //   } else {
  //     return this.api.postResMpWebhook('/webhook/demo-metadata', { custId })
  //   }

  // }

}
