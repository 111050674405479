<app-spinner-overlay [showSpinner]="showSpinner"></app-spinner-overlay>
<app-spinner-overlay [showSpinner]="showSpinnerWithMessage" [subText]="subText"></app-spinner-overlay>
<div class="loading-overlay" *ngIf="loading">
  <div class="spinner"></div>
</div>
<div style="background-position: center;
background-repeat: no-repeat;
background-size: cover;
height: 100vh; padding-top: 3rem;">

  <div>
    <div class="blurred">

    </div>
    <div>
      <mat-toolbar color="info"
        style="height: 115px; position: fixed;margin-top: 1.5rem; padding-left: 0px; background: white; z-index: 999;width: 100%; box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);"
        *ngIf="showStepper">
        <wl-stepper style="width: 100%;" [stepper]="stepper" [langfrbi]="language"></wl-stepper>
      </mat-toolbar>
    </div>

  </div>

  <!-- <wl-fresh-or-resume (value)="freshOrResume($event)" *ngIf="showFreshOrResumeCard"></wl-fresh-or-resume>  -->

  <div *ngIf="showForm" style="margin-top: 9.5rem;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12" style="text-align: right;padding:0"
      *ngIf="referenceName">
      <p style=" text-align:right ; top: 0px; color: #00569d; font-weight: bold; margin-left: 0px;">
        Assisted by {{journeyType == "BC"?"BC":""}}: {{referenceName}}</p>
    </div>
    <!-- <wl-card> -->
    <form [formGroup]="detailsForm" *ngIf="showForm">

      <!-- mobileNo NTB -->
      <div class="cardStyles">
        <app-mobile-verification (value)="mobileVerificationDone($event)" *ngIf="showMobileCard"></app-mobile-verification>
      </div>
      <!-- AADHAAR  showAadhaarField-->
      <div class="cardStyles">
        <app-aadhaar-verification (value)="aadhaarVerificationDone($event)" *ngIf="showAadhaarCard" [aadhaarInfo]="aadhaarInfo">
        
          
         <!-- <div style="width: 100%; font-weight: bold;font-size: 1.0rem; text-align: center;">
              <button class="mybutton" (click)="openAadharReport()">View Aadhar Report</button>
            </div> -->
          </app-aadhaar-verification> 
      </div>
      <!-- showPanOrVoterIdField -->
      <div class="cardStyles">
        <app-pan-vot-verification (value)="panVotSuccess($event)" *ngIf="showPanVotCard" [panOrVoterInfo]="panOrVoterInfo"></app-pan-vot-verification>

      </div>

   <!--<div style="width: 100%; font-weight: bold;font-size: 1.0rem; text-align: center;" *ngIf="showPanVotCard">
        <button class="mybutton" (click)="openGst()">View GST Report</button>
      </div>-->

      <div class="cardStyles" *ngIf="showCreditScoreCard">
        <wl-card heading=" {{LOCAL_LANGUAGE.Credit_Score}} - Credit Score" [subheading1]="showPanOrVoterTick">
          <div style="width: 100%; font-weight: bold;font-size: 1.0rem; text-align: center;">
            Credit Score: {{creditScore}}
          </div>
          <br>
          <div style="width: 100%; font-weight: bold;font-size: 1.0rem; text-align: center;">
            <button class="mybutton" (click)="openCreditReport()">View Credit Report</button>
          </div>
        </wl-card>
      </div>

    </form>

    <!-- </wl-card> -->
  </div>


  <div class="cardStyles" *ngIf="showForm">
    <land-records-ap *ngIf="showAndhraCard" (value)="landRecordsDone($event)" [landRecordsList]="landRecordsList" [isResume]="isResume"></land-records-ap>
  </div>

  <div class="cardStyles" *ngIf="showForm">
    <app-land-records-tel *ngIf="showTelenganaCard" [landRecordsList]="landRecordsList" (value)="landRecordsDone($event)"  [isResume]="isResume"></app-land-records-tel>
  </div>

    <div class="cardStyles" *ngIf="showForm">
    <app-land-records-ka *ngIf="showKACard" [landRecordsList]="landRecordsList" (value)="landRecordsDone($event)" [isResume]="isResume" [state]="state"></app-land-records-ka>
  </div>

  <!-- showCroppingPattern-->
  <div class="cardStyles" *ngIf="showForm">
    <app-cropping-pattern *ngIf="showCropCard" (value)="loanAmountCalculationDone($event, false)" [cropList]="cropList"></app-cropping-pattern>
  </div>

  <div class="cardStyles" *ngIf="showForm">
    <app-loan-amount *ngIf="showLoanAmtCard" [loanAmountInfo]="loanAmountInfo" (value)="loanAmountSubmitted($event)"></app-loan-amount>
  </div>

  <div class="cardStyles" *ngIf="showForm">
    <app-loan-successful *ngIf="showLoanSuccessfulCard" [finalLoanAmounts]="finalLoanAmounts"></app-loan-successful>
  </div>



</div>


  <app-keyfact></app-keyfact>
  

<!-- <div class="statusDiv" [ngStyle]="{'display':shouldStatusesBeDisplay}">
  <p>Mob:{{mobileVerificationStatus}}</p>
  <p>Aadhaar:{{aadhaarVerificationStatus}}</p>
  <p>PanVot:{{panVotVerificationStatus}}</p>
</div> -->
