<!-- <p fxLayout="row" fxLayoutAlign="start center">
  <label fxFlex="30" fxShow fxHide.xs>{{label}}</label>
  <md-input-container fxFlex.xs="100" fxFlex.gt-xs="50">
    <input matInput [type]="type" [placeholder]="placeholder" [readonly]="readonly" [(ngModel)]="value" name="value" [disabled]="disabled" [required]="required"> -->
    <!-- <input matInput [type]="type" [placeholder]="placeholder" [(ngModel)]="value" name="value"  [required]="required"> -->
  <!-- </md-input-container>
</p> -->

<!-- <div class="container">
  <div class="row">
     <div class="col-12"  > -->
      <div style="text-align: center; width: 100%;">
        <mat-form-field  >
          <input matInput [type]="type" [style]="style"   [placeholder]="placeholder" [readonly]="readonly" [(ngModel)]="value" name="value" [disabled]="disabled" [required]="required">
         <!-- <input matInput [type]="type" [placeholder]="placeholder" [(ngModel)]="value" name="value"  [required]="required"> -->
       </mat-form-field>
      </div>

    <!-- </div>
  </div>
</div> -->

<!-- <div class="d-flex  mb-2">
  <div class="p-2 bg-info flex-fill">{{label}}</div>
  <div class="p-2 bg-warning flex-fill">   <md-input-container> <input matInput [type]="type" [placeholder]="placeholder" [readonly]="readonly" [(ngModel)]="value" name="value" [disabled]="disabled" [required]="required"> -->
    <!-- <input matInput [type]="type" [placeholder]="placeholder" [(ngModel)]="value" name="value"  [required]="required"> -->
  <!-- </md-input-container></div> -->
  <!-- <div class="p-2 bg-primary">Flex item 3</div> -->
<!-- </div> -->

<!-- <p class="d-flex flex-row " >
  <label >{{label}}</label>
  <md-input-container>
    <input matInput [type]="type" [placeholder]="placeholder" [readonly]="readonly" [(ngModel)]="value" name="value" [disabled]="disabled" [required]="required"> -->
    <!-- <input matInput [type]="type" [placeholder]="placeholder" [(ngModel)]="value" name="value"  [required]="required"> -->
  <!-- </md-input-container>
</p> -->
<!-- <p fxLayout="row" fxLayoutAlign="start center">
  <label fxFlex="30" fxShow fxHide.xs>{{label}}</label>
  <md-input-container fxFlex.xs="100" fxFlex.gt-xs="50">
    <input matInput [type]="type" [placeholder]="placeholder" [formControl]="value" [disabled]="disabled">
  </md-input-container>
</p> -->


<!-- <link rel="stylesheet" href="../../../assets/bootstrap-grid.css"> -->
