import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OpenDialogYesOrNo } from '../dialog-yes-or-no/dialog-yes-or-no.component';
import { MatDialog } from '@angular/material/dialog';
import * as constants from "../../constant";
import * as HindiText from "../../mp-popup-message";
import { ApplicantService } from 'src/app/applicant.service';
import { forkJoin } from 'rxjs';
import { LanguageService } from 'src/app/language.service';
import { fadeInOut } from '../animation';
import { MatSnackBar } from '@angular/material/snack-bar';
import { duration } from 'moment';

@Component({
  selector: 'app-cropping-pattern',
  templateUrl: './cropping-pattern.component.html',
  styleUrls: ['./cropping-pattern.component.scss'],
  animations: [fadeInOut]
})
export class CroppingPatternComponent {
  multiCropDetailsForm: FormGroup;
  sameValueDisable: boolean = false;
  disableSubmitCrops: boolean = false;
  sameValueDisableKharif: boolean = false;
  sameValueDisableRabi: boolean = false;
  sameValueDisableSummer: boolean = false;
  isSummerSelected: boolean;
  isRabiSelected: boolean;
  isKharifSelected: boolean;
  landDetailsArray = [];
  cropSelectionTick: boolean;
  estimatedLoanAmount: any;
  showSpinner: boolean;
  showButtons: boolean;
  showLoanAmountTick: boolean;
  disableSubmitButtonForever: boolean;
  tehsilDisabled: boolean;
  villageDisabled: boolean;
  khasraDisabled: boolean;
  halkaDisabled: boolean;
  districtDisabled: boolean;
  croppingPatternDisabled: boolean;
  differentLoanAmountDisabled: boolean;
  DisabledFlagValue: string;
  isFetchButtonDisabled: boolean;
  colU: any;
  showCustomerLoanAmount: any;
  subText: string;
  showSpinnerWithMessage: boolean;
  isNTB: any;
  stepper: any;
  disableEsignButton: boolean;
  showCroppingPattern: boolean = true;
  kharifCropListNew2 = [
    // { cropName: "Lemon" },
    // { cropName: "Mango" },
    // { cropName: "Wheat" }
  ]
  rabiCropListNew2 = [
    // { cropName: "Lemon" },
    // { cropName: "Mango" },
    // { cropName: "Wheat" }
  ]
  summerCropListNew2 = [
    // { cropName: "Lemon" },
    // { cropName: "Mango" },
    // { cropName: "Wheat" }
  ]
  @ViewChild('customerLoanAmountCardLayout') private customerLoanAmountCardLayout: ElementRef;
  summerDisabled: boolean;
  rabiDisabled: boolean;
  kharifDisabled;
  LOCAL_LANGUAGE: any;
  @Output() value = new EventEmitter();
  @Input() cropList:any;
  @Input() state:string;
  isResume: boolean = false;



  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private applicant: ApplicantService,
    private snackbar: MatSnackBar,
    private languageservice: LanguageService,
    private changeDetection:ChangeDetectorRef
    ) {
    this.multiCropDetailsForm = this.fb.group({
      // acressumnum: [""],
      loanAmtFarmer: ["", Validators.required],
      estimatedLoanAmt: ["", Validators.required],
      loanAmountSubmissionCheckOne: ["", Validators.required],
      loanAmountSubmissionCheckTwo: ["", Validators.required],
      cropArrayKharif: this.fb.array([]),
      cropArrayRabi: this.fb.array([]),
      cropArraySummer: this.fb.array([]),
      // loanAmtDiffYes:[''],
      // loanAmtDiffNo:[''],
      loanAmtDiff: [""],
    });

  }

  ngOnInit() {
    let language = sessionStorage.getItem('lang')

    this.LOCAL_LANGUAGE = this.languageservice.getConstants(language)

    let data = {}
    console.log(sessionStorage.getItem('selectedDistrict'))
    debugger
    data['district'] = this.getDistrictForCrops();
    if(data['district']){
      this.showSpinner = true;
      this.applicant.getCrops(data).subscribe((resp) => {
        console.log(resp)
        this.showSpinner = false;

        if (resp && resp.success && resp.data) {
          if (resp.data.summer) {
            this.summerCropListNew2 = resp.data.summer
          } else {
            this.summerCropListNew2 = [];
          }
          if (resp.data.kharif) {
            this.kharifCropListNew2 = resp.data.kharif
          } else {
            this.kharifCropListNew2 = []
          }
          if (resp.data.rabi) {
            this.rabiCropListNew2 = resp.data.rabi
          } else {
            this.rabiCropListNew2 = []
          }
          let text = ""
          let count = 0;
          if(!this.summerCropListNew2.length){
            text = text+" summer crops";
            count++
          }
          if(!this.kharifCropListNew2.length){
            text = text+" kharif crops";
            count++
          }
          if(!this.rabiCropListNew2.length){
            text = text+" rabi crops";
            count++
          }
          if(count==3){
            this.snackbar.open(`No crops found`,'OK',{duration:5000})
          }else{
            this.snackbar.open(`No ${text} found`,'OK',{duration:5000})
          }

          let language = sessionStorage.getItem('lang')

          this.LOCAL_LANGUAGE = this.languageservice.getConstants(language)
          this.changeDetection.detectChanges()

        }else{
          this.snackbar.open("Not able to fetch crops","OK",{duration:5000})
        }
      },(err)=>{
        this.snackbar.open("Error while fetching crops","OK",{duration:5000})
      })
    }else{
      this.showSpinner = false;
      this.snackbar.open("No district name found to fetch crops","OK",{duration:5000})
    }


  }

  ngAfterContentInit(){
    let language = sessionStorage.getItem('lang')

    this.LOCAL_LANGUAGE = this.languageservice.getConstants(language)
    this.changeDetection.detectChanges()
  }

  getDistrictForCrops(){
    //let sessionState = sessionStorage.getItem('state');
     let sessionState = "Andhra Pradesh";
    let selectedDistrict = JSON.parse(sessionStorage.getItem('selectedDistrict')).name;
    if(this.state && this.state == constants.STATE_LIST.KA){
      let district = selectedDistrict;
      return (district.toLowerCase())
    }else if(this.state && this.state == constants.STATE_LIST.AP){
      let district = selectedDistrict.split('-')[0].trim();
      return (district.toLowerCase())
    }else if( sessionState && sessionState.toLowerCase() == constants.STATE_LIST.KA.toLowerCase()){
      let district = selectedDistrict;
      return (district.toLowerCase())
    }else if(sessionState && sessionState.toLowerCase() == constants.STATE_LIST.AP.toLowerCase()){
      let district = selectedDistrict.split('-')[0].trim();
      return (district.toLowerCase())
    }else if(sessionState && sessionState.toLowerCase() == constants.STATE_LIST.TEL.toLowerCase()){
      let district = selectedDistrict;
      return (district.toLowerCase())
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cropList']) {
      console.log('Data updated:', changes['cropList']);
      console.log(this.cropList)
      this.isResume = true
      this.updateCropsTable()
    }
  }

  updateCropsTable(){
    // if(this.cropList && this.cropList.isResume){
    //   this.isResume = true;
    // }
  }

  cropArrayKharif(): FormArray {
    return this.multiCropDetailsForm.get("cropArrayKharif") as FormArray;
  }
  cropArrayRabi(): FormArray {
    return this.multiCropDetailsForm.get("cropArrayRabi") as FormArray;
  }

  cropArraySummer(): FormArray {
    return this.multiCropDetailsForm.get("cropArraySummer") as FormArray;
  }

  newCropArrayKharif(): FormGroup {
    return this.fb.group({
      kharif: ["", Validators.required],
    });
  }

  newCropArrayRabi(): FormGroup {
    return this.fb.group({
      rabi: ["", Validators.required],
    });
  }

  newCropArraySummer(): FormGroup {
    return this.fb.group({
      summer: ["", Validators.required],
    });
  }

  addKharifCrop() {

    console.log(this.cropArrayKharif().value.length);
    let len_of_kharif = this.cropArrayKharif().value.length;
    if (len_of_kharif == 0) {
      this.cropArrayKharif().push(this.newCropArrayKharif());
    } else if (len_of_kharif == 1) {
      this.cropArrayKharif().push(this.newCropArrayKharif());
    } else if (len_of_kharif == 2) {
      // this.snackbar.open('Only two crops allowed per season', 'OK', { duration: 3000 })
      const dialogRef = this.showDialog(
        "auto",
        "auto",
        // HindiText.ONLY_TWO_CROPS_ALLOWD.hindi,
        "",
        HindiText.ONLY_TWO_CROPS_ALLOWD.english,
        "OK",
        ""
      );
    }
  }

  addRabiCrop() {
    console.log(this.cropArrayRabi().value.length);
    let len_of_rabi = this.cropArrayRabi().value.length;
    if (len_of_rabi == 0) {
      this.cropArrayRabi().push(this.newCropArrayRabi());
    } else if (len_of_rabi == 1) {
      this.cropArrayRabi().push(this.newCropArrayRabi());
    } else if (len_of_rabi == 2) {
      // this.snackbar.open('Only two crops allowed per season', 'OK', { duration: 3000 })
      const dialogRef = this.showDialog(
        "auto",
        "auto",
        // HindiText.ONLY_TWO_CROPS_ALLOWD.hindi,
        "",
        HindiText.ONLY_TWO_CROPS_ALLOWD.english,
        "OK",
        ""
      );
    }
  }

  addSummerCrop() {
    console.log(this.cropArraySummer().value.length);
    let len_of_summer = this.cropArraySummer().value.length;
    if (len_of_summer == 0) {
      this.cropArraySummer().push(this.newCropArraySummer());
    } else if (len_of_summer == 1) {
      this.cropArraySummer().push(this.newCropArraySummer());
    } else if (len_of_summer == 2) {
      // this.snackbar.open('Only two crops allowed per season', 'OK', { duration: 3000 })
      const dialogRef = this.showDialog(
        "auto",
        "auto",
        // HindiText.ONLY_TWO_CROPS_ALLOWD.hindi,
        "",
        HindiText.ONLY_TWO_CROPS_ALLOWD.english,
        "OK",
        ""
      );
    }
  }

  removeRowKharif(i) {
    this.cropArrayKharif().removeAt(i);
    let formData = this.multiCropDetailsForm.value;
    let kharif_list = this.getKharifList(formData);
    let rabi_list = this.getRabiList(formData);
    let data = {
      distId: sessionStorage.getItem("selectedDist"),
      kharif_list,
      rabi_list,
      landarea: sessionStorage.getItem("landArea"),
    };

  }
  removeRowRabi(i) {
    this.cropArrayRabi().removeAt(i);
    let formData = this.multiCropDetailsForm.value;
    let kharif_list = this.getKharifList(formData);
    let rabi_list = this.getRabiList(formData);
    let data = {
      distId: sessionStorage.getItem("selectedDist"),
      kharif_list,
      rabi_list,
      landarea: sessionStorage.getItem("landArea"),
    };

  }

  removeRowSummer(i) {
    this.cropArraySummer().removeAt(i);
    let formData = this.multiCropDetailsForm.value;
    let kharif_list = this.getKharifList(formData);
    let rabi_list = this.getRabiList(formData);
    let summer_list = this.getSummerList(formData);
    let data = {
      distId: sessionStorage.getItem("selectedDist"),
      kharif_list,
      rabi_list,
      summer_list,
      landarea: sessionStorage.getItem("landArea"),
    };

  }

  kharifSelectedEvent(event, i) {
    console.log("kharifSelectedEvent--->", event);
    // console.log(JSON.stringify(this.multiCropDetailsForm.value));
    const myForm = (<FormArray>(
      this.multiCropDetailsForm.get("cropArrayKharif")
    )).at(i);
    // debugger
    // myForm.patchValue({ kharifId: event.id })
    this.khasraDisabled = false;
    let formData = this.multiCropDetailsForm.value;
    let kharif_list = this.getKharifList(formData);
    let rabi_list = this.getRabiList(formData);
    let summer_list = this.getSummerList(formData);
    this.isKharifSelected = true;


    console.log("kharif_list--->", kharif_list);
    const fruits = ["promegranate", "lemon"];
    if (fruits.includes(kharif_list[0].toLowerCase())) {

      let dialogLongTerm = this.showDialogWithTwoButton(
        "auto",
        "auto",
        // HindiText.Long_TEMR_CROP_ERROR_1.hindi,
        "",
        HindiText.Long_TEMR_CROP_ERROR_1.english,
        "NO",
        "YES"
      );
      dialogLongTerm.afterClosed().subscribe((res) => {
        if (res == "dontClose") {
          this.croppingPatternDisabled = true;
          this.isRabiSelected = true;
          this.isSummerSelected = true;
          this.rabiDisabled
          this.summerDisabled
          if (
            kharif_list.length == 0 &&
            rabi_list.length == 0 &&
            summer_list.length == 0
          ) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        } else {
          this.resetKharif(kharif_list);
          if (
            kharif_list.length == 0 &&
            rabi_list.length == 0 &&
            summer_list.length == 0
          ) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        }
      });

    } else if ( kharif_list[1] && fruits.includes(kharif_list[1].toLowerCase())) {

      let dialogLongTerm = this.showDialogWithTwoButton(
        "auto",
        "auto",
        // HindiText.Long_TEMR_CROP_ERROR_2.hindi,
        "",
        HindiText.Long_TEMR_CROP_ERROR_2.english,
        "NO",
        "YES"
      );
      dialogLongTerm.afterClosed().subscribe((res) => {
        if (res == "dontClose") {
          this.croppingPatternDisabled = true;
          this.isRabiSelected = true;
          this.isSummerSelected = true;
          this.rabiDisabled
          this.summerDisabled
          this.removeRowKharif(0);
          if (
            kharif_list.length == 0 &&
            rabi_list.length == 0 &&
            summer_list.length == 0
          ) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        } else {
          this.resetKharif(kharif_list);
          if (kharif_list.length == 0 && rabi_list.length == 0 &&
            summer_list.length == 0) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        }
      });
    }
    if(kharif_list[0] == kharif_list[1]) {

      let dialogLongTerm = this.showDialog(
        "auto",
        "auto",
        // HindiText.Long_TEMR_CROP_ERROR_2.hindi,
        "",
        HindiText.SELECT_DIFFERENT_CROP.english,
        "OK",
        ""
      );
      dialogLongTerm.afterClosed().subscribe((res) => {
      this.removeRowKharif(1);
       } );

    }
  }

  rabiSelectedEvent(event, i) {
    console.log(event);
    console.log(this.multiCropDetailsForm.value);
    const myForm = (<FormArray>(
      this.multiCropDetailsForm.get("cropArrayRabi")
    )).at(i);
    // myForm.patchValue({ rabiId: event.id })
    this.rabiDisabled = false;
    let formData = this.multiCropDetailsForm.value;
    let kharif_list = this.getKharifList(formData);
    let rabi_list = this.getRabiList(formData);
    let summer_list = this.getSummerList(formData);
    console.log(kharif_list, rabi_list, summer_list);
    this.isRabiSelected = true;

    if (rabi_list[0] == "sugarcane") {
      let dialogLongTerm = this.showDialogWithTwoButton(
        "auto",
        "auto",
        // HindiText.Long_TEMR_CROP_ERROR_2.hindi,
        "",
        HindiText.Long_TEMR_CROP_ERROR_2.english,
        "YES",
        "NO"
      );
      dialogLongTerm.afterClosed().subscribe((res) => {
        if (res == "dontClose") {
          this.croppingPatternDisabled = true;
          this.isKharifSelected = true;
          this.isSummerSelected = true;
          this.resetKharif(kharif_list);
          this.resetSummer(summer_list);
          if (kharif_list.length == 0 && rabi_list.length == 0 &&
            summer_list.length == 0) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        } else {
          this.resetRabi(rabi_list);
          if (kharif_list.length == 0 && rabi_list.length == 0 &&
            summer_list.length == 0) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        }
      });
    } else if (rabi_list[1] == "Sugarcane") {
      let dialogLongTerm = this.showDialogWithTwoButton(
        "auto",
        "auto",
        // HindiText.Long_TEMR_CROP_ERROR_2.hindi,
        "",
        HindiText.Long_TEMR_CROP_ERROR_2.english,
        "YES",
        "NO"
      );
      dialogLongTerm.afterClosed().subscribe((res) => {
        if (res == "dontClose") {
          this.croppingPatternDisabled = true;
          this.isKharifSelected = true;
          this.isSummerSelected = true;
          this.resetKharif(kharif_list);
          this.resetSummer(summer_list);
          this.removeRowRabi(0);
          if (
            kharif_list.length == 0 &&
            rabi_list.length == 0 &&
            summer_list.length == 0
          ) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        } else {
          this.resetRabi(rabi_list);
          if (
            kharif_list.length == 0 &&
            rabi_list.length == 0 &&
            summer_list.length == 0
          ) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        }
      });
    }
    if(rabi_list[0] == rabi_list[1]) {
      let dialogLongTerm = this.showDialog(
        "auto",
        "auto",
        // HindiText.Long_TEMR_CROP_ERROR_2.hindi,
        "",
        HindiText.SELECT_DIFFERENT_CROP.english,
        "OK",
        ""
      );
      dialogLongTerm.afterClosed().subscribe((res) => {
      this.removeRowRabi(1);
       } );
    }
  }

  summerSelectedEvent(event, i) {
    console.log(event);
    console.log(this.multiCropDetailsForm.value);
    const myForm = (<FormArray>(
      this.multiCropDetailsForm.get("cropArraySummer")
    )).at(i);
    // myForm.patchValue({ rabiId: event.id })
    this.summerDisabled = false;
    let formData = this.multiCropDetailsForm.value;
    let kharif_list = this.getKharifList(formData);
    let rabi_list = this.getRabiList(formData);
    let summer_list = this.getSummerList(formData);
    console.log(kharif_list, rabi_list);
    this.isSummerSelected = true;


    const fruits = ["sugarcane", "amla","banana","guava"];
    
    if (summer_list[0] && fruits.includes(summer_list[0].toLowerCase()))  {

      let dialogLongTerm = this.showDialogWithTwoButton(
        "auto",
        "auto",
        // HindiText.Long_TEMR_CROP_ERROR_2.hindi,
        "",
        HindiText.Long_TEMR_CROP_ERROR_2.english,
        "NO",
        "YES"
      );
      dialogLongTerm.afterClosed().subscribe((res) => {
        if (res == "dontClose") {
          this.croppingPatternDisabled = true;
          this.isKharifSelected = true;
          this.isRabiSelected = true;
          this.kharifDisabled
          this.rabiDisabled
          if (
            kharif_list.length == 0 &&
            rabi_list.length == 0 &&
            summer_list.length == 0
          ) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        } else {
          this.resetSummer(summer_list);
          if (
            kharif_list.length == 0 &&
            rabi_list.length == 0 &&
            summer_list.length == 0
          ) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        }
      });

    } else if (summer_list[1] && fruits.includes(summer_list[1].toLowerCase())) {

      let dialogLongTerm = this.showDialogWithTwoButton(
        "auto",
        "auto",
        // HindiText.Long_TEMR_CROP_ERROR_2.hindi,
        "",
        HindiText.Long_TEMR_CROP_ERROR_2.english,
        "NO",
        "YES"
      );
      dialogLongTerm.afterClosed().subscribe((res) => {
        if (res == "dontClose") {
          this.croppingPatternDisabled = true;
          this.isKharifSelected = true;
          this.isRabiSelected = true;
          this.rabiDisabled
          this.kharifDisabled
          this.removeRowSummer(0);
          if (
            kharif_list.length == 0 &&
            rabi_list.length == 0 &&
            summer_list.length == 0
          ) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        } else {
          this.resetSummer(summer_list);
          if (
            kharif_list.length == 0 &&
            rabi_list.length == 0 &&
            summer_list.length == 0
          ) {
            this.isKharifSelected = false;
            this.isRabiSelected = false;
            this.isSummerSelected = false;
          }
        }
      });
    }
    if(summer_list[0] == summer_list[1]) {
      let dialogLongTerm = this.showDialog(
        "auto",
        "auto",
        // HindiText.Long_TEMR_CROP_ERROR_2.hindi,
        "",
        HindiText.SELECT_DIFFERENT_CROP.english,
        "OK",
        ""
      );
      dialogLongTerm.afterClosed().subscribe((res) => {
      this.removeRowSummer(1);
       } );
    }
  }

  resetRabi(rabi_list) {
    this.removeRowRabi(0);
    if (rabi_list.length > 0) {
      this.removeRowRabi(0);
    }
    const dropDownFormRabi = this.fb.group({
      rabi: ["", Validators.required],
    });
    this.cropArrayRabi().push(dropDownFormRabi);
  }

  resetKharif(kharif_list) {
    this.removeRowKharif(0);
    if (kharif_list.length > 0) {
      this.removeRowKharif(0);
    }
    const dropDownForm = this.fb.group({
      kharif: ["", Validators.required],
    });
    this.cropArrayKharif().push(dropDownForm);
  }

  resetSummer(summer_list) {
    this.removeRowSummer(0);
    if (summer_list.length > 0) {
      this.removeRowSummer(0);
    }
    const dropDownForm = this.fb.group({
      summer: ["", Validators.required],
    });
    this.cropArraySummer().push(dropDownForm);
  }

  getProposedLoanAmount(cropsformdata) {
    try{
      let formData = this.multiCropDetailsForm.value;
      console.log(formData)
      let kharif_list = this.getKharifList(formData);
      let rabi_list = this.getRabiList(formData);
      let summer_list = this.getSummerList(formData);

      console.log(sessionStorage.getItem('landArray'))
      // debugger
      let landArray = JSON.parse(sessionStorage.getItem('landArray'))

      let state = sessionStorage.getItem('state');
      let data ;

      let area = this.getLandArea(landArray);

      if(state.toLowerCase() == constants.STATE_LIST.KA.toLowerCase()){
        data=     {
          "crops": {
            "summer": summer_list,
              "rabi": rabi_list,
                "kharif": kharif_list,
          },
          "applicantId": sessionStorage.getItem('applicantId'),
            "landArea": area,
          "district": landArray[0].district.toLowerCase()
        }
      }else if(state.toLowerCase() == constants.STATE_LIST.AP.toLowerCase()){
        data=     {
          "crops": {
            "summer": summer_list,
              "rabi": rabi_list,
                "kharif": kharif_list,
          },
          "applicantId": sessionStorage.getItem('applicantId'),
            "landArea": sessionStorage.getItem('landArea'),
       //   "landArea":0.98,
          "district": landArray[0].district.split('-')[0].trim().toLowerCase()
            //"district" : "tirupati"
        }
      }else if(state.toLowerCase() == constants.STATE_LIST.TEL.toLowerCase()){
        data=     {
          "crops": {
            "summer": summer_list,
              "rabi": rabi_list,
                "kharif": kharif_list,
          },
          "applicantId": sessionStorage.getItem('applicantId'),
            "landArea": landArray[0].landExtent,
          "district": landArray[0].districtName.toLowerCase()
        }
      }

      this.applicant.getProposedLoanAmount(data).subscribe(
        (resp) => {
          console.log(resp);

          if (resp && resp.success && resp.data.totalLoanAmount) {
            this.cropSelectionTick = true;
            this.disableSubmitCrops = true;
            const dialogRef = this.showDialog(
              "auto",
              "auto",
              // HindiText.LOAN_AMOUNT_CALCULATED.hindi,
              "",
              HindiText.LOAN_AMOUNT_CALCULATED.english,
              "OK",
              this.applicant.moreInfo(resp)
            );
            dialogRef.afterClosed().subscribe(() => {
              // this.initiateScroll(this.customerLoanAmountCardLayout)
            })
            this.estimatedLoanAmount = resp.data.totalLoanAmount;
            // this.multiCropDetailsForm.controls['loanAmtFarmer'].setValidators([Validators.required, Validators.max(this.estimatedLoanAmount)]);
            sessionStorage.setItem(
              "proposedLoanAmountResp",
              this.estimatedLoanAmount.toString()
            );
            this.loanAmountCalculationDone()

          } else {
            const dialogRef = this.showDialog(
              "auto",
              "auto",
              // HindiText.FAILED_TO_CALCULATE_LA.hindi,
              "",
              HindiText.FAILED_TO_CALCULATE_LA.english,
              "OK",
              this.applicant.moreInfo(resp)
            );
          }
        },
        (err) => {
          const dialogRef = this.showDialog(
            "auto",
            "auto",
            HindiText.FAILED_TO_CALCULATE_LA.hindi,
            HindiText.FAILED_TO_CALCULATE_LA.english,
            "OK",
            ""
          );
          this.disableSubmitCrops = false;
        }
      );
    }catch(error){
      console.log(error)
    }

  }

  getLandArea(array){
    let total = 0
    array.forEach(element => {
      if(element && element.surveyNoArea){
        total = total +Number(element.surveyNoArea)
      }
    });

    return total;
  }




  getKharifList(data) {
    if (data && data.cropArrayKharif && data.cropArrayKharif.length > 0) {
      let kharif_list = [];
      let kharifCrops = data.cropArrayKharif;
      for (let i = 0; i < kharifCrops.length; i++) {
        if (
          kharifCrops[i] &&
          kharifCrops[i].kharif &&
          kharifCrops[i].kharif != ""
        ) {
          kharif_list.push(kharifCrops[i].kharif);
        }
      }
      return kharif_list;
    } else {
      console.log("no crops in this season")
      return []
    }
  }

  getRabiList(data) {
    if (data && data.cropArrayRabi && data.cropArrayRabi.length > 0) {
      let rabi_list = [];
      let rabiCrops = data.cropArrayRabi;
      for (let i = 0; i < rabiCrops.length; i++) {
        if (rabiCrops[i] && rabiCrops[i].rabi && rabiCrops[i].rabi != "") {
          rabi_list.push(rabiCrops[i].rabi);
        }
      }
      return rabi_list;
    } else {
      console.log("no crops in this season")
      return []
    }
  }

  getSummerList(data) {
    if (data && data.cropArraySummer && data.cropArraySummer.length > 0) {
      let summer_list = [];
      let summerCrops = data.cropArraySummer;
      for (let i = 0; i < summerCrops.length; i++) {
        if (
          summerCrops[i] &&
          summerCrops[i].summer &&
          summerCrops[i].summer != ""
        ) {
          summer_list.push(summerCrops[i].summer);
        }
      }
      return summer_list;
    } else {
      console.log("no crops in this season")
      return []
    }
  }

  showDialog(
    width: any,
    height: any,
    content1: string,
    content2: string,
    buttonText: string,
    errorText: string
  ) {
    let dialogRef = this.dialog.open(OpenDialogYesOrNo, {
      width: width,
      height: height,
      data: {
        content1: content1,
        content2: content2,
        button1Text: buttonText,
        errorText: errorText
      },
    });

    return dialogRef;
  }

  showDialogWithTwoButton(
    width: any,
    height: any,
    content1: string,
    content2: string,
    buttonText: string,
    button2Text: string
  ) {
    let dialogRefYesOrNo = this.dialog.open(OpenDialogYesOrNo, {
      width: width,
      height: height,
      data: {
        content1: content1,
        content2: content2,
        button1Text: buttonText,
        button2Text: button2Text,
      },
    });
    return dialogRefYesOrNo;
  }

  initiateScroll(el: ElementRef) {
    setTimeout(() => {
      console.log('---------')
      el.nativeElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 200)
  }

  sendSpinnerData() {
    let data = {
      showSpinner: this.showSpinner,
      spinnerWithMessage: this.showSpinnerWithMessage,
      subText: this.subText,
      stepper: this.stepper,
    };
    this.applicant.passValue(data);
  }

  deleteMultipleKhasraLandRecords() {
    let deleteLandRecObj = {};
    if (this.landDetailsArray.length == 0) {
      deleteLandRecObj = {
        appId: sessionStorage.getItem("mpApplicantId"),
        index: 0,
        type: "bhoomi",
      };
    } else {
      deleteLandRecObj = {
        appId: sessionStorage.getItem("mpApplicantId"),
        index: this.landDetailsArray.length,
        type: "bhoomi",
      };
    }

  }


  updateDocsData() {
    let data = {
      data: [
        {
          la: sessionStorage.getItem("loanAppRefID"),
        },
        {
          sl: sessionStorage.getItem("sanctionLetterRefID"),
        },
        {
          dm: sessionStorage.getItem("demandRefID"),
        },
        {
          ct: sessionStorage.getItem("continuityRefID"),
        },
        {
          hp: sessionStorage.getItem("hypoRefID"),
        },
        {
          nt: sessionStorage.getItem("negativeRefID"),
        },
        {
          it: sessionStorage.getItem("interestRefID"),
        },
        {
          dc: sessionStorage.getItem("declarationRefID"),
        },
        {
          ut: sessionStorage.getItem("undertakingRefID"),
        },
        {
          dp: sessionStorage.getItem("DPNRefID"),
        },
      ],
      appId: sessionStorage.getItem("mpApplicantId"),
    };
    this.applicant.updateDocsData(data).subscribe((res) => {
      console.log("updateDocsData---->", res);
      if (this.isNTB) {
        this.applicant.updateStatus();
      }
    });
  }



  updateNTBDocsData() {
    let data = {
      data: [
        {
          dp: sessionStorage.getItem("DPNRefID"),
        },

        {
          la: sessionStorage.getItem("loanAppRefID"),
        },
        {
          sl: sessionStorage.getItem("sanctionLetterRefID"),
        },
      ],
      appId: sessionStorage.getItem("mpApplicantId"),
    };
    this.applicant.updateDocsData(data).subscribe((res) => {
      console.log("updateDocsData---->", res);
      this.applicant.updateStatus();
    });
  }

  loanAmountCalculationDone(){
    this.croppingPatternDisabled = true;
    let data = {
      success:true,
      loanAmount:this.estimatedLoanAmount
    }

    this.value.emit(data)
  }


}
