
<wl-card heading="ಭೂ ದಾಖಲೆ - Land Record" [subheading1]="showLandVerifiedTick">

  <form novalidate [formGroup]="bhoomiForm" (submit)="verify(bhoomiForm.value)" *ngIf="showDropdowns"  >

    <!-- <md-progress-bar *ngIf="distFetch" mode="buffer"> Please Wait</md-progress-bar> -->

    <div >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="padding-top: 10px !important;text-align: center;">
          <label>ಜಿಲ್ಲೆ - District</label>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" >
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-select placeholder="ಜಿಲ್ಲೆ ಆಯ್ಕೆಮಾಡಿ - Select District" formControlName="distId"
              (selectionChange)="getTaluks($event.value, $event.name)" appearance="outline" [disabled]="districtReadOnly  || fetchDisabled">
              <mat-option *ngFor="let dst of districts" [value]="dst.value"
                >{{dst.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div><br>


    <!-- <md-progress-bar *ngIf="talukFetch" mode="buffer"> Please Wait</md-progress-bar> -->

    <div >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="padding-top: 10px !important;text-align: center;">
          <label>ತಾಲೂಕು - Taluk</label>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" >
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-select placeholder="ತಾಲೂಕನ್ನು ಆಯ್ಕೆ ಮಾಡಿ - Select Taluk" formControlName="talId"
              (selectionChange)="getHobli($event.value)" appearance="outline" [disabled]="fetchDisabled">
              <mat-option *ngFor="let tlk of taluks" [value]="tlk.value"
                >{{tlk.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div><br>


    <!-- <md-progress-bar *ngIf="hobliFetch" mode="buffer"> Please Wait</md-progress-bar> -->

    <div >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="padding-top: 10px !important;text-align: center;">
          <label>ಹೋಬಳಿ - Hobli</label>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" >
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-select placeholder="ಹೋಬಳಿ ಆಯ್ಕೆಮಾಡಿ - Select Hobli" formControlName="hobId"
              (selectionChange)="getVillage($event.value)" appearance="outline" [disabled]="fetchDisabled">
              <mat-option *ngFor="let hbl of hoblis" [value]="hbl.value"
                >{{hbl.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div><br>


    <!-- <md-progress-bar *ngIf="villageFetch" mode="buffer"> Please Wait</md-progress-bar> -->

    <div >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="padding-top: 10px !important;text-align: center;">
          <label>ಗ್ರಾಮ - Village</label>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" >

          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-select placeholder="ಗ್ರಾಮವನ್ನು ಆಯ್ಕೆಮಾಡಿ - Select Village" formControlName="village"
              (selectionChange)="setVillage($event.value)" appearance="outline" [disabled]="fetchDisabled">
              <mat-option *ngFor="let vlg of villages" [value]="vlg.value"
                >{{vlg.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <br>

    <div >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="padding-top: 10px !important;text-align: center;">
          <label>ಸರ್ವೆ ಸಂಖ್ಯೆ - Survey Number</label>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" >

          <mat-form-field style="width: 100%;" appearance="outline" [disabled]="fetchDisabled">
            <input type="text" matInput placeholder="ಸರ್ವೆ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ - Enter Survey Number" formControlName="surveyno" [readonly]="isSurveyNoReadOnly">
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- <md-progress-bar *ngIf="surnocFetch" mode="buffer"> Please Wait</md-progress-bar> -->
    <div *ngIf="showFetch" >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="padding-top: 10px !important;text-align: center;">
          <label>ಸುರ್ನೋಕ್ ಸಂಖ್ಯೆ - Surnoc No.</label>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" >

          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-select placeholder="ಸುರ್ನೋಕ್ ಆಯ್ಕೆಮಾಡಿ - Select Surnoc No." formControlName="surnoc"
              (selectionChange)="getHissa($event.value)" appearance="outline" [disabled]="fetchDisabled">
              <mat-option *ngFor="let surnoc of surnoc_num" [value]="surnoc.id"
                >{{surnoc.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <br>
    <!-- <md-progress-bar *ngIf="hissaFetch" mode="buffer"> Please Wait</md-progress-bar> -->
    <div *ngIf="showHissas" >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="padding-top: 10px !important;text-align: center;">
          <label>ಹಿಸ್ಸಾ ಸಂಖ್ಯೆ - Hissa No.</label>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" >

          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-select placeholder="ಹಿಸ್ಸಾ ಆಯ್ಕೆಮಾಡಿ - Select Hissa No." formControlName="hisNo"
              (selectionChange)="getPeriod($event.value)" appearance="outline" [disabled]="fetchDisabled">
              <mat-option *ngFor="let hissa of hissas" [value]="hissa.value"
                >{{hissa.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <br>
    <!-- <md-progress-bar *ngIf="showPeriods" mode="buffer"> Please Wait</md-progress-bar> -->

    <div *ngIf="showPeriods" >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="padding-top: 10px !important;text-align: center;">
          <label>ಅವಧಿ - Period</label>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" >

          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-select placeholder="ಅವಧಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ - Select Period" formControlName="rtcPeriod"
             appearance="outline" [disabled]="disablePeriods || fetchDisabled">
              <mat-option *ngFor="let period of rtc_period" [value]="period.value"
                >{{period.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!--<div *ngIf="showPeriods" >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" style="padding-top: 10px !important;text-align: center;">
          <label>ಭೂ ಪ್ರದೇಶದ - Land Area</label>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4" >

          <mat-form-field style="width: 100%;" appearance="outline">
            <input type="text" matInput placeholder="ಭೂ ಪ್ರದೇಶವನ್ನು ನಮೂದಿಸಿ - Enter Land Area" formControlName="landArea" [readonly]="isLandAreaReadOnly || fetchDisabled">
          </mat-form-field>
        </div>
      </div>
    </div>-->

    <div *ngIf="showFetch">

    </div>
    <br>
    <!-- <wl-button [disabled]="!bhoomiForm.valid">{{btnTxt}}</wl-button> -->
    <div style="text-align: center;">
      <button class="mybutton" [disabled]="!bhoomiForm.valid|| landDetailsArray.length >=5 || fetchDisabled">{{btnTxt}}</button>
    </div>

  </form>
  <br>
  <!-- <wl-progress [hidden]="isDataPending"></wl-progress> -->
  <div style="width: 100%" *ngIf="landDetailsArray.length > 0">
    <div style="
      width: 100%;
      padding-top: 3rem;
      display: grid;
      justify-content: center;
      overflow-x: auto;
    ">
      <table style="border: 1px solid black; width: 100%">
        <tr>
          <th style="padding:3px;border: 1px solid black; text-align: center">
            District
          </th>
          <th style="padding:3px;border: 1px solid black; text-align: center">
            Taluk
          </th>
          <th style="padding:3px;border: 1px solid black; text-align: center">
            Hobli
          </th>
          <th style="padding:3px;border: 1px solid black; text-align: center">
            Village
          </th>
          <th style="padding:3px;border: 1px solid black; text-align: center">
           Survey No.
          </th>
          <th style="padding:3px;border: 1px solid black; text-align: center">
            Hissa No.
           </th>
           <th style="padding:3px;border: 1px solid black; text-align: center">
            Period
           </th>

           <th style="padding:3px;border: 1px solid black; text-align: center" >
          <b>  Area</b> 

           </th>
           <th style="padding:3px;border: 1px solid black; text-align: center">
            View
           </th>
           <th style="padding:3px;border: 1px solid black; text-align: center">
            Delete
           </th>
           <th style="padding:3px;border: 1px solid black; text-align: center">
            Verify
           </th>
        </tr>
        <tr *ngFor="let totalLandRecords of landDetailsArray; let i = index;">
          <td style="padding:3px;border: 1px solid black; text-align: center">
            {{ totalLandRecords.district }}
          </td>
          <td style="padding:3px;border: 1px solid black; text-align: center">
            {{ totalLandRecords.taluk }}
          </td>
          <td style="padding:3px;border: 1px solid black; text-align: center">
            {{ totalLandRecords.hobli }}
          </td>
          <td style="padding:3px;border: 1px solid black; text-align: center">
            {{ totalLandRecords.village }}
          </td>
          <td style="padding:3px;border: 1px solid black; text-align: center">
            {{ totalLandRecords.surveyNo }}
          </td>
          <td style="padding:3px;border: 1px solid black; text-align: center">
            {{ totalLandRecords.hissaNo }}
          </td>
          <td style="padding:3px;border: 1px solid black; text-align: center">
            {{ totalLandRecords.period }}
          </td>
          <td style="padding:3px;border: 1px solid black; text-align: center" *ngIf="totalLandRecords.verified">
            {{ totalLandRecords.surveyNoArea }}
          </td>
          <td style="padding:3px;border: 1px solid black; text-align: center" *ngIf="!totalLandRecords.verified">

            -

          </td>
          <td style="padding:3px;border: 1px solid black; text-align: center">
            <button class="mybutton" (click)="openLR(i)">View</button>
          </td>
          <td style="padding:3px;border: 1px solid black; text-align: center">
            <button *ngIf="!totalLandRecords.verified" class="mybutton" (click)="deleteLR(i)">Delete</button>
            <p style="font-weight: bold;" *ngIf="totalLandRecords.verified">-</p>
          </td>
          <td style="padding:3px;border: 1px solid black; text-align: center">
            <button *ngIf="!totalLandRecords.verified" class="mybutton" (click)="openVerifyLandRecord(i)">Verify</button>
            <p style="font-weight: bold;" *ngIf="totalLandRecords.verified">Verified</p>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <br>

  <div style="text-align: center;">
    <button class="mybutton" [disabled]="landDetailsArray.length == 0 || landDetailsArray.length >5 || disableSubmit" (click)="submitLR()">ಭೂ ದಾಖಲೆಗಳನ್ನು ಸಲ್ಲಿಸಿ(Submit Land Records)</button>
  </div>

  <div class="loading-overlay" *ngIf="showSpinner">
    <div class="spinner"></div>
  </div>

</wl-card>
