import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartPageComponent } from './start-page/start-page.component';
import { BifurcationComponent } from './bifurcation/bifurcation.component';
import { LandingComponent } from './landing.component';
import { BankOrBcLoginComponent } from './bc-login/bank-or-bc-login/bank-or-bc-login.component';
import { BcBaseComponent } from './bc-login/bank-or-bc-login/bc-base/bc-base.component';
import { AssistedBifurcationComponent } from './assisted-bifurcation/assisted-bifurcation.component';
import { LoginMethodComponent } from '../login-method/login-method.component';
import { AuthGuard } from '../auth.guard';
import { InitiateAssistedComponent } from './assisted-bifurcation/initiate-assisted/initiate-assisted.component';
import { RenewalFlowComponent } from './renewal-flow/renewal-flow.component';
import { RenewalConsentComponent } from './renewal-flow/renewal-consent/renewal-consent.component';
import { RenewalStepsComponent } from './renewal-flow/renewal-steps/renewal-steps.component';


const routes: Routes = [
  {
    path: '', component: LandingComponent, children: [
      { path: 'accesstype',component: LoginMethodComponent},
      { path: 'start', component: StartPageComponent },
      { path: 'journey', component: BifurcationComponent },
      { path:'bc-login',component:BcBaseComponent},
      { path:'assisted-journey',canActivate:[AuthGuard],component:AssistedBifurcationComponent},
      { path:'renewal',canActivate:[AuthGuard],component:RenewalStepsComponent},
      { path:'renewal-consent',component:RenewalConsentComponent},
      { path:'',redirectTo:'accesstype',pathMatch:'full'},
      // { path:'assisted-journey',component:AssistedBifurcationComponent},
      //{path:'gst-authentication',component:GstComponent},
     
      {
        path:'initiate-assisted',component:InitiateAssistedComponent
      },
      { path:'',redirectTo:'accesstype',pathMatch:'full'}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingRoutingModule { }
